import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ApiService } from 'src/app/services/api.service';
import { StoreService as GlobalStoreService } from 'src/app/services/store.service';
import { MerchantItems } from 'src/app/models/items';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  addProductMethods = [
    { name: 'jiji', image: 'assets/images/logos/jiji.png', available: true },
    { name: 'jumia', image: 'assets/images/logos/jumia.svg', available: true },
    { name: 'konga', image: 'assets/images/logos/konga.png', available: true },
    { name: 'flutterwave', image: 'assets/images/logos/flutterwave.svg', available: true },
    { name: 'paystack', image: 'assets/images/logos/paystack.png', available: true },
    { name: 'amazon', image: 'assets/images/logos/amazon.png', available: false },
    { name: 'aliexpress', image: 'assets/images/logos/aliexpress.svg', available: false },
    { name: 'olist', image: 'assets/images/logos/olist.png', available: false },
    { name: 'ebay', image: 'assets/images/logos/ebay.png', available: false },
    { name: 'pinterest', image: 'assets/images/logos/pinterest.png', available: false },
  ];
  subscriptions: Subscription[] = [];
  inputToCreateItemComponent = null;
  modal = '';
  top = '';

  constructor(
    private router: Router,
    public _general: GeneralService,
    public _store: GlobalStoreService,
    public _auth: AuthService,
    private _api: ApiService
  ) {
  }

  getProductMethod(name) {
    return this.addProductMethods.find(i => i.name === name);
  }

  async ngOnInit() {
    if (!this._store.products.items) await this.fetchProducts();
    this.determineTop();
  }

  async fetchProducts() {
    try {
      const { data, ...meta } = await this._api.fetchAllItems().toPromise();
      this._store.setState('products', { ...this._store.products, items: data, meta }, true);
    } catch (error) {
      this._general.notify('error', 'Unable to fetch products');
    }
  }

  determineTop() {
    const { items: products } = this._store.products;
    const { other_platforms, instagram, online, whatsapp_no } = this._auth.merchant;

    if (whatsapp_no && !online) {
      this.top = 'whatsapp';
    } else if (!online) {
      this.top = 'setup-order';
    } else if (products && products.length === 1) {
      this.top = 'single-product';
    } else if (products && products.length > 1) {
      this.top = 'share';
    } else if (other_platforms) {
      this.top = 'other-platforms';
    } else if (instagram) {
      this.top = 'instagram';
    } else {
      this.top = 'default';
    }
  }

  finishOnboarding() {
    this._store.setShowOnboarding('dashboard', false);
    this.modal = '';
    document.getElementById('addProductAndShare').click();
  }

  shareProductLink(event: MerchantItems) {
    if (!this._auth.canShare) return this.showPendingSetup();
    const { item_link, description } = event;
    this.modal = '';
    const obj = { info: { description, link: item_link } };
    this._general.broadCastSocialShare(obj);
  }

  copyStoreLink() {
    if (!this._auth.canShare) return this.showPendingSetup();
    this._general.copyLink(this._auth.merchant.global_catalogue_link);
  }

  openSupportWhatsappLink() {
    const link = `https://wa.me/+2349053535333?text=Hello`;
    window.open(link, '_blank');
  }

  copyWhatsappLink() {
    if (!this._auth.canShare) return this.showPendingSetup();
    const link = `https://wa.me/+234${ this._auth.merchant.phone.substring(1) }?text=Hello`;
    this._general.copyLink(link);
  }

  createProductDone(product) {
    this.fetchProducts();
    this.router.navigate([`/products`], { queryParams: { id: product.id } });
  }

  showPendingSetup() {
    this.modal = '';
    this._general.showPendingSetup();
  }

  launchModal(modal) {
    this.modal = modal;
  }

  launchModalIfProfileComplete(modal) {
    if (!this._auth.canShare) return this.showPendingSetup();
    this.modal = modal;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => element.unsubscribe());
  }
}
