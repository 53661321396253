import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetForm: FormGroup;
  view = '';
  loading = false;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private router: Router,
    private _general: GeneralService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      emailOrPhone: ['', Validators.required]
    });

    this.resetForm = this.fb.group({
      new_password: ['', Validators.required],
      confirm_new_password: ['', Validators.required],
      token: ['', Validators.required]
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      const { identity } = params;
      if (identity) {
        this.forgotPasswordForm.get('emailOrPhone').setValue(identity);
        this.submitForgotPassword();
        this.view = 'loading';
      } else {
        this.view = 'identity';
      }
    });
  }

  isEmail(value) {
    return !parseInt(value, 10);
  }

  async submitForgotPassword() {
    const { emailOrPhone } = this.forgotPasswordForm.value;
    const payload = {
      type: this.isEmail(emailOrPhone) ? 1 : 2,
      identity: emailOrPhone
    };
    this.loading = true;
    try {
      await this._api.forgotPassword(payload);
      this.view = 'password';
    } catch (err) {
      this.view = 'identity';
      this._general.notify('error', err?.error?.message || `We couldn't send reset information to ${ emailOrPhone }. Please try again!`);
    }
    this.loading = false;
  }

  async submitResetForm() {
    const { token, new_password, confirm_new_password } = this.resetForm.value;
    if (new_password !== confirm_new_password) return this._general.notify('error', 'Passwords do not match');
    const { emailOrPhone } = this.forgotPasswordForm.value;
    const payload = {
      token,
      password: new_password,
      identity: emailOrPhone,
      type: `${ this.isEmail(emailOrPhone) ? 1 : 2 }`,
    };
    this.loading = true;
    try {
      const res = await this._api.resetPassword(payload);
      if (res) {
        this._general.notify('success', `${ res.data }, Login with your new password`);
        await this.router.navigate(['/login']);
      }
    } catch (err) {
      this._general.notify('error', err?.error?.message || `We couldn't send reset your password. Please try again`);
    }
    this.loading = false;
  }
}
