<div class="d-flex justify-content-between align-items-center mb-4">
  <div class="fw-bold text-primary font-weight-medium m-0">Sections</div>
  <button [disabled]="loading" (click)="saveSections()" class="btn btn-outline-success btn-xs">Save</button>
</div>
<div
  *ngFor="let section of sections"
  class="card shadow-none bg-off-white d-flex flex-row justify-content-between align-items-center py-3 px-4 mb-3 flex-wrap"
>
  <div class="d-flex align-items-center">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [id]="section.name" [(ngModel)]="section.enabled" [disabled]="section.required">
      <label class="form-check-label" [for]="section.name">
        {{ section.name.replace('_', ' ') | capitalize }}
      </label>
    </div>
  </div>
  <div *ngIf="section.columns" class="d-flex align-items-center">
    <span class="text-muted font-size-md me-3">No. of columns:</span>
    <div class="input-group input-group-sm w-auto d-flex align-items-center" style="flex-wrap: nowrap;">
      <div class="input-group-prepend">
        <button (click)="decrementColumns(section.name)" [disabled]="section.columns <= 2" class="btn btn-outline-secondary" type="button">
          -
        </button>
      </div>
      <input [(ngModel)]="section.columns" type="text" class="form-control override" style="max-width: 40px;min-width: 40px;" readonly>
      <div class="input-group-append">
        <button (click)="incrementColumns(section.name)" [disabled]="section.columns >= 5" class="btn btn-outline-secondary" type="button">
          +
        </button>
      </div>
    </div>
  </div>
</div>
