import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard-whatsapp-integration',
  templateUrl: './dashboard-whatsapp-integration.component.html',
  styleUrls: ['./dashboard-whatsapp-integration.component.scss']
})
export class DashboardWhatsappIntegrationComponent implements OnInit {
  @Output() modal = new EventEmitter();

  constructor(
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
  }

}
