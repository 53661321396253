<div *ngIf="!_auth.isProfileComplete" class="card p-0 mb-4">
  <div class="bg-light px-4 py-4 border-bottom d-md-none">
    <h5 class="title mb-1">
      Incomplete profile
    </h5>
    <p class="m-0">Please take a few seconds to complete your profile</p>
  </div>
  <nav (click)="showIncompleteProfile()" class="custom-nav-container py-4 py-md-5 bg-light border-bottom">
    <div id="bank_details" class="custom-nav">
      <div *ngIf="merchant?.completion_details.bank_details" class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant?.completion_details.bank_details" class="icon-container mx-auto mb-1">
        <i class="fas fa-money-check"></i>
      </div>
      <small class="text-muted mt-2">Bank Details</small>
    </div>
    <div id="business_details" class="custom-nav">
      <div *ngIf="merchant?.completion_details.business_details"
        class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant?.completion_details.business_details" class="icon-container mx-auto mb-1">
        <i class="fas fa-suitcase"></i>
      </div>
      <small class="text-muted mt-2">Store Details</small>
    </div>
    <div id="picture" class="custom-nav">
      <div *ngIf="merchant?.completion_details.picture" class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant?.completion_details.picture" class="icon-container mx-auto mb-1">
        <i class="fas fa-image"></i>
      </div>
      <small class="text-muted mt-2">Picture</small>
    </div>
    <div id="email_validated" class="custom-nav">
      <div *ngIf="merchant?.completion_details.email_validated"
        class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant?.completion_details.email_validated" class="icon-container mx-auto mb-1">
        <i class="fas fa-user-lock"></i>
      </div>
      <small class="text-muted mt-2">Verification</small>
    </div>
  </nav>

  <div class="d-none d-md-flex justify-content-between align-items-center p-4 p-md-5">
    <div>
      <h5 class="title mb-1">
        Incomplete profile
      </h5>
      <p>Please take a few seconds to complete your profile</p>
    </div>
    <button (click)="showIncompleteProfile()" type="button" class="btn btn-outline-secondary ms-3">
      Continue
    </button>
  </div>
</div>
