import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-settings-bank-partnership',
  templateUrl: './settings-bank-partnership.component.html',
  styleUrls: ['./settings-bank-partnership.component.css']
})
export class SettingsBankPartnershipComponent implements OnInit {
  @Output() done = new EventEmitter();
  @Input() merchant: MerchantProfileDetails;
  @Input() start: boolean;
  modal = '';
  view = 'home';
  code = '';
  loading = false;

  timer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  date = '07/12/2021';
  interval = null;

  constructor(
    public _general: GeneralService,
    private _api: ApiService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.startTimer();
    if (this.start && this._auth.merchant.partnership === 0) this.enablePartnership();
  }

  startTimer() {
    this.countdown(this.date);
    this.interval = setInterval(() => {
      this.countdown(this.date);
    }, 1000);
  }

  countdown(date) {
    const future = new Date(date);
    const now = new Date(Date.now());

    if (future < now) {
      this.timer = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
      return;
    }

    // @ts-ignore
    const diff = future - now;

    this.timer.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.timer.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.timer.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.timer.seconds = Math.floor((diff % (1000 * 60)) / 1000);
  }


  verify() {
    if (this.merchant.partnership === 1) {
      this.confirmDisablePartnership();
    } else {
      this.confirmEnablePartnership();
    }
  }

  async enablePartnership() {
    this.view = 'activating';
    this._api.activatePartnership().subscribe(val => {
      this.view = 'verify';
      this._general.notify('success', val.message);
    }, () => {
      this.view = 'home';
      this._general.notify('error', 'Could not send verification code, email address is possible incorrect, please try again');
    });
  }

  confirmEnablePartnership() {
    this.view = 'notifying';
    this.loading = true;
    this._api.confirmActivatePartnership(this.code).pipe(take(1)).subscribe((val: any) => {
      this.loading = false;
      this._general.notify('success', val.message);
      if (val.status) {
        this.done.emit();
        this._auth.setMerchant({ ...this.merchant, partnership: 1 });
        this.view = 'home';
      }
    }, (err) => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }

  async disablePartnership() {
    this.view = 'deactivating';
    this._api.deactivatePartnership().subscribe(val => {
      this.loading = false;
      this.view = 'verify';
      this._general.notify('success', val.message);
    }, () => {
      this.view = 'home';
      this.loading = false;
      this._general.notify('error', 'Could not send verification code, email address is possible incorrect, please try again');
    });
  }

  confirmDisablePartnership() {
    this.loading = true;
    this._api.confirmDeactivatePartnership(this.code).pipe(take(1)).subscribe((val: any) => {
      this.loading = false;
      this._general.notify('success', val.message);
      if (val.status) {
        this.done.emit();
        this.view = 'home';
        this._auth.setMerchant({ ...this.merchant, partnership: 0 });
      }
    }, (err) => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }
}
