import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { GeneralService } from 'src/app/services/general.service';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-view-a-repayment',
  templateUrl: './view-a-repayment.component.html',
  styleUrls: ['./view-a-repayment.component.css']
})
export class ViewARepaymentComponent implements OnInit {
  @Input() repayment: any;
  @Output() close = new EventEmitter<any>();
  inputCustomerDetails;
  partPayment: number;
  partPaymentForm: FormGroup;
  paymentHistoryForCurrentRepayment: any[] = [];
  schedule_ids: any[] = [];
  schedule_amount_paid = 0;
  request_id = undefined;
  inputsHoldingSchedules: HTMLInputElement[] = [];
  loading = false;
  spinner;
  partPaymentScheduleId: any;
  view = 'details';
  mobile = window.innerWidth < 768;
  generating = false;
  paymentLink: string = undefined;
  modal = '';
  carouselOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: false,
    items: 1,
    stagePadding: 30,
    margin: 15
  };
  itemsCarouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    margin: 10,
    dots: false,
    nav: true,
    navText: ['&#8249;', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      575: {
        items: 2
      },
      700: {
        items: 3
      }
    }
  };
  padding = [];

  public get card(): any {
    return JSON.parse(this.repayment.card) || null;
  }

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    public _general: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.partPaymentForm = this.fb.group({
      amount_paid: ['', Validators.required],
      date_paid: ['', Validators.required]
    });

    this.calculatePadding(this.repayment.items);
  }

  viewCustomerInfo() {
    const { full_name, email, gender } = this.repayment;
    this.inputCustomerDetails = { full_name, email, gender };
    this.modal = 'customer';
  }

  recordPayment() {
    this.partPayment = 0;
    this.modal = 'record-payment';
  }

  fetchPaymentHistory(event: Event = null) {
    if (event) (event.target as HTMLAnchorElement).textContent = 'Fetching ...';
    const { request_id } = this.repayment;
    this._api.getPaymentHistory({ request_id, page_number: 1 }).subscribe(val => {
      if (event) (event.target as HTMLAnchorElement).textContent = 'View';
      const { data } = val;
      this.paymentHistoryForCurrentRepayment = [];
      this.paymentHistoryForCurrentRepayment = [...data];
      this.modal = 'repayment-history';
    });
  }

  markAsPaid(event) {
    let formToSubmit: {};
    const target = event.target as HTMLButtonElement;
    target.innerText = 'SAVING...';
    target.disabled = true;
    this.spinner = true;
    if (this.schedule_ids.length > 1) {
      const schedule_ids = this.schedule_ids.join(',');
      const obj = {
        schedule_ids,
        schedule_amount_paid: this.schedule_amount_paid,
        request_id: this.request_id,
        schedule_id: 0
      };
      formToSubmit = { ...obj };
    } else {
      const obj = {
        schedule_id: this.schedule_ids[0],
        request_id: this.request_id
      };
      formToSubmit = { ...obj };
    }
    const arrayOfHttpRequests = this._api.markPaymentsAsPaid(formToSubmit);
    forkJoin(arrayOfHttpRequests).subscribe(
      val => {
        this.spinner = false;
        if (val.length === this.schedule_ids.length) {
          target.innerText = 'Save';
          target.disabled = false;
          this.schedule_ids = [];
          this.inputsHoldingSchedules = [];
          const { total_paid, total_balance } = val[val.length - 1];
          this.repayment.schedule = [];
          this.repayment.amount_left = total_balance;
          this.repayment.amount_paid = total_paid;
          const { data } = val[val.length - 1];
          this.repayment.schedule = [...data];
          this.modal = '';
          target.innerText = 'Save';
          target.disabled = false;
          this.request_id = undefined;
          this.schedule_amount_paid = undefined;
          this.partPaymentForm.reset();
          this.partPaymentForm.enable();
        }
      },
      err => {
        this.spinner = false;
        target.disabled = false;
        target.innerText = 'SAVE';
        this.inputsHoldingSchedules.forEach(e => (e.checked = false));
        this.schedule_ids = [];
        this.inputsHoldingSchedules = [];

        if (err instanceof HttpErrorResponse && err.status.toString().startsWith('4')) {
          return this._general.notify('error', 'Full payment cannot be made for this schedule because some part payment exist!');
        }
        this._general.notify('error', 'Oops! An Error occured. Please try again later!');
      }
    );
  }

  checkThisInput(event, id, repayment): void {
    const { target } = event;
    const input: HTMLInputElement = target.previousElementSibling;
    input.checked = !input.checked;
    this.schedule_ids.push(id);
    this.request_id = repayment.request_id;
    this.schedule_amount_paid += parseInt(repayment.amount, 10);
    this.inputsHoldingSchedules.push(input);
    if (!input.checked) {
      this.schedule_ids = this.schedule_ids.filter(_id => _id !== input.value);
      this.inputsHoldingSchedules = this.inputsHoldingSchedules.filter(
        inputElement => inputElement.value !== id
      );
    }
  }

  goBackToFirstDiv() {
    this.partPayment = 0;
    this.partPaymentForm.reset();
    this.partPaymentScheduleId = undefined;
  }

  modifyAmount(event: Event) {
    const input = event.target as HTMLInputElement;
    input.type = 'text';
    const res = this._general.putCommasInNumbers(input.value);
    input.value = res === 0 ? '' : res;
  }

  submitPartPayment(form: FormGroup, event) {
    const btn = (event.target as HTMLFormElement).querySelector('button');
    let amount_paid = form.value.amount_paid;
    const date_paid = form.value.date_paid;
    amount_paid =
      typeof amount_paid === 'string'
        ? amount_paid.replace(',', '').toString()
        : amount_paid.toString();
    // check date
    let lowestDate: string | Date = this.repayment.schedule[0].date;
    lowestDate = new Date(lowestDate);
    const userDate: string | Date = new Date(date_paid);
    if (userDate.getTime() < lowestDate.getTime()) {
      this._general.notify('warning',
        'Please enter a date within the range of payment schedule dates'
      );
      return;
    }
    btn.innerText = 'SAVING...';

    form.disable();
    const formToSubmit = {
      amount_paid,
      date_paid,
      schedule_id: this.partPaymentScheduleId
    };
    this._api.makePartPayment(formToSubmit).subscribe(val => {
      this.partPaymentScheduleId = undefined;
      const { total_paid, total_balance } = val;
      this.repayment.schedule = [];
      this.repayment.amount_left = total_balance;
      this.repayment.amount_paid = total_paid;
      const { data } = val;
      this.repayment.schedule = [...data];
      // this.showOrHideRepaymentModal('mfpSeven');
      this.close.emit(true);
    }, err => {
      form.enable();
      btn.innerText = 'Save';
      const { message } = err.error;
      if (err instanceof HttpErrorResponse && err.status.toString().startsWith('4')) {
        return this._general.notify('error', message);
      } else {
        this._general.notify('error', 'Oops something went wrong! Please try again later.');
      }
    });
  }

  async generatePaymentLink(event: Event) {
    const anc = event.target as HTMLAnchorElement;
    anc.style.pointerEvents = 'none';
    const prevText: string = anc.childNodes[0].textContent;
    this.generating = true;
    anc.childNodes[0].textContent = 'Generating...';
    const res = await this._api.generatePaymentLink(this.repayment.id);
    const newRes = await res.json();
    this.paymentLink = newRes.data;
    this.modal = 'payment-link';
    anc.childNodes[0].textContent = prevText;
    this.generating = false;
  }

  copyLink(event: Event) {
    const input = document.createElement('input');
    input.value = document.querySelector('.link').textContent;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    const prevText = (event.target as HTMLElement).textContent;
    (event.target as HTMLSpanElement).innerHTML = 'Copied';
    setTimeout(() => {
      (event.target as HTMLElement).innerText = prevText;
    }, 2500);
    input.remove();
  }

  calculatePadding(items: any[]) {
    const itemToMultiply: Partial<any> = {
      primary_picture: 'assets/images/svg/no-product.svg'
    };
    if (items.length % 3 === 1) {
      this.padding.push(itemToMultiply);
      this.padding.push(itemToMultiply);
    } else if (items.length % 3 === 2) {
      this.padding.push(itemToMultiply);
    }
  }
}
