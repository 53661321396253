<div class="bottom-nav d-flex d-md-none">
  <a [routerLink]="['/dashboard']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fa fa-home"></i></div>
    <small class="text">Home</small>
  </a>
  <a *ngIf="!_auth.whatsappOnly" [routerLink]="['/products']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fa fa-shopping-cart"></i></div>
    <small class="text">Products</small>
  </a>
  <a [routerLink]="['/requests']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fa fa-clipboard-list"></i></div>
    <small class="text">Orders</small>
  </a>
  <a *ngIf="!_auth.whatsappOnly" [routerLink]="['/payment']" [routerLinkActive]="['active']" class="bottom-nav-item">
    <div class="icon"><i class="fa fa-money-bill"></i></div>
    <small class="text">Payment</small>
  </a>
</div>
