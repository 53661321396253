import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GeneralService } from '../../services/general.service';
import { nPercentOf } from '../../lib/utils';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit, OnDestroy {
  @Output() refresh = new EventEmitter();
  @Input() online = false;

  priceMismatch = false;
  creditForm: FormGroup;
  newProductForm: FormGroup;
  customerForm: FormGroup;
  searching = false;
  query = '';
  storeItems = [];
  newItems = [];
  searchResults = [];
  croppedImage: any = null;
  imageChangedEvent: any = null;
  modal = '';
  submitting = false;
  link = '';
  termsAccepted = false;
  carouselOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false,
    items: 1
  };
  max = Math.max;
  upfrontValues: any[] = [];
  specifyUpfront: boolean;
  destroyObservables: Subscription[] = [];
  req_id = null;
  installments = [
    { value: '-1', text: '7', unit: 'Days' },
    { value: '-2', text: '15', unit: 'Days' },
    { value: '1', text: '1', unit: 'Month' },
    { value: '2', text: '2', unit: 'Month' },
    { value: '3', text: '3', unit: 'Month' },
    { value: '6', text: '6', unit: 'Month' },
  ];
  schedule = [];
  history: string[] = [];

  public get view(): string {
    return this.history[this.history.length - 1];
  }

  get items() {
    return [...this.newItems, ...this.storeItems];
  }

  get monthly_amt() {
    return (+this.creditForm.value.credit_amount / +this.creditForm.value.no_of_months || 0);
  }

  set newMonthly_amt(value) {
    this.creditForm.value.credit_amount = value;
  }

  get credit_amount() {
    return this.creditForm.get('credit_amount');
  }

  pushView(view: string) {
    if (this.history.includes(view)) {
      this.history = this.history.reduce((acc, v) => {
        if (!acc.includes(view)) {
          acc.push(v);
        }
        return acc;
      }, []);
    } else {
      this.history.push(view);
    }
  }

  popView() {
    this.history.pop();
  }

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _store: StoreService,
    private router: Router,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
    this.creditForm = this.fb.group({
      credit_amount: ['', Validators.required],
      no_of_months: ['', Validators.required],
      upfront_amt_option: ['0', Validators.required],
      upfront_amt: ['', Validators.required],
      sell_on_credit: ['0']
    });
    this.newProductForm = this.fb.group({
      primary_picture: ['', Validators.required],
      name: ['', Validators.required],
      price: ['', Validators.required],
      quantity: [1, Validators.required]
    });
    this.customerForm = this.fb.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/(^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$)/)]],
      phone_no: ['', [Validators.required, Validators.pattern(/(^[0]\d{10}$)/)]]
    });

    if (this._store.showOnboarding('createOrder')) {
      this.pushView('onboarding');
    } else {
      this.pushView('items-list');
    }
  }

  handleShowOnboardingChange(e) {
    this._store.setShowOnboarding('createOrder', !e.target.checked);
  }

  populateUpfrontValues(newCreditAmt?: number) {
    const { no_of_months, credit_amount } = this.creditForm.value;
    if (!no_of_months && !credit_amount) {
      return;
    }
    const values = [];
    if (+no_of_months > 0) {
      for (let i = 1; i <= Array(+no_of_months).slice(0, 3).length; i++) {
        if (i === 1) {
          values.push({
            amount: newCreditAmt
              ? newCreditAmt / +no_of_months
              : credit_amount / +no_of_months,
            label: 'Pay 1st month'
          });
        } else {
          values.push({
            amount: newCreditAmt
              ? (newCreditAmt / +no_of_months) * i
              : (credit_amount / +no_of_months) * i,
            label: `Pay up to ${ i } months`
          });
        }
      }
    }
    values.push({ amount: credit_amount / 2, label: 'Pay Half' });
    values.push({ amount: credit_amount / 4, label: 'Pay Quarter' });
    this.upfrontValues = values;
    this.specifyUpfront = false;
    this.creditForm.get('upfront_amt').setValue('0');
  }

  handleUpfrontChange(value) {
    if (value) {
      if (value === 'specify') {
        this.specifyUpfront = true;
      } else {
        this.specifyUpfront = false;
        this.creditForm.get('upfront_amt').setValue(value);
      }
    } else {
      this.creditForm.get('upfront_amt').setValue('0');
    }
  }

  submitNewProductForm() {
    this.newItems.push({
      id: this.items.length + 1,
      ...this.newProductForm.value
    });
    this.newProductForm.reset();
    this.newProductForm.get('quantity').setValue(1);
    this.pushView('items-list');
  }

  submitItems() {
    const price = this.items.reduce((acc, item) => acc + (+item.price * item.quantity), 0);
    if (this._auth.merchant.partnership === 0) {
      const { monthly_interest_rate } = this._auth.merchant;
      const interest = +price + ((+monthly_interest_rate / 100) * +price);
      this.creditForm.get('credit_amount').setValue(interest);
      this.pushView('sell-on-credit');
    } else {
      const upfront = nPercentOf(5, price);
      this.creditForm.get('credit_amount').setValue(price - upfront);
      this.creditForm.get('sell_on_credit').setValue('1');
      this.creditForm.get('no_of_months').setValue(3);
      this.creditForm.get('upfront_amt').setValue(upfront);
      this.pushView('customer');
    }
  }

  setSellOnCredit(sell: boolean) {
    if (sell) {
      const { max_credit_duration, partnership } = this._auth.merchant;
      this.creditForm.get('sell_on_credit').setValue('1');
      if (partnership) {
        this.selectInstallment(3);
      } else if (max_credit_duration) {
        this.selectInstallment(+max_credit_duration / 30);
      } else {
        this.pushView('credit-installment');
      }
      this.newItems = this.newItems.map(item => ({ ...item, sell_on_credit: 1 }));
      this.storeItems = this.storeItems.map(item => ({ ...item, sell_on_credit: 1 }));
    } else {
      this.creditForm.reset();
      const price = this.items.reduce((acc, item) => acc + (+item.price * item.quantity), 0);
      this.creditForm.get('credit_amount').setValue(price);
      this.creditForm.get('sell_on_credit').setValue('0');
      this.pushView('customer');
      this.newItems = this.newItems.map(item => ({ ...item, sell_on_credit: 0 }));
      this.storeItems = this.storeItems.map(item => ({ ...item, sell_on_credit: 0 }));
    }
  }

  selectInstallment(value) {
    this.creditForm.get('no_of_months').setValue(value);
    const { upfront_rate } = this._auth.merchant;
    if (upfront_rate) {
      const { credit_amount } = this.creditForm.value;
      const upfront = (+upfront_rate / 100) * +credit_amount;
      this.selectUpfront(upfront);
    } else {
      this.populateUpfrontValues();
      this.pushView('credit-upfront');
    }
  }

  selectUpfront(value) {
    this.creditForm.get('upfront_amt').setValue(value);
    this.pushView('customer');
  }

  incrementQuantity(id) {
    let updated = false;
    this.newItems = this.newItems.map(item => {
      if (item.id === id) {
        updated = true;
        return { ...item, quantity: +item.quantity + 1 };
      }
      return item;
    });
    if (!updated) {
      this.storeItems = this.storeItems.map(item => {
        if (item.id === id) {
          updated = true;
          return { ...item, quantity: +item.quantity + 1 };
        }
        return item;
      });
    }
  }

  decrementQuantity(id) {
    let updated = false;
    this.newItems = this.newItems.map(item => {
      if (item.id === id) {
        updated = true;
        return {
          ...item,
          quantity: +item.quantity === 1 ? item.quantity : --item.quantity
        };
      }
      return item;
    });
    if (!updated) {
      this.storeItems = this.storeItems.map(item => {
        if (item.id === id) {
          return {
            ...item,
            quantity: +item.quantity === 1 ? item.quantity : --item.quantity
          };
        }
        return item;
      });
    }
  }

  removeItem(id) {
    let removed = false;
    this.newItems = this.newItems.filter(item => {
      if (item.id === id) {
        removed = true;
        return false;
      }
      return true;
    });
    if (!removed) {
      this.storeItems = this.storeItems.filter(item => item.id !== id);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.modal = 'cropping';
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageCroppingDone() {
    this.newProductForm.get('primary_picture').setValue(this.croppedImage);
    this.modal = '';
  }

  search() {
    if (this.query) {
      this.searching = true;
      this._api.searchItems({ search: this.query, page_number: '1' }, true).subscribe(({ data }) => {
        this.searching = false;
        this.searchResults = data;
      }, err => {
        console.log({ err });
      });
    }
  }

  selectFromStore(item) {
    let exists = false;
    this.storeItems = this.storeItems.map(_item => {
      if (_item.id === item.id) {
        exists = true;
        return { ..._item, quantity: _item.quantity + 1 };
      }
      return _item;
    });
    if (!exists) {
      this.storeItems.push({ ...item, quantity: 1 });
    }
    this.pushView('items-list');
    this.searchResults = [];
    this.query = '';
  }

  createOrder() {
    const newItems = this.newItems.map(({ id: _, ...item }) => item);
    const { upfront_amt_option: __, ...creditProps } = this.creditForm.value;
    const { full_name, email, phone_no } = this.customerForm.value;
    const customer = { full_name, email, phone_no };
    const data = {
      ...creditProps,
      monthly_amt: this.monthly_amt,
      items: [...newItems, ...this.storeItems],
      ...customer
    };
    this.submitting = true;
    this._api.createInstoreOrder(data).subscribe(({ req_id }) => {
      this.refresh.emit();
      this.req_id = req_id;
      this.createSchedule(req_id);
      this.generateLink(req_id);
      this.pushView('share');
    }, () => {
      this.submitting = false;
    });
  }

  createSchedule(id) {
    if (this.schedule.length) {
      this._api.createSchedule(id, this.schedule).toPromise();
    }
  }

  computeSchedule() {
    if (this.creditForm.value.sell_on_credit === '0') {
      this.schedule = [];
      return;
    }
    const credit_amount = +this.creditForm.value.credit_amount;
    const no_of_months = +this.creditForm.value.no_of_months;
    const upfront_amt = +this.creditForm.value.upfront_amt;
    const monthly_amount = (credit_amount - upfront_amt) / no_of_months;
    const schedule = [];
    for (let i = 1; i <= no_of_months; i++) {
      const today = new Date(Date.now());
      const month = today.getMonth() + i + 1 < 12 ? today.getMonth() + i + 1 : today.getMonth() + i + 1 - 12;
      const year = today.getMonth() < month ? today.getFullYear() : today.getFullYear() + 1;
      const date = new Date(year, month, today.getDate());
      schedule.push({ date, serial: i, amount: monthly_amount });
    }
    this.schedule = schedule;
  }

  generateLink(id) {
    const url = `https://shop.creditclan.com/request/${ id }`;
    this._api.generateRequestLink(id, url).pipe(take(1)).subscribe(({ data }) => {
      this.link = data;
      this.submitting = false;
      this.pushView('share');
    }, () => {
      this.link = url;
      this.submitting = false;
    });
  }

  async shareLink() {
    try {
      await window.navigator['share']({
        text: 'Customer request link',
        title: 'Request',
        url: this.link
      });
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  copyLink() {
    this._general.copyLink(this.link);
  }

  viewRequest() {
    this.router.navigate(['/requests'], {
      queryParams: { id: this.req_id }
    });
  }

  ngOnDestroy() {
    this.destroyObservables.forEach(element => element.unsubscribe());
  }
}
