import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MerchantItems } from 'src/app/models/items';
import { debounceTime, take } from 'rxjs/operators';
import { fromEvent, Subscription, TimeoutError } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService as GlobalStoreService } from 'src/app/services/store.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import platforms from '../../lib/platforms';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('search') input: ElementRef;

  addProductMethods = platforms;
  products: MerchantItems[] = [];
  searched = {
    data: [],
    meta: null,
  };
  filter: { data: any[], meta: any, params: any } = {
    data: [],
    meta: null,
    params: null,
  };
  global = 0;
  subscriptions: Array<Subscription> = [];
  loading = false;
  fetching = false;
  mobile = window.innerWidth < 768;
  currentItem = null;
  modal = '';
  meta: any;

  constructor(
    public _general: GeneralService,
    private _api: ApiService,
    public _store: GlobalStoreService,
    public _auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {
  }

  public get items(): MerchantItems[] {
    if (this.searched.meta) return this.searched.data;
    else if (this.filter.meta) return this.filter.data;
    else return this.products;
  }

  onScroll() {
    if (this.searched.data.length) {
      if (this.searched.meta.next) this.loadMore();
      return;
    } else if (this.filter.data.length) {
      if (this.filter.meta.next) this.loadMore();
      return;
    } else if (this.meta.next) {
      this.loadMore();
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const request = this.products.find(r => `${ r.id }` === `${ params.id }`);
        if (request) {
          this.currentItem = request;
          this.modal = !this.mobile ? 'edit-product' : 'view-product';
        } else {
          this.fetchSingleProduct(params.id);
        }
      }
    });

    if (!this._store.products.items) {
      this.getAllItemsAddedByMerchant();
    }

    this.subscriptions.push(
      this._store.$products.subscribe({
        next: (products) => {
          if (!products.items) return;
          this.products = products.items;
          this.meta = products.meta;
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(1000)).subscribe((e: any) => {
        const query = e.target.value;
        if (query) {
          this.search(query);
        } else {
          this.searched = { data: [], meta: null };
        }
      })
    );
  }

  search(query, page_number = 1) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.searchItems({ search: query, page_number: `${ page_number }` }).subscribe(({ data, ...meta }) => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      if (page_number === 1) {
        this.searched.data = data;
      } else {
        this.searched.data = [...this.searched.data, ...data];
      }
      this.searched.meta = meta;
    }, (err) => {
      console.log({ err });
    });
  }

  async fetchSingleProduct(id) {
    try {
      const { data }: any = await this._api.getSingleProduct(id).toPromise();
      this.currentItem = data;
      this.modal = !this.mobile ? 'edit-product' : 'view-product';
    } catch (e) {
      await this.router.navigate([`/products`]);
    }
  }

  viewProduct(item: MerchantItems) {
    this.router.navigate([`/products`], { queryParams: { id: item.id } });
  }

  closeViewProduct() {
    this.currentItem = null;
    this.modal = '';
    this.location.back();
  }

  closeEditProduct() {
    if (!this.mobile) return this.closeViewProduct();
    this.modal = 'view-product';
  }

  createItemDone(product) {
    if (this.currentItem) {
      this.currentItem = product;
      this.modal = 'edit-product';
    } else {
      this.modal = '';
      this.viewProduct(product);
    }
  }

  getAllItemsAddedByMerchant() {
    this.loading = true;
    this._api.fetchAllItems().pipe(take(1)).subscribe(({ data, ...meta }) => {
      this._store.setState('products', { ...this._store.products, items: data, meta }, true);
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err instanceof TimeoutError) {
        this._general.notify('error', 'Network error. Please try again later');
      }
      this._general.notify('error', 'Oops! Something went wrong please try again later');
    });
  }

  loadMore() {
    if (this.fetching) return;
    if (this.searched.data.length) {
      return this.search(this.input.nativeElement.value, this.searched.meta.next);
    }
    if (this.filter.data.length) {
      return this.filterProducts(this.filter.meta.next);
    }
    this.fetching = true;
    this._api.fetchAllItems(`${ this.meta.next }`).pipe(take(1)).subscribe(({ data, ...meta }) => {
      this._store.setState('products', {
        ...this._store.products, items: [...this.products, ...data], meta
      }, true);
      this.fetching = false;
    }, () => {
      this.fetching = false;
      this._general.notify('error', 'Unable to fetch requests, please check your network and reload');
    });
  }

  handleFilterChange(e) {
    this.modal = '';
    const { id } = e.target;
    if (id === 'none') {
      this.filter.data = [];
      this.filter.meta = null;
      this.filter.params = null;
      return;
    }
    const params = {
      quantity: 0,
      recent: 0,
      views: 0,
      comments: 0,
      likes: 0,
      sold: 0,
      discount_order: 0,
    };
    if (id === 'quantity') params.quantity = 1;
    if (id === 'recent') params.recent = 1;
    if (id === 'views') params.views = 1;
    if (id === 'comments') params.comments = 1;
    if (id === 'sold') params.sold = 1;
    if (id === 'likes') params.likes = 1;
    if (id === 'discount_order') params.discount_order = 1;
    this.filter.params = params;
    this.filterProducts();
  }

  async filterProducts(page_number = 1) {
    const { id } = this._auth.merchant;
    const payload = { ...this.filter.params, merchant_id: id, page_number };
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    try {
      const { data, ...meta } = await this._api.filterItems(payload).toPromise();
      if (page_number === 1) {
        this.filter.data = data;
      } else {
        this.filter.data = [...this.filter.data, ...data];
      }
      this.filter.meta = meta;
    } catch (e) {
      console.dir(e);
    }
    this[page_number === 1 ? 'loading' : 'fetching'] = false;
  }

  shareLink() {
    this.modal = '';
    if (!this._auth.canShare) return this._general.showPendingSetup();
    if (window.navigator['share']) {
      window.navigator['share']({
        title: 'Store',
        text: `See my store for authentic things`,
        url: `${ this._auth.merchant.global_catalogue_link }`
      }).then(() => console.log('Successful share')).catch(error => console.log('Error sharing', error));
    } else {
      this._general.broadCastSocialShare({
        info: {
          description: 'Store',
          link: this._auth.merchant.global_catalogue_link,
          whatsapp_link: this._auth.merchant.whatsapp_link
        }
      });
    }
  }

  updateCurrentProduct(product) {
    this.currentItem = product;
    const items = this._store.products.items.map(i => i.id === product.id ? product : i);
    this._store.setState('products', { ...this._store.products, items }, true);
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((e: Subscription) => e.unsubscribe());
    }
  }
}
