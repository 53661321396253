import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InstagramResult, ModifiedInstagramData } from 'src/app/models/instagram-result';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import { AuthService } from 'src/app/services/auth.service';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile.js';
import { FormBuilder } from '@angular/forms';
import { ProductCategories } from 'src/app/models/categories';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})
export class InstagramComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @Input() merchant: MerchantProfileDetails;
  @Output() close = new EventEmitter();
  search = false;
  searching = false;
  next: string = undefined;
  userId: string = undefined;
  posts: Array<ModifiedInstagramData> = [];
  displayPosts: Array<ModifiedInstagramData> = [];
  error: string = undefined;
  selected = null;
  loading = '';
  fetching = false;
  view = 'initializing';
  username = '';
  query = '';
  confirmed = false;
  categories: Array<{ id: number; name: string }> = ProductCategories().data;
  images = [];
  page = {
    end_cursor: '',
    first_id: '',
    last_id: '',
    next: 0,
    pages: 0,
    end: false,
    total_count: 0
  };
  modal = '';
  countdown = 20;
  private interval = null;

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _general: GeneralService,
    private fb: FormBuilder,
    private _store: StoreService
  ) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  startCountDown() {
    this.countdown = 20;
    this.interval = setInterval(() => {
      if (this.countdown > 0) this.countdown--;
    }, 1000);
  }

  stopCountdown(cb?) {
    if (this.interval) clearInterval(this.interval);
    this.countdown = 0;
    if (cb) setTimeout(() => cb(), 1000);
  }

  change() {
    this.view = 'change';
    this.posts = [];
    this.displayPosts = [];
    this.page = {
      end_cursor: '',
      first_id: '',
      last_id: '',
      next: 0,
      pages: 0,
      end: false,
      total_count: 0
    };
    this.query = '';
    this.userId = null;
  }

  async initialize() {
    if (this.merchant.instagram) {
      this.username = `${ this.merchant.instagram }`.replace('https://www.instagram.com/', '');
      await this.getSavedPosts();
    } else {
      this.view = 'username';
    }
  }

  async handleUsernameChange() {
    this.persistUsername();
    await this.getSavedPosts();
  }

  async getSavedPosts(page_number = 1) {
    try {
      if (page_number === 1) this.loading = 'Please wait..';
      else this.fetching = true;
      const res = await this._api.getStoredInstagramData(this.username, page_number).toPromise<any>();
      const { data, stored_data_exists, user_id, ...page } = res;
      if (!stored_data_exists) {
        this.fetching = false;
        return await this.initFetch();
      }
      this.posts = [...this.posts, ...data];
      this.displayPosts = this.posts;
      this.userId = user_id;
      this.page = page;
      this.view = 'list';
    } catch (e) {
      this._general.notify('error', `We couldn't fetch any data for ${ this.username }`);
    }
    this.fetching = false;
    this.loading = '';
  }

  async loadMore() {
    if (this.page.next) await this.getSavedPosts(this.page.next);
    else if (this.page.end_cursor) {
      await this.continueFetch(this.page.end_cursor);
    }
  }

  async initFetch() {
    this.loading = 'Fetching posts..';
    try {
      const res = await this._api.getInstagramId(this.username);
      this.userId = res.id;
      this.startCountDown();
      const data = await this.fetchPosts();
      const posts = await this.savePosts(data);
      this.stopCountdown(() => {
        this.posts = posts;
        this.displayPosts = posts;
        this.view = 'list';
      });
    } catch (error) {
      this.view = 'username';
      this._general.notify('error', `We couldn't fetch any data for ${ this.username }`);
    }
    this.loading = '';
  }

  async continueFetch(next) {
    this.fetching = true;
    try {
      const data = await this.fetchPosts(next);
      this.posts = await this.savePosts(data);
      this.displayPosts = this.posts;
    } catch (error) {
      this._general.notify('error', `We couldn't fetch any data for ${ this.username }`);
    }
    this.fetching = false;
  }

  async fetchPosts(next = '', posts = []) {
    if (posts.length && !next) return { posts, next };
    if (posts.length >= 20) return { posts, next };
    try {
      const data = await this._api.getInstagramPosts(this.userId, next);
      const { posts: _posts, next: _next } = this.parseResponse(data);
      return { posts: _posts, next: _next };
    } catch (error) {
      if (posts.length) return { posts, next };
    }
  }

  async refresh() {
    this.loading = `Fetching most recent posts from ${ this.username }`;
    this.startCountDown();
    try {
      const data = await this.fetchRecent();
      this.posts = await this.savePosts(data, false);
      this.displayPosts = this.posts;
    } catch {
      this._general.notify('error', `We couldn't fetch any data for ${ this.username }`);
    }
    this.stopCountdown(() => {
      this.loading = '';
    });
  }

  async fetchRecent(next = '', posts = [], failed = 0) {
    const exists = posts.reduce((acc, post) => {
      if (this.posts.find(p => p.id === post.id)) acc = true;
      return acc;
    }, false);
    if (exists) {
      posts = posts.filter(post => this.posts.find(p => p.id !== post.id));
      return { posts, next };
    }
    try {
      const data = await this._api.getInstagramPosts(this.userId, next);
      const { posts: _posts, next: _next } = this.parseResponse(data);
      return this.fetchRecent(_next, [...posts, ..._posts], failed);
    } catch (e) {
      if (failed === 1) return { posts, next };
      else return this.fetchRecent(next, [...posts], ++failed);
    }
  }

  async savePosts({ next, posts }, append = true) {
    const payload = {
      username: this.username,
      user_id: this.userId,
      first_id: posts[0].id,
      last_id: posts[posts.length - 1].id,
      end_cursor: append ? next : this.page.end_cursor,
      media: append ? [...this.posts, ...posts] : [...posts, ...this.posts]
    };
    await this._api.saveInstagramData(payload).toPromise();
    this.page.end_cursor = payload.end_cursor;
    return payload.media;
  }

  persistUsername() {
    const { instagram } = this.merchant;
    if (instagram && instagram.replace('https://www.instagram.com/', '') === this.username) {
      return;
    }
    const payload = { instagram: `https://www.instagram.com/${ this.username }` };
    this._api.socialMediaUpdate(payload).subscribe(({ message }) => {
      this._general.notify('success', message);
      this._auth.setMerchant({ ...this.merchant, ...payload });
    }, () => {
      this._general.notify('error', 'Could not save instagram handle');
    });
  }

  handleSearch() {
    const query = this.query;
    if (query.length) {
      this.displayPosts = this.posts.filter(p => {
        return p.description && p.description.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.displayPosts = this.posts;
    }
  }

  stopSearch() {
    this.query = '';
    this.search = false;
    this.displayPosts = this.posts;
  }

  parseResponse(response) {
    const posts: ModifiedInstagramData[] = [];
    const { edges, page_info } = response;
    if ((edges as Array<any>).length > 0) {
      (edges as Array<any>).forEach((current) => {
        const { node } = current;
        if (!(node as InstagramResult).is_video) {
          const obj: ModifiedInstagramData = {
            images: node.edge_sidecar_to_children
              ? (node.edge_sidecar_to_children.edges as Array<any>).map(e => e.node.display_url)
              : [node.thumbnail_src],
            description: node.edge_media_to_caption.edges.length
              ? node.edge_media_to_caption.edges[0].node.text
              : '',
            id: node.id,
          };
          posts.push(obj);
        }
      });
    }
    const next = page_info.has_next_page ? page_info.end_cursor : '';
    return { posts, next };
  }

  select(item: ModifiedInstagramData) {
    let priceMatch = item.description?.match(/((NGN|N) ?(\d+,?){2,})/g) || '';
    if (!priceMatch) priceMatch = item.description?.match(/\d+k/gi) || '';
    if (!priceMatch) priceMatch = item.description?.match(/(\d+,?){5,}/gi) || '';
    if (!priceMatch) priceMatch = item.description?.match(/(\d+,?){4,}/gi) || '';
    let price = (priceMatch && priceMatch[0]) ? priceMatch[0] : '';
    if (price) price = price.replace(/₦|NGN|N|,| /gi, '');
    if (price) price = price.replace(/k/gi, '000');
    this.selected = {
      name: item.description?.split(' ').slice(0, 10).join(' ') ?? '',
      price,
      images: [...item.images].splice(0, 5),
      description: item.description,
      id: item.id
    };
    this.view = 'create-product';
  }

  closeCreateProduct() {
    this.selected = null;
    this.view = 'list';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
