import { Component, Input, OnInit } from '@angular/core';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-homepage-banner',
  templateUrl: './settings-homepage-banner.component.html',
  styleUrls: ['./settings-homepage-banner.component.scss']
})
export class SettingsHomepageBannerComponent implements OnInit {
  @Input() merchant: MerchantProfileDetails;
  adding = false;
  loading = false;
  new_banner = {
    id: '',
    title: '',
    subtitle: '',
    image_file: null,
    image_preview: null
  };
  deleting = [];

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  handleBannerImageChange(event: Event) {
    if (event.target['files'][0].size > 5000000) {
      return this._general.notify('warning', 'Please upload pictures less than 5.0MB');
    }
    this.new_banner.image_file = event.target['files'][0];
    let reader: FileReader;
    if (FileReader) {
      reader = new FileReader();
      reader.onload = e => {
        this.new_banner.image_preview = `${ e.target['result'] }`;
      };
      reader.readAsDataURL(event.target['files'][0]);
    }
  }

  async addBanner() {
    this.loading = true;
    const fd = new FormData();
    fd.append('title', this.new_banner.title);
    fd.append('subtitle', this.new_banner.subtitle);
    fd.append('image', this.new_banner.image_file);
    const res = await this._api.addBanner(fd).toPromise<any>();
    this._general.notify('success', res.message || 'Saved');
    const banner_slides = [...this._auth.merchant.banner_slides, res.banner];
    this._auth.updateMerchant({ banner_slides });
    this.loading = false;
    this.adding = false;
    Object.keys(this.new_banner).forEach(key => this.new_banner[key] = null);
  }

  async editBanner() {
    console.log('Saving');
  }

  async deleteSlide(slide) {
    this.deleting.push(slide.id);
    await this._api.deleteBanner(slide.id).toPromise<any>();
    const banner_slides = this._auth.merchant.banner_slides.filter(s => s.id !== slide.id);
    this._auth.updateMerchant({ banner_slides });
    this.deleting = this.deleting.filter(id => id !== slide.id);
  }

  editSlide(slide: any) {
    this.cancelAdd();
    const { title, subtitle, image: image_preview, id } = slide;
    this.new_banner = { title, subtitle, id, image_preview, image_file: '' };
    this.adding = true;
  }

  cancelAdd() {
    this.adding = false;
    this.new_banner = {
      id: '',
      title: '',
      subtitle: '',
      image_file: null,
      image_preview: null
    };
  }
}
