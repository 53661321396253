import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';

@Component({
  selector: 'app-incomplete-profile-display',
  templateUrl: './incomplete-profile-display.component.html',
  styleUrls: ['./incomplete-profile-display.component.css']
})
export class IncompleteProfileDisplayComponent implements OnInit, OnDestroy {
  profileCompletionScore = 0;
  completedStages: string[] = [];
  destroyObservables: Subscription;
  subscriptions: Subscription[] = [];
  formOfContactToBeVerified: string;
  merchant: MerchantProfileDetails = null;

  constructor(
    private _general: GeneralService,
    public _auth: AuthService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this._auth.$merchant.subscribe({
        next: merchant => {
          this.merchant = merchant;
        }
      })
    );
  }

  showIncompleteProfile() {
    this._general.showPendingSetup();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
