<div class="card mb-4">
  <div class="row">
    <div class="col-sm-8">
      <h5 class="title mb-2">Social Media Accounts</h5>
      <p style="max-width: 600px;" class="m-0 mb-5">
        Specify your social media accounts
      </p>
    </div>
  </div>

  <form [formGroup]="socialMediaForm" class="m-0">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group required-field mt-3">
          <label for="ig"><span class="me-2"><i class="fab text-danger fa-instagram"></i></span>Instagram</label>
          <input formControlName="instagram" type="text" class="form-control override mt-2" id="ig" placeholder="https://www.instagram.com/username">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group required-field mt-3">
          <label for="fb"><span class="me-2"><i class="fab text-primary fa-facebook"></i></span> Facebook</label>
          <input formControlName="facebook" type="text" class="form-control override mt-2" id="fb" placeholder="https://www.facebook.com/profile">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group required-field mt-3">
          <label for="tw"><span class="me-2"><i class="fab text-info fa-twitter"></i></span>Twitter</label>
          <input formControlName="twitter" type="text" class="form-control override mt-2" id="tw" placeholder="https://www.twitter.com/profile">
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button (click)="submitSocialMedia(socialMediaForm, $event)" class="btn btn-success">Save</button>
    </div>
  </form>
</div>
