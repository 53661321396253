import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IInitializeMBSPayload } from 'src/app/models/api';
import { ApiService } from 'src/app/services/api.service';
import banks from '../../lib/mbs-banks';

@Component({
  selector: 'app-credit-analysis-bank-statement',
  templateUrl: './credit-analysis-bank-statement.component.html',
  styleUrls: ['./credit-analysis-bank-statement.component.css']
})
export class CreditAnalysisBankStatementComponent implements OnInit {

  verifyForm: FormGroup;
  @Output() back = new EventEmitter();
  @Input() request;

  transaction_id = null;
  view = '';
  loading = false;
  settings: any = {};
  error = '';
  analysis: any;

  constructor(
    private _api: ApiService,
    private fb: FormBuilder
  ) {
  }

  async ngOnInit() {
    if (this.request.account_number && this.request.bank_code) {
      this.loading = true;
      await this.getSettings();
      this.loading = false;
      this.view = '';
      this.start();
    } else {
      this.view = 'no-account';
    }

    this.verifyForm = this.fb.group({
      ticketNo: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async getSettings() {
    this.settings = await this._api.getLenderMBSSettings().toPromise();
  }

  start() {
    const startDate = this.formatDate(new Date(), 2);
    const endDate = this.formatDate(new Date());
    const bankId = banks.find(bank => bank.sortCode === this.request.bank_code).id;
    const data: IInitializeMBSPayload = {
      destinationId: this.settings.clientid,
      accountNo: this.request.account_number,
      bankId,
      country: 'NG',
      startDate,
      endDate,
      phone: this.request.phone_no,
      role: 'Applicant',
      username: 'support@creditclan.com',
      applicant: {
        name: this.request.account_name
      }
    };
    this.loading = true;
    this._api.intializeMBS(data).subscribe(res => {
      this.transaction_id = res.transaction_id;
      this.checkStatus();
    }, err => {
      this.loading = false;
      this.error = err.error.error || 'Failed to initialize statement request, please try again';
      this.view = 'error';
    });
  }

  async checkStatus() {
    try {
      const data = await this._api.checkMBSStatus(this.transaction_id).toPromise();
      if (data.status === 'Error') {
        this.error = data.feedback;
        this.view = 'error';
        this.loading = false;
      } else if (data.status === 'Pending') {
        setTimeout(() => {
          return this.checkStatus();
        }, 2000);
      } else if (data.status === 'Ticket') {
        this.view = 'verification';
        this.loading = false;
      }
    } catch (e) {
      this.error = e.message || 'An error occurred, please try again!';
      this.view = 'error';
      this.loading = false;
    }
  }

  submitVerifyForm() {
    const data = this.verifyForm.value;
    this.loading = true;
    this._api.confirmMBSRequest(this.transaction_id, data).subscribe(res => {
      if (res.completed) {
        this.view = '';
        return this.checkAnalysisStatus();
      }
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log({ err });
    });
  }

  checkAnalysisStatus(count = 0) {
    if (count === 30) {
      this.error = 'Couldnt not analyze bank statement';
      this.view = 'error';
      return;
    }
    this._api.checkAnalysisStatus(this.transaction_id).subscribe((data: any) => {
      if (!data.analytics_pdf) {
        return setTimeout(() => this.checkAnalysisStatus(++count), 2000);
      }
      this.getResult();
    }, () => {
      this.loading = false;
      this.error = `Couldn't not analyze bank statement`;
      this.view = 'error';
    });
  }

  getResult() {
    this.loading = true;
    this._api.getAnalysisResult(this.transaction_id).subscribe(({ analysis }: any) => {
      this.analysis = analysis;
      this.loading = false;
      this.view = 'analysis-complete';
    });
  }

  formatDate(date: Date, minus = 0) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth() - minus > -1 ? date.getMonth() - minus : 12];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${ day }-${ month }-${ year }`;
  }
}
