<div class="card mb-4">
  <div class="d-flex justify-content-between align-items-center mb-5">
    <h5 class="text-muted mb-0">Product tags</h5>
    <button type="button" class="btn btn-outline-secondary btn-sm ms-auto my-3" data-bs-toggle="collapse"
            data-bs-target="#addtag">
      <i class="fa fa-plus me-2"></i> New tag
    </button>
  </div>
  <div class="collapse" id="addtag">
    <form [formGroup]="tagForm" class="d-flex flex-row align-items-center mb-4" (submit)="addTag()">
      <div class="form-group m-0">
        <input type="text" class="form-control override" formControlName="name" id="tag-name"
               placeholder="Tag name">
      </div>
      <button [disabled]="loading" type="submit" class="btn btn-primary ms-2 btn-sm">Add</button>
    </form>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let tag of tags">
      <div class="card mb-4 shadow-none bg-off-white py-4">
        <i class="fa fa-tag text-muted" style="opacity: .8; font-size: 1.2rem;"></i>
        <span class="mt-3 font-size-md">
                    {{ tag.name }}
                </span>
      </div>
    </div>
  </div>
</div>
