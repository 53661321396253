import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { GeneralService } from './services/general.service';
import { StoreService } from './services/store.service';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  socialShareDetails: any;
  modal = '';

  constructor(
    private router: Router,
    public _general: GeneralService,
    private _auth: AuthService,
    private _store: StoreService,
    private _api: ApiService,
  ) {
  }

  ngOnInit() {
    if (window.location.href.match(/dashboard/gi)) {
      this.router.navigate(['/']);
    }

    this._general.pendingSetup$.subscribe(() => this.checkSetup());

    this._general.socialShare$.subscribe(val => {
      if (val) {
        this.socialShareDetails = val;
        this.modal = 'share';
      } else this.modal = '';
    });

    this.setVH();
    window.addEventListener('resize', () => this.setVH());
    this.getSummary();
  }

  setVH() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${ vh }px`);
  }

  async getSummary() {
    const res = await this._api.fetchProductsSummary().toPromise<any>();
    const products = { ...this._store.products, total: res.data.products_count };
    this._store.setState('products', products, true);
  }

  checkSetup(skip = '') {
    // if (!this._auth.merchant.sender_id && skip !== 'whatsapp') {
    //   this.modal = 'setup-whatsapp';
    //   return;
    // }
    if (!this._auth.isProfileComplete) {
      this.modal = 'profile-mgt';
      return;
    }
    if (!this._auth.isCreditSetup) {
      this.modal = 'welcome-settings';
      return;
    }
  }

  closeSetupAccount() {
    this.modal = '';
    if (this._auth.isAccountSetup && !this._auth.isCreditSetup) this.modal = 'welcome-settings';
  }

  closeShareModal() {
    this.socialShareDetails = null;
    this.modal = '';
  }
}
