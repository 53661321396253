<app-header [currentRoute]="'sales'"></app-header>

<app-default-layout>
  <div class="row">
    <div class="col-md-9">
      <nav class="card py-3 px-4 d-none d-md-flex justify-content-between align-items-center flex-row mb-4">
        <div class="d-inline-flex align-items-center">
          <form class="form-inline m-0">
            <div class="form-group m-0">
              <select name="orderby" class="form-control m-0">
                <option value="" selected>Sales by customer</option>
                <option value="date">Sales by items</option>
              </select>
            </div>
          </form>
        </div>

        <input #searchSalesBox style="width: 300px" type="search" class="form-control m-0" name="q" id="q"
               placeholder="Search..."/>
        <!-- End .toolbox-item -->
      </nav>

      <div *ngIf="noData" class="col-12 text-center">
        <img src="assets/images/Empty-rafiki.svg" class="mx-auto" style="max-width: 260px;" alt=""/>
        <h3 class="text-dark mb-0">
          No sales have been recorded
        </h3>
        <p class="mb-3 pt-2">
          Your sales will show here
        </p>
        <br>
        <br>
        <br>
        <br>
      </div>

      <div *ngIf="loading && !noData">
        <div *ngFor="let item of [1,2]; index as i" class="mb-3 mb-md-4">
          <div class="card d-flex flex-row align-items-center p-0">
            <div class="modal-img-transact-container skeleton fit-content" style="position: relative;">
              <img src="/assets/images/plus.svg" alt="img-trasact"/>
            </div>
            <div class="modal-transact-content flex-grow-1 ms-md-4 ps-1 pe-2 pe-md-4 pt-2 pb-2">
              <div class="d-flex flex-row align-items-center">
                <div class="skeleton fit-content me-3 ms-2 d-md-none">
                  <img src="/assets/images/plus.svg" alt="img-trasact" class="d-md-none rounded img-mobile"/>
                </div>
                <div class="flex-grow-1">
                  <h5 class="product-title mb-1 d-flex align-items-center skeleton">Lorem, ipsum.</h5>
                  <h5 class="product-title fw-normal mb-1 skeleton">Lorem, ipsum dolor.</h5>
                  <div class="mt-2 skeleton">Lorem.</div>
                </div>
              </div>

              <div class="d-none d-md-flex mt-4">
                <div class="parentImg skeleton fit-content">
                  <img src="/assets/images/plus.svg"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
                <div class="parentImg skeleton fit-content ms-2">
                  <img src="/assets/images/plus.svg"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
              </div>

              <div class="mt-md-4 d-none d-md-block">
                <button type="button" class="btn btn-outline-secondary btn-sm skeleton fit-content">
                  View details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let customer of allCustomers; index as i" class="mb-3 mb-md-4">
        <div class="card d-flex flex-row align-items-center p-0">
          <div class="modal-img-transact-container" style="position: relative;">
            <img [src]="customer?.pictureInView" alt="img-trasact"/>
          </div>
          <div class="modal-transact-content flex-grow-1 ms-md-4 px-2 pe-md-4 pt-3 pb-2">
            <div class="d-flex flex-row align-items-center">
              <img [src]="customer?.pictureInView" alt="img-trasact" style="width: 80px"
                   class="ms-2 me-3 d-md-none rounded img-mobile"/>
              <div>
                <div class="title fw-bold mb-1">
                  {{ customer?.full_name }}&nbsp;•&nbsp;{{ customer?.phone_no }}
                </div>
                <div class="title">
                  Date: {{ customer?.req_date | date: "mediumDate" }}
                </div>
                <div class="title">
                  ₦{{ customer?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}
                </div>
              </div>
            </div>

            <div class="mt-3 border-top border-bottom py-3 d-none d-md-block">
              <owl-carousel-o [options]="mySalesCarouselOptions">
                <ng-container *ngFor="let item of customer?.items; index as i">
                  <ng-template [width]="widthNumber" carouselSlide>
                    <div>
                      <img (click)="changePictureInView(customer, $event)" [src]="item?.primary_picture"
                           [alt]="item?.name" [title]="item?.name"
                           style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>

            <!-- <hr class="divider mb-0 my-2 d-md-none">

            <button (click)="viewSale(customer)" class="btn btn-outline-secondary mt-4 d-none d-md-inline-block">
              View details
            </button>

            <div class="text-center d-md-none">
              <button (click)="viewSale(customer)" class="btn btn-link btn-sm">
                View details
              </button>
            </div> -->
            <hr class="divider mt-0 mb-2 d-none d-md-block">

            <div class="mt-md-4 d-none d-md-block">
              <button (click)="viewSale(customer)" class="btn btn-outline-secondary btn-sm">
                View details
              </button>
            </div>

            <hr class="divider my-2 d-md-none">

            <div class="d-flex d-md-none align-items-center justify-content-around">
              <div class="flex-grow-1 text-center">
                <button (click)="viewSale(customer)" class="btn btn-link btn-sm me-2">
                  <i class="fa fa-clipboard-list me-1"></i>
                  View details
                </button>
              </div>
              <!-- <div class="flex-grow-1 text-center border-right border-left">
                <button (click)="viewSale(customer)" class="btn btn-link btn-sm" type="button">
                  <i class="fa fa-stream me-1"></i>
                  View items
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3 d-none d-md-flex">
      <div class="sticky-sidebar w-100">
        <div class="card mb-3 py-5 flex-column align-items-center text-center">
          <div class="h2 mb-2">{{ allCustomers.length }}</div>
          <div class="m-0 text-muted title">Total sales</div>
        </div>
        <div class="card p-0">
          <ul class="mobile-menu">
            <li class="border-bottom">
              <a (click)="_general.navigateTo('/requests')">
                <i class="icon fa fa-exclamation-circle text-danger"></i>Pending orders
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/repayments')">
                <i class="icon fa fa-luggage-cart text-primary"></i>Credit orders
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>


<app-dialog *ngIf="modal === 'details' && currentCustomer" (close)="modal = ''" size="lg">
  <div class="row">
    <div class="col-11 mx-auto">
      <div class="row mt-4">
        <div class="col-sm-5 pb-5">
          <div class="text-align avatar-container">
            <img
              [src]="currentCustomer?.modifiedCustomer?.picture || 'assets/images/placeholder-profile.png'"
              class="display-sm-inline mod-avatar" alt=""
            />
          </div>
        </div>
        <div class="col-sm-7">
          <div class="border-bottom mb-3 pb-3">
            <h5 class="title mb-1">Contact information</h5>
            <div class="title">
              {{ currentCustomer?.full_name }} / {{ currentCustomer?.phone_no }}
            </div>
          </div>

          <div class="border-bottom mb-3 pb-3">
            <h5 class="title mb-1">Product price</h5>
            <div class="title">
              ₦{{ currentCustomer?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}
            </div>
          </div>

          <div class="border-bottom mb-3 pb-3">
            <h5 class="title mb-1">Date of sale</h5>
            <div class="title">
              {{ currentCustomer?.created_at }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 ps-0 pe-0">
          <div class="row">
            <div *ngFor="let item of currentCustomer?.items; index as i" class="pt-0 col-12 col-sm-6 col-md-3">
              <figure class="m-0">
                <img [src]="item?.primary_picture"/>
              </figure>
              <div class="product-details p-3">
                <div class="title fw-bold mb-1">{{ item?.name }}</div>
                <div class="title">₦{{ item?.price | currency:'NGN':'₦':'0.0' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End .row -->
    </div>
  </div>
</app-dialog>

<!--<div id="mfpNine" class="modal modal-one">-->
<!--  <div class="product-single-container product-single-default product-quick-view wide">-->
<!--    <button (click)="showOrHide('mfpNine')" title="Close (Esc)" type="button" class="mfp-close">-->
<!--      <i class="fa fa-times"></i>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->
