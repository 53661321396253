import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { ILoanStages } from '../../models/api';
import { jsonToBase64 } from '../../lib/utils';

@Component({
  selector: 'app-loan-analysis',
  templateUrl: './loan-analysis.component.html',
  styleUrls: ['./loan-analysis.component.scss']
})
export class LoanAnalysisComponent implements OnInit, OnDestroy {
  listener = null;
  iframe: any;
  loading = '';
  @Input() stages: ILoanStages;

  constructor(
    public _auth: AuthService,
    private _api: ApiService,
    private _general: GeneralService
  ) {
    this.listener = this.handleMessage.bind(this);
  }

  ngOnInit(): void {
    window.addEventListener('message', this.listener);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.listener);
  }

  async handleMessage(e) {
    if (!e.data || !e.data.id) return;
    if (e.data.id === 'success' || e.data.id === 'cclan-success') {
      await this.addToCompleted(this.iframe.id);
      this.iframe.remove();
      this.loading = '';
      await this.saveCreditclanRequestId(e.data.request_id);
      await this.addToCompleted('data');
    }
    if (e.data.id === 'cancel' || e.data.id === 'cclan-cancel') {
      this.loading = '';
      this.iframe.remove();
    }
  }

  isCompleted(value) {
    return this._auth.merchant.loan.analysis_completed.includes(value);
  }

  launchDataCollectionWidget() {
    if (this.isCompleted('data')) return;
    const merchant = this._auth.merchant;
    const data = {
      loan: {
        amount: this._auth.merchant.loan.amount,
        tenor: this._auth.merchant.loan.duration,
      },
      profile: {
        picture: this._auth.merchant.picture || '',
        personal: {
          full_name: merchant.name,
          email: merchant.email,
          phone: merchant.phone,
          date_of_birth: '',
          gender: '',
          marital_status: '',
          nationality: '',
          state_of_origin: ''
        },
      },
      bank: {
        bank_id: `${ merchant.bank_id }`,
        bank_code: `0${ merchant.bank_code }`.slice(-3),
        account_name: `${ merchant.account_name }`,
        account_number: `${ merchant.account_number }`
      }
    };
    const forms = [
      'location',
      'profile',
      'frequently_called_numbers',
      'identity',
      'categories',
      'company_profile',
      'products_pictures',
      'sales_summary',
      'physical_store',
    ];
    const payload = {
      key: 'z2BhpgFNUA99G8hZiFNv77mHDYcTlecgjybqDACv',
      data, forms
    };
    const token = jsonToBase64(payload);
    const url = `https://cc-datawidget.netlify.app/?token=${ token }`;
    this.loading = 'data';
    this.launchIframe('data', url);
  }

  async saveCreditclanRequestId(creditclan_request_id) {
    await this._api.saveCreditclanRequestId(this._auth.merchant.loan.id, creditclan_request_id).toPromise();
    const loan = { ...this._auth.merchant.loan, creditclan_request_id };
    this._auth.updateMerchant({ loan });
  }

  async launchBankStatementWidget(has_online_banking = 0) {
    const id = this._auth.merchant.loan?.creditclan_request_id;
    if (!id || this.isCompleted('cashflow')) {
      return;
    }
    const { bank_id, account_name, account_number } = this._auth.merchant;
    if (!account_number || !account_name || !bank_id) {
      return;
    }
    const account = { bank_id: `${ bank_id }`, account_name, account_number };
    this.loading = 'cashflow';
    const data = await this._api.preBankStatementWidget(id, account, has_online_banking).toPromise<any>();
    if (data.status) {
      this.launchIframe('cashflow', data.url);
    } else {
      this._general.notify('error', data.error || data.message);
    }
    this.loading = '';
  }

  async launchRepaymentWidget() {
    const id = this._auth.merchant.loan?.creditclan_request_id;
    if (!id || this.isCompleted('repayment')) {
      return;
    }
    this.loading = 'repayment';
    const data = await this._api.preRepaymentWidget(id).toPromise<any>();
    this.launchIframe('repayment', data.url);
    this.loading = '';
  }

  launchIframe(id, url) {
    const iframe = document.createElement('IFRAME');
    iframe.setAttribute('src', url);
    iframe.setAttribute('allow', 'geolocation');
    const style = {
      'z-index': '999999',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      position: 'fixed',
      inset: 0,
      width: '100%',
      height: '100vh',
    };
    Object.assign(iframe.style, style);
    iframe.id = id;
    this.iframe = iframe;
    document.body.append(iframe);
  }

  async addToCompleted(value) {
    this.loading = value;
    const id = this._auth.merchant.loan.id;
    const analysis_completed = [...this._auth.merchant.loan.analysis_completed];
    analysis_completed.push(value);
    const res = await this._api.updateAnalysisCompleted(id, analysis_completed).toPromise<any>();
    this._auth.updateMerchant({ loan: res.data });
    this.loading = '';
  }
}
