import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-settings-social-accounts',
  templateUrl: './settings-social-accounts.component.html',
  styleUrls: ['./settings-social-accounts.component.css']
})
export class SettingsSocialAccountsComponent implements OnInit, OnChanges {
  socialMediaForm: FormGroup;
  @Input() merchant: MerchantProfileDetails;

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private fb: FormBuilder,
    private _auth: AuthService
  ) {
    this.socialMediaForm = this.fb.group({
      twitter: [''],
      facebook: [''],
      instagram: ['']
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { twitter, facebook, instagram } = changes.merchant.currentValue;
    this.socialMediaForm.patchValue({ twitter, facebook, instagram });
  }

  submitSocialMedia(form: FormGroup, event: Event) {
    const btn = event.target as HTMLButtonElement;
    btn.disabled = true;
    btn.textContent = 'Saving changes ....';
    this._api.socialMediaUpdate(this.socialMediaForm.value).pipe(take(1)).subscribe(({ message }) => {
      this._general.notify('success', message);
      this._auth.setMerchant({ ...this.merchant, ...form.value });
      btn.disabled = false;
      btn.textContent = 'Save';
    }, () => {
      this._general.notify('error', 'Sorry an error occured while trying to update your social media links!');
    });
  }
}
