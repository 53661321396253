<div class="card mb-4">
  <h5 class="title mb-5">Logo and Store Details</h5>
  <app-settings-profile-picture [merchant]="merchant"></app-settings-profile-picture>
  <form [formGroup]="updateProfileForm" (ngSubmit)="saveProfileData()" class="mb-0">
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-4 required-field">
          <label class="form-label" for="store-name">Store name</label>
          <input formControlName="name" type="text" class="form-control override" id="store-name">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-4 required-field">
          <label class="form-label" for="whatsapp_no">Whatsapp number</label>
          <input formControlName="whatsapp_no" type="tel" class="form-control override" id="whatsapp_no">
        </div>
      </div>
    </div>

    <div class="mb-4 required-field">
      <label class="form-label">Category of products sold</label>
      <select formControlName="business_type" class="form-select override">
        <option value="" selected>Choose...</option>
        <option [value]="business?.id" *ngFor="let business of businessTypes; index as i">
          {{ business?.value | titlecase }}
        </option>
      </select>
    </div>

    <div class="mb-4 required-field">
      <label class="form-label" for="store-address">Store address</label>
      <textarea formControlName="address" type="text" class="form-control override" id="store-address"></textarea>
    </div>

    <div class="mt-5">
      <button [disabled]="updateProfileForm.invalid || loading" class="btn btn-success">
        Save
      </button>
    </div>
  </form>
</div>
