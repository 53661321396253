<div class="card mt-4 mb-3 text-center">
  <div class="row">
    <div class="col-md-4">
      <i class="icon-pencil text-primary" style="font-size: 3rem;"></i>
      <div class="mt-1">
        <h5 class="title m-0">Create an Item</h5>
        <div>Add more items</div>
      </div>
    </div>

    <div class="col-md-4 py-5 py-md-0">
      <i class="icon-export text-primary" style="font-size: 3rem;"></i>
      <div class="mt-1">
        <h5 class="title m-0">Copy &amp; Share link</h5>
        <div>Share on websites and social media</div>
      </div>
    </div>

    <div class="col-md-4">
      <i class="icon-reply text-primary" style="font-size: 3rem;"></i>
      <div class="mt-1">
        <h5 class="title m-0">Get Repayment</h5>
        <p>Seamlessly &amp; Stressfree</p>
      </div>
    </div>

  </div>
</div>