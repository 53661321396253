import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MerchantItems } from 'src/app/models/items';
import { GeneralService } from 'src/app/services/general.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthService } from 'src/app/services/auth.service';
import { StoreService } from 'src/app/services/store.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { clothSizes, genders, shippingSources, shoeSizesEuro, shoeSizesUK } from 'src/app/lib/variations';
import genericTags from '../../lib/tags';
import { populateUpfrontValues, resetAndClearCreditValidators } from '../../lib/utils';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css']
})
export class CreateItemComponent implements OnInit, OnDestroy {
  @ViewChild('pictureInput') pictureInput: ElementRef;

  @Input() inputFromAllItems: MerchantItems;
  @Output() shareAProductLink = new EventEmitter();
  @Output() done = new EventEmitter();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Product description',
    defaultParagraphSeparator: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontSize', 'fontName', 'textColor', 'backgroundColor'],
      ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
      ['subscript', 'superscript', 'heading', 'indent', 'outdent']
    ]
  };

  primary_picture = '';
  secondary_picture = '';
  tertiary_picture = '';
  fourth_picture = '';
  fifth_picture = '';
  pictureInView = '';
  rawImages = {};
  productCategories: Array<{ id: number; name: string }> = [];
  subcategories: Array<{ id: number; name: string }> = [];
  productForm: FormGroup;
  subscriptions: Subscription[] = [];
  activePicture = '';
  imageChangedEvent: any;
  croppedImage: any;
  upfrontValues = [];
  specifyUpfront: boolean;
  installments = [
    { value: '-1', text: '7', unit: 'Days' },
    { value: '-2', text: '15', unit: 'Days' },
    { value: '1', text: '1', unit: 'Month' },
    { value: '2', text: '2', unit: 'Month' },
    { value: '3', text: '3', unit: 'Month' },
    { value: '6', text: '6', unit: 'Month' },
  ];
  clothSizes = clothSizes;
  shoeSizesEuro = shoeSizesEuro;
  shoeSizesUK = shoeSizesUK;
  genders = genders;
  sources = shippingSources;
  loading: boolean;
  history: string[] = [];
  modal = '';
  selectedSizes = [];
  selectedGenders = [];
  selectedTags = [];
  tagForm: FormGroup;
  addingTag = false;
  tags = [];
  genericTags = [];
  creating = false;

  public get view(): string {
    return this.history[this.history.length - 1];
  }

  constructor(
    public _general: GeneralService,
    private fb: FormBuilder,
    private _api: ApiService,
    public _auth: AuthService,
    private _store: StoreService
  ) {
  }

  getCategories() {
    this._api.getCategories().pipe(take(1)).subscribe((res: any) => {
      this.productCategories = res.data;
    });
  }

  getSubcategories() {
    this._api.getSubcategories(this.productForm.value.category_id).pipe(take(1)).subscribe((res: any) => {
      this.subcategories = res.data;
    });
  }

  pushView(view: string) {
    this.history.push(view);
  }

  popView() {
    this.history.pop();
  }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      category_id: ['', Validators.required],
      sub_category_id: [''],
      price: ['', Validators.required],
      credit_price: '',
      sell_on_credit: '',
      monthly_amount: '',
      no_of_months: '',
      upfront_amount_option: '',
      upfront_amount: '',
      description: '',
      discount_price: '',
      discount_expiry_date: '',
      quantity: '1',
      minimum_order: '',
      maximum_order: '',
      sizes: '',
      genders: '',
      tag_id: '',
      shipping_source: '0'
    });

    this.tagForm = this.fb.group({
      name: ['', Validators.required]
    });

    if (this._auth.merchant.partnership === 1) resetAndClearCreditValidators(this.productForm);

    this.pushView('picture');
    this.getTags();
    this.getCategories();
  }

  submitProductDetails() {
    if (this._auth.merchant.partnership === 1) {
      this.setSellOnCredit('0');
    } else {
      this.pushView('credit-option');
    }
  }

  handleUpfrontChange(e) {
    const { no_of_months, credit_price } = this.productForm.value;
    let value;
    try {
      value = e.target.value;
    } catch (error) {
      value = e;
      if (isNaN(value)) {
        this.specifyUpfront = true;
        this.productForm.get('upfront_amount').setValue(value);
        return;
      }
      this.specifyUpfront = false;
      this.productForm.get('upfront_amount').setValue(value);
    }
    this.productForm.get('monthly_amount').setValue((+credit_price - +value) / +no_of_months);
    if (value) {
      if (value === 'specify') {
        this.specifyUpfront = true;
      } else {
        this.specifyUpfront = false;
        this.productForm.get('upfront_amount').setValue(value);
      }
    } else {
      this.productForm.get('upfront_amount').setValue('');
    }
  }

  setSellOnCredit(value: string) {
    this.productForm.get('sell_on_credit').patchValue(value);
    if (value === '1') {
      const { max_credit_duration, upfront_rate, monthly_interest_rate: rate = 0 } = this._auth.merchant;
      const { price, no_of_months } = this.productForm.value;
      if (!rate) return this.pushView('credit-price');
      const credit_price = +price + ((+rate / 100) * +price);
      this.productForm.get('credit_price').setValue(credit_price);
      if (!max_credit_duration) return this.pushView('credit-price');
      const months = max_credit_duration / 30;
      this.productForm.get('no_of_months').setValue(`${ months }`);
      if (!upfront_rate) {
        this.upfrontValues = populateUpfrontValues(no_of_months, credit_price);
        return this.pushView('credit-upfront');
      }
      const upfront = (+upfront_rate / 100) * +credit_price;
      this.productForm.get('upfront_amount').setValue(upfront);
      this.validateCredit();
    } else {
      resetAndClearCreditValidators(this.productForm);
      this.pushView('product-details-more');
    }
  }

  handleCreditChange() {
    const { no_of_months, credit_price } = this.productForm.value;
    this.upfrontValues = populateUpfrontValues(no_of_months, credit_price);
  }

  selectInstallment(value) {
    this.productForm.get('no_of_months').setValue(value);
    this.handleCreditChange();
    this.pushView('credit-upfront');
  }

  selectUpfront(value) {
    this.handleUpfrontChange(value);
    this.validateCredit();
  }

  addPicture(key) {
    this.activePicture = key;
    if (this.pictureInput) {
      this.pictureInput.nativeElement.click();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  croppingDone() {
    this[this.activePicture] = this.croppedImage;
    this.rawImages[this.activePicture] = this.imageChangedEvent.target.files[0];
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.modal = '';
  }

  cancelCropping() {
    this.rawImages[this.activePicture] = null;
    this.croppedImage = undefined;
    this.modal = '';
  }

  startCropping(event: any) {
    if (!event.target.files.length) {
      return;
    }
    this.imageChangedEvent = event;
    this.modal = 'crop';
  }

  validateCredit() {
    this.pushView('product-details-more');
  }

  async createItem(form: FormGroup) {
    if (this.productForm.value.sell_on_credit.toString() === '1') this.setMonthlyAmount();
    const payload = {
      ...form.value,
      primary_picture: await this.resolveImage(this.primary_picture, 'primary_picture'),
      secondary_picture: await this.resolveImage(this.secondary_picture, 'secondary_picture'),
      tertiary_picture: await this.resolveImage(this.tertiary_picture, 'tertiary_picture'),
      fourth_picture: await this.resolveImage(this.fourth_picture, 'fourth_picture'),
      fifth_picture: await this.resolveImage(this.fifth_picture, 'fifth_picture'),
    };
    const fd = new FormData();
    Object.keys(payload).forEach(key => fd.append(key, payload[key]));
    // this.pushView('creating');
    this.creating = true;
    this._api.additems(fd).subscribe(async val => {
      const { data } = val;
      this._store.setState('products', {
        ...this._store.products,
        items: [data, ...this._store.products.items],
        total: this._store.products.total + 1
      }, true);
      this.done.emit(data);
      this.creating = false;
    }, () => {
      this.popView();
      this.creating = false;
    });
  }

  setMonthlyAmount() {
    const { credit_price, no_of_months, upfront_amount } = this.productForm.value;
    this.productForm.get('monthly_amount').setValue((+credit_price - +upfront_amount) / +no_of_months);
  }

  async resolveImage(image: string, name) {
    if (image && image.includes('data:image/')) {
      return await this._general.dataUrlToFile(image, this.rawImages[name].name);
    } else {
      return image;
    }
  }

  handleCategoryChange() {
    this.productForm.get('sub_category_id').setValue('');
    this.getSubcategories();
    if (this.productForm.value.category_id !== '7') {
      ['sizes', 'genders'].forEach(v => this.productForm.get(v).reset());
    }
    const tags = genericTags[this.productForm.value.category_id];
    this.genericTags = tags || [];
  }

  handleSizeChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      [this.clothSizes.map(i => i.value), this.shoeSizesEuro.map(i => i.value), this.shoeSizesUK.map(i => i.value)].forEach((arr) => {
        if (arr.includes(value)) {
          this.selectedSizes = this.selectedSizes.filter(s => arr.includes(s));
          this.selectedSizes.push(value);
        }
      });
    } else {
      this.selectedSizes = this.selectedSizes.filter(s => s !== value);
    }
    this.productForm.get('sizes').setValue(this.selectedSizes.join(','));
  }

  handleGenderChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedGenders.push(value);
    } else {
      this.selectedGenders = this.selectedGenders.filter(s => s !== value);
    }
    this.productForm.get('genders').setValue(this.selectedGenders.join(','));
  }

  handleTagChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedTags.push(value);
    } else {
      this.selectedTags = this.selectedTags.filter(s => s !== value);
    }
    this.productForm.get('tag_id').setValue(this.selectedTags.join(','));
  }

  async addTag() {
    if (this.tagForm.invalid) return;
    this.addingTag = true;
    this.tagForm.disable();
    const res = await this._api.addTag(this.tagForm.value.name).toPromise<any>();
    this.tags.push(res.data);
    this.addingTag = false;
    this.tagForm.enable();
    this.tagForm.reset();
  }

  async getTags() {
    const res = await this._api.getTags().toPromise<any>();
    this.tags = res.data;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
