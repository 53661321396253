import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-more-links',
  templateUrl: './more-links.component.html',
  styleUrls: ['./more-links.component.css']
})
export class MoreLinksComponent {
  modal = '';

  constructor(
    public _general: GeneralService,
    public _auth: AuthService
  ) {
  }

  showPendingSetup() {
    this.modal = '';
    this._general.showPendingSetup();
  }

  launchModal(modal) {
    this.modal = modal;
  }

  launchModalIfProfileComplete(modal) {
    if (!this._auth.canShare) {
      return this.showPendingSetup();
    }
    this.modal = modal;
  }
}
