<div class="d-flex flex-column wrap">
    <div class="left-content flex-grow-1 top pb-4">
        <div class="text-center py-5">
            <img src="assets/images/signal.svg" class="mb-2 d-inline" style="width: 200px; margin: auto;" alt="" />
            <h4 class="text-white title mt-5 mb-0">Welcome to Merchant Clan</h4>
            <p class="text-white mt-3 mb-0">
                Join hundreds of merchants using Merchant Clan
                <br>
                to reach more audience and sell on credit
            </p>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center px-5 pt-5 pb-4 bottom">
        <a href="javascript:void(0)" routerLink="/onboarding" class="btn btn-primary btn-lg mt-3">
            Get started
        </a>
        <a href="javascript:void(0)" routerLink="/login" class="btn btn-link btn-lg mt-2">
            Sign in
        </a>
        <small class="text-muted mt-5">
            &copy; Powered by CreditClan
        </small>
    </div>
</div>