<div class="logo-container mb-5">
  <img class="rounded border w-100 h-100 fit-contain" [src]="merchant.picture || 'assets/images/add-item.png'" alt="">
  <label *ngIf="!loading" for="logo"
         class="image-picker d-flex flex-column align-items-center justify-content-center">
    <i class="fa fa-pen"></i>
    <small class="mt-2">Click to change</small>
  </label>
  <div *ngIf="loading" class="image-loading d-flex flex-column align-items-center justify-content-center">
    <i class="fa fa-circle-notch fa-spin fa-2x"></i>
  </div>
  <input hidden (change)="handleLogoChange($event)" type="file" id="logo" name="myImage"
         accept="image/x-png,image/gif,image/jpeg" class="my-4 hidden">
</div>
