<div *ngIf="_auth.merchant.loan" class="card mb-4 bg-off-white">
  <h5 class="title mb-4">Current loan</h5>
  <div class="alert alert-warning alert-sm d-flex justify-content-between align-items-center rounded py-3 px-3">
    Analysis for eligibility pending
    <button class="btn btn-outline-secondary btn-sm" (click)="modal = 'business-loan'">Run</button>
  </div>
  <swiper [slidesPerView]="1.5" [spaceBetween]="10" class="w-100 mt-2">
    <ng-template swiperSlide>
      <div class="card align-items-start py-5 overflow-hidden">
        <h5 class="text-dark-blue mb-2">{{ _auth.merchant.loan.amount | currency:'NGN':'₦':'0.0' }}</h5>
        <h6 *ngIf="loan.tenor" class="text-muted">
          <i class="fa fa-clock me-2"></i>for {{ _auth.merchant.loan.duration }} month(s)</h6>
        <br>
        <br><br><br><br><br>
        <div class="watermark-text">Daily<br>Payment</div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="card align-items-start py-5 overflow-hidden">
        <h5 class="text-dark-blue mb-2">{{ _auth.merchant.loan.created_at | date: "shortDate" }}</h5>
        <h6 class="text-muted">
          <i class="fa fa-calendar-alt me-2"></i>Date requested
        </h6>
        <br>
        <br><br><br><br><br>
        <div class="watermark-text">Daily<br>Payment</div>
      </div>
    </ng-template>
  </swiper>
</div>

<div *ngIf="!_auth.merchant.loan" class="card mb-4 bg-off-white">
  <h5 class="title mb-1">Get up to N50M in business loan</h5>
  <p class="mb-2">50+ finance institutions waiting to fund</p>
  <swiper [slidesPerView]="1.5" [spaceBetween]="10" class="w-100 mt-3">
    <ng-template swiperSlide *ngFor="let loan of quickLoans">
      <div class="card align-items-start py-5 overflow-hidden" [ngClass]="{ disabled: loan.amount > 50000 }">
        <h5 class="text-dark-blue mb-2">{{ loan.amount | currency:'NGN':'₦':'0.0' }}</h5>
        <h6 *ngIf="loan.tenor" class="text-muted">
          <i class="fa fa-calendar-alt me-1"></i> {{ loan.tenor }} month
        </h6>
        <h6 *ngIf="!loan.tenor" class="text-muted">
          <br>
        </h6>
        <button (click)="selectQuickLoan(loan)" class="btn btn-outline-secondary d-flex align-items-center">
          Select <i class="fa fa-chevron-right ms-2"></i>
        </button>
        <br><br><br><br><br>
        <div class="watermark-text">Daily<br>Payment</div>
      </div>
    </ng-template>
  </swiper>
</div>

<app-dialog *ngIf="modal === 'business-loan'" (close)="modal = ''" [close-button]="false" title="Business Loan"
            #dialogBL>
  <app-business-loan [selected]="loan" (close)="dialogBL.dismiss()"></app-business-loan>
</app-dialog>
