import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Merchant } from '../models/merchant';
import { MerchantItems } from '../models/items';
import { AuthResponse } from '../models/auth';
import { MerchantDashboardData } from 'src/app/models/dashboard';
import { CreditBureauData } from 'src/app/models/creditBureauData';
import { IConfirmMBSPayload, IInitializeMBSPayload, ILoanStages } from 'src/app/models/api';
import { AuthService } from './auth.service';

interface ConfirmBankDetails {
  data: { account_name: string; account_number: string; bank_id: number };
  status: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private generalApiUrl = 'https://sellbackend.creditclan.com/merchantclan/public/index.php/api/';

  constructor(
    private http: HttpClient,
    private _auth: AuthService
  ) {
  }

  generateHttpOptions(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${ this._auth.token }`
    });
  }

  generateKeyForBankStatementAnalysis(key: string): HttpHeaders {
    return new HttpHeaders({
      'x-api-key': `${ key }`
    });
  }

  // this function will register the user
  register(obj: Merchant): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${ this.generalApiUrl }register`, obj);
  }

  // this function will verify the user when registration is successful
  verifyMerchantEmail(token: string): Observable<{ status: boolean; message: string }> {
    return this.http.post<any>(`${ this.generalApiUrl }verify/email`, { token }, {
      headers: this.generateHttpOptions()
    });
  }

  // ApiService for item begins here
  // this function send an item to the server
  additems(obj: FormData): Observable<{ data: MerchantItems; message: string; status: boolean }> {
    return this.http.post<{
      data: MerchantItems; message: string; status: boolean;
    }>(`${ this.generalApiUrl }item`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  // edits an item
  editItems(obj: FormData, id: string): Observable<{ data: MerchantItems; message: string; status: boolean }> {
    return this.http.post<{
      data: MerchantItems; message: string; status: boolean;
    }>(`${ this.generalApiUrl }item/${ id }/edit`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  //  fetches all items
  fetchAllItems(page_number?: string): Observable<{
    data: Array<MerchantItems>; end: boolean; prev: any; next: any; status: boolean;
  }> {
    if (!page_number) {
      page_number = '0';
    }
    return this.http.post<any>(`${ this.generalApiUrl }items`, { page_number }, { headers: this.generateHttpOptions() });
  }

  //  delete an items
  deleteItem(id: string): Promise<{ message: string; status: boolean }> {
    return this.http.delete<any>(`${ this.generalApiUrl }item/${ id }/delete`, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  // search All items
  searchItems(search: { search: string; page_number: string; }, instore = false): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }items/search`, { ...search, instore }, {
      headers: this.generateHttpOptions()
    });
  }

  filterItems(payload): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }items/filter`, payload, {
      headers: this.generateHttpOptions()
    });
  }

  // Confirms the account details entered by merchant
  getAccountDetails(obj: {
    account_number: string; bank_code: string;
  }): Observable<ConfirmBankDetails> {
    const url = 'https://mobile.creditclan.com/webapi/v1/account/resolve';
    const httpHeaders = new HttpHeaders({
      'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228'
    });
    return this.http.post<any>(url, obj, { headers: httpHeaders });
  }

  // updates the bankInfo done by a merchant
  updateBankInfo(form: {
    bank_id: string; account_name: string; account_number: string; password: string;
  }): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }profilebank`, form, {
      headers: this.generateHttpOptions()
    });
  }

  // updates the profile Data
  updateProfileData(form: Partial<{
    phone: string; name: string; address: string; business_type: number; whatsapp_no: string
  }>): Observable<any> {
    return this.http.post<any>(`${ this.generalApiUrl }profile`, form, {
      headers: this.generateHttpOptions()
    });
  }

  updateProfilePicture(picture: FormData): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }profilepicture`, picture, {
      headers: this.generateHttpOptions()
    });
  }

  sendVerificationEmail(): Observable<{ status: boolean; message: string }> {
    return this.http.get<any>(`${ this.generalApiUrl }sendverifymail`, {
      headers: this.generateHttpOptions()
    });
  }

  getAllRecentRequests(page_number = '1'): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }request/recent`, { page_number }, { headers: this.generateHttpOptions() });
  }

  getAllCustomerRequests(page?: number): Observable<{
    data: any[]; end: boolean; next: null | number; prev: null | number; total_results_count: number; status: boolean;
  }> {
    const page_number = page ? page : '1';
    return this.http.get<any>(`${ this.generalApiUrl }customers/${ page_number }`, {
      headers: this.generateHttpOptions()
    });
  }

  getAllMerchantRepaymens(page?: number): Observable<any> {
    const page_number = page ? page : '1';
    // repayments
    return this.http.post(`${ this.generalApiUrl }repayments`, { page_number }, { headers: this.generateHttpOptions() });
  }

  modifyTotalPriceOfRequest(form, id: string) {
    return this.http.post(`${ this.generalApiUrl }request/${ id }/edit`, form, {
      headers: this.generateHttpOptions()
    });
  }

  deleteOrder(id: string) {
    return this.http.delete(`${ this.generalApiUrl }request/${ id }/delete`, {
      headers: this.generateHttpOptions()
    });
  }

  markPaymentsAsPaid(id: {
    schedule_id?: any; schedule_ids?: any; request_id?: any; schedule_amount_paid?: any;
  }): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }markpayment`, id, {
      headers: this.generateHttpOptions()
    });
  }

  makePartPayment(form: {
    amount_paid: any; date_paid: any; schedule_id: any;
  }): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }partpayment`, form, {
      headers: this.generateHttpOptions()
    });
  }

  completeADeal(id: {
    request_id: string; password: string;
  }): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }checkout`, id, {
      headers: this.generateHttpOptions()
    });
  }

  fetchASingleSchedule(id: string): Promise<{
    data: any[]; amount_left: number; amount_paid: number; total_repayment_amount: number; status: boolean;
  }> {
    return this.http.post<any>(`${ this.generalApiUrl }request/${ id }/schedule`, id, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  salesByMerchant(): Observable<any> {
    return this.http.get(`${ this.generalApiUrl }sales`, { headers: this.generateHttpOptions() });
  }

  fetchCompletionDetails(): Promise<{ score: number; status: boolean }> {
    return this.http.get<any>(`${ this.generalApiUrl }profilecomplete`, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  notifyCustomer(id: {
    request_id: string;
  }): Promise<{ message: string; status: boolean }> {
    return this.http.post<{ message: string; status: boolean }>(`${ this.generalApiUrl }notify/customer`, id, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  sendVerificationSMS(): Observable<any> {
    return this.http.get(`${ this.generalApiUrl }sendverifyphone`, {
      headers: this.generateHttpOptions()
    });
  }

  sendVerificationCodeFromPhoneToServer(obj: {
    code: string;
  }): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }verify/phone`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  searchOrdersOrRepaymentOrSales(obj: {
    search: string; page_number: any; sales?: boolean; repayments?: boolean; orders?: boolean;
  }) {
    return this.http.post(`${ this.generalApiUrl }search/orders`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  filterOrders(payload): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }filter/order`, payload, {
      headers: this.generateHttpOptions()
    });
  }

  searchInCustomers(obj: { search: string; page_number?: any }) {
    return this.http.post(`${ this.generalApiUrl }customer/search`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  changeVerificationToPhoneOrEmail(obj: { email?: string; phone?: string }) {
    return this.http.post(`${ this.generalApiUrl }change/verification`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  getPaymentHistory(obj: {
    request_id: any; page_number: any;
  }): Observable<{
    data: any[]; end: boolean; status: boolean; next: null | string; prev: null | string;
  }> {
    return this.http.post<any>(`${ this.generalApiUrl }repayments/history`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  getDashboard(filter: string): Observable<{ data: MerchantDashboardData; status: boolean }> {
    return this.http.get<any>(`${ this.generalApiUrl }dashboard/${ filter }`, {
      headers: this.generateHttpOptions()
    });
  }

  async getBusinessInterests(): Promise<any> {
    return await this.http.get(`${ this.generalApiUrl }interests`).toPromise();
  }

  async forgotPassword(obj: { identity?: string; type?: number; }): Promise<{ message: string; }> {
    return this.http.post<any>(`${ this.generalApiUrl }reset/code`, obj).toPromise();
  }

  async resetPassword(obj: {
    identity?: string; type?: string; token?: string; password?: string;
  }): Promise<{ data: string; }> {
    return this.http.post<any>(`${ this.generalApiUrl }reset/password`, obj).toPromise();
  }

  socialMediaUpdate(obj: {
    facebook?: string; twitter?: string; instagram?: string;
  }): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }update/social`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  removeAGlobalItemFromMerchantsList(id: string): Promise<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }global/item/${ id }/remove`, {}, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  fetchGlobalCatalogueBySearch(obj: {
    search: string; page_number?: string; global: boolean;
  }): Observable<{
    data: any[]; end: boolean; next: null | number; prev: null | number; status: boolean; total_results_count: number;
  }> {
    return this.http.post<any>(`${ this.generalApiUrl }global/catalogue/search`, obj);
  }

  async getInstagramId(userName: string): Promise<any> {
    return this.http.get(`${ this.generalApiUrl }insta/${ userName }`).toPromise();
  }

  async getInstagramPosts(userid: string, after = '', first = 80): Promise<any> {
    const url = 'https://instagram40.p.rapidapi.com/account-medias';
    const params: any = {
      after, userid, first
    };
    const headers = new HttpHeaders({
      'x-rapidapi-key': 'bdac755d78msha920a8d278fd596p1adacbjsn1b1b384e667b',
      'x-rapidapi-host': 'instagram40.p.rapidapi.com',
    });
    return this.http.get<any>(`${ url }`, { headers, params }).toPromise();
  }

  fetchGlobalInterests(): Promise<any> {
    return this.http.get(`${ this.generalApiUrl }merchant/interests`, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  // setEnableCatalogueOrActivatePartnership(){
  //   return this.http.post(`${this.generalApiUrl}items/search`, {} {
  //     headers: this.generateHttpOptions()
  //   });
  // }

  createInstoreOrder(data): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }merchant/order`, data, {
      headers: this.generateHttpOptions()
    });
  }

  createSchedule(id, payload): Observable<any> {
    return this.http.post(`https://sellbackend.creditclan.com/merchantclanbackend/public/index.php/api/request_schedule/${ id }`,
      { payload }, { headers: this.generateHttpOptions() }
    );
  }

  generateRequestLink(id, url): Observable<any> {
    return this.http.patch(
      `https://sellbackend.creditclan.com/merchantclanbackend/public/index.php/api/request/${ id }/generate`,
      { url }, { headers: this.generateHttpOptions() }
    );
  }

  async getMerchantNotifications(page_number?: string): Promise<Response> {
    return fetch(`${ this.generalApiUrl }notifications/${ page_number ? page_number : '1' }`, {
      method: 'GET', headers: { Authorization: `Bearer ${ this._auth.token }` }
    });
  }

  async iHaveOpenedNotifications(arrayOfIds: Array<number>): Promise<Response | any> {
    const data = { unopened_notifications_ids: arrayOfIds };
    return fetch(`${ this.generalApiUrl }notifications`, {
      method: 'PUT', body: JSON.stringify(data), headers: {
        Authorization: `Bearer ${ this._auth.token }`, 'Content-Type': 'application/json'
      }
    });
  }

  fetchMerchantsCollectionsHistory(page_number?: string) {
    return fetch(`${ this.generalApiUrl }collections/${ page_number ? page_number : 1 }`, {
      method: 'GET', headers: {
        Authorization: `Bearer ${ this._auth.token }`
      }
    });
  }

  generatePaymentLink(request_id) {
    const body = JSON.stringify({ url: `https://zen-knuth-57e93d.netlify.app/payment/${ request_id }` });
    return fetch(`${ this.generalApiUrl }payment/link`, {
      method: 'POST', body, headers: {
        Authorization: `Bearer ${ this._auth.token }`, 'Content-Type': 'application/json'
      }
    });
  }

  messagesHasBeenRead(id: string): Promise<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }message/${ id }`, {}, { headers: this.generateHttpOptions() }).toPromise();
  }

  activatePartnership(): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }activate/partnership`, {}, { headers: this.generateHttpOptions() });
  }

  confirmActivatePartnership(partnership_email_token) {
    return this.http.patch('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/activate/partnership', {
      partnership_email_token
    }, { headers: this.generateHttpOptions() });
  }

  deactivatePartnership(): Observable<any> {
    return this.http.post(`${ this.generalApiUrl }deactivate/partnership`, {}, { headers: this.generateHttpOptions() });
  }

  confirmDeactivatePartnership(partnership_email_token) {
    return this.http.patch('https://sellbackend.creditclan.com/merchantclan/public/index.php/api/deactivate/partnership', {
      partnership_email_token
    }, { headers: this.generateHttpOptions() });
  }

  getCreditBureauData(obj: {
    phone: string; repayment_amount: string;
  }): Observable<CreditBureauData> {
    return this.http.post<any>(`${ this.generalApiUrl }creditbureau`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  getLenderMBSSettings(): Observable<any> {
    return this.http.get(`https://lendingapis.creditclan.com/api/v3/mbs/lender/settings`, {
      headers: this.generateKeyForBankStatementAnalysis('WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228')
    });
  }

  intializeMBS(data: IInitializeMBSPayload): Observable<any> {
    return this.http.post('https://app.bankstatement.ai/mbs/initialize', data, {
      headers: this.generateKeyForBankStatementAnalysis('6f2FArftQk2NY5bXymekdAGhCz9oWIpHbpYc3Hoq')
    });
  }

  checkMBSStatus(transaction_id: string): Observable<any> {
    return this.http.get(`https://app.bankstatement.ai/mbs/status/${ transaction_id }`, {
      headers: this.generateKeyForBankStatementAnalysis('6f2FArftQk2NY5bXymekdAGhCz9oWIpHbpYc3Hoq')
    });
  }

  confirmMBSRequest(transaction_id: string, data: IConfirmMBSPayload): Observable<any> {
    return this.http.post(`https://app.bankstatement.ai/mbs/confirm/${ transaction_id }`, data, {
      headers: this.generateKeyForBankStatementAnalysis('6f2FArftQk2NY5bXymekdAGhCz9oWIpHbpYc3Hoq')
    });
  }

  sendCreditToLender(obj: any) {
    const headers = new HttpHeaders({
      'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228'
    });
    return this.http.post(`https://mobile.creditclan.com/webapi/v1/loan/merchantapply`, obj, {
      headers
    }).toPromise();
  }

  sendMerchantInterestRates(obj: {
    monthly_interest_rate: string; interest_on_total: string; interest_on_balance: string;
  }): Observable<{ message: string; status: boolean }> {
    return this.http.patch<any>(`${ this.generalApiUrl }interest`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  checkAnalysisStatus(transaction_id) {
    return this.http.get(`https://app.bankstatement.ai/analytics/status/${ transaction_id }`);
  }

  getAnalysisResult(transaction_id) {
    return this.http.get(`https://app.bankstatement.ai/analytics/status/credible/${ transaction_id }`);
  }

  getMerchantRepaymentReports(repayment_type?: string, start_date?: string, end_date?: string, generate?: boolean): Observable<{
    count: number; data: any[]; end: boolean; next: null | number; prev: null | number; status: boolean; sum: number;
    total_result_count: number;
  }> {
    const obj = {};
    if (generate) {
      obj['start_date'] = start_date;
      obj['end_date'] = end_date;
    }
    obj['repayment_type'] = repayment_type;
    return this.http.post<any>(`${ this.generalApiUrl }repayment/report/1`, obj, {
      headers: this.generateHttpOptions()
    });
  }

  fetchProductsSummary() {
    return this.http.get(`${ this.generalApiUrl }items/summary`, {
      headers: this.generateHttpOptions()
    });
  }

  activatePopularProducts(): Observable<any> {
    return this.http.patch(`${ this.generalApiUrl }popular/activate`, {}, { headers: this.generateHttpOptions() });
  }

  deactivatePopularProducts(): Observable<any> {
    return this.http.patch(`${ this.generalApiUrl }popular/deactivate`, {}, { headers: this.generateHttpOptions() });
  }

  getSingleRepayment(id: string) {
    return this.http.get(`${ this.generalApiUrl }repayment/${ id }`, {
      headers: this.generateHttpOptions()
    });
  }

  getSingleRequest(id: string) {
    return this.http.get(`${ this.generalApiUrl }order/${ id }`, {
      headers: this.generateHttpOptions()
    });
  }

  getSingleProduct(id: string) {
    return this.http.get(`${ this.generalApiUrl }item/${ id }`, {
      headers: this.generateHttpOptions()
    });
  }

  getStoredInstagramData(username: string, page_number: number) {
    return this.http.get(`${ this.generalApiUrl }instagram/${ username }?page=${ page_number }`, {
      headers: this.generateHttpOptions()
    });
  }

  saveInstagramData(payload) {
    return this.http.post(`${ this.generalApiUrl }instagram`, payload, {
      headers: this.generateHttpOptions()
    });
  }

  resolveImages(payload) {
    return this.http.post('https://dataupload.creditclan.com/api/v2/converter/instaproxy', payload);
  }

  getProductComments(id: string, page: number) {
    return this.http.get(`${ this.generalApiUrl }item/${ id }/comments/${ page }`);
  }

  confirmUpfrontPayment(id: string | number) {
    return this.http.patch(`${ this.generalApiUrl }confirm/payment`, { request_id: `${ id }` }, { headers: this.generateHttpOptions() });
  }

  enableProduct(id: string | number) {
    return this.http.patch(`${ this.generalApiUrl }item/enable`, { item_id: id }, { headers: this.generateHttpOptions() });
  }

  disableProduct(id: string | number) {
    return this.http.patch(`${ this.generalApiUrl }item/disable`, { item_id: id }, { headers: this.generateHttpOptions() });
  }

  saveCreditInterestSettings(data): Observable<any> {
    return this.http.patch(`${ this.generalApiUrl }interest`, data, { headers: this.generateHttpOptions() });
  }

  fetchProductsFromKonga(shop, page): Observable<any> {
    const headers = new HttpHeaders({ 'x-api-key': '93544c045db854d4ebd534d4672a4fc6decdc554aadfa7a4ac464a1d0f787e73a80ca84c79c2c45ede8128a947a4ad9df3cfbaafc317a6d9490e037e4ee8be9a' });
    return this.http.get(`https://whatsapp.creditclan.com/webcrawler/konga?shop=${ shop }&page=${ page }`, { headers });
  }

  // fetchProductsFromJiji(shop, page): Observable<any> {
  //   const headers = new HttpHeaders({ 'x-api-key':
  //     '93544c045db854d4ebd534d4672a4fc6decdc554aadfa7a4ac464a1d0f787e73a80ca84c79c2c45ede8128a947a4ad9df3cfbaafc317a6d9490e037e4ee8be9a'
  //   });
  //   return this.http.get(`https://whatsapp.creditclan.com/webcrawler/jiji?shop=${ shop }&page=${ page }`, { headers });
  // }

  fetchProductsFromJiji(shop, page): Observable<any> {
    return this.http.get(`https://products-scraper.glitch.me/api/v1/jiji/${ shop }?page=${ page }`);
  }

  fetchProductsFromJumia(shop, page): Observable<any> {
    const headers = new HttpHeaders({ 'x-api-key': '93544c045db854d4ebd534d4672a4fc6decdc554aadfa7a4ac464a1d0f787e73a80ca84c79c2c45ede8128a947a4ad9df3cfbaafc317a6d9490e037e4ee8be9a' });
    return this.http.get(`https://whatsapp.creditclan.com/webcrawler/jumia?shop=${ shop }&page=${ page }`, { headers });
  }

  fetchProductsFromFlutterwave(shop, page): Observable<any> {
    const headers = new HttpHeaders({ 'x-api-key': '93544c045db854d4ebd534d4672a4fc6decdc554aadfa7a4ac464a1d0f787e73a80ca84c79c2c45ede8128a947a4ad9df3cfbaafc317a6d9490e037e4ee8be9a' });
    return this.http.get(`https://whatsapp.creditclan.com/webcrawler/flutterwave?store=${ shop }&page=${ page }`, { headers });
  }

  addTag(name: string) {
    return this.http.post(`${ this.generalApiUrl }tags`, { name }, { headers: this.generateHttpOptions() });
  }

  getTags() {
    return this.http.get(`${ this.generalApiUrl }tags`, { headers: this.generateHttpOptions() });
  }

  updateOnlineStore(data) {
    return this.http.post(`${ this.generalApiUrl }onlinestore`, data, { headers: this.generateHttpOptions() });
  }

  updateProductReviewSettings(data) {
    return this.http.patch(`${ this.generalApiUrl }reviews`, data, { headers: this.generateHttpOptions() });
  }

  addBanner(data) {
    return this.http.post(`${ this.generalApiUrl }banner`, data, { headers: this.generateHttpOptions() });
  }

  deleteBanner(id) {
    return this.http.delete(`${ this.generalApiUrl }banner/${ id }/delete`, { headers: this.generateHttpOptions() });
  }

  sendLoanRequest(data) {
    return this.http.post(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/merchant/loan`, data, {
      headers: this.generateHttpOptions()
    });
  }

  saveCreditclanRequestId(loanId, creditclan_request_id) {
    return this.http.patch(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/request/${ loanId }`, {
      merchant: true, creditclan_request_id
    });
  }

  initAnalysis(amount) {
    return this.http.post<ILoanStages>(`https://sellbackend.creditclan.com/schoolcredit/public/index.php/api/offer/stages`,
      { amount }
    );
  }

  preBankStatementWidget(request_id, account, has_online_banking) {
    const headers = new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' });
    return this.http.post('https://mobile.creditclan.com/api/v3/bankstatement/initiate', {
      request_id, account, has_online_banking
    }, { headers });
  }

  preRepaymentWidget(request_id) {
    const headers = new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' });
    return this.http.post('https://mobile.creditclan.com/api/v3/card/tokenization', { request_id }, { headers });
  }

  updateAnalysisCompleted(id, analysis_completed) {
    return this.http.patch(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/stages/${ id }`, {
      analysis_completed, type: 'merchant'
    });
  }

  cancelLoan(id) {
    return this.http.delete(`https://sellbackend.creditclan.com/merchantclan/public/index.php/api/merchant/loan/${ id }`, {
      headers: this.generateHttpOptions()
    });
  }

  cancelLoanCC(request_id) {
    const headers = new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' });
    return this.http.post(`https://mobile.creditclan.com/api/v3/cancel/loan_request`, { request_id }, { headers });
  }

  ccLoanChecklist(request_id, user_id) {
    const headers = new HttpHeaders({ 'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228' });
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/checklists', { request_id, user_id }, { headers });
  }

  filterCCStore(search, page_number) {
    return this.http.post(`${ this.generalApiUrl }global/search`, {
      search, page_number, all_items: true
    });
  }

  filterCCStoreBySlug(slug, page) {
    return this.http.get<any>(`${ this.generalApiUrl }items?slug=${ slug }&page=${ page }`);
  }

  addCCItemsBulk(items) {
    return this.http.post<any>(`${ this.generalApiUrl }items/add`, { items }, { headers: this.generateHttpOptions() });
  }

  sendNewEmailOTP({ email, password }) {
    return this.http.post<any>(`${ this.generalApiUrl }email/otp`, {
      email,
      password
    }, { headers: this.generateHttpOptions() });
  }

  sendNewPhoneOTP({ phone, password }) {
    return this.http.post<any>(`${ this.generalApiUrl }phone/otp`, {
      phone,
      password
    }, { headers: this.generateHttpOptions() });
  }

  changeEmail({ email, otp }) {
    return this.http.post<any>(`${ this.generalApiUrl }email`, { email, otp }, { headers: this.generateHttpOptions() });
  }

  changePhone({ phone, otp }) {
    return this.http.post<any>(`${ this.generalApiUrl }phone`, { phone, otp }, { headers: this.generateHttpOptions() });
  }

  updateCreditAnalysisSettings(data) {
    return this.http.patch(`${ this.generalApiUrl }collection/toggle`, data, { headers: this.generateHttpOptions() });
  }

  updateRequestItems(requestId, items) {
    return this.http.post(`${ this.generalApiUrl }order/${ requestId }/items`, { items }, { headers: this.generateHttpOptions() });
  }

  sendReminderToCustomer(request_id) {
    return this.http.post(`${ this.generalApiUrl }reminder/${ request_id }`, null, { headers: this.generateHttpOptions() });
  }

  getWhatsappStatusBySlug(slug) {
    return this.http.get(`https://whatsapp.creditclan.com/merchant/status/${ slug }`);
  }

  getWhatsappQRCode(payload) {
    const headers = new HttpHeaders({ 'x-api-key': '6f2FArftQk2NY5bXymekdAGhCz9oWIpHbpYc3Hoq' });
    return this.http.post('https://whatsapp.creditclan.com/api/sender/generate/qr', payload, { headers });
  }

  checkWhatsappQRCodeStatus(reference) {
    const headers = new HttpHeaders({ 'x-api-key': '6f2FArftQk2NY5bXymekdAGhCz9oWIpHbpYc3Hoq' });
    return this.http.get(`https://whatsapp.creditclan.com/api/status/${ reference }`, { headers });
  }

  saveWhatsappBotSettings(payload) {
    return this.http.put(`${ this.generalApiUrl }bot`, payload, { headers: this.generateHttpOptions() });
  }

  getCategories() {
    return this.http.get(`${ this.generalApiUrl }item/categories`);
  }

  getSubcategories(category_id) {
    return this.http.get(`${ this.generalApiUrl }item/category/${ category_id }/subcategories`);
  }
}
