<div class="card d-flex flex-column align-items-stretch h-100">
  <form [formGroup]="accountInfoForm" (ngSubmit)="submitAccountInfoForm()">
    <h3 class="title fw-bold m-0" style="max-width: 300px">Start getting paid</h3>
    <p class="mb-5 mt-1">Provide your bank account to get paid into</p>

    <div class="form-group required mb-4">
      <label for="bankName">Select Bank</label>
      <select id="bankName" formControlName="bank_code" class="form-control">
        <option value="" selected> Choose bank</option>
        <option [value]="bank.bank_code" *ngFor="let bank of nigerianBanks">{{ bank.name }}</option>
      </select>
    </div>

    <div class="form-group required mb-4 position-relative">
      <label for="acc-number-input">Account Number</label>
      <input
        formControlName="account_number" type="tel" pattern="[0-9]{10}" id="acc-number-input"
        placeholder="Account number" class="form-control"
      />
    </div>

    <div *ngIf="accountInfoForm.value.account_name" class="alert alert-success rounded p-4">
      Account name <span class="fw-bold">{{ accountInfoForm.value.account_name }}</span>
    </div>

    <div class="mt-5">
      <button
        (click)="validateAccount()" *ngIf="!accountInfoForm.value.account_name" type="button"
        [disabled]="!accountInfoForm.valid || loading" class="btn me-3 btn-md btn-primary"
      >
        Validate
      </button>
      <button
        *ngIf="accountInfoForm.value.account_name" [disabled]="!accountInfoForm.valid || loading"
        class="btn me-3 btn-md btn-primary"
      >
        Save
      </button>
      <button
        *ngIf="accountInfoForm.value.account_name" (click)="accountInfoForm.get('account_name').reset()"
        [disabled]="loading" class="btn btn-link"
      >
        Change
      </button>
    </div>
  </form>

  <img class="w-50 ms-auto mt-auto" src="assets/images/svg/personal-finance.svg" alt="Illustration"
       style="max-width: 200px;">
</div>
