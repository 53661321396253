import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { NigeriaBanks, NigerianBank } from 'src/app/models/nigerianBanks';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-setup-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent implements OnInit {
  @Output() close = new EventEmitter();
  public nigerianBanks: Array<NigerianBank> = NigeriaBanks().data;
  accountInfoForm: any;
  loading: boolean;

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private fb: FormBuilder,
    private _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.accountInfoForm = this.fb.group({
      bank_code: ['', Validators.required],
      account_number: ['', [Validators.required, Validators.pattern(/(^\d{10}$)/gi)]],
      account_name: [''],
      bank_id: ['']
    });
  }

  submitAccountInfoForm() {
    const payload = { ...this.accountInfoForm.value };
    this.loading = true;
    this._api.updateBankInfo(payload).subscribe(async () => {
      this._auth.setMerchant({
        ...this._auth.merchant,
        ...payload,
        completion_details: { ...this._auth.merchant.completion_details, bank_details: true }
      });
      this.loading = false;
      this.close.emit();
    }, err => {
      this.loading = false;
      return this._general.notify('error', err.error.message || 'An error occurred, please try again');
    });
  }

  validateAccount() {
    const { account_number, bank_code } = this.accountInfoForm.value;
    const payload = { account_number, bank_code };
    this.loading = true;
    this._api.getAccountDetails(payload).pipe(take(1)).subscribe((val: any) => {
      if (val.status) {
        const { account_name } = val.data;
        this.accountInfoForm.get('account_name').setValue(account_name);
        const bank_id = this.nigerianBanks.find(bank => bank.bank_code === this.accountInfoForm.value.bank_code).id;
        this.accountInfoForm.get('bank_id').setValue(bank_id);
      } else {
        this._general.notify('error', val.message || 'Couldn\'t validate account, please check and try again');
      }
      this.loading = false;
    }, () => {
      this.loading = false;
      this._general.notify('error', 'Could not confirm you bank details. Please check that you entered the right details');
    });
  }
}
