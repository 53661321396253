import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private pendingSetupSubject = new Subject();
  public pendingSetup$ = this.pendingSetupSubject.asObservable();

  private socialShareSubject = new Subject<{
    socialMediaPlatform?: 'facebook' | 'twitter' | 'instagram' | 'whatsapp_link';
    info: { description: null | string; link: null | string, whatsapp_link?: string };
  }>();
  public socialShare$ = this.socialShareSubject.asObservable();

  private communicateNotificationsDataSubject = new BehaviorSubject([]);
  public receiveNotificationsData$ = this.communicateNotificationsDataSubject.asObservable();

  clientBaseUrl = 'https://zen-knuth-57e93d.netlify.app';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _auth: AuthService
  ) {
  }

  broadCastSocialShare(anything: {
    socialMediaPlatform?: 'facebook' | 'twitter' | 'instagram' | 'whatsapp_link';
    info: { description: null | string; link: null | string; whatsapp_link?: string };
  }) {
    this.socialShareSubject.next(anything);
  }

  //  sends notifications data to notifications Comp
  sendNotificationsToNotificationsComp(data: any[]) {
    this.communicateNotificationsDataSubject.next(data);
  }

  showPendingSetup() {
    this.pendingSetupSubject.next();
  }

  notify(type, message) {
    this.toastr[type](message);
  }

  maskEmail(email) {
    return email.replace(/^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }

  formatCurrency(value) {
    return `₦${ parseFloat(value).toLocaleString() }`;
  }

  putCommasInNumbers(n: any, returnVal?: any) {
    if (!n) {
      return returnVal ? returnVal : 0;
    }
    let result;
    if (n.length > 3 && !isNaN(n)) {
      if (n % 1 !== 0) {
        let temp: any | Array<string> = n.toString().split('.');
        // catch any errors
        try {
          temp[1] = temp[1].substring(0, 3);
          temp = temp.join('.');
          result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return result;
        } catch (e) {
          result = 0;
          return result;
        }
      }
      result = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return result;
    }
    return n;
  }

  copyLink(link: string) {
    if (!this._auth.canShare) return this.showPendingSetup();
    const input = document.createElement('input');
    input.value = link;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    this.notify('success', 'Link copied to clipboard');
    input.remove();
  }

  async navigateTo(page) {
    await this.router.navigate([page]);
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpeg' });
  }
}
