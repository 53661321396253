import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { WelcomeComponent } from './components/auth/welcome/welcome.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
// external modules and custom modules
import { ToastrModule } from 'ngx-toastr';

import { IsLoggedInGuard } from './is-logged-in.guard';
import { AuthGuard } from './auth.guard';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { OnboardingComponent } from './components/auth/onboarding/onboarding.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { ProfileMgtComponent } from './components/shared/profile-mgt/profile-mgt.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ViewCustomerDataComponent } from './components/view-customer-data/view-customer-data.component';
import { IncompleteProfileDisplayComponent } from './components/incomplete-profile-display/incomplete-profile-display.component';
import { CreateItemComponent } from './components/create-item/create-item.component';
import { GlobalFooterComponent } from './components/global-footer/global-footer.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { BottomNavComponent } from './components/shared/bottom-nav/bottom-nav.component';
import { CreateOrderComponent } from './components/create-order/create-order.component';
import { SearchStoreItemsComponent } from './components/search-store-items/search-store-items.component';
import { CreditAnalysisComponent } from './components/credit-analysis/credit-analysis.component';
import { CreditAnalysisBankStatementComponent } from './components/credit-analysis-bank-statement/credit-analysis-bank-statement.component';
import { CreditAnalysisCreditBeareauComponent } from './components/credit-analysis-credit-beareau/credit-analysis-credit-beareau.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ActivateBankPartnershipComponent } from './components/activate-bank-partnership/activate-bank-partnership.component';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { ShortenNumberPipe } from './pipes/shorten-number.pipe';
import { WelcomeSettingsComponent } from './components/welcome-settings/welcome-settings.component';
import { CreateProductOptionsComponent } from './components/create-product-options/create-product-options.component';
import { SquareBoxComponent } from './components/square-box/square-box.component';
import { AddProductsFromKongaComponent } from './components/add-products-from-konga/add-products-from-konga.component';
import { CreateProductEmbeddedComponent } from './components/create-product-embedded/create-product-embedded.component';
import { AddProductsFromJijiComponent } from './components/add-products-from-jiji/add-products-from-jiji.component';
import { AddProductsFromJumiaComponent } from './components/add-products-from-jumia/add-products-from-jumia.component';
import { SetupAccountComponent } from './components/setup-account/setup-account.component';
import { BusinessLoanComponent } from './components/business-loan/business-loan.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { OnlineStoreComponent } from './components/online-store/online-store.component';
import { SettingsProfilePictureComponent } from './components/settings-profile-picture/settings-profile-picture.component';
import { SettingsBusinessDetailsComponent } from './components/settings-business-details/settings-business-details.component';
import { SettingsTagsComponent } from './components/settings-tags/settings-tags.component';
import { SettingsCollectionItemsComponent } from './components/settings-collection-items/settings-collection-items.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { CollectionsHistoryComponent } from './components/collections-history/collections-history.component';
import { SettingsInterestComponent } from './components/settings-interest/settings-interest.component';
import { SettingsBankPartnershipComponent } from './components/settings-bank-partnership/settings-bank-partnership.component';
import { SettingsAccountDetailsComponent } from './components/settings-account-details/settings-account-details.component';
import { SettingsSocialAccountsComponent } from './components/settings-social-accounts/settings-social-accounts.component';
import { SettingsChangeEmailComponent } from './components/settings-change-email/settings-change-email.component';
import { SettingsHomepageBannerComponent } from './components/settings-homepage-banner/settings-homepage-banner.component';
import { SettingsThemeColorComponent } from './components/settings-theme-color/settings-theme-color.component';
import { SettingsHomepageTemplateComponent } from './components/settings-homepage-template/settings-homepage-template.component';
import { SettingsHomepageSectionsComponent } from './components/settings-homepage-sections/settings-homepage-sections.component';
import { LoanStatusComponent } from './components/loan-status/loan-status.component';
import { LoanAnalysisComponent } from './components/loan-analysis/loan-analysis.component';
import { SettingsCollectionFilterComponent } from './components/settings-collection-filter/settings-collection-filter.component';
import { SettingsCollectionBannerComponent } from './components/settings-collection-banner/settings-collection-banner.component';
import { SettingsItemDetailsTemplateComponent } from './components/settings-item-details-template/settings-item-details-template.component';
import { SettingsItemDetailsComponent } from './components/settings-item-details/settings-item-details.component';
import { AddProductsFromFlutterwaveComponent } from './components/add-products-from-flutterwave/add-products-from-flutterwave.component';
import { AddProductsFromCreditclanComponent } from './components/add-products-from-creditclan/add-products-from-creditclan.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { ProductCommentsComponent } from './components/product-comments/product-comments.component';
import { DashboardAddProductOptionsComponent } from './components/dashboard-add-product-options/dashboard-add-product-options.component';
import { TransactionSummaryComponent } from './components/dashboard/transaction-summary/transaction-summary.component';
import { ProxyImageComponent } from './components/proxy-image/proxy-image.component';
import { SettingsChangePhoneComponent } from './components/settings-change-phone/settings-change-phone.component';
import { ProductsComponent } from './components/allitems/products.component';
import { ViewItemComponent } from './components/view-item/view-item.component';
import { RepaymentsComponent } from './components/repayments/repayments.component';
import { SalesComponent } from './components/sales/sales.component';
import { RepaymentReportComponent } from './components/repayment-report/repayment-report.component';
import { ViewARepaymentComponent } from './components/view-a-repayment/view-a-repayment.component';
import { RepaymentsReportsTablesComponent } from './components/repayments-reports-tables/repayments-reports-tables.component';
import { RecentRequestsComponent } from './components/recent-requests/recent-requests.component';
import { CustomersComponent } from './components/customers/customers.component';
import { RequestedItemsComponent } from './components/requested-items/requested-items.component';
import { ViewARecentRequestComponent } from './components/view-a-recent-request/view-a-recent-request.component';
import { EditARequestComponent } from './components/edit-a-request/edit-a-request.component';
import { RepaymentInRequestComponent } from './components/repayment-in-request/repayment-in-request.component';
import { SearchRequestsComponent } from './components/search-requests/search-requests.component';
import { MoreLinksComponent } from './components/more-links/more-links.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { SwiperModule } from 'swiper/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SettingsComponent } from './components/settings-component/settings.component';
import { SettingsRequestAnalysisComponent } from './components/settings-request-analysis/settings-request-analysis.component';
import { OnboardingWhatsappComponent } from './components/auth/onboarding-whatsapp/onboarding-whatsapp.component';
import { RequestManualItemsComponent } from './components/request-manual-items/request-manual-items.component';
import { MobileNavigationDrawerComponent } from './components/mobile-navigation-drawer/mobile-navigation-drawer.component';
import { PaymentComponent } from './components/payment/payment.component';
import { AddProductsFromWhatsappComponent } from './components/add-products-from-whatsapp/add-products-from-whatsapp.component';
import { ConnectWhatsappComponent } from './components/connect-whatsapp/connect-whatsapp.component';
import { DashboardWhatsappIntegrationComponent } from './components/dashboard-whatsapp-integration/dashboard-whatsapp-integration.component';
import { ManageWhatsappConnectionComponent } from './components/manage-whatsapp-connection/manage-whatsapp-connection.component';
import { WhatsappSettingsComponent } from './components/whatsapp-settings/whatsapp-settings.component';
import { PricingComponent } from './components/pricing/pricing.component';

SwiperCore.use([Navigation, Pagination]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    WelcomeComponent,
    SplashScreenComponent,
    OnboardingComponent,
    DefaultLayoutComponent,
    SocialShareComponent,
    ProfileMgtComponent,
    HeaderComponent,
    InstagramComponent,
    FooterComponent,
    ViewCustomerDataComponent,
    IncompleteProfileDisplayComponent,
    CreateItemComponent,
    GlobalFooterComponent,
    GlobalSearchComponent,
    BottomNavComponent,
    CreateOrderComponent,
    SearchStoreItemsComponent,
    CreditAnalysisComponent,
    CreditAnalysisBankStatementComponent,
    CreditAnalysisCreditBeareauComponent,
    DialogComponent,
    ActivateBankPartnershipComponent,
    TimeAgoPipe,
    ShortenNumberPipe,
    WelcomeSettingsComponent,
    CreateProductOptionsComponent,
    SquareBoxComponent,
    AddProductsFromKongaComponent,
    CreateProductEmbeddedComponent,
    AddProductsFromJijiComponent,
    AddProductsFromJumiaComponent,
    SetupAccountComponent,
    BusinessLoanComponent,
    CapitalizePipe,
    OnlineStoreComponent,
    SettingsProfilePictureComponent,
    SettingsBusinessDetailsComponent,
    SettingsTagsComponent,
    SettingsCollectionItemsComponent,
    NotificationsComponent,
    WalletComponent,
    CollectionsHistoryComponent,
    SettingsComponent,
    SettingsInterestComponent,
    SettingsBankPartnershipComponent,
    SettingsAccountDetailsComponent,
    SettingsSocialAccountsComponent,
    SettingsChangeEmailComponent,
    SettingsHomepageBannerComponent,
    SettingsThemeColorComponent,
    SettingsHomepageTemplateComponent,
    SettingsHomepageSectionsComponent,
    LoanStatusComponent,
    LoanAnalysisComponent,
    SettingsCollectionFilterComponent,
    SettingsCollectionBannerComponent,
    SettingsItemDetailsTemplateComponent,
    SettingsItemDetailsComponent,
    AddProductsFromFlutterwaveComponent,
    AddProductsFromCreditclanComponent,
    EditProductComponent,
    ProductCommentsComponent,
    DashboardAddProductOptionsComponent,
    TransactionSummaryComponent,
    ProxyImageComponent,
    SettingsChangePhoneComponent,
    ProxyImageComponent,
    SettingsChangePhoneComponent,
    ProductsComponent,
    ViewItemComponent,
    RepaymentsComponent,
    SalesComponent,
    RepaymentReportComponent,
    ViewARepaymentComponent,
    RepaymentsReportsTablesComponent,
    RecentRequestsComponent,
    CustomersComponent,
    RequestedItemsComponent,
    ViewARecentRequestComponent,
    EditARequestComponent,
    RepaymentInRequestComponent,
    SearchRequestsComponent,
    MoreLinksComponent,
    HeaderComponent,
    DashboardComponent,
    SettingsRequestAnalysisComponent,
    OnboardingWhatsappComponent,
    RequestManualItemsComponent,
    MobileNavigationDrawerComponent,
    PaymentComponent,
    AddProductsFromWhatsappComponent,
    ConnectWhatsappComponent,
    DashboardWhatsappIntegrationComponent,
    ManageWhatsappConnectionComponent,
    WhatsappSettingsComponent,
    PricingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: false
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    ImageCropperModule,
    CarouselModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    RouterModule,
    AngularEditorModule,
  ],
  providers: [AuthGuard, IsLoggedInGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}
