<div *ngIf="!_auth.merchant.sender_id" class="d-flex flex-column align-items-center text-center py-5">
  <img src="/assets/images/svg/no-connection.svg" alt="no connection" class="w-25">
  <h4 class="mt-5 mb-0">Enable Whatsapp integration</h4>
  <p class="mt-3">Connect your whatsapp to enable whatsapp shopping and add products from your whatsapp status</p>
  <button (click)="connect.emit()" class="btn btn-primary mt-4">Connect whatsapp</button>
</div>

<ng-template [ngIf]="_auth.merchant.sender_id">
  <div class="d-flex align-items-center py-4">
    <i class="fa fa-circle text-success"></i>
    <div class="ms-3">
      <div class="fw-bold">Whatsapp connected</div>
      <div class="mt-1">
        <i class="fab fa-whatsapp me-1 fs-6"></i> {{ _auth.merchant.whatsapp_no }}
      </div>
    </div>
  </div>

  <div class="card bg-off-white mt-3 px-3 py-1">
    <div (click)="settings.emit()" class="py-3 d-flex flex-row align-items-center cursor-pointer">
      <i class="fa fa-cog me-3"></i>
      Manage bot settings
    </div>
    <div class="py-3 d-flex flex-row align-items-center border-top cursor-pointer">
      <i class="fa fa-times me-3 text-danger"></i>
      Disconnect
    </div>
  </div>
</ng-template>
