<div *ngIf="customer.credit" class="card p-0 rounded-circle overflow-hidden mx-auto mb-5"
  style="width: 100px; height: 100px;">
  <img class="w-100 h-100 fit-cover" [src]="customer?.picture || 'assets/images/placeholder-profile.png'" alt="">
</div>

<div class="card mb-4">
  <small class="text-primary mb-4">Profile</small>
  <ul class="list-group list-group-flush border-top-0">
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Name</div>
      <div class="ms-2 text-end">
        {{ customer?.full_name }}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Phone number</div>
      <div class="ms-2 text-end">
        {{ customer?.phone_no || 'N/A' }}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0">
      <div class="title text-muted">Email</div>
      <div class="ms-2 text-end">
        {{ customer?.email || 'N/A' }}
      </div>
    </li>
  </ul>
</div>

<div class="card mb-4">
  <small class="text-primary mb-4">Shipping information</small>
  <ul class="list-group list-group-flush border-top-0">
    <li class="list-group-item d-flex justify-content-between align-items-start px-0">
      <div class="title text-muted">Address</div>
      <div class="ms-2 text-end">
        {{ customer?.address || "No address given" }}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0">
      <div class="title text-muted">City/Town</div>
      <div class="ms-2 text-end">{{ customer?.city || "N/A" }}</div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0">
      <div class="title text-muted">State</div>
      <div class="ms-2 text-end">{{ customer?.state || "N/A" }}</div>
    </li>
  </ul>
</div>

<div *ngIf="customer?.credit" class="card mb-4">
  <small class="text-primary mb-4">Work Information</small>
  <ul class="list-group list-group-flush border-top-0">
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Company name</div>
      <div class="ms-2 text-end">
        {{ customer?.company_name || 'N/A' }}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Work address</div>
      <div class="ms-2 text-end">
        {{ customer?.work_address || 'N/A' }}
      </div>
    </li>
  </ul>
</div>

<div *ngIf="customer?.credit" class="card mb-4">
  <small class="text-primary mb-4">Income information</small>
  <ul class="list-group list-group-flush border-top-0">
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Net monthly income</div>
      <div class="ms-2 text-end">
        {{ customer?.net_monthly_income || 'N/A' }}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start px-0 border-top-0">
      <div class="title text-muted">Occupation</div>
      <div class="ms-2 text-end">
        {{ customer?.occupation || 'N/A' }}
      </div>
    </li>
  </ul>
</div>

<a *ngIf="customer?.phone_no" [href]="'https://api.whatsapp.com/send?phone=' + customer.phone_no" target="_blank"
  class="btn btn-success mt-4">
  <i class="fab fa-whatsapp me-2"></i> Chat with customer
</a>
