import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CollectionsHistory, CollectionsHistoryResponse } from 'src/app/models/merchant';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService } from 'src/app/services/mockstore/store.service';

@Component({
  selector: 'app-collections-history',
  templateUrl: './collections-history.component.html',
  styleUrls: ['./collections-history.component.css']
})
export class CollectionsHistoryComponent implements OnInit, OnDestroy {
  collectionsHistoryData: CollectionsHistory[] = [];
  loadingCollections = false;

  constructor(
    private _api: ApiService,
    public _general: GeneralService,
    private mockstore: StoreService
  ) {
  }

  ngOnInit(): void {
    this.getMerchantCollections();
  }

  onScroll() {
    // console.log('scrolled!', this.mockstore.nextSetOfMerchantNotifications);
    this.loadingCollections = true;
    if (this.mockstore.nextSetOfCollections) {
      this.getMerchantCollections(
        this.mockstore.nextSetOfCollections.toString()
      );
    } else {
      this.loadingCollections = false;
    }
  }

  async getMerchantCollections(page_number?: string) {
    const res: Response = await this._api.fetchMerchantsCollectionsHistory(
      page_number
    );
    try {
      const newRes: CollectionsHistoryResponse = await res.json();
      const { data, next } = newRes;
      this.mockstore.nextSetOfCollections = next;
      this.collectionsHistoryData = [...this.collectionsHistoryData, ...data];
      this.loadingCollections = false;
    } catch (error) {
      this.loadingCollections = false;
    }
  }

  ngOnDestroy() {
    this.mockstore.nextSetOfCollections = null;
  }
}
