<div class="card mb-4">
  <h5 class="title mb-5">Account Information</h5>
  <form [formGroup]="updateBankInfoForm" class="mb-0">
    <div *ngIf="updateBankInfoForm.value.account_name" class="alert alert-primary d-flex align-items-center justify-content-between mb-4">
      Account name: {{ updateBankInfoForm.value.account_name }}
      <button class="btn btn-outline-primary btn-sm" (click)="change()">Change</button>
    </div>
    <div class="form-group required-field mt-3">
      <label class="form-label" for="bankName">Bank Name</label>
      <select id="bankName" formControlName="bank_code" class="form-select">
        <option value="" selected> Choose bank</option>
        <option [value]=" bank?.bank_code" *ngFor="let  bank of nigerianBanks">
          {{  bank?.name }}
        </option>
      </select>
    </div>
    <div class="form-group required-field mt-3">
      <label class="form-label" for="account-number">Account Number</label>
      <input formControlName="account_number" type="text" class="form-control override" id="account-number">
    </div>

    <div class="mt-5">
      <button
        [disabled]="loading" *ngIf="!updateBankInfoForm.value.account_name" (click)="verifyAccount()"
        class="btn btn-warning d-flex align-items-center"
      >
        <span class="spinner-grow spinner-grow-sm me-2" role="status" *ngIf="loading">
          <span class="visually-hidden">Loading...</span>
        </span>
        Validate
      </button>
      <button
        [disabled]="updateBankInfoForm.invalid || loading" *ngIf="updateBankInfoForm.value.account_name"
        (click)="submit()" class="btn btn-success"
      >
        Save
      </button>
    </div>
  </form>
</div>
