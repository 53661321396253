import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-manage-whatsapp-connection',
  templateUrl: './manage-whatsapp-connection.component.html',
  styleUrls: ['./manage-whatsapp-connection.component.scss']
})
export class ManageWhatsappConnectionComponent implements OnInit {
  @Output() settings = new EventEmitter();
  @Output() connect = new EventEmitter();

  constructor(
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
  }
}
