import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings-component',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  merchant: MerchantProfileDetails = null;
  subscriptions: Subscription[] = [];
  section = 'general';

  constructor(
    private _auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.section = params.section;
    });
    this.subscriptions.push(
      this._auth.$merchant.subscribe({
        next: (merchant) => {
          this.merchant = merchant;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  changeSection(section) {
    this.router.navigate([`/settings`, section]);
  }
}
