import { FormGroup } from '@angular/forms';

export const checkNull = (str) => {
  return (!str || str === 'null') ? '' : str;
};

export const populateUpfrontValues = (no_of_months, credit_price) => {
  if (!no_of_months && !credit_price) return [];
  const values = [];
  if (+no_of_months > 0) {
    for (let i = 1; i <= Array(+no_of_months).slice(0, 3).length; i++) {
      if (i === 1) {
        values.push({
          amount: credit_price / +no_of_months,
          label: 'Pay 1st month'
        });
      } else {
        values.push({
          amount: (credit_price / +no_of_months) * i,
          label: `Pay up to ${ i } months`
        });
      }
    }
  }
  values.push({ amount: credit_price / 2, label: 'Pay Half' });
  values.push({ amount: credit_price / 4, label: 'Pay Quarter' });
  return values;
};

export const resetAndClearCreditValidators = (form: FormGroup) => {
  form.get('credit_price').reset();
  form.get('monthly_amount').reset();
  form.get('no_of_months').reset();
  form.get('upfront_amount').reset();
  form.get('credit_price').clearValidators();
  form.get('credit_price').updateValueAndValidity();
  form.get('monthly_amount').clearValidators();
  form.get('monthly_amount').updateValueAndValidity();
  form.get('no_of_months').clearValidators();
  form.get('no_of_months').updateValueAndValidity();
  form.get('upfront_amount').clearValidators();
  form.get('upfront_amount').updateValueAndValidity();
};

export const nPercentOf = (n, amount) => {
  return (n / 100) * amount;
};

export const jsonToBase64 = (obj) => {
  const str = JSON.stringify(obj);
  return btoa(str);
};
