<div class="row row-sparse d-none d-md-flex p-5">
  <div class="col-6 product-single-gallery" style="align-self: flex-start; position: sticky; top: 50px;">
    <div class="mb-2">
      <swiper [slidesPerView]="1" [spaceBetween]="10" navigation pagination>
        <ng-template swiperSlide *ngFor="let picture of pictures">
          <img [src]="picture" class="h-100 w-100 rounded border" alt="product"/>
        </ng-template>
      </swiper>
    </div>

    <div class="d-flex">
      <app-square-box *ngFor="let picture of pictures; index as i" class="ms-2">
        <img [src]="picture" alt="product h-100 w-100"/>
      </app-square-box>
    </div>
  </div>

  <div class="col-6 product-single-details">
    <h5 class="title mb-1">{{ product?.name }}</h5>

    <div class="ratings-container">
      <div class="product-ratings">
        <span class="ratings" style="width: 60%;"></span>
      </div>
    </div>

    <div *ngIf="product?.discount > 0; else NODISCOUNT" class="price-box">
      <span class="old-price">₦ {{ product?.price | currency:'NGN':'₦':'0.0' }}</span>
      <span class="product-price">₦{{product?.discount_price | currency:'NGN':'₦':'0.0'}}</span>
    </div>

    <small class="alert alert-primary fw-bold rounded-sm mb-2 p-2 d-inline-flex" *ngIf="product.quantity">
      {{ product.quantity }} items in stock
    </small>
    <small class="alert alert-danger fw-bold rounded-sm mb-2 p-2 d-inline-flex" *ngIf="!product.quantity">
      Out of stock
    </small>

    <ng-template #NODISCOUNT>
      <div class="price-box">
        <span class="product-price">₦ {{ product?.price | currency:'NGN':'₦':'0.0' }}</span>
      </div>
    </ng-template>

    <hr class="divider"/>

    <div class="row mt-0 disc-content py-2">
      <div class="col-12 my-1">
        <span class="me-3 text-muted" style="font-size: 1.2rem;vertical-align: center;width: 40px;position: relative;">
          <i class="fas fa-credit-card d-inline" style="width: 100%;"></i>
        </span>
        <span *ngIf="product?.sell_on_credit == 1; else outRight">
          {{ product?.price | currency:'NGN':'₦':'0.0' }}
          <span *ngIf="_auth.merchant.partnership == 0">
            / {{ product?.no_of_months }} month(s)
          </span>
        </span>
        <ng-template #outRight>
          <span style="font-size: 1.2rem;vertical-align: center;width: 40px;position: relative;">
            {{ product?.price | currency:'NGN':'₦':'0.0' }} outright
          </span>
        </ng-template>
      </div>
      <div [hidden]="!(product?.upfront_amount && product?.credit_price == true)" class="col-12 my-1">
        <span class="me-3 text-muted" style="font-size: 1.2rem;vertical-align: center;width: 40px;position: relative;">
          <i class="fas fa-money-bill d-inline" style="width: 100%;"></i>
        </span>
        <span *ngIf="product?.upfront_amount">{{ product?.upfront_amount | currency:'NGN':'₦':'0.0' }}</span>
        <span *ngIf="!product?.upfront_amount">No upfront amount</span>
      </div>
      <div class="col-12 my-1 d-flex justify-content-between align-items-center">
        <div>
          <span
            style="font-size: 1.2rem;vertical-align: center;width: 40px;position: relative;" class="me-3 text-muted"
          >
            <i class="fas fa-link d-inline" style="width: 100%;"></i>
          </span>
          <span class="ms-1" id="itemLink">{{ product?.item_link ? product?.item_link : "No link found" }}</span>
        </div>
        <button *ngIf="product?.item_link" (click)="copyToClipBoard('itemLink')" class="btn btn-link btn-xs ms-2">
          Copy
        </button>
        <span *ngIf="!product?.item_link" (click)="generateALink($event)" class="btn btn-outline-success">
          Generate link
        </span>
      </div>
    </div>

    <hr class="divider"/>

    <div class="d-flex py-2 align-items-center">
      <button (click)="edit.emit()" class="btn btn-md btn-outline-secondary">
        Edit
      </button>
      <button (click)="modal = 'more'" class="btn btn-md btn-outline-secondary ms-2">
        More
      </button>

      <div class="d-flex ms-auto align-items-center">
        <div *ngIf="product.disabled === 0" class="badge bg-success py-1 px-2">Enabled</div>
        <div *ngIf="product.disabled === 1" class="badge bg-danger py-1 px-2">Disabled</div>
        <div class="form-check form-switch ms-2">
          <input
            class="form-check-input" type="checkbox" [checked]="product.disabled === 0"
            (change)="handleEnabledChange($event)"
          />
        </div>
      </div>
    </div>

    <hr class="divider"/>

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center" *ngIf="product?.global == 0">
        <div class="title text-dark pt-1 fw-normal me-2">Share:</div>
        <a role="button" tabindex="0" class="icon-button" (click)="socialShareLink($event, 'facebook')">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a role="button" tabindex="0" class="icon-button ms-2" (click)="socialShareLink($event, 'twitter')">
          <i class="fab fa-twitter"></i>
        </a>
        <a (click)="shareThisProduct()" role="button" tabindex="0" class="icon-button ms-2">
          <i class="fa fa-share-alt"></i>
        </a>
      </div>
      <div class="d-flex">
        <div class="d-inline-flex align-items-center">
          <i class="fa fa-thumbs-up me-1 mt-n1 text-primary"></i>
          <span class="text-muted">{{ product?.likes }}</span>
        </div>
        <button
          (click)="modal = 'comments'" class="btn btn-light px-2 py-1 rounded-full ms-3"
          [disabled]="!product?.total_ratings"
        >
          <i class="fa fa-comment text-danger me-1"></i>
          <span class="text-muted">{{ product?.total_ratings }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->

<div class="d-md-none h-100">
  <div class="h-100 d-flex flex-column">
    <div class="position-relative mb-2 overflow-hidden" style="height: 70%">
      <div
        *ngIf="_auth.merchant.partnership == 1" class="position-absolute text-light px-2 py-1"
        style="top: 0;left: 0;z-index: 2;width: 100%;text-align: center;background-color: #226fc4;"
      >
        Bank covered
      </div>
      <swiper [slidesPerView]="1" [spaceBetween]="10" navigation class="h-100">
        <ng-template swiperSlide *ngFor="let picture of pictures">
          <img [src]="picture" class="h-100 w-100 fit-cover" alt="product"/>
        </ng-template>
      </swiper>
      <div class="floating-title d-flex flex-column">
        <div class="title">{{ product?.name }}</div>
        <div class="d-flex mt-1 align-items-center">
          <div *ngIf="product?.discount > 0; else NODISCOUNT" class="price-box">
            <span class="old-price text-white">{{ product?.price | currency:'NGN':'₦':'0.0' }}</span>
            <span class="product-price">{{product?.discount_price | currency:'NGN':'₦':'0.0'}}</span>
          </div>
          <ng-template #NODISCOUNT>
            <div class="price-box">
              <span class="product-price">{{ product?.price | currency:'NGN':'₦':'0.0' }}</span>
            </div>
          </ng-template>
          <div class="d-flex ms-auto align-items-center ms-2">
            <div *ngIf="product.disabled === 0" class="badge bg-success py-1 px-2">Enabled</div>
            <div *ngIf="product.disabled === 1" class="badge bg-danger py-1 px-2">Disabled</div>
            <div class="form-check form-switch ms-2">
              <input
                class="form-check-input" type="checkbox" [checked]="product.disabled === 0"
                (change)="handleEnabledChange($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 py-5 overflow-y-auto d-flex flex-column justify-content-center flex-grow-1">
      <div class="d-flex flex-row justify-content-around text-center">
        <div (click)="copyToClipBoard('itemLink')" class="d-flex flex-column align-items-center cursor-pointer">
          <i class="fa fa-link text-info" style="font-size: 1.4rem;"></i>
          <small class="mt-2">Copy link </small>
        </div>
        <div (click)="shareThisProduct()" class="d-flex flex-column align-items-center cursor-pointer">
          <i class="fa fa-share-alt text-danger" style="font-size: 1.4rem;"></i>
          <small class="mt-2">Share link</small>
        </div>
        <div (click)="modal = 'more'" class="d-flex flex-column align-items-center cursor-pointer">
          <i class="fa fa-ellipsis-h text-warning" style="font-size: 1.4rem;"></i>
          <small class="mt-2">More</small>
        </div>
      </div>
    </div>

    <div class="p-3 pb-4 border-top text-center px-5">
      <button (click)="edit.emit()" class="btn btn-md btn-outline-primary btn-block">
        <i class="fa fa-edit me-2"></i> Edit
      </button>
    </div>
  </div>
</div>

<app-dialog *ngIf="modal === 'more'" (close)="modal = ''" [close-button]="false" #modalMore [nested]="true">
  <div>
    <span *ngIf="product?.sell_on_credit == 1; else outRight" class="fw-bold">
      <span *ngIf="_auth.merchant.partnership == 0">
        {{ +product?.price / +product?.no_of_months | currency:'NGN':'₦':'0.0' }} / month
      </span>
      <span *ngIf="_auth.merchant.partnership == 1">
        {{ product.price | currency:'NGN':'₦':'0.0' }}
      </span>
      <span class="d-block">
        {{ product?.no_of_months }} month(s) repayment
      </span>
    </span>
    <ng-template #outRight>
      <span class="fw-bold">{{ product?.price | currency:'NGN':'₦':'0.0' }} outright</span>
    </ng-template>
  </div>

  <div [hidden]="!(product?.upfront_amount && product?.credit_price == true)" class="col-12 my-1">
    <span class="me-3 text-muted">
      <i class="fas fa-money-bill d-inline" style="width: 100%;"></i>
    </span>
    {{ (product?.upfront_amount | currency:'NGN':'₦':'0.0') || "No Upfront amount" }}
  </div>

  <hr class="divider my-4"/>

  <div class="product-desc m-0">
    <p *ngIf="!product?.description">No Description</p>
    <p *ngIf="product?.description == 'null'">No Description</p>
    <p *ngIf="product?.description != 'null'" [innerHTML]="product.description"></p>
  </div>

  <div class="text-center mt-3 mb-3 border-top pt-4 pb-1">
    <button (click)="modal = 'comments'" class="btn btn-light px-4 py-1 rounded-full" *ngIf="product?.total_ratings">
      <i class="fa fa-comment text-primary me-3"></i>
      <span class="text-muted">View Comments ({{ product?.total_ratings }})</span>
    </button>
    <button class="btn btn-light px-4 py-1 rounded-full" disabled *ngIf="!product?.total_ratings">
      <i class="fa fa-comment text-danger me-3"></i>
      <span class="text-muted">No comments</span>
    </button>
  </div>

  <div class="card bg-off-white d-flex flex-row justify-content-around text-center mb-4">
    <div class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-heart text-danger" style="font-size: 1.4rem;"></i>
      <small class="mt-2">{{ product?.likes }} likes</small>
    </div>
    <div class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-eye text-info" style="font-size: 1.4rem;"></i>
      <small class="mt-2">{{ product?.views }} views</small>
    </div>
    <div class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-chart-line text-primary" style="font-size: 1.4rem;"></i>
      <small class="mt-2">{{ product?.quantity_sold }} sold</small>
    </div>
  </div>
  <div class="text-center">
    <button (click)="modalMore.dismiss(); modal = 'delete'" class="btn btn-md btn-link text-danger">
      <i class="fa fa-trash me-2"></i>{{ product?.global == 0 ? 'Delete' : 'Remove'}}
    </button>
  </div>
</app-dialog>

<app-dialog
  *ngIf="modal === 'delete'" (close)="modal = ''" #modalDelete [closable]="false" [nested]="true"
  [centered]="true" [confirmation]="true"
>
  <div class="text-center">
    <div class="text-center mx-auto mb-3" style="max-width: 350px;">
      Are you sure you want to delete this product? This action is irreversible
    </div>
    <button [disabled]="deleting" (click)="modalDelete.dismiss()" class="btn btn-outline-secondary mb-0 noDelete">
      No, Cancel
    </button>
    <button [disabled]="deleting" class="btn btn-outline-danger ms-2" (click)="delete()">
      <span *ngIf="deleting" class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
      Yes, delete
    </button>
  </div>
</app-dialog>

<app-dialog
  *ngIf="modal === 'share'" (close)="modal = ''" [no-padding]="true" [nested]="true" size="sm"
  title="Share product link"
>
  <app-social-share
    [link]="this.product.item_link" [description]="this.product.description || 'Come see my ' + this.product.name"
    (search)="modal = 'search-items'"
  >
  </app-social-share>
</app-dialog>

<app-dialog *ngIf="modal === 'comments'" (close)="modal = ''" [nested]="true" [no-padding]="true" size="sm">
  <app-product-comments [product]="product"></app-product-comments>
</app-dialog>
