import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-loan-status',
  templateUrl: './loan-status.component.html',
  styleUrls: ['./loan-status.component.scss']
})
export class LoanStatusComponent implements OnInit {

  modal = '';
  quickLoans = [
    { amount: 50000, tenor: 3 },
    { amount: 100000, tenor: 0 },
    { amount: 250000, tenor: 0 },
    { amount: 500000, tenor: 0 },
    { amount: 1000000, tenor: 0 },
    { amount: 5000000, tenor: 0 },
    { amount: 10000000, tenor: 0 },
    { amount: 25000000, tenor: 0 },
    { amount: 50000000, tenor: 0 },
  ];
  loan = {
    amount: 5000,
    tenor: 1
  };

  constructor(
    public _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  selectQuickLoan(loan) {
    this.loan = loan;
    this.launchModalIfProfileComplete('business-loan');
  }

  showPendingSetup() {
    this.modal = '';
    this._general.showPendingSetup();
  }

  launchModal(modal) {
    this.modal = modal;
  }

  launchModalIfProfileComplete(modal) {
    if (!this._auth.canShare) {
      return this.showPendingSetup();
    }
    this.modal = modal;
  }
}
