import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-proxy-image',
  templateUrl: './proxy-image.component.html',
  styleUrls: ['./proxy-image.component.scss']
})
export class ProxyImageComponent implements OnInit, AfterViewInit {
  @ViewChild('image') image: ElementRef;
  @Input() src: string;
  url = '';
  error = false;
  loaded = false;

  constructor(private _general: GeneralService) {
  }

  ngOnInit(): void {
    if (this.src.startsWith('data:image/')) this.url = this.src;
    else this.url = 'https://proxy.cclan.cc/' + this.src;
  }

  ngAfterViewInit(): void {
    this.initListeners();
  }

  initListeners() {
    const image = this.image.nativeElement;
    image.onload = () => {
      this.loaded = true;
    };
    image.onerror = () => {
      this.error = true;
    };
  }
}
