<a *ngIf="!_auth.merchant.sender_id" class="card mb-4 bg-off-white">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h5 class="title mb-1">Enable whatsapp integration</h5>
      <p class="mb-0">Allow your customer buy easily via Whatsapp</p>
      <button (click)="modal.emit('connect-whatsapp')" class="btn btn-outline-success mt-4">Enable</button>
    </div>
    <div>
      <img src="assets/images/whatsapp.svg" width="52" alt="">
    </div>
  </div>
</a>

<a *ngIf="_auth.merchant.sender_id" class="card mb-4 bg-light-green">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h5 class="title mb-1">Whatsapp integration active</h5>
      <p class="mb-0">Customer can now buy easily via Whatsapp</p>
      <button (click)="modal.emit('manage-whatsapp')" class="btn btn-outline-success mt-4">
        Manage connection
      </button>
    </div>
    <div>
      <img src="assets/images/whatsapp.svg" width="52" alt="">
    </div>
  </div>
</a>
