<div class="row wrap">
  <div class="col-0 col-sm-6 left-content py-5 d-none d-md-block"></div>
  <div class="col-12 col-md-6 left">
    <div class="content">
      <div class="my-md-auto">
        <!-- Do you sell online -->
        <div *ngIf="view === 'do-you-sell-online'">
          <h5 class="title mb-3">
            Do you currently sell your products online
          </h5>
          <p class="text-muted mb-5">Or Instagram, Jiji, Flutterwave, etc.</p>
          <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
               (click)="pushView('whatsapp-business')">
            Yes I do
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
               (click)="pushView('physical-store')">
            No I dont
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <p class="mt-5">
          <span class="d-block mt-3">
            Already have an account ?
            <a routerLink="/login" class="text-primary" id="login">Login</a>
          </span>
          </p>
        </div>

        <!-- Provide Instagram -->
        <div *ngIf="view === 'provide-instagram'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            Offer credit to your customers on Instagram
          </h5>
          <p class="text-muted mb-5">Kindly provide your instagram username</p>
          <div class="form-group">
            <label for="ig_username"></label>
            <input
              [(ngModel)]="instagram" type="text" class="form-control form-control-lg" id="ig_username"
              placeholder="Enter your instagram username"
            >
          </div>
          <button (click)="pushView('other-platforms')" [disabled]="!instagram" class="btn btn-primary mt-4">Next
          </button>
          <p class="mt-4">
          <span class="d-block mt-3">
            No I dont sell on instagram
            <button (click)="instagram = ''; pushView('other-platforms')" class="text-primary btn btn-link px-0">
              Skip
            </button>
          </span>
          </p>
        </div>

        <!-- Provide Whatsapp -->
        <div *ngIf="view === 'whatsapp-business'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            Sell directly on Whatsapp
          </h5>
          <p class="text-muted mb-5">Kindly provide your whatsapp business number</p>
          <div class="form-group">
            <label for="whatsapp_no"></label>
            <input [(ngModel)]="whatsapp_no" type="tel" class="form-control form-control-lg" id="whatsapp_no"
                   placeholder="Enter whatsapp number">
          </div>
          <button (click)="pushView('provide-instagram')" [disabled]="!whatsapp_no" class="btn btn-primary mt-4">Next
          </button>
          <p class="mt-4">
          <span class="d-block mt-3">
            No I dont sell on whatsapp business
            <button (click)="whatsapp_no = ''; pushView('provide-instagram')" class="text-primary btn btn-link px-0">Skip</button>
          </span>
          </p>
        </div>

        <!-- Do you sell on other platforms -->
        <div *ngIf="view === 'other-platforms'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            You can also sell on credit on other platforms
          </h5>
          <p class="text-muted mb-5">Sell other platforms you sell on below</p>
          <div class="other-platforms mt-4">
            <swiper [slidesPerView]="2.5" [spaceBetween]="10">
              <template *ngFor="let platform of otherPlatforms">
                <ng-template swiperSlide>
                  <div
                    class="card p-0 my-3" [ngClass]="{'border-success': selectedPlatforms.includes(platform.name)}"
                    style="height: 200px"
                  >
                    <div class="flex-grow-1 p-4 d-flex flex-column justify-content-center align-items-center">
                      <img [src]="platform.image" [alt]="platform.name"/>
                    </div>
                    <button
                      (click)="selectPlatform(platform)" class="btn btn-block btn-card-bottom"
                      [ngClass]="{'btn-success': selectedPlatforms.includes(platform.name)}"
                    >
                      {{ selectedPlatforms.includes(platform.name) ? 'Remove' : 'Select'}}
                    </button>
                  </div>
                </ng-template>
              </template>
            </swiper>
          </div>

          <button
            (click)="pushView('create-account')" [disabled]="!selectedPlatforms.length" class="btn btn-primary mt-4"
          >
            Next
          </button>
          <p class="mt-4">
            No I dont have any of these accounts
            <button
              (click)="selectedPlatforms = []; (instagram || whatsapp_no) ? pushView('create-account') : pushView('setup-info')"
              class="text-primary btn btn-link px-0"
            >
              Skip
            </button>
          </p>
        </div>

        <!-- Setup product -->
        <form *ngIf="view.startsWith('setup')" [formGroup]="productForm">
          <!-- Setup product - Info -->
          <div *ngIf="view === 'setup-info'">
            <button class="btn btn-link mb-4 px-0" (click)="popView()">
              <i class="fas fa-arrow-left me-2"></i> Back
            </button>
            <h5 class="title">Let's setup your first product online</h5>

            <div class="form-group mb-4 required-field">
              <label for="productName">Name of Product</label>
              <input type="text" formControlName="name" class="form-control" id="productName"/>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group mb-4 required-field">
                  <label>Products Category</label>
                  <select formControlName="category_id" class="form-control">
                    <option value="" selected>Select Categories</option>
                    <option [value]="productCategory?.id" *ngFor="let productCategory of categories; index as i">
                      {{ productCategory?.name | titlecase }}</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group mb-4 required-field modifiedInputParent">
                  <label>Price</label>
                  <input type="number" onfocus="this.type = 'number'" autocomplete="off" class="form-control ps-4"
                         formControlName="price"/>
                  <small class="money" style="top: 36px">&#8358;</small>
                </div>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-between align-items-center">
              <button
                (click)="pushView('setup-credit-option')" [disabled]="!productForm.valid"
                class="btn btn-primary btn-lg btn-next"
              >
                Next
              </button>
            </div>

            <!--            <p class="mt-4">-->
            <!--            <span class="d-block mt-4">-->
            <!--              I dont have products,-->
            <!--              <a class="text-primary" href="javascript:;" (click)="modal = 'setup-info'">please help me</a>-->
            <!--            </span>-->
            <!--            </p>-->
          </div>

          <!-- Setup product - Credit option -->
          <div *ngIf="view === 'setup-credit-option'">
            <button class="btn btn-link mb-4 px-0" (click)="popView()">
              <i class="fas fa-arrow-left me-2"></i> Back
            </button>
            <h5 class="title">Do you want to sell this product on credit?</h5>

            <div (click)="setSellOnCredit('1')"
                 class="card flex-row align-items-center justify-content-between px-4 py-4 cursor-pointer">
              <span>Yes, sell on credit</span>
              <i class="fas fa-angle-right float-right ms-2"></i>
            </div>
            <div (click)="setSellOnCredit('0')"
                 class="card flex-row align-items-center justify-content-between px-4 py-4 cursor-pointer mt-2">
              <span>No, outright payment</span>
              <i class="fas fa-angle-right float-right ms-2"></i>
            </div>
          </div>

          <!-- Setup product - Credit info -->
          <div *ngIf="view === 'setup-credit'">
            <button class="btn btn-link mb-4 px-0" (click)="popView()">
              <i class="fas fa-arrow-left me-2"></i> Back
            </button>
            <h5 class="title">Enter credit information</h5>

            <div class="form-group mb-4 modifiedInputParent">
              <label for="creditPrice">Credit Price</label>
              <!-- (input)="creditCalculation($event)" -->
              <input (change)="populateUpfrontValues()" type="number" formControlName="credit_price"
                     class="form-control ps-4" id="creditPrice" placeholder="eg N3,500"/>
              <small class="money">&#8358;</small>
              <!-- <small *ngIf="creditPriceIsSmall" class="text-danger">
                Credit price cannot be lower than current price
              </small> -->
            </div>

            <div class="form-group mb-4 required-field">
              <label>How many installments?</label>
              <select formControlName="no_of_months" class="form-control" (change)="populateUpfrontValues()">
                <option value="" selected>Enter number of installments</option>
                <option value="-1">7 days</option>
                <option value="-2">15 days</option>
                <option value="1">1 month</option>
                <option value="2">2 months</option>
                <option value="3">3 months</option>
                <option value="4">4 months</option>
                <option value="5">5 months</option>
                <option value="6">6 months</option>
                <option value="9">9 months</option>
                <option value="12">12 months</option>
              </select>
            </div>

            <div class="form-group mb-4 required-field">
              <label>How much does buyers contribute upfront?</label>
              <select class="form-control" (change)="handleUpfrontChange($event.target.value)">
                <option value="" selected>Select one</option>
                <option [value]="item.amount" *ngFor="let item of upfrontValues">{{ item.label }}</option>
                <option value="specify">Specify amount</option>
              </select>
            </div>

            <div [style.display]="specifyUpfront ? 'block': 'none'"
                 class="form-group mb-4 required-field modifiedInputParent">
              <label>Specify upfront amount?</label>
              <input type="text" autocomplete="off" class="form-control ps-4" formControlName="upfront_amount"/>
              <small class="money" style="top: 36px;">&#8358;</small>
            </div>

            <button (click)="pushView('setup-picture')" [disabled]="!productForm.valid" type="submit"
                    class="btn btn-primary btn-lg btn-next mt-4">
              Next
            </button>
          </div>

          <!-- Setup product - Picture -->
          <div *ngIf="view === 'setup-picture'">
            <button class="btn btn-link mb-4 px-0" (click)="popView()">
              <i class="fas fa-arrow-left me-2"></i> Back
            </button>
            <h5 class="title mb-3">
              Add picture
            </h5>
            <label class="card p-0 mb-4 cursor-pointer" style="max-width: 200px">
              <input hidden type="file" id="take-picture" accept="image/*" (change)="fileChangeEvent($event)">
              <img [src]="productForm.get('primary_picture').value || 'assets/images/plus.svg'" class="rounded" alt="">
            </label>
            <button (click)="pushView('create-account')" [disabled]="!productForm.get('primary_picture').value"
                    class="btn btn-primary btn-md mt-4 add-img-btn">
              Next
            </button>
            <div class="mt-4">
              <button class="btn btn-link px-0" (click)="pushView('setup-global-search')">
                Please help me with pictures
              </button>
            </div>
          </div>

          <!-- Setup product - Global search -->
          <div *ngIf="view === 'setup-global-search'">
            <button class="btn btn-link mb-4 px-0" (click)="popView()">
              <i class="fas fa-arrow-left me-2"></i> Back
            </button>
            <app-global-search [embedded]="true"></app-global-search>
          </div>
        </form>

        <!-- Physical store -->
        <div *ngIf="view === 'physical-store'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            Do you sell from a physical store?
          </h5>
          <p class="text-muted mb-5">We set you up for credit there.</p>
          <div
            class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
            (click)="pushView('categories')"
          >
            Yes I have a physical store
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <div
            class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
            (click)="pushView('setup-info')"
          >
            No I dont
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
        </div>

        <!-- Categories -->
        <div *ngIf="view === 'categories'">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-link mb-4 px-0" (click)="popView()">
                <i class="fas fa-arrow-left me-2"></i> Back
              </button>
              <h5 class="title">Select the categories of products you sell</h5>
              <div *ngFor="let slide of businessInterests; index as i"
                   class="form-group form-group-custom-control m-0 mb-2">
                <div class="custom-control custom-checkbox p-0">
                  <input (change)="selectCategory($event, slide)" type="checkbox" class="custom-control-input"
                         [id]="slide?.title" [checked]="selectedCategories.includes(slide.id)">
                  <label class="custom-control-label" [attr.for]="slide?.title"> {{ slide?.title }}
                  </label>
                </div>
              </div>

              <button (click)="pushView('start-selling-online')" [disabled]="selectedCategories.length < 1"
                      class="btn btn-done btn-primary btn-lg mt-5">
                Next
              </button>
            </div>
          </div>
        </div>

        <!-- Start selling online -->
        <div *ngIf="view === 'start-selling-online'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            Would you also like to start selling online
          </h5>
          <p class="text-muted mb-5">We set you up for credit there.</p>
          <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
               (click)="pushView('setup-info')">
            Yes please set me up
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <div
            class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
            (click)="setOnline('0'); pushView('create-account')"
          >
            No offline alone
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
        </div>

        <!-- Business interest -->
        <div *ngIf="view === 'interests'" class="onboard-content">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-link mb-4 px-0" (click)="popView()">
                <i class="fas fa-arrow-left me-2"></i> Back
              </button>
              <h5 class="title mb-3">Specify your business interest</h5>
              <div *ngIf="selectedInterests.length" class="badge bg-danger mb-4 px-2 py-2 font-weight-light">
                {{ selectedInterests.length }} interest(s) selected
              </div>
              <owl-carousel-o class="slideForBoard" [options]="customisedOptions">
                <ng-container *ngFor="let slide of businessInterests; index as i">
                  <ng-template [width]="270" carouselSlide>
                    <div
                      class="parentImg border p-4 modifiedScroll rounded me-2"
                      style="position: relative; height: 350px; overflow: auto;"
                    >
                      <!-- <img [src]="slide?.primary_picture" [alt]="slide?.name" /> -->
                      <div class="title mb-3">{{ slide?.title }}</div>
                      <div
                        *ngFor="let interest of slide.interests; index as i"
                        class="form-group form-group-custom-control m-0 mb-2"
                      >
                        <div class="custom-control custom-checkbox p-0">
                          <input
                            (click)="selectInterest($event, interest)" type="checkbox" class="custom-control-input"
                            [id]="interest?.interest"
                          >
                          <label class="custom-control-label" [attr.for]="interest?.interest"> {{ interest?.interest }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>

              <button
                (click)="pushView('create-account')" [disabled]="selectedInterests.length < 1"
                class="btn btn-done btn-primary btn-lg mt-5"
              >
                Done
              </button>
            </div>
          </div>
        </div>

        <!-- Create account -->
        <div *ngIf="view === 'create-account'">
          <div class="">
            <button (click)="popView()" class="btn btn-link mb-4 px-0">
              <i class="fas fa-arrow-left me-2"></i>Back
            </button>
            <h5 class="title mb-5">
              Finally create your Creditclan account
            </h5>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="merchantForm" class="m-0">
                  <div class="form-group mb-4 required-field">
                    <label for="businessName">Business name</label>
                    <input type="text" formControlName="name" class="form-control" id="businessName"/>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group mb-4 required-field">
                        <label for="email">Email or phone</label>
                        <input formControlName='emailOrPhone' type="email" class="form-control" id="email"/>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-4 required-field">
                    <label for="inputPassword">Password</label>
                    <input formControlName="password" type="password" id="inputPassword" class="form-control"
                           aria-describedby="passwordHelpInline" autocomplete="off"/>
                    <small id="passwordHelpInline" class="text-muted">
                      Must be 8-20 characters long.
                    </small>
                  </div>

                  <div class="form-group form-check d-flex align-items-center mt-5">
                    <input type="checkbox" class="form-check-input mt-0" id="exampleCheck1" [(ngModel)]="termsAccepted"
                           [ngModelOptions]="{standalone: true}">
                    <label class="form-check-label ms-2" for="exampleCheck1">I accept
                      <a class="text-primary">terms and conditions</a>
                    </label>
                  </div>

                  <div class="mt-5">
                    <button [disabled]="!merchantForm.valid || !termsAccepted || loading" (click)="submitDetails()"
                            class="btn btn-lg btn-login btn-primary">
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Creating -->
        <div *ngIf="view === 'creating'">
          <div class="loader-bounce">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
          <div class="text-center mt-5">
            <h5 class="w-75 my-0 mx-auto">
              Creating your page
            </h5>
            <p class="mt-3 text-muted mx-auto" style="max-width: 300px">No doubt your sales will blow by 500%</p>
          </div>
        </div>

        <!-- Fetching instagram posts -->
        <div *ngIf="view === 'fetching-instagram'">
          <div class="loader-bounce">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
          <div class="text-center mt-5">
            <h5 class="w-75 my-0 mx-auto">
              Connecting to instagram
            </h5>
            <p class="mt-3 text-muted mx-auto" style="max-width: 300px">Almost done now</p>
          </div>
        </div>

        <!-- Success -->
        <div *ngIf="view === 'success'">
          <div class="mx-auto text-center d-flex flex-column align-items-center">
            <img src="../../../../assets/images/svg/shopping-cart-success.svg" class="mx-auto rounded mb-2 mt-5" alt=""
                 style="max-width: 200px;"/>
            <div class="mt-5 position-relative text-center">
              <h5 class="title mb-2">Congratulations!</h5>
              <div class="title">
                Your store has been setup successfully
              </div>
            </div>
            <a routerLink="/dashboard" class="btn mt-5 btn-primary btn-lg">
              Continue to store
            </a>
            <button (click)="share()" class="btn btn-link mt-2">
              Share link online
            </button>
          </div>
        </div>
      </div>

      <ng-template [ngIf]="view !== 'success' && view !== 'creating' && view !== 'fetching-instagram'">
        <div
          *ngIf="view.includes('instagram')" style="position: relative; bottom: 0; right: 0;"
          class="flex-grow-1 d-flex d-md-none flex-column justify-content-end align-items-end"
        >
          <img
            class="ms-auto mt-4" src="/assets/images/instagram-phone-half.png" alt="Illustration" style="width: 180px"
          />
        </div>

        <div
          *ngIf="view.includes('whatsapp')" style="position: relative; bottom: 0; right: 0;"
          class="flex-grow-1 d-flex d-md-none flex-column justify-content-end align-items-end"
        >
          <img
            class="ms-auto mt-4" src="/assets/images/whatsapp-phone-half.png" alt="Illustration" style="width: 180px"
          />
        </div>

        <div
          *ngIf="!view.includes('whatsapp') && !view.includes('instagram') && !view.includes('other-platforms')"
          style="position: relative; bottom: 0; right: 0;"
          class="flex-grow-1 d-flex d-md-none flex-column justify-content-end align-items-end"
        >
          <img
            class="ms-auto mt-4" src="/assets/images/status-phone-half.png" alt="Illustration" style="width: 180px"
          />
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- MODALS  -->
<app-dialog *ngIf="modal === 'setup-info'" (close)="modal = 'options'" [close-button]="false" #dialogSI>
  <div class="py-4">
    <h5 class="title">We will setup your credit store from scratch in 3 steps</h5>
    <ol class="p-0 ms-3 my-4">
      <li>You specify area of your business</li>
      <li>Get your own credit click</li>
      <li>Start sharing on Instagram, facebook, whatsapp, etc</li>
    </ol>
    <button class="btn btn-primary mt-5" (click)="dialogSI.dismiss(); pushView('interests')">Continue</button>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'cropping'" [closable]="false" [nested]="true">
  <div>
    <image-cropper
      style="width: 100%; max-height: 500px;" [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true" [aspectRatio]="1" [resizeToWidth]="500" [resizeToHeight]="500"
      [cropperMinWidth]="510" [cropperMinHeight]="510" [onlyScaleDown]="true" format="jpeg" [imageQuality]="92"
      (imageCropped)="imageCropped($event)"
    >
    </image-cropper>
  </div>
  <div class="text-center mt-5">
    <button (click)="imageCroppingDone()" type="button" class="btn btn-success">
      Done
    </button>
  </div>
</app-dialog>

