<div *ngIf="view === 'onboarding'" class="pb-5 mb-md-0 pb-md-0 text-center">
  <owl-carousel-o [options]="carouselOption">
    <ng-template carouselSlide>
      <div class="px-5 pt-5 text-center">
        <img src="/assets/images/svg/todo.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
        <h5 class="title mt-5 mx-auto">
          Customer walks into <span class="text-warning">your store</span>
        </h5>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="px-5 pt-5 text-center">
        <img src="/assets/images/svg/shared-goals.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
        <h5 class="title mt-5 mx-auto">
          You create a <span class="text-warning">credit order</span> for customer
        </h5>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="px-5 pt-5 text-center">
        <img src="/assets/images/svg/shared-goals.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
        <h5 class="title mt-5 mx-auto">
          Customer provides more <span class="text-warning">information for recollection</span>
        </h5>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="px-5 pt-5 text-center">
        <img src="/assets/images/svg/todo.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
        <h5 class="title mt-5 mx-auto">
          If satisfied with deal you can <span class="text-warning">checkout</span>
        </h5>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="px-5 pt-5 text-center">
        <img src="/assets/images/svg/cloud-sync.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
        <h5 class="title mt-5 mx-auto">
          We help you with <span class="text-warning">recollection of your funds</span>
        </h5>
      </div>
    </ng-template>
  </owl-carousel-o>
  <div class="d-flex justify-content-center mb-0 mt-4">
    <div class="form-group form-check d-flex align-items-center">
      <input type="checkbox" class="form-check-input mt-0 TANDC" id="dont-show"
             (change)="handleShowOnboardingChange($event)">
      <label class="form-check-label ms-2" for="dont-show">Don't show this again</label>
    </div>
  </div>
  <div class="d-md-inline-flex d-none justify-content-center mt-5">
    <button (click)="this.history = ['items-list']" class="flex-grow-1 btn btn-primary ms-2 flex-grow-1">
      Create order
    </button>
  </div>
  <div class="d-flex d-md-none justify-content-center bottom-actions">
    <button (click)="this.history = ['items-list']" class="flex-grow-1 btn btn-primary ms-2 flex-grow-1">
      Create order
    </button>
  </div>
</div>

<div *ngIf="view === 'items-list'">
  <div *ngIf="items.length">
    <h5 class="title mb-5">Items Purchased</h5>
    <div class="d-flex">
      <div *ngIf="!online" (click)="pushView('add-product-name')" class="add-box mb-5 flex-grow-1 me-1">
        <i class="fa fa-plus me-3"></i><span>Add manually</span>
      </div>
      <div (click)="pushView('search')" class="add-box mb-5 flex-grow-1 ms-1">
        <i class="fa fa-search me-3"></i><span>Search store</span>
      </div>
    </div>
    <div class="d-flex align-items-center pb-3 mb-3 border-bottom" *ngFor="let item of items">
      <img [src]="item.primary_picture || 'assets/images/plus.svg'" alt="Item" class="rounded" style="width: 30px;"/>
      <div class="flex-grow-1 px-3">
        <div>{{ item.name }}</div>
        <small>{{ item.price | currency:'NGN':'₦':'0.0' }}</small>
      </div>
      <div class="input-group input-group-sm w-auto" style="flex-wrap: nowrap;">
        <div class="input-group-prepend">
          <button (click)="decrementQuantity(item.id)" class="btn btn-outline-secondary" type="button">-</button>
        </div>
        <input type="text" class="form-control override" [(ngModel)]="item.quantity" style="max-width: 40px;min-width: 40px;">
        <div class="input-group-append">
          <button (click)="incrementQuantity(item.id)" class="btn btn-outline-secondary" type="button">+</button>
        </div>
      </div>
      <button (click)="removeItem(item.id)" class="btn btn-outline-danger btn-sm ms-2">
        <i class="fa fa-trash"></i>
      </button>
    </div>
    <button *ngIf="items.length" (click)="submitItems()" type="button" class="btn btn-primary mt-5">Next</button>
  </div>

  <div *ngIf="!items.length">
    <h5 class="title mb-5">Add product</h5>
    <div class="card mb-4 p-5 text-center cursor-pointer mt-4" (click)="pushView('add-product-name')">
      <br><br>
      <i class="fa fa-plus fa-2x mb-2 text-warning"></i> Add manually
      <br><br>
    </div>
    <div class="card mb-4 p-5 text-center cursor-pointer" (click)="pushView('search')">
      <br><br>
      <i class="fa fa-search fa-2x mb-2 text-primary"></i> Search
      <br><br>
    </div>
  </div>
</div>


<div *ngIf="view.startsWith('add-product')">
  <form [formGroup]="newProductForm">
    <!-- Product form -->
    <div *ngIf="view.startsWith('add-product-name')">
      <h5 class="title mb-5">
        <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
          <i class="fa fa-arrow-left"></i>
        </button>
        Setup product
      </h5>
      <label class="card p-0 mb-4 cursor-pointer" style="max-width: 100px">
        <input hidden type="file" id="take-picture" accept="image/*" (change)="fileChangeEvent($event)">
        <img [src]="newProductForm.get('primary_picture').value || 'assets/images/plus.svg'" class="rounded" alt=""/>
      </label>
      <div class="form-group mb-4">
        <label for="name">Product name</label>
        <input formControlName="name" class="form-control m-0" type="text" id="name">
      </div>
      <div class="d-flex align-items-center">
        <div class="form-group flex-grow-1">
          <label for="price">Product price</label>
          <input formControlName="price" class="form-control m-0" type="number" id="price">
          <small class="text-danger" *ngIf="priceMismatch">
            Product Price cannot be bigger than credit price
          </small>
        </div>
        <div class="form-group ms-3">
          <label for="name">Quantity</label>
          <div class="input-group w-auto mt-2" style="flex-wrap: nowrap;">
            <div class="input-group-prepend">
              <button
                class="btn btn-outline-secondary" type="button"
                (click)="newProductForm.get('quantity').setValue(max(1, +newProductForm.value.quantity - 1))"
              >-
              </button>
            </div>
            <input type="number" class="form-control override" formControlName="quantity"
                   style="max-width: 60px;min-width: 60px;">
            <div class="input-group-append">
              <button
                (click)="newProductForm.get('quantity').setValue(+newProductForm.value.quantity + 1)"
                class="btn btn-outline-secondary" type="button"
              >+
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <button (click)="pushView('search')" class="btn btn-link px-0">Search from store</button>
        <button (click)="submitNewProductForm()" type="button" [disabled]="newProductForm.invalid" class="btn btn-primary">
          Add product
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="view === 'sell-on-credit'">
  <h5 class="title mb-5">
    <button (click)="pushView('items-list')" class="btn btn-sm btn-link px-0 me-3">
      <i class="fa fa-arrow-left"></i>
    </button>
    Sell products on credit?
  </h5>
  <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer" (click)="setSellOnCredit(true)">
    Yes, sell on credit
    <i class="fas fa-angle-right float-right ms-2"></i>
  </div>
  <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer" (click)="setSellOnCredit(false)">
    No, instant payment
    <i class="fas fa-angle-right float-right ms-2"></i>
  </div>
</div>

<div *ngIf="view.startsWith('credit')">
  <form [formGroup]="creditForm">
    <div *ngIf="view.startsWith('credit-installment')">
      <h5 class="title mb-5">
        <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
          <i class="fa fa-arrow-left"></i>
        </button>
        For how long is credit for?
      </h5>
      <div class="row mb-4">
        <div (click)="selectInstallment(installment.value)" *ngFor="let installment of installments"
             class="col-4">
          <div class="card p-3 mb-3 bg-off-white cursor-pointer text-center"
               (click)="selectInstallment(installment.value)">
            <div class="h4 m-0">{{ installment.text }}</div>
            <small>{{ installment.unit }}</small>
          </div>
        </div>
      </div>
      <button (click)="pushView('credit-installment-specify')" *ngIf="view === 'credit-installment'" class="btn btn-link px-0">
        More
      </button>
      <div *ngIf="view === 'credit-installment-specify'" class="form-group">
        <label for="no_of_months">Number of installments</label>
        <select formControlName="no_of_months" class="form-control" id="no_of_months" (change)="populateUpfrontValues()">
          <option value="" selected>Enter number of installments</option>
          <option value="-1">7 days</option>
          <option value="-2">15 days</option>
          <option value="1">1 month</option>
          <option value="2">2 months</option>
          <option value="3">3 months</option>
          <option value="4">4 months</option>
          <option value="5">5 months</option>
          <option value="6">6 months</option>
          <option value="9">9 months</option>
          <option value="12">12 months</option>
        </select>
        <button [disabled]="!creditForm.value.no_of_months" (click)="pushView('credit-upfront')" class="btn btn-primary mt-4">
          Next
        </button>
      </div>
    </div>
    <div *ngIf="view.startsWith('credit-upfront')">
      <h5 class="title mb-5">
        <button (click)="popView()" class="btn btn-link px-0 me-2">
          <i class="fa fa-arrow-left"></i>
        </button>
        How much is paid upfront?
      </h5>
      <div *ngFor="let value of upfrontValues" (click)="selectUpfront(value.amount)"
           class="card d-flex flex-row justify-content-between align-items-center bg-off-white cursor-pointer px-4 py-3 mb-2">
        <div class="title">{{ value.label }}</div>
        <i class="fa fa-arrow-right text-muted"></i>
      </div>
      <button (click)="pushView('credit-upfront-specify')" *ngIf="view === 'credit-upfront'" class="btn btn-link px-0">
        Enter specific amount
      </button>
      <div *ngIf="view === 'credit-upfront-specify'" class="form-group">
        <label for="upfront_amount">Upfront amount</label>
        <input formControlName="upfront_amt" class="form-control" type="number" id="upfront_amount" min="0">
        <button [disabled]="!creditForm.value.upfront_amt" (click)="pushView('customer')" class="btn btn-primary mt-4">
          Next
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="view === 'customer'">
  <form [formGroup]="customerForm">
    <h5 class="title mb-5">
      <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
        <i class="fa fa-arrow-left"></i>
      </button>
      Customer information
    </h5>
    <div class="form-group">
      <label for="full_name">Full name</label>
      <input formControlName="full_name" class="form-control" type="text" id="full_name" min="0">
    </div>
    <div class="form-group mb-4 mb-md-0">
      <label for="email">Email address</label>
      <input formControlName="email" class="form-control" type="email" id="email">
    </div>
    <div class="form-group mb-4 mb-md-0">
      <label for="phone">Phone number</label>
      <input formControlName="phone_no" class="form-control" type="tel" id="phone">
    </div>
    <div class="mt-5">
      <button (click)="computeSchedule(); pushView('summary')" type="button" class="btn btn-primary mt-4" [disabled]="customerForm.invalid">
        Next
      </button>
    </div>
  </form>
</div>

<div *ngIf="view === 'summary'">
  <h5 class="title mb-5">
    <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
      <i class="fa fa-arrow-left"></i>
    </button>
    Summary
  </h5>
  <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
    <span class="title">Customer name</span>
    <span class="title fw-bold">{{ customerForm.value.full_name }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
    <span class="title">Email</span>
    <span class="title fw-bold">{{ customerForm.value.email }}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
    <span class="title">Phone</span>
    <span class="title fw-bold">{{ customerForm.value.phone_no }}</span>
  </div>
  <div *ngIf="creditForm.value.sell_on_credit === '1'">
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
      <span class="title">Credit amount</span>
      <span class="title fw-bold">{{ creditForm.value.credit_amount | currency:'NGN':'₦':'0.0' }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
      <span class="title">No. of installments (months)</span>
      <span class="title fw-bold">{{ creditForm.value.no_of_months }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
      <span class="title">Upfront amount</span>
      <span class="title fw-bold">{{ creditForm.value.upfront_amt | currency:'NGN':'₦':'0.0' }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
      <span class="title">Schedule</span>
      <button (click)="pushView('schedule')" class="btn btn-outline-secondary btn-sm ms-2">View</button>
    </div>
  </div>
  <div *ngIf="creditForm.value.sell_on_credit === '0'">
    <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
      <span class="title">Price</span>
      <span class="title fw-bold">
          {{ creditForm.value.credit_amount | currency:'NGN':'₦':'0.0' }}
      </span>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
    <span class="title">{{ items.length }} items</span>
    <button (click)="pushView('items-list')" class="btn btn-outline-secondary btn-sm ms-2">Edit items</button>
  </div>

  <div class="form-group form-check d-flex align-items-center mt-4">
    <input type="checkbox" class="form-check-input mt-0" id="exampleCheck1" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}">
    <label class="form-check-label ms-2" for="exampleCheck1">I accept terms and conditions</label>
  </div>

  <button (click)="createOrder()" type="button" [disabled]="submitting || !termsAccepted" class="btn btn-primary mt-4">
    <i class="fa fa-circle-notch fa-spin me-2" *ngIf="submitting"></i>
    Create order
  </button>
</div>

<div *ngIf="view === 'schedule'">
  <h5 class="title mb-5">
    <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
      <i class="fa fa-arrow-left"></i>
    </button>
    Schedule
  </h5>
  <table class="table table-bordered">
    <tr>
      <th class="py-2">S/N</th>
      <th class="py-2">Amount</th>
      <th class="py-2">Date</th>
    </tr>
    <tr *ngFor="let s of schedule">
      <td class="py-2">{{ s.serial }}</td>
      <td class="py-2">{{ s.amount | currency:'NGN':'₦':'0.0' }}</td>
      <td class="py-2">{{ s.date | date: "shortDate" }}</td>
    </tr>
  </table>
</div>

<div *ngIf="view === 'search'" style="min-height: 300px;">
  <h5 class="title mb-5">
    <button (click)="popView()" class="btn btn-sm btn-link px-0 me-3">
      <i class="fa fa-arrow-left"></i>
    </button>
    Search from store
  </h5>

  <div class="d-flex align-items-center" id="searchDiv">
    <input
      type="text" id="searchInput" (keydown.enter)="search()" autocomplete="off"
      class="form-control override rounded-full form-control-lg m-0 mb-1" placeholder="Search..." [(ngModel)]="query"
    />
    <button (click)="search()" class="search-button ms-2">
      <i class="fa fa-search text-primary"></i>
    </button>
  </div>
  <button *ngIf="!online" (click)="pushView('add-product-name')" class="btn btn-link px-0 mt-2">
    Add product manually
  </button>

  <div *ngIf="searching" class="row mt-4">
    <div class="col-6 col-md-4 mb-3" *ngFor="let i of [1,2]">
      <div class="card p-0 skeleton fit-content">
        <img [src]="'assets/images/plus.svg'" alt="">
      </div>
      <div class="title skeleton fit-content mt-2">Product name</div>
      <small class="skeleton fit-content">N200,000</small>
    </div>
  </div>
  <div class="row mt-4" *ngIf="!searching && searchResults.length">
    <div class="col-6 col-md-4 mb-3" *ngFor="let item of searchResults">
      <div class="card p-0 cursor-pointer" (click)="selectFromStore(item)">
        <img [src]="item.primary_picture" alt="">
      </div>
      <div class="title mt-2">{{ item.name }}</div>
      <small>{{ item.price | currency:'NGN':'₦':'0.0' }}</small>
    </div>
  </div>
</div>

<div *ngIf="view === 'share'" class="d-flex flex-column align-items-center text-center">
  <h5 class="title mb-5">Share this link with customer</h5>
  <i class="fa fa-share-alt fa-10x text-primary"></i>
  <div class="d-flex mt-5">
    <button (click)="viewRequest()" class="btn btn-primary">
      View order
    </button>
    <button (click)="shareLink()" class="btn btn-outline-secondary ms-2">Share link</button>
  </div>
  <button (click)="copyLink()" class="btn btn-link mt-3">Copy link</button>
</div>

<!-- MODALS  -->

<app-dialog *ngIf="modal === 'cropping'" [closable]="false" [nested]="true" [centered]="true">
  <div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)" [maintainAspectRatio]="true" [aspectRatio]="1"
      [resizeToWidth]="500" [resizeToHeight]="500" [cropperMinWidth]="500" [cropperMinHeight]="500" [onlyScaleDown]="true" format="jpeg"
      [imageQuality]="92"
    >
    </image-cropper>
  </div>
  <div class="text-center mt-5">
    <button (click)="imageCroppingDone()" type="button" class="btn btn-success">
      Done
    </button>
  </div>
</app-dialog>
