import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MerchantItems } from '../../models/items';
import { checkNull, populateUpfrontValues, resetAndClearCreditValidators } from '../../lib/utils';
import { GeneralService } from '../../services/general.service';
import { ApiService } from '../../services/api.service';
import { StoreService } from '../../services/store.service';
import { ProductCategories } from '../../models/categories';
import { AuthService } from '../../services/auth.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { clothSizes, genders, shippingSources, shoeSizesEuro, shoeSizesUK } from 'src/app/lib/variations';
import { ImageCroppedEvent } from 'ngx-image-cropper';

const editorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '0',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Product description',
  defaultParagraphSeparator: '',
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['fontSize', 'fontName', 'textColor', 'backgroundColor'],
    ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
    ['subscript', 'superscript', 'heading', 'indent', 'outdent']
  ]
};

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  @ViewChild('pictureInput') pictureInput: ElementRef;
  @Input() product: MerchantItems;
  @Output() done = new EventEmitter();
  @Output() update = new EventEmitter();
  productForm: FormGroup;
  tagForm: FormGroup;
  primary_picture = '';
  secondary_picture = '';
  tertiary_picture = '';
  fourth_picture = '';
  fifth_picture = '';
  upfrontValues = [];
  specifyUpfront = false;
  selectedSizes = [];
  selectedGenders = [];
  selectedTags = [];
  loading = false;
  rawImages = {};
  categories = ProductCategories().data;
  editorConfig = editorConfig;
  addingTag = false;
  tags = [];
  activePicture = '';
  imageChangedEvent: any;
  croppedImage: any;
  modal = '';
  clothSizes = clothSizes;
  shoeSizesEuro = shoeSizesEuro;
  shoeSizesUK = shoeSizesUK;
  genders = genders;
  sources = shippingSources;
  deleting = false;
  mobile = window.innerWidth < 768;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _store: StoreService,
    public _general: GeneralService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getTags();
  }

  initForm() {
    this.tagForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.productForm = this.fb.group({
      name: [this.product.name, Validators.required],
      category_id: [this.product.category_id, Validators.required],
      price: [this.product.price, Validators.required],
      credit_price: [this.product.credit_price],
      sell_on_credit: [this.product.sell_on_credit?.toString()],
      monthly_amount: [this.product.monthly_amount],
      no_of_months: [this.product.no_of_months],
      upfront_amount_option: '',
      upfront_amount: [this.product.upfront_amount],
      description: [this.product.description],
      discount_price: [this.product.discount_price],
      discount_expiry_date: [this.product.discount_expiry_date?.substr(0, 10)],
      quantity: [this.product.quantity],
      minimum_order: [this.product.minimum_order],
      maximum_order: [this.product.maximum_order],
      sizes: [this.product.sizes],
      genders: [this.product.genders],
      tag_id: [this.product.tag_id],
      shipping_source: [this.product.shipping_source?.toString() || '0']
    });

    this.primary_picture = checkNull(this.product.primary_picture);
    this.secondary_picture = checkNull(this.product.secondary_picture);
    this.tertiary_picture = checkNull(this.product.tertiary_picture);
    this.fourth_picture = checkNull(this.product.fourth_picture);
    this.fifth_picture = checkNull(this.product.fifth_picture);

    this.upfrontValues = populateUpfrontValues(this.product.no_of_months, this.product.credit_price);
    if (this.upfrontValues.includes(v => v.amount === this.product.upfront_amount)) {
      this.productForm.get('upfront_amount_option').setValue(this.product.upfront_amount);
    } else {
      this.productForm.get('upfront_amount_option').setValue('specify');
      this.specifyUpfront = true;
    }

    this.selectedSizes = this.product.sizes ? this.product.sizes.split(',') : [];
    this.selectedGenders = this.product.genders ? this.product.genders.split(',') : [];
    this.selectedTags = this.product.tag_id ? this.product.tag_id.split(',') : [];
  }

  async submit() {
    if (this.productForm.value.sell_on_credit.toString() === '1') this.setMonthlyAmount();
    const data = {
      ...this.productForm.value,
      primary_picture: await this.resolveImage(this.primary_picture, 'primary_picture'),
      secondary_picture: await this.resolveImage(this.secondary_picture, 'secondary_picture'),
      tertiary_picture: await this.resolveImage(this.tertiary_picture, 'tertiary_picture'),
      fourth_picture: await this.resolveImage(this.fourth_picture, 'fourth_picture'),
      fifth_picture: await this.resolveImage(this.fifth_picture, 'fifth_picture'),
    };
    const payload = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== null) acc[key] = data[key];
      return acc;
    }, []);
    const fd = new FormData();
    Object.keys(payload).forEach(key => fd.append(key, payload[key]));
    this.loading = true;
    try {
      const _data = await this._api.editItems(fd, `${ this.product.id }`).toPromise<any>();
      const items = this._store.products.items?.map(p => p.id === _data.data.id ? _data.data : p);
      this._store.setState('products', { ...this._store.products, items }, true);
      this._general.notify('success', _data.message);
      this.done.emit();
    } catch (e) {
      this.loading = false;
    }
  }

  handlePriceChange() {
    this.setSellOnCredit(this.productForm.value.sell_on_credit);
  }

  handleCreditChange() {
    const { no_of_months, credit_price } = this.productForm.value;
    this.upfrontValues = populateUpfrontValues(no_of_months, credit_price);
  }

  handleSellOnCreditChange(e) {
    this.setSellOnCredit(e.target.value);
  }

  setSellOnCredit(value: string) {
    this.productForm.get('sell_on_credit').patchValue(value);
    if (value === '1') {
      const { max_credit_duration, upfront_rate, monthly_interest_rate: rate = 0 } = this._auth.merchant;
      const { price, no_of_months } = this.productForm.value;
      if (!rate) return;
      const credit_price = +price + ((+rate / 100) * +price);
      this.productForm.get('credit_price').setValue(credit_price);
      if (!max_credit_duration) return;
      const months = max_credit_duration / 30;
      this.productForm.get('no_of_months').setValue(`${ months }`);
      if (!upfront_rate) {
        this.upfrontValues = populateUpfrontValues(no_of_months, credit_price);
        return;
      }
      const upfront = (+upfront_rate / 100) * +credit_price;
      this.productForm.get('upfront_amount').setValue(upfront);
    } else {
      resetAndClearCreditValidators(this.productForm);
    }
  }

  handleUpfrontChange(e) {
    const value = e.target.value;
    if (value) {
      if (value === 'specify') {
        this.specifyUpfront = true;
      } else {
        this.specifyUpfront = false;
        this.productForm.get('upfront_amount').setValue(value);
      }
    } else {
      this.productForm.get('upfront_amount').setValue('0');
    }
    this.setMonthlyAmount();
  }

  async resolveImage(image: string, name) {
    if (image && image.includes('data:image/')) {
      return await this._general.dataUrlToFile(image, this.rawImages[name].name);
    } else {
      return image;
    }
  }

  setMonthlyAmount() {
    const { credit_price, no_of_months, upfront_amount } = this.productForm.value;
    this.productForm.get('monthly_amount').setValue((+credit_price - +upfront_amount) / +no_of_months);
  }

  addPicture(key) {
    this.activePicture = key;
    if (this.pictureInput) {
      this.pictureInput.nativeElement.click();
    }
  }

  startCropping(event: any) {
    if (!event.target.files.length) {
      return;
    }
    this.imageChangedEvent = event;
    this.modal = 'crop';
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  croppingDone() {
    this[this.activePicture] = this.croppedImage;
    this.rawImages[this.activePicture] = this.imageChangedEvent.target.files[0];
    this.imageChangedEvent = null;
    this.croppedImage = null;
    this.modal = '';
  }

  cancelCropping() {
    this.rawImages[this.activePicture] = null;
    this.croppedImage = undefined;
    this.modal = '';
  }

  async addTag() {
    if (this.tagForm.invalid) return;
    this.addingTag = true;
    this.tagForm.disable();
    const res = await this._api.addTag(this.tagForm.value.name).toPromise<any>();
    this.tags.push(res.data);
    this.addingTag = false;
    this.tagForm.enable();
    this.tagForm.reset();
  }

  handleTagChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedTags.push(value);
    } else {
      this.selectedTags = this.selectedTags.filter(s => s !== value);
    }
    this.productForm.get('tag_id').setValue(this.selectedTags.join(','));
  }

  async getTags() {
    const res = await this._api.getTags().toPromise<any>();
    this.tags = res.data;
  }

  handleSizeChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      [this.clothSizes.map(i => i.value), this.shoeSizesEuro.map(i => i.value), this.shoeSizesUK.map(i => i.value)].forEach((arr) => {
        if (arr.includes(value)) {
          this.selectedSizes = this.selectedSizes.filter(s => arr.includes(s));
          this.selectedSizes.push(value);
        }
      });
    } else {
      this.selectedSizes = this.selectedSizes.filter(s => s !== value);
    }
    this.productForm.get('sizes').setValue(this.selectedSizes.join(','));
  }

  handleGenderChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedGenders.push(value);
    } else {
      this.selectedGenders = this.selectedGenders.filter(s => s !== value);
    }
    this.productForm.get('genders').setValue(this.selectedGenders.join(','));
  }

  showPendingSetup() {
    this.done.emit();
    this._general.showPendingSetup();
  }

  share() {
    if (!this._auth.canShare) return this.showPendingSetup();
    if (!window.navigator['share']) {
      window.navigator['share']({
        title: 'Product link',
        text: this.product.name,
        url: `${ this.product.item_link }`
      }).then(() => console.log('Successful share')).catch(error => console.log('Error sharing', error));
    } else {
      this.modal = 'share';
    }
  }

  async delete() {
    const { id } = this.product;
    this.deleting = true;
    try {
      await this._api.deleteItem(id.toString());
      const items = this._store.products.items?.filter(p => p.id !== id);
      this._store.setState('products', { ...this._store.products, items }, true);
      this._general.notify('success', 'Item deleted successfully!');
      this.done.emit();
    } catch (error) {
      console.log(error);
      this.deleting = false;
    }
  }

  updateCurrentProduct(product) {
    const items = this._store.products.items?.map(i => i.id === product.id ? product : i);
    this._store.setState('products', { ...this._store.products, items }, true);
  }

  async handleEnabledChange(e) {
    if (e.target.checked) {
      await this._api.enableProduct(this.product.id).toPromise();
      this.updateCurrentProduct({ ...this.product, disabled: 0 });
      this._general.notify('success', 'Product enabled');
      this.update.emit({ ...this.product, disabled: 0 });
    } else {
      await this._api.disableProduct(this.product.id).toPromise();
      this.updateCurrentProduct({ ...this.product, disabled: 1 });
      this._general.notify('success', 'Product disabled');
      this.update.emit({ ...this.product, disabled: 1 });
    }
  }
}
