import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';
import { ApiService } from '../../services/api.service';
import { MerchantProfileDetails } from '../../models/merchantProfile';

@Component({
  selector: 'app-settings-homepage-template',
  templateUrl: './settings-homepage-template.component.html',
  styleUrls: ['./settings-homepage-template.component.scss']
})
export class SettingsHomepageTemplateComponent implements OnInit {
  @Input() merchant: MerchantProfileDetails;
  @Output() options = new EventEmitter();

  breakpoints = { 0: { slidesPerView: 1.2, }, 480: { slidesPerView: 1.2, }, 640: { slidesPerView: 1.5, } };

  templates = [
    { id: 1, name: 'Default', class: 'text-primary fa-layer-group', enabled: true },
    { id: 2, name: 'Boxed', class: 'text-danger fa-th', enabled: true },
    { id: 3, name: 'BannerLess', class: 'text-success fa-window-maximize', enabled: true },
    { id: 4, name: 'Window', class: 'text-warning fa-border-all', enabled: true },
    { id: 5, name: 'Catalogue', class: 'text-info fa-window-restore', enabled: true },
    { id: 6, name: 'Plymuth', class: 'text-info fa-adjust', enabled: false },
    { id: 7, name: 'Astrath', class: 'text-info fa-adjust', enabled: false },
    { id: 8, name: 'Venturi', class: 'text-info fa-adjust', enabled: false },
    { id: 9, name: 'Marioneth', class: 'text-info fa-adjust', enabled: false },
    { id: 10, name: 'Rivera', class: 'text-info fa-adjust', enabled: false },
    { id: 11, name: 'Raya', class: 'text-info fa-adjust', enabled: false },
    { id: 12, name: 'obabi', class: 'text-info fa-adjust', enabled: false },
    { id: 13, name: 'obizi', class: 'text-info fa-adjust', enabled: false },
    { id: 14, name: 'ririth', class: 'text-info fa-adjust', enabled: false },
  ];
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  async saveTemplate(template) {
    this.loading = true;
    const prevTemplate = this._auth.merchant.template;
    this._auth.updateMerchant({ template });
    try {
      const res = await this._api.updateOnlineStore({ template }).toPromise<any>();
      this._general.notify('success', res.message || 'Saved');
    } catch (e) {
      this._general.notify('error', e?.error?.message || 'An error occurred!');
      this._auth.updateMerchant({ template: prevTemplate });
    }
    this.loading = false;
  }
}
