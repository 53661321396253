import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CreditBureauData } from 'src/app/models/creditBureauData';

@Component({
  selector: 'app-credit-analysis-credit-beareau',
  templateUrl: './credit-analysis-credit-beareau.component.html',
  styleUrls: ['./credit-analysis-credit-beareau.component.css']
})
export class CreditAnalysisCreditBeareauComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Input() request;
  view = '';
  noCreditInformationFound = false;
  riskAssessed = false;
  loading = false;
  riskAssessmentResponse: CreditBureauData;

  constructor(private _api: ApiService) {
  }

  ngOnInit(): void {
    this.fetchCreditBureauInfo();
    // this.calculate();
  }

  fetchCreditBureauInfo() {
    this.loading = true;
    this._api.getCreditBureauData({
      phone: '07066198768',
      repayment_amount: this.request.upfront_amt
    }).subscribe(
      val => {
        this.loading = false;
        this.riskAssessmentResponse = val;
        this.riskAssessed = true;
      }, () => {
        this.noCreditInformationFound = true;
        this.loading = false;
      }
    );
  }

  checkCustomersCreditStatus(Delinquency: string, compareNumber: string) {
    Delinquency = Delinquency.split('.')[0];
    Delinquency = Delinquency.replace(/,/g, '');

    const DelinquencyNumber = Number(Delinquency);
    const compare = Number(compareNumber);
    if (isNaN(DelinquencyNumber)) {
      this.noCreditInformationFound = true;
      return;
    }
    if (DelinquencyNumber === 0) {
      this.noCreditInformationFound = true;
      return;
    }
    if (!compareNumber) return;
    compareNumber = compareNumber.split('.')[0];
    // console.log(DelinquencyNumber, compare);
    const resultOfCheck = DelinquencyNumber / compare;
    if (resultOfCheck >= 1) {
      this.loading = true;
      return;
    }
  }
}
