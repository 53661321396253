<app-header [currentRoute]="'products'"></app-header>

<app-default-layout>
  <div class="row">
    <div class="col-md-9">
      <nav class="card py-3 mb-4 d-none d-md-flex flex-row justify-content-between align-items-center">
        <h5 class="title m-0">
          Products
          <span class="badge bg-primary rounded-full px-2 py-1 ms-2"> {{ products.length }}</span>
        </h5>
        <div class="form-group m-0">
          <input
            type="search" name="search" id="search" class="form-control form-control-lg override rounded-full me-2"
            placeholder="Search.." #search
          />
        </div>
        <button *ngIf="!filter.params" (click)="modal = 'filter'" class="btn btn-outline-secondary px-3 rounded-full">
          <i class="fa fa-filter me-2"></i> Filter
        </button>
        <button *ngIf="filter.params" (click)="modal = 'filter'" class="btn btn-outline-primary px-3 rounded-full">
          <i class="fa fa-filter me-2"></i> Change filter
        </button>
      </nav>

      <div *ngIf="!loading && !items.length">
        <div *ngIf="!searched.meta && !filter.meta">
          <div class="card mb-4 overflow-hidden bg-light-blue desktop-lg">
            <h5 class="title mb-1">Setup your products</h5>
            <div class="subtitle text-muted mb-3 pe-4">Let's get you started with selling online</div>
            <div class="mt-4">
              <button (click)="modal = 'create-options'" class="btn btn-outline-primary">
                Add products
              </button>
            </div>
            <img class="ms-auto mt-4 overflow-card-image" src="assets/images/instagram-phone.png" alt="Illustration">
          </div>

          <div class="card d-none d-md-flex">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-center py-4">
                <img
                  (click)="modal = 'instagram'" class="cursor-pointer" src="assets/images/svg/instagram.svg" alt=""
                  style="width: 50px"
                >
              </div>
              <div
                *ngFor="let method of addProductMethods"
                (click)="modal = method.name" class="col-md-4 d-flex justify-content-center py-5 cursor-pointer"
              >
                <img class="cursor-pointer fit-contain" [src]="method.image" alt="" style="width: 40%;">
              </div>
            </div>
          </div>

          <app-dashboard-add-product-options (modal)="modal = $event"></app-dashboard-add-product-options>
        </div>
        <div *ngIf="searched.meta || filter.meta" class="d-flex flex-column align-items-center text-center">
          <br><br><br><br><br>
          <div
            class="d-flex align-items-center justify-content-center p-3"
            style="width: 100px; height: 100px; background: rgba(0, 0, 0, .03); border-radius: 50%;"
          >
            <img src="assets/images/svg/search-error.svg" alt="Search error" style="width: 50px;">
          </div>
          <h5 class="text-muted mt-4">No result</h5>
          <br><br><br><br><br>
        </div>
      </div>

      <div
        class="row row-sm" style="position: relative" *ngIf="!loading && items.length" infiniteScroll
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" (scrolled)="onScroll()"
      >

        <div class="d-flex d-md-none justify-content-center mb-4 position-sticky" style="top: 20px; z-index: 100">
          <button *ngIf="filter.params" (click)="modal = 'filter'" class="btn btn-primary px-3 rounded-full">
            <i class="fa fa-filter me-2"></i> Change filter
          </button>
        </div>

        <div *ngFor="let item of items; index as i" class="col-6 col-sm-4">
          <app-square-box class="d-block mb-3 cursor-pointer border rounded overflow-hidden">
            <img [src]="item?.primary_picture" alt="Product" class="w-100" (click)="viewProduct(item)"/>
            <span
              *ngIf="item?.global == 0 && item?.disabled == 0" (click)="_general.copyLink(item.item_link)"
              title="copy link" class="position-absolute share-item"
            >
              <i class="fa fa-clone"></i>
            </span>
            <div *ngIf="item.disabled === 1" class="product-disabled">Disabled</div>

            <div class="position-absolute bottom-0 start-0 mb-2 ms-2">
              <div *ngIf="filter.params?.likes === 1" class="badge bg-danger">{{ item.likes }} likes</div>
              <div *ngIf="filter.params?.sold === 1" class="badge bg-danger">{{ item.buys }} sold</div>
              <div *ngIf="filter.params?.views === 1" class="badge bg-danger">{{ item.views }} views</div>
              <div *ngIf="filter.params?.recent === 1" class="badge bg-danger">Created {{ item.created_when }}</div>
              <div *ngIf="filter.params?.quantity === 1" class="badge bg-danger">{{ item.quantity }} available</div>
              <div *ngIf="filter.params?.comments === 1" class="badge bg-danger">{{ item?.comments }} comments</div>
            </div>
          </app-square-box>
          <div class="mb-4">
            <div class="text-truncate mb-1">{{ item?.name }}</div>
            <div class="price-box">
              <div *ngIf="item?.sell_on_credit != 0">
                <span *ngIf="_auth.merchant.partnership == 0">
                  {{ item?.monthly_amount | currency:'NGN':'₦':'0.0' }}/mo
                </span>
                <span *ngIf="_auth.merchant.partnership == 1">
                  {{ item?.price | currency:'NGN':'₦':'0.0' }}
                </span>
              </div>
              <div *ngIf="item?.sell_on_credit == 0" class="d-flex align-items-center">
                <span *ngIf="item.discount_price" class="text-decoration-line-through text-muted fs-6 me-1">
                  {{ (+item?.price) | currency:'NGN':'₦':'0.0' }}
                </span>
                <span>
                  {{ (item?.discount_price || item?.price) | currency:'NGN':'₦':'0.0' }}
                </span>
              </div>
            </div>
            <div *ngIf="filter.params?.discount_order === 1" class="price-box">
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="loading || fetching" class="row">
        <div *ngFor="let item of [1,2,3]" class="col-6 col-sm-4">
          <figure class="skeleton fit-content">
            <a href="javascript:void(0)">
              <img src="/assets/images/plus.svg" alt="Icon"/>
            </a>
          </figure>
        </div>
      </div>
    </div>

    <div class="col-md-3 d-none d-md-flex">
      <div class="sticky-sidebar w-100">
        <div class="card mb-4 py-5 flex-column align-items-center text-center">
          <div class="h2 mb-2">{{ _store.products.total }}</div>
          <div class="m-0 text-muted title">Total products</div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-6">
              <div (click)="modal = 'create-options'" class="d-flex flex-column align-items-center cursor-pointer py-4">
                <i class="fa fa-plus text-info" style="font-size: 1.4rem;"></i>
                <small class="mt-2">Add</small>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex flex-column align-items-center cursor-pointer py-4">
                <i class="fa fa-share-alt text-primary" style="font-size: 1.4rem;"></i>
                <small class="mt-2">Share</small>
              </div>
            </div>
            <div class="col-6">
              <div (click)="modal = 'summary'" class="d-flex flex-column align-items-center cursor-pointer py-4">
                <i class="fa fa-book text-primary" style="font-size: 1.4rem;"></i>
                <small class="mt-2">Summary</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button (click)="modal = 'options'" class="floating-action-button d-md-none">
    <i class="fa fa-stream"></i>
  </button>
</app-default-layout>


<app-dialog *ngIf="modal === 'filter'" (close)="modal = ''" title="Filter products" size="xs">
  <div class="mt-5">
    <div class="form-check">
      <input
        (input)="handleFilterChange($event)" [checked]="!filter.params" class="form-check-input"
        type="radio" name="filter" id="none"
      >
      <label class="form-check-label" for="none">
        None
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.quantity === 1" class="form-check-input"
        type="radio" name="filter" id="quantity"
      >
      <label class="form-check-label" for="quantity">
        Availability
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.reorder === 1" class="form-check-input"
        type="radio" name="filter" id="reorder"
      >
      <label class="form-check-label" for="reorder">
        Requires reorder
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.recent === 1" class="form-check-input"
        type="radio" name="filter" id="recent"
      >
      <label class="form-check-label" for="recent">
        Recent
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.views === 1" class="form-check-input"
        type="radio" name="filter" id="views"
      >
      <label class="form-check-label" for="views">
        Viewed
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.comments === 1" class="form-check-input"
        type="radio" name="filter" id="comments"
      >
      <label class="form-check-label" for="comments">
        Most commented
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.sold === 1" class="form-check-input"
        type="radio" name="filter" id="sold"
      >
      <label class="form-check-label" for="sold">
        Most sold
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.likes === 1" class="form-check-input"
        type="radio" name="filter" id="likes"
      >
      <label class="form-check-label" for="likes">
        Most liked
      </label>
    </div>
    <div class="form-check mt-4">
      <input
        (input)="handleFilterChange($event)" [checked]="filter.params?.discount_order === 1"
        class="form-check-input" type="radio" name="filter" id="discount_order"
      >
      <label class="form-check-label" for="discount_order">
        Discount
      </label>
    </div>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'create-product'" (close)="modal = 'view-item'" #dialogCreate [fullscreen]="true">
  <app-create-item [inputFromAllItems]="currentItem" (done)="dialogCreate.dismiss(); createItemDone($event)">
  </app-create-item>
</app-dialog>

<app-dialog
  *ngIf="modal === 'edit-product'" (close)="closeEditProduct()" [fullscreen]="true" title="Edit product" #dialogEdit
>
  <app-edit-product [product]="currentItem" (done)="dialogEdit.dismiss()" (update)="updateCurrentProduct($event)">
  </app-edit-product>
</app-dialog>

<app-dialog
  *ngIf="modal === 'view-product' && currentItem" (close)="closeViewProduct()" [fullscreen]="true"
  [no-padding]="true" #dialogView size="lg" title="Product details"
>
  <app-view-item
    [product]="currentItem" (close)="dialogView.close.emit($event)"
    (edit)="dialogView.dismiss(); modal = 'edit-product'" (update)="updateCurrentProduct($event)"
  >
  </app-view-item>
</app-dialog>

<app-dialog *ngIf="modal === 'search-items'" (close)="modal = ''">
  <app-search-store-items></app-search-store-items>
</app-dialog>

<app-dialog *ngIf="modal === 'options'" (close)="modal = ''" [close-button]="false" #optionsDialog>
  <div class="row">
    <div class="col-4">
      <div (click)="optionsDialog.dismiss(); modal = 'create-options'"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-plus text-info" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Add</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="shareLink()" class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-share-alt text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Share</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="optionsDialog.dismiss(); modal = 'search-items'"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-search text-warning" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Search</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="modal = 'summary'" class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-book text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Summary</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="modal = 'filter'" class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-filter text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Filter</small>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'summary'" (close)="modal = ''" #summaryDialog [close-button]="false">
  <div class="row">
    <div class="col-4">
      <div (click)="summaryDialog.dismiss(); modal = 'create-options'"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-shopping-bag text-info" style="font-size: 1.4rem;"></i>
        <small class="mt-2">0 products</small>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-eye text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">0 views</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="summaryDialog.dismiss(); modal = 'search-items'"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-heart text-warning" style="font-size: 1.4rem;"></i>
        <small class="mt-2">0 likes</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="modal = 'summary'" class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-comments text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">0 comments</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="_general.navigateTo('/settings')" class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-share-alt text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">0 shares</small>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'create-options'" (close)="modal = ''" [fullscreen]="true" title="Setup a product">
  <app-create-product-options (open)="modal = $event"></app-create-product-options>
</app-dialog>

<app-dialog *ngIf="modal === 'instagram'" (close)="modal = ''" #dialogIG fullscreen>
  <app-instagram [merchant]="_auth.merchant" (close)="dialogIG.dismiss()"></app-instagram>
</app-dialog>

<app-dialog *ngIf="modal === 'konga'" (close)="modal = ''" fullscreen>
  <app-add-products-from-konga></app-add-products-from-konga>
</app-dialog>

<app-dialog *ngIf="modal === 'jiji'" (close)="modal = ''" fullscreen>
  <app-add-products-from-jiji></app-add-products-from-jiji>
</app-dialog>

<app-dialog *ngIf="modal === 'jumia'" (close)="modal = ''" fullscreen>
  <app-add-products-from-jumia></app-add-products-from-jumia>
</app-dialog>

<app-dialog *ngIf="modal === 'flutterwave'" (close)="modal = ''" fullscreen>
  <app-add-products-from-flutterwave></app-add-products-from-flutterwave>
</app-dialog>

<app-dialog *ngIf="modal === 'creditclan'" (close)="modal = ''" fullscreen>
  <app-add-products-from-creditclan></app-add-products-from-creditclan>
</app-dialog>

<app-dialog *ngIf="modal === 'whatsapp'" (close)="modal = ''">
  <app-add-products-from-whatsapp (connect)="modal = 'connect-whatsapp'"></app-add-products-from-whatsapp>
</app-dialog>

<app-dialog *ngIf="modal === 'connect-whatsapp'" (close)="modal = ''" #cwDialog>
  <app-connect-whatsapp (close)="cwDialog.dismiss()" (done)="modal = 'manage-whatsapp'"></app-connect-whatsapp>
</app-dialog>

<app-dialog *ngIf="modal === 'manage-whatsapp'" (close)="modal = ''">
  <app-manage-whatsapp-connection (settings)="modal = 'whatsapp-settings'"></app-manage-whatsapp-connection>
</app-dialog>

<app-dialog *ngIf="modal === 'whatsapp-settings'" (close)="modal = ''" title="Whatsapp bot settings">
  <app-whatsapp-settings></app-whatsapp-settings>
</app-dialog>
