<div (click)="close.emit()" class="backdrop" [@backdrop]="state"></div>

<div class="dialog-wrap" [@dialogDesktop]="state" (@dialogDesktop.done)="done($event)">
  <div class="outside-click-close" [@backdrop]="state" (click)="dismiss()"></div>
  <div class="dialog">
    <header class="header d-flex py-5">
      <div class="d-flex align-items-center">
        <div class="image">
          <img [src]="_auth.merchant.picture || '/assets/images/svg/avatar-common.png'" alt="merchant"/>
        </div>
        <div class="ms-3">
          <h2 class="title">{{ _auth.merchant.name }}</h2>
          <p class="subtitle mt-1">{{ _auth.merchant.email }}</p>
        </div>
      </div>
    </header>
    <div class="body">
      <div class="mobile-menu-wrapper p-0">
        <nav class="mobile-nav">
          <ul class="mobile-menu">
            <li>
              <a (click)="_general.navigateTo('/products')" class="d-flex align-items-center">
                <i class="icon fa fa-shopping-cart text-muted"></i>Products
                <span *ngIf="_store.products.total" class="badge bg-danger rounded-full ms-auto">
                  {{ _store.products.total }}
                </span>
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/requests')" class="d-flex align-items-center">
                <i class="icon fa fa-clipboard-list text-muted"></i>Orders
                <!--                <span class="badge bg-danger rounded-full ms-auto">2</span>-->
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/payment')" class="d-flex align-items-center">
                <i class="icon fa fa-receipt text-muted"></i>Payment
                <!--                <span class="badge bg-danger rounded-full ms-auto">-->
                <!--                  {{ 200 | currency:'NGN':'₦':'0.0' }}-->
                <!--                </span>-->
              </a>
            </li>
            <li class="border-top my-1"></li>
            <li>
              <a (click)="modal.emit('online-store')">
                <i class="icon fa fa-globe text-muted"></i>Online store
              </a>
            </li>
            <li>
              <a (click)="modal.emit('business-loan')">
                <i class="icon fa fa-money-bill text-muted"></i>Business loan
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/repayment-reports')">
                <i class="icon far fa-clipboard text-muted"></i>Reports
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/pricing')">
                <i class="icon fa fa-tags text-muted"></i>Pricing
              </a>
            </li>
            <li class="border-top my-1"></li>
            <li>
              <a (click)="modal.emit('settings')">
                <i class="icon fa fa-cog text-muted"></i>Settings
              </a>
            </li>
            <li>
              <a (click)="_auth.logout()">
                <i class="icon fa fa-power-off text-danger"></i>Logout
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
