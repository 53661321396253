<app-header [currentRoute]="'customers'"></app-header>

<app-default-layout>
  <div class="row">
    <div class="col-md-9">
      <nav class="card py-3 d-none d-md-flex justify-content-between align-items-center flex-row">
        <h5 class="title m-0">Customers</h5>

        <div class="form-group m-0">
          <input type="search" name="search" id="search" class="form-control form-control-lg override rounded-full me-2"
                 placeholder="Search.." #search/>
        </div>
      </nav>

      <div *ngIf="!customers.length && !loading" class="col-12 text-center">
        <img src="assets/images/Empty-rafiki.svg" class="mx-auto" style="max-width: 260px;" alt=""/>
        <h3 class="mb-0">No customer found</h3>
        <p *ngIf="!searched.meta" class="mb-3 pt-2">
          All customers that patronise you will show here.
        </p>
        <p *ngIf="searched.meta" class="mb-3 pt-2">
          We couldn't get any results
        </p>
        <br>
        <br>
        <br>
        <br>
      </div>

      <div class="row row-sm" *ngIf="!loading">
        <div *ngFor="let customer of items; index as i" class="col-6 col-sm-4 mt-4">
          <div class="card p-0 inner-icon h-100">
            <div class="p-1">
              <app-square-box>
                <img [src]="customer?.picture ? customer?.picture : 'assets/images/placeholder-profile.png'"
                     class="w-100 h-100 fit-cover d-block" alt="customer">
              </app-square-box>
            </div>
            <div class="card-body">
              <div class="fw-bold mb-1">{{ customer?.full_name }}</div>
              <div class="title">
                ₦ {{ customer?.total_transaction | currency:'NGN':'₦':'0.0' }} <span>In Transaction</span>
              </div>
            </div>
            <button class="card-footer btn border-top cursor-pointer p-2" (click)="viewCustomer(customer)">View</button>
          </div>
        </div>
      </div>

      <div *ngIf="loading || fetching" class="d-flex justify-content-center align-items-center">
        <div class="row">
          <div *ngFor="let i of [1,2,3,4,5]" class="col-6 col-sm-4 mt-4">
            <div class="card p-2 inner-icon h-100">
              <div class="skeleton fit-content">
                <img [src]="'assets/images/placeholder-profile.png'" class="w-100 h-100" alt="..."
                     style="object-fit: cover;">
              </div>
              <div class="card-body">
                <h5 class="title skeleton mb-1">Lorem, ipsum dolor.</h5>
                <div class="title skeleton">Lorem, ipsum.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 text-center"
           *ngIf="!searched.data.length && !(loading || fetching) && items.length && meta.next">
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
      <div class="mt-5 text-center" *ngIf="!(loading || fetching) && searched.data.length && searched.meta.next">
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
    </div>

    <div class="col-md-3 d-none d-md-flex">
      <div class="sticky-sidebar w-100">
        <div class="card mb-3 py-5 flex-column align-items-center text-center">
          <div class="h2 mb-2">{{ customers.length }}</div>
          <div class="m-0 text-muted title">Total customers</div>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>

<app-dialog
  *ngIf="currentCustomer" (close)="closeViewCustomer()" [size]="'lg'" title="Customer details"
  [fullscreen]="true"
>
  <div class="row mt-4">
    <div class="col-sm-5 pb-5">
      <div class="text-align avatar-container">
        <img
          [src]="currentCustomer?.picture? currentCustomer?.picture: 'assets/images/placeholder-profile.png'"
          class="display-sm-inline mod-avatar w-100 h-100" style="object-fit: cover;" alt=""
        />
      </div>
    </div>
    <div class="col-sm-7">
      <div class="border-bottom mb-3 pb-3">
        <div class="fw-bold mb-1">Contact information</div>
        <div>{{ currentCustomer?.full_name }} /{{ currentCustomer?.phone }} /{{ currentCustomer?.email }}</div>
      </div>

      <div class="border-bottom mb-3 pb-3">
        <div class="fw-bold mb-1">Total transactions</div>
        <div>
          ₦&nbsp;{{ currentCustomer?.total_transaction | currency:'NGN':'₦':'0.0' }}
          from {{ currentCustomer?.requests.length }} purchases
        </div>
      </div>

      <div class="border-bottom mb-3 pb-3">
        <div class="fw-bold mb-1">Total Credit</div>
        <div>₦&nbsp;{{ currentCustomer?.total_credit | currency:'NGN':'₦':'0.0' }}</div>
      </div>

      <div class="border-bottom mb-3 pb-3">
        <div class="fw-bold mb-1">Total Balance</div>
        <div>₦&nbsp;{{ currentCustomer?.total_balance | currency:'NGN':'₦':'0.0' }}</div>
      </div>

      <div class="border-bottom mb-3 pb-3">
        <div class="fw-bold mb-1">Number of items bought</div>
        <div>{{ currentCustomer?.items_bought }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6 mb-3">
      <div class="card bg-light-blue">
        <div class="row align-items-center">
          <div class="col-3 m-0 py-0" style="font-size: 4rem;">
            <i class="icon-credit-card"></i>
          </div>
          <div class="col-7">
            <div class="h4 m-0">**** **** ****</div>
            <span>No repayment card added</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 mb-3">
      <div class="card bg-light-red h-100 d-flex flex-column justify-content-center">
        <h5 class="title mb-1">Courier Information</h5>
        <div class="row align-items-center">
          <div class="col-10 py-1">
            <span class="">25, Ferarri Street, Dolphin Estate Abule Ado, ilasa</span>
          </div>
          <div class="col-2 text-align-right"></div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
