<app-header [currentRoute]="'requests'"></app-header>

<app-default-layout>
  <div class="row" id="thisParent">
    <div class="col-md-9">
      <nav class="card w-100 py-3 px-4 d-none d-md-flex justify-content-between align-items-center flex-row mb-4">
        <h5 class="title m-0">
          Pending orders
          <span class="badge bg-danger rounded-full px-2 py-1 ms-2"> {{ requests.length }}</span>
        </h5>
        <div class="form-group m-0">
          <input
            type="search" name="search" id="search" class="form-control form-control-lg override rounded-full me-2"
            placeholder="Search.." #search
          />
        </div>
        <button *ngIf="!filter.params" (click)="modal = 'filter'" class="btn btn-outline-secondary px-3 rounded-full">
          <i class="fa fa-filter me-2"></i> Filter
        </button>
        <button *ngIf="filter.params" (click)="modal = 'filter'" class="btn btn-outline-primary px-3 rounded-full">
          <i class="fa fa-filter me-2"></i> Change filter
        </button>
      </nav>

      <div *ngIf="!loading && !items.length">
        <div *ngIf="!searched.meta">
          <div class="card mb-4 overflow-hidden bg-light-blue desktop-lg">
            <h5 class="title mb-1">Create order</h5>
            <div class="text-muted mb-3">Setup order for your customer</div>
            <div>
              <button (click)="launchModalIfProfileComplete('create-options')" class="btn btn-outline-primary">
                Create
              </button>
            </div>
            <img
              class="ms-auto mt-4 overflow-card-image" src="assets/images/svg/online-shopping.svg" alt="Illustration"
              style="max-width: 150px;"
            >
          </div>
          <div class="mb-4 d-md-none">
            <swiper [slidesPerView]="1.3" [spaceBetween]="10">
              <ng-template swiperSlide>
                <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
                  <img src="assets/images/svg/social-girl.svg" alt="" style="width: 100px">
                  <div class="flex-grow-1 ms-4">
                    <h5 class="mb-2">Share link</h5>
                    <button (click)="modal = 'instagram'" class="btn btn-outline-secondary btn-sm">Share</button>
                  </div>
                </div>
              </ng-template>
              <ng-template swiperSlide>
                <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
                  <img src="assets/images/svg/social-girl.svg" alt="" style="width: 100px">
                  <div class="flex-grow-1 ms-4">
                    <h5 class="mb-2">Share link</h5>
                    <button (click)="modal = 'instagram'" class="btn btn-outline-secondary btn-sm">Share</button>
                  </div>
                </div>
              </ng-template>
            </swiper>
          </div>
        </div>
        <div *ngIf="searched.meta" class="d-flex flex-column align-items-center text-center">
          <br><br><br><br><br>
          <div
            class="d-flex align-items-center justify-content-center p-3"
            style="width: 100px; height: 100px; background: rgba(0, 0, 0, .03); border-radius: 50%;"
          >
            <img src="assets/images/svg/search-error.svg" alt="Search error" style="width: 50px;">
          </div>
          <h5 class="text-muted mt-4">No result</h5>
          <br><br><br><br><br>
        </div>
      </div>

      <div *ngIf="!loading">
        <div *ngFor="let request of items; index as i" class="mb-3 mb-md-4">
          <!-- Desktop -->
          <div class="card flex-row align-items-center p-0 d-none d-md-flex">
            <div class="modal-img-transact-container p-3 border-0 position-relative">
              <app-square-box class="d-flex border rounded">
                <img
                  *ngIf="request?.items[0]?.primary_picture" [src]="request?.items[0]?.primary_picture"
                  alt="img-trasact" class="rounded"
                />
                <div *ngIf="!request?.items[0]?.primary_picture" class="text-center py-5">
                  <i class="fa fa-shopping-cart text-muted fa-3x"></i>
                </div>
              </app-square-box>
            </div>
            <div class="modal-transact-content flex-grow-1 ms-4 ps-2 pe-4 pt-3 pb-3">
              <div class="d-flex flex-row align-items-center">
                <div class="flex-grow-1">
                  <h5 class="product-title mb-1 d-flex align-items-center" style="font-size: 1.2rem;">
                    {{request?.cred_plus_upfront | currency:'NGN':'₦':'0.0'}}
                  </h5>
                  <h5 class="product-title fw-normal mb-1">
                    {{ request?.full_name ? request?.full_name + " • " : "" }}{{ request?.phone_no || request?.email }}
                  </h5>
                  <div class="credit-tag">
                    <span *ngIf="request.credit" class="badge bg-danger px-4 py-3">Credit</span>
                    <span *ngIf="!request.credit" class="badge bg-primary px-4 py-3">Sale</span>
                  </div>
                  <div class="mt-1">
                    <small *ngIf="request?.upfront_paid" class="me-2">
                      <i class="fa fa-check-circle text-success me-2"></i> Upfront paid
                    </small>
                    <small *ngIf="request?.card_exists" class="me-2">
                      <i class="fa fa-credit-card text-primary"></i>
                    </small>
                  </div>
                </div>
              </div>

              <div *ngIf="request?.items?.length" class="row mt-3 border-top border-bottom py-4">
                <swiper [slidesPerView]="4.5" [spaceBetween]="10">
                  <template *ngFor="let item of request?.items; index as i">
                    <ng-template swiperSlide>
                      <img
                        [src]="item?.primary_picture" [alt]="item?.name" [title]="item?.name"
                        style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"
                      />
                    </ng-template>
                  </template>
                </swiper>
              </div>

              <div class="mt-4 d-flex align-items-center">
                <button (click)="viewRequest(request)" type="button" class="btn btn-primary me-2">
                  View details
                </button>
              </div>
            </div>
          </div>
          <!-- Mobile -->
          <div
            class="card d-flex flex-row align-items-center p-0 d-md-none cursor-pointer" style="background: #f8f8f8;"
            (click)="viewRequest(request)"
          >
            <div class="modal-transact-content flex-grow-1 ms-md-4 ps-2 pe-2 pt-3 pb-2">
              <div class="d-flex flex-row align-items-center">
                <div class="position-relative">
                  <app-square-box class="d-flex border rounded me-2" style="width: 60px">
                    <img
                      *ngIf="request?.items[0]?.primary_picture" [src]="request?.items[0]?.primary_picture"
                      alt="img-trasact" class="rounded"
                    />
                    <div *ngIf="!request?.items[0]?.primary_picture" class="text-center py-2 d-flex align-items-center">
                      <i class="fa fa-shopping-cart text-muted fa-2x"></i>
                    </div>
                  </app-square-box>
                  <span *ngIf="request?.upfront_paid" class="badge-count bg-success" style="top: 5px;right:10px;">
                    <i class="fa fa-check-circle text-white"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h5 class="product-title mb-0 d-flex align-items-center">
                    {{request?.cred_plus_upfront | currency:'NGN':'₦':'0.0'}}
                  </h5>
                  <h5 class="product-title fw-normal mb-0">{{ request?.full_name || request?.phone_no }}</h5>
                  <h5 class="product-title fw-normal mb-1">{{ request?.req_date | timeago }}</h5>
                  <div class="credit-tag">
                    <span *ngIf="!request.credit" class="badge bg-primary px-2">Sale</span>
                    <span *ngIf="request.credit" class="badge bg-danger px-2">Credit</span>
                  </div>
                </div>
              </div>

              <hr class="divider my-2 d-md-none"/>

              <div class="d-flex d-md-none align-items-center justify-content-around">
                <div class="flex-grow-1 text-center">
                  <button (click)="viewRequest(request)" type="button" class="btn btn-link btn-sm me-2">
                    <i class="fa fa-list me-1"></i>
                    Items ({{ request.items.length }})
                  </button>
                </div>
                <div class="flex-grow-1 text-center border-left">
                  <button (click)="viewRequest(request)" type="button" class="btn btn-link btn-sm me-2">
                    <i class="fa fa-clipboard-list me-1"></i>
                    View details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="loading || fetching">
        <div *ngFor="let item of [1,2]; index as i" class="mb-3 mb-md-4">
          <div class="card d-flex flex-row align-items-center p-0">
            <div class="modal-img-transact-container skeleton fit-content" style="position: relative;">
              <img src="/assets/images/plus.svg" alt="img-trasact"/>
            </div>
            <div class="modal-transact-content flex-grow-1 ms-md-4 ps-1 pe-2 pe-md-4 pt-2 pb-2">
              <div class="d-flex flex-row align-items-center">
                <div class="skeleton fit-content me-3 ms-2 d-md-none">
                  <img src="/assets/images/plus.svg" alt="img-trasact" class="d-md-none rounded img-mobile"/>
                </div>
                <div class="flex-grow-1">
                  <h5 class="product-title mb-1 d-flex align-items-center skeleton">Lorem, ipsum.</h5>
                  <h5 class="product-title fw-normal mb-1 skeleton">Lorem, ipsum dolor.</h5>
                  <div class="mt-2 skeleton">Lorem.</div>
                </div>
              </div>

              <div class="d-none d-md-flex mt-4">
                <div class="parentImg skeleton fit-content">
                  <img src="/assets/images/plus.svg" alt="plus icon"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
                <div class="parentImg skeleton fit-content ms-2">
                  <img src="/assets/images/plus.svg" alt="plus icon"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
              </div>

              <div class="mt-md-4 d-none d-md-block">
                <button type="button" class="btn btn-outline-secondary btn-sm skeleton fit-content">
                  View details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-4 text-center"
        *ngIf="!searched.data.length && !filter.data.length && !loading && items.length && meta.next"
      >
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
      <div class="mt-4 text-center" *ngIf="!loading && searched.data.length && searched.meta.next">
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
    </div>

    <div class="col-md-3 d-none d-md-flex">
      <div class="sticky-sidebar w-100">
        <div class="card mb-3 py-5 flex-column align-items-center text-center">
          <div class="h2 mb-2">{{ requests.length }}</div>
          <div class="m-0 text-muted title">Total requests</div>
        </div>
        <div class="card mb-4">
          <div class="row">
            <div class="col-6">
              <div (click)="launchModalIfProfileComplete('create-options')"
                   class="d-flex flex-column align-items-center cursor-pointer py-4">
                <i class="fa fa-plus text-info" style="font-size: 1.4rem;"></i>
                <small class="mt-2">New order</small>
              </div>
            </div>
            <div class="col-6">
              <div (click)="modal = 'search-requests'"
                   class="d-flex flex-column align-items-center cursor-pointer py-4">
                <i class="fa fa-search text-warning" style="font-size: 1.4rem;"></i>
                <small class="mt-2">Search</small>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-0">
          <ul class="mobile-menu">
            <li class="border-bottom">
              <a (click)="_general.navigateTo('/repayments')">
                <i class="icon fa fa-luggage-cart text-primary"></i>Credit orders
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/sales')">
                <i class="icon fa fa-door-closed text-success"></i>Closed orders
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <button (click)="modal = 'filter'" class="floating-action-button d-md-none">
    <i class="fa fa-filter"></i>
  </button>
</app-default-layout>

<app-dialog *ngIf="modal === 'filter'" (close)="modal = ''" [close-button]="false" size="xs" title="Filter orders">
  <div class="mt-3 pb-3 border-bottom">
    <div class="mb-3">On credit</div>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="credit_outright" value="credit"
        [(ngModel)]="filterOptions.creditOrOutright" (change)="handleFilterChange()"
      />
      <span class="form-check-label">On credit</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="credit_outright" value="outright"
        [(ngModel)]="filterOptions.creditOrOutright" (change)="handleFilterChange()"
      />
      <span class="form-check-label">Outright</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="credit_outright" value="all"
        [(ngModel)]="filterOptions.creditOrOutright" (change)="handleFilterChange()"
      />
      <span class="form-check-label">All</span>
    </label>
  </div>
  <div class="mt-3 pb-3 border-bottom">
    <div class="mb-3">Confirmation status</div>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="confirmation-status" value="rejected"
        [(ngModel)]="filterOptions.confirmationStatus" (change)="handleFilterChange()"
      />
      <span class="form-check-label">Rejected</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="confirmation-status" value="pending"
        [(ngModel)]="filterOptions.confirmationStatus" (change)="handleFilterChange()"
      />
      <span class="form-check-label">Pending</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="confirmation-status" value="none"
        [(ngModel)]="filterOptions.confirmationStatus" (change)="handleFilterChange()"
      />
      <span class="form-check-label">None</span>
    </label>
  </div>
  <div class="mt-3 pb-3 border-bottom">
    <div class="mb-3">Delivery status</div>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="delivery-status" value="shipping"
        [(ngModel)]="filterOptions.deliveryStatus" (change)="handleFilterChange()"
      >
      <span class="form-check-label">Shipping</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="delivery-status" value="delivered"
        [(ngModel)]="filterOptions.deliveryStatus" (change)="handleFilterChange()"
      >
      <span class="form-check-label">Delivered</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="delivery-status" value="none"
        [(ngModel)]="filterOptions.deliveryStatus" (change)="handleFilterChange()"
      >
      <span class="form-check-label">None</span>
    </label>
  </div>
  <div class="mt-3 pb-3">
    <div class="mb-3">Upfront/Outright payment</div>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="payment" value="upfront_paid"
        [(ngModel)]="filterOptions.payment" (change)="handleFilterChange()"
      >
      <span class="form-check-label">Upfront paid</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="payment" value="paid"
        [(ngModel)]="filterOptions.payment" (change)="handleFilterChange()"
      >
      <span class="form-check-label">Fully paid</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="payment" value="pending"
        [(ngModel)]="filterOptions.payment" (change)="handleFilterChange()"
      >
      <span class="form-check-label">Pending</span>
    </label>
    <label class="form-check mt-2">
      <input
        class="form-check-input" type="radio" name="payment" value="none"
        [(ngModel)]="filterOptions.payment" (change)="handleFilterChange()"
      >
      <span class="form-check-label">All</span>
    </label>
  </div>
  <div class="mt-5">
    <button *ngIf="filtered" (click)="resetFilter()" class="btn btn-outline-danger px-3 rounded-full">
      Reset filter
    </button>
  </div>
</app-dialog>

<app-dialog
  *ngIf="modal === 'view' && currentRequest" (close)="closeViewRequest($event)" #dialogView
  [fullscreen]="true" [no-padding]="true"
>
  <app-view-a-recent-request
    [request]="currentRequest" (close)="dialogView.close.emit($event)" (update)="updateCurrentRequest($event)"
    class="d-flex flex-column flex-grow-1"
  ></app-view-a-recent-request>
</app-dialog>

<!--<app-dialog *ngIf="modal === 'search-requests'" (close)="modal = ''" #dialogSearch>-->
<!--  <app-search-requests (view)="dialogSearch.dismiss(); viewRequest($event)"></app-search-requests>-->
<!--</app-dialog>-->

<!--<app-dialog *ngIf="modal === 'create-order'" (close)="modal = ''" [fullscreen]="true">-->
<!--  <app-create-order (refresh)="fetchRequests()"></app-create-order>-->
<!--</app-dialog>-->

<!--<app-dialog *ngIf="modal === 'create-order-online'" (close)="modal = ''" [fullscreen]="true" title="Create order">-->
<!--  <app-create-order [online]="true" (refresh)="fetchRequests()"></app-create-order>-->
<!--</app-dialog>-->

<!--<app-dialog *ngIf="modal === 'create-options'" (close)="modal = ''" #co [close-button]="false" title="Create order">-->
<!--  <div (click)="co.dismiss(); modal = 'create-order'"-->
<!--       class="card d-flex align-items-center flex-row cursor-pointer mb-3">-->
<!--    <div class="icon me-4 text-danger">-->
<!--      <i class="fa fa-store" style="font-size: 2rem;"></i>-->
<!--    </div>-->
<!--    <div class="flex-grow-1">-->
<!--      <div class="title mb-1 fw-bold">Create instore order</div>-->
<!--      <div>Create instant order for walk in customers</div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div (click)="co.dismiss(); modal = 'create-order-online'"-->
<!--       class="card d-flex align-items-center flex-row cursor-pointer mb-3">-->
<!--    <div class="icon me-4 text-primary">-->
<!--      <i class="fa fa-globe" style="font-size: 2rem;"></i>-->
<!--    </div>-->
<!--    <div class="flex-grow-1">-->
<!--      <div class="title mb-1 fw-bold">Book online order</div>-->
<!--      <div>Create order with products from your online store</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</app-dialog>-->

<!--<app-dialog *ngIf="modal === 'options'" (close)="modal = ''" #optionsDialog [close-button]="false">-->
<!--  <div class="row">-->
<!--    <div class="col-4">-->
<!--      <div-->
<!--        (click)="optionsDialog.dismiss(launchModalIfProfileComplete, this, ['create-options'])"-->
<!--        class="d-flex flex-column align-items-center cursor-pointer py-4"-->
<!--      >-->
<!--        <i class="fa fa-plus text-info" style="font-size: 1.4rem;"></i>-->
<!--        <small class="mt-2">Add</small>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-4">-->
<!--      <div (click)="modal = 'filter'" class="d-flex flex-column align-items-center cursor-pointer py-4">-->
<!--        <i class="fa fa-filter text-primary" style="font-size: 1.4rem;"></i>-->
<!--        <small class="mt-2">Filter</small>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-4">-->
<!--      <div-->
<!--        (click)="optionsDialog.dismiss(); modal = 'search-requests'"-->
<!--        class="d-flex flex-column align-items-center cursor-pointer py-4"-->
<!--      >-->
<!--        <i class="fa fa-search text-warning" style="font-size: 1.4rem;"></i>-->
<!--        <small class="mt-2">Search</small>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</app-dialog>-->
