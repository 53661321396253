export const NigeriaBanks = () => ({
  data: [
    { id: '1', bank_code: '214', name: 'First City Monument Bank Plc' },
    { id: '2', bank_code: '032', name: 'Union Bank Plc' },
    { id: '3', bank_code: '221', name: 'Stanbic IBTC Bank Plc' },
    { id: '4', bank_code: '232', name: 'Sterling Bank Plc' },
    { id: '6', bank_code: '305', name: 'Paycom' },
    { id: '8', bank_code: '309', name: 'First Bank Nigeria Mobile' },
    { id: '9', bank_code: '311', name: 'Parkway' },
    { id: '11', bank_code: '322', name: 'Zenith Bank Mobile' },
    { id: '13', bank_code: '401', name: 'Aso Savings and Loans' },
    { id: '14', bank_code: '044', name: 'Access Bank Plc' },
    { id: '15', bank_code: '014', name: 'Afribank Nigeria Plc' },
    { id: '16', bank_code: '063', name: 'Access Bank(Diamond)' },
    { id: '17', bank_code: '050', name: 'Ecobank Nigeria Plc' },
    { id: '18', bank_code: '084', name: 'Enterprise Bank Plc' },
    { id: '19', bank_code: '070', name: 'Fidelity Bank Plc' },
    { id: '20', bank_code: '011', name: 'First Bank Plc' },
    { id: '21', bank_code: '058', name: 'Guaranty Trust Bank Plc' },
    { id: '22', bank_code: '030', name: 'Heritage Bank' },
    { id: '23', bank_code: '082', name: 'Keystone Bank Plc' },
    { id: '24', bank_code: '076', name: 'Skye Bank Plc' },
    { id: '25', bank_code: '068', name: 'Standard Chartered Bank Nigeria Limited' },
    { id: '26', bank_code: '032', name: 'Union Bank Of Nigeria Plc' },
    { id: '27', bank_code: '033', name: 'United Bank for Africa Plc (UBA)' },
    { id: '28', bank_code: '035', name: 'Wema Bank Plc' },
    { id: '29', bank_code: '057', name: 'Zenith Bank Plc' },
    { id: '30', bank_code: '301', name: 'Jaiz Bank Plc' },
    { id: '31', bank_code: '101', name: 'Providus Bank' },
    { id: '32', bank_code: '023', name: 'Citibank Nigeria' },
    { id: '33', bank_code: '014', name: 'MainStreet Bank' },
    { id: '34', bank_code: '100', name: 'SunTrust Bank' },
    { id: '35', bank_code: '215', name: 'Unity Bank' },
    { id: '36', bank_code: '302', name: 'Eartholeum' },
    { id: '37', bank_code: '303', name: 'Chams Mobile' },
    { id: '38', bank_code: '317 ↵', name: 'Cellulant' },
    { id: '39', bank_code: '526', name: 'Parallex Microfinance Bank' }
  ]
});

export interface NigerianBank {
  id: string;
  bank_code: string;
  name: string;
}
