import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import platforms from '../../lib/platforms';

@Component({
  selector: 'app-create-product-options',
  templateUrl: './create-product-options.component.html',
  styleUrls: ['./create-product-options.component.scss']
})
export class CreateProductOptionsComponent implements OnInit {
  @Output() open = new EventEmitter();

  options = platforms;

  constructor() {
  }

  ngOnInit(): void {
  }
}
