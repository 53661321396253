import { Component, Input, OnInit } from '@angular/core';
import { MerchantProfileDetails } from '../../models/merchantProfile';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings-request-analysis',
  templateUrl: './settings-request-analysis.component.html',
  styleUrls: ['./settings-request-analysis.component.scss']
})
export class SettingsRequestAnalysisComponent implements OnInit {
  @Input() merchant: MerchantProfileDetails;
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  async handleCollectionChange(e) {
    // tslint:disable-next-line:prefer-const
    let { eligibility, insurance, widget } = this.merchant;
    const collection = e.target.checked ? 1 : 0;
    insurance = eligibility === 0 ? 0 : insurance;
    await this.sendUpdates({ collection, eligibility, insurance, widget });
  }

  async handleEligibilityChange(e) {
    // tslint:disable-next-line:prefer-const
    let { collection, insurance, widget } = this.merchant;
    const eligibility = e.target.checked ? 1 : 0;
    insurance = eligibility === 0 ? 0 : insurance;
    widget = eligibility === 0 ? 0 : widget;
    await this.sendUpdates({ collection, eligibility, insurance, widget });
  }

  async handleInsuranceChange(e) {
    let { collection, eligibility, widget } = this.merchant;
    const insurance = e.target.checked ? 1 : 0;
    collection = insurance === 1 ? 1 : collection;
    eligibility = insurance === 1 ? 1 : eligibility;
    widget = insurance === 1 ? 0 : widget;
    await this.sendUpdates({ collection, eligibility, insurance, widget });
  }

  async handleWidgetChange(e) {
    // tslint:disable-next-line:prefer-const
    let { collection, eligibility, insurance } = this.merchant;
    const widget = e.target.checked ? 1 : 0;
    eligibility = widget === 1 ? 1 : eligibility;
    await this.sendUpdates({ collection, eligibility, insurance, widget });
  }

  async sendUpdates(data) {
    this.loading = true;
    try {
      await this._api.updateCreditAnalysisSettings(data).toPromise();
      this._auth.updateMerchant(data);
      this._general.notify('success', 'Settings updated');
    } catch (e) {
      this._general.notify('error', 'An error occurred, please try again');
      console.log({ e });
    }
    this.loading = false;
  }
}
