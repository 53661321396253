<div class="card mb-4 d-none d-md-flex">
  <h5 class="title mb-1">Connect your products</h5>
  <p class="mb-4">Update your profile to unlock more features</p>
  <div style="width: 100%">
    <swiper [slidesPerView]="1.5" [spaceBetween]="10">
      <ng-template swiperSlide>
        <div class="card border d-flex flex-row align-items-center" style="min-height: 140px;">
          <img src="assets/images/svg/camera.svg" alt="" style="width: 60px">
          <div class="flex-grow-1 ms-4">
            <div class="mb-1 fw-bold">From your device</div>
            <button (click)="modal.emit('create-product')" class="btn btn-outline-success btn-sm">
              Add product
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="card border d-flex flex-row align-items-center" style="min-height: 140px;">
          <img src="assets/images/logos/whatsapp.svg" alt="" style="width: 50px">
          <div class="flex-grow-1 ms-4">
            <div class="mb-1 fw-bold">From Whatsapp status</div>
            <a (click)="modal.emit('whatsapp')" class="btn btn-outline-secondary btn-sm">
              Add product
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="card border d-flex flex-row align-items-center" style="min-height: 140px;">
          <img src="assets/images/svg/instagram.svg" alt="" style="width: 50px">
          <div class="flex-grow-1 ms-4">
            <div class="mb-1 fw-bold">From Instagram</div>
            <button (click)="modal.emit('instagram')" class="btn btn-outline-secondary btn-sm">Add product</button>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngFor="let method of addProductMethods">
        <div class="card border d-flex flex-row align-items-center" style="min-height: 140px;">
          <img [src]="method.image" alt="" style="max-width: 80px;">
          <div class="flex-grow-1 ms-4">
            <div class="mb-1 fw-bold">From {{ method.name }}</div>
            <button *ngIf="method.available" (click)="modal.emit(method.name)" class="btn btn-outline-secondary btn-sm">
              Add product
            </button>
            <span *ngIf="!method.available" class="badge bg-danger">Beta</span>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>

<!-- Add product options -->
<swiper [slidesPerView]="1.2" [spaceBetween]="10" class="mb-4 d-md-none">
  <ng-template swiperSlide>
    <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
      <img src="assets/images/svg/camera.svg" alt="" style="width: 60px">
      <div class="flex-grow-1 ms-4">
        <div class="mb-1 fw-bold">Add product</div>
        <button (click)="modal.emit('create-product')" class="btn btn-outline-success btn-sm">From device</button>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
      <img src="assets/images/logos/whatsapp.svg" alt="" style="width: 50px">
      <div class="flex-grow-1 ms-4">
        <div class="mb-1 fw-bold">From status</div>
        <a (click)="modal.emit('whatsapp')" class="btn btn-outline-secondary btn-sm">
          Add products
        </a>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
      <img src="assets/images/svg/instagram.svg" alt="" style="width: 50px">
      <div class="flex-grow-1 ms-4">
        <div class="mb-1 fw-bold">From Instagram</div>
        <button (click)="modal.emit('instagram')" class="btn btn-outline-secondary btn-sm">Add product</button>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide *ngFor="let method of addProductMethods">
    <div class="card d-flex flex-row align-items-center" style="min-height: 140px;">
      <img [src]="method.image" alt="" style="max-width: 80px;">
      <div class="flex-grow-1 ms-4">
        <div class="mb-1 fw-bold">From {{ method.name }}</div>
        <button *ngIf="method.available" (click)="modal.emit(method.name)" class="btn btn-outline-secondary btn-sm">
          Add products
        </button>
        <span *ngIf="!method.available" class="badge bg-danger">Beta</span>
      </div>
    </div>
  </ng-template>
</swiper>

