import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MerchantItems } from 'src/app/models/items';
import { GeneralService } from 'src/app/services/general.service';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/store.service';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.css']
})
export class ViewItemComponent implements OnInit {
  @Input('product') product: MerchantItems;
  @Output() close = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() update = new EventEmitter();
  // pictures: any[] = [];
  index = 1;
  facebook: string;
  twitter: string;
  modal = '';
  deleting = false;
  carouselOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&#8249;', '&#8250;'],
    nav: true,
    margin: 15,
    freeDrag: true,
    items: 1,
    autoHeight: true
  };

  public get pictures(): string[] {
    const { primary_picture, secondary_picture, tertiary_picture, fourth_picture, fifth_picture } = this.product;
    return [primary_picture, secondary_picture, tertiary_picture, fourth_picture, fifth_picture].map(p => (!p || p === 'null' || p === 'undefined') ? 'assets/images/svg/no-product.svg' : p);
  }

  constructor(
    public _general: GeneralService,
    private _api: ApiService,
    public _auth: AuthService,
    private _store: StoreService
  ) {
  }

  ngOnInit(): void {
    this.facebook = `https://www.facebook.com/sharer/sharer.php?app_id=1217981644879628&u=${this.product.item_link}`;
    this.twitter = `https://twitter.com/share?text=${this.product.description ? this.product.description : `Come see my ${this.product.name}`}&url=${this.product.item_link}`;
  }

  async generateALink(event: Event) {
    const anchor = event.target as HTMLAnchorElement;
    anchor.textContent = 'Generating...';
    anchor.style.pointerEvents = 'none';
    const ammendedForm = new FormData();
    for (const key in this.product) {
      if (this.product.hasOwnProperty(key)) {
        ammendedForm.append(`${key}`, this.product[key]);
      }
    }
    // TODO: Review
    this._api.additems(ammendedForm).subscribe(async () => {
      anchor.style.display = 'none';
      anchor.style.pointerEvents = 'auto';
    }, () => {
      anchor.textContent = 'Generate Link';
      anchor.style.pointerEvents = 'auto';
    });
  }

  async socialShareLink(event: Event, socialPlatform) {
    if (!this._auth.canShare) return this.showPendingSetup();
    try {
      await window.navigator['share']({
        text: 'Customer request link',
        title: 'Request',
        url: this[socialPlatform]
      });
    } catch (err) {
      const element =
        event.target instanceof HTMLAnchorElement
          ? event.target
          : (event.target as HTMLElement).closest('a');

      (element as HTMLAnchorElement).href = this[socialPlatform];
      (element as HTMLAnchorElement).target = '_blank';
    }
  }

  shareThisProduct() {
    if (!this._auth.canShare) return this.showPendingSetup();
    if (window.navigator['share']) {
      window.navigator['share']({
        title: 'Checkout this product',
        text: this.product.description || this.product.name,
        url: `${this.product.item_link}`
      }).then(() => console.log('Successful share')).catch(error => console.log('Error sharing', error));
    } else {
      this.modal = 'share';
    }
  }

  async delete() {
    const { id } = this.product;
    this.deleting = true;
    try {
      if (this.product.global === 0) {
        await this._api.deleteItem(id.toString());
      } else {
        await this._api.removeAGlobalItemFromMerchantsList(id.toString());
      }
      const items = this._store.products.items.filter(p => p.id !== id);
      this._store.setState('products', { ...this._store.products, items }, true);
      this._general.notify('success', 'Item deleted successfully!');
      this.modal = '';
      this.deleting = false;
      this.close.emit();
    } catch (error) {
      this.deleting = false;
    }
  }

  async handleEnabledChange(e) {
    if (e.target.checked) {
      await this._api.enableProduct(this.product.id).toPromise();
      this.update.emit({ ...this.product, disabled: 0 });
    } else {
      await this._api.disableProduct(this.product.id).toPromise();
      this.update.emit({ ...this.product, disabled: 1 });
    }
  }

  showPendingSetup() {
    this.close.emit();
    this._general.showPendingSetup();
  }

  copyToClipBoard(link: string) {
    if (!this._auth.canShare) return this.showPendingSetup();
    this._general.copyLink(link);
  }
}
