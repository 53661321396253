<div class="card mb-4">
  <h5 class="title mb-2">Email address</h5>
  <p class="mb-5">Update primary email address</p>

  <ng-template [ngIf]="view === 'default'">
    <div *ngIf="merchant.email" class="form-group required mt-3">
      <label class="form-label d-flex align-items-center" for="email">
        Primary email address
        <span class="badge bg-warning ms-2" *ngIf="!merchant.email_validated">Not verified</span>
        <span class="badge bg-success ms-2" *ngIf="merchant.email_validated">Verified</span>
      </label>
      <input type="text" class="form-control override" id="email" placeholder="Email" readonly [value]="merchant.email">
      <div class="mt-3 d-flex justify-content-end">
        <button
          *ngIf="!merchant.email_validated" (click)="sendVerificationEmail()" class="btn btn-outline-secondary rounded-full btn-xs px-3 me-2"
          [disabled]="loading"
        >
          <i class="fa fa-redo-alt font-size-xs me-2" [ngClass]="{'fa-spin': loading}"></i> Resend otp code
        </button>
        <button (click)="view = 'change'" class="btn btn-outline-secondary rounded-full btn-xs px-3">
          <i class="fa fa-pen font-size-xs me-2"></i> Change
        </button>
      </div>
    </div>
    <div *ngIf="!merchant.email" class="d-flex justify-content-between align-items-center">
      <small class="text-danger">No email address added</small>
      <button (click)="view = 'change'" class="btn btn-outline-secondary rounded-full btn-xs px-3">
        <i class="fa fa-plus font-size-xs me-2"></i> Add email address
      </button>
    </div>
  </ng-template>

  <ng-template [ngIf]="view === 'change'">
    <form [formGroup]="emailForm" (ngSubmit)="handleEmailFormSubmit()">
      <label class="form-group required mt-3 d-block">
        <span class="form-label">Enter new email address</span>
        <input type="email" class="form-control override" formControlName="email"/>
      </label>
      <label class="form-group required mt-3 d-block mb-3">
        <span class="form-label">Enter your password</span>
        <input type="password" class="form-control override" formControlName="password"/>
      </label>
      <div class="d-flex align-items-center mt-4">
        <button [disabled]="emailForm.invalid || loading" type="submit" class="btn btn-success">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
          Continue
        </button>
        <button (click)="view = 'default'" [disabled]="loading" class="btn btn-outline-danger ms-2">Cancel</button>
      </div>
    </form>
  </ng-template>

  <ng-template [ngIf]="view === 'otp'">
    <form [formGroup]="otpForm" (ngSubmit)="handleOtpFormSubmit()">
      <label class="form-group required mt-3 d-block">
        <span class="form-label">Enter OTP code</span>
        <input type="number" class="form-control override" formControlName="otp"/>
      </label>
      <div class="mt-4">
        <button [disabled]="otpForm.invalid || loading" type="submit" class="btn btn-success">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
          {{ emailForm.value.email ? 'Change email address' : 'Submit' }}
        </button>
      </div>
    </form>
  </ng-template>
</div>
