import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-repayments-reports-tables',
  templateUrl: './repayments-reports-tables.component.html',
  styleUrls: ['./repayments-reports-tables.component.css']
})
export class RepaymentsReportsTablesComponent implements OnInit {
  loading = false;
  noData = false;
  day = '';
  repaymentReportList: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      switch (id) {
        case '1':
          this.day = 'today';
          break;
        case '2':
          this.day = 'this month';
          break;
        default:
          this.day = id;
      }
      this.loading = true;
      this.fetchRepaymentReport(id);
    });
  }

  fetchRepaymentReport(id: any) {
    this._api.getMerchantRepaymentReports(id).subscribe(
      ({ data }) => {
        this.repaymentReportList = [...data];
        if (this.repaymentReportList.length < 1) {
          this.loading = false;
          this.noData = true;
          return;
        }

        this.loading = false;
        this.noData = false;
      },
      () => {
        this.noData = true;
        this.loading = false;
      }
    );
  }
}
