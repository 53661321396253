import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IRequest } from 'src/app/models/requests';

interface IMeta {
  count: number;
  end: boolean;
  next: number | boolean;
  prev: number | null;
}

interface IRequestsState {
  meta: IMeta | null;
  items: IRequest[] | null;
  total: any;
  sortedBy: string;
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  onboard = {};
  public registration = { done: false, route: null, product: null };

  private _products = { meta: null, items: null, total: 0 };
  private _requests: IRequestsState = { meta: null, items: null, total: 0, sortedBy: 'recent' };
  private _repayments = { meta: null, items: null, total: 0 };
  private _customers = { meta: null, items: null };
  private _catalogues = { meta: null, items: null };
  private _stats = {};

  public $products = new BehaviorSubject<any>(this._products);
  public $requests = new BehaviorSubject<IRequestsState>(this._requests);
  public $repayments = new BehaviorSubject<any>(this._repayments);
  public $customers = new BehaviorSubject<any>(this._customers);
  public $catalogues = new BehaviorSubject<any>(this._catalogues);
  public $stats = new BehaviorSubject<any>(this._stats);

  public get stats(): any {
    return this._stats;
  }

  public get products(): any {
    return this._products;
  }

  public get requests(): any {
    return this._requests;
  }

  public get repayments(): any {
    return this._repayments;
  }

  public get customers(): any {
    return this._customers;
  }

  public get catalogues(): any {
    return this._catalogues;
  }

  constructor() {
    this.onboard = JSON.parse(localStorage.getItem('onboard')) || {};
  }

  setState(key, value, notify = false) {
    this[`_${ key }`] = value;
    if (notify) {
      this[`$${ key }`].next(this[key]);
    }
  }

  showOnboarding(key) {
    if (key in this.onboard) {
      return this.onboard[key];
    } else {
      return true;
    }
  }

  setShowOnboarding(key, value) {
    this.onboard[key] = value;
    localStorage.setItem('onboard', JSON.stringify(this.onboard));
  }

  reset() {
    this._products = { meta: null, items: null, total: 0 };
    this._requests = { meta: null, items: null, total: 0, sortedBy: 'recent' };
    this._repayments = { meta: null, items: null, total: 0 };
    this.$products.next(this._products);
    this.$requests.next(this._requests);
    this.$repayments.next(this._repayments);
  }
}
