<router-outlet></router-outlet>

<app-dialog *ngIf="modal === 'share' && socialShareDetails" (close)="closeShareModal()" [no-padding]="true"
            title="Share link">
  <app-social-share
    [link]="socialShareDetails.info.link" [description]="socialShareDetails.info.description"
    (search)="modal = 'search-items'"
  ></app-social-share>
</app-dialog>

<app-dialog *ngIf="modal === 'profile-mgt'" (close)="modal = ''" [no-padding]="true" #dialogProfile
            title="Update profile">
  <app-profile-mgt (close)="dialogProfile.dismiss()"></app-profile-mgt>
</app-dialog>

<app-dialog *ngIf="modal === 'search-items'" (close)="modal = ''">
  <app-search-store-items></app-search-store-items>
</app-dialog>

<app-dialog *ngIf="modal === 'welcome-settings'" (close)="this.modal = ''" [fullscreen]="true" #dialogSettings>
  <app-welcome-settings (close)="dialogSettings.dismiss()"></app-welcome-settings>
</app-dialog>

<app-dialog *ngIf="modal === 'setup-account'" (close)="closeSetupAccount()" [fullscreen]="true" #dialogAccount>
  <app-setup-account (close)="dialogAccount.dismiss()"></app-setup-account>
</app-dialog>

<app-dialog *ngIf="modal === 'setup-whatsapp'" (close)="modal = ''" #dialogAccount>
  <div class="d-flex flex-column align-items-center text-center py-5">
    <img src="/assets/images/svg/no-connection.svg" alt="no connection" class="w-25">
    <h4 class="mt-5 mb-0">Enable Whatsapp integration</h4>
    <p class="mt-3">Connect your whatsapp to enable whatsapp shopping and add products from your whatsapp status</p>
    <button (click)="modal = 'connect-whatsapp'" class="btn btn-primary mt-4">Connect whatsapp</button>
    <button (click)="checkSetup('whatsapp')" class="btn btn-link mt-3">Not now</button>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'connect-whatsapp'" (close)="modal = ''" #cwDialog>
  <app-connect-whatsapp (close)="cwDialog.dismiss()" (done)="checkSetup()"></app-connect-whatsapp>
</app-dialog>
