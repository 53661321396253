<!-- Homepage banner -->
<div class="d-flex align-items-center justify-content-between mb-4">
  <div class="fw-bold text-primary font-weight-medium m-0">Banner</div>
  <button *ngIf="!adding && merchant.banner_slides.length" (click)="adding = true" class="btn btn-outline-secondary btn-xs">
    <i class="fa fa-plus me-2"></i>
    Add new slide
  </button>
</div>

<ng-container *ngIf="adding">
  <div class="banner-image overflow-hidden" style="height:200px">
    <img *ngIf="new_banner.image_preview" [src]="new_banner.image_preview" alt="" class="fit-cover w-100 h-100 rounded">
    <div *ngIf="!new_banner.image_preview" class="w-100 h-100 d-flex justify-content-center align-items-center border bg-gray rounded">
      <i class="fa fa-image fa-3x text-muted"></i>
    </div>
    <div class="banner-image-options d-flex flex-row justify-content-center align-items-center">
      <div class="card d-flex flex-column align-items-center justify-content-center me-4 cursor-pointer">
        <i class="fa fa-hand-pointer fa-2x"></i>
        <small class="mt-3">Choose an image</small>
      </div>
      <label for="banner_image" class="card d-flex flex-column align-items-center justify-content-center ms-4 cursor-pointer">
        <i class="fa fa-upload fa-2x"></i>
        <small class="mt-3">Upload from device</small>
      </label>
      <input
        hidden (change)="handleBannerImageChange($event)" type="file" id="banner_image" name="myImage"
        accept="image/x-png,image/gif,image/jpeg" class="my-4 hidden"
      >
    </div>
  </div>

  <div class="my-5">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <input [(ngModel)]="new_banner.title" type="text" class="form-control" id="text" placeholder="Banner title" [disabled]="loading">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input [(ngModel)]="new_banner.subtitle" type="text" class="form-control" id="subtext" placeholder="Banner subtitle" [disabled]="loading">
        </div>
      </div>
    </div>

    <div class="d-flex mt-4">
      <button *ngIf="!new_banner.id" [disabled]="loading" (click)="addBanner()" class="btn btn-success">Add banner</button>
      <button *ngIf="new_banner.id" [disabled]="loading" (click)="editBanner()" class="btn btn-success">Save</button>
      <button [disabled]="loading" (click)="cancelAdd()" class="btn btn-outline-danger ms-2">
        Cancel
      </button>
    </div>
  </div>
</ng-container>

<div
  *ngIf="!merchant.banner_slides.length && !adding" (click)="adding = true"
  class="card bg-off-white d-flex flex-row justify-content-center text-muted border-dashed p-4 mb-4 cursor-pointer"
>
  <i class="fa fa-plus me-3"></i> Add slide
</div>

<div *ngFor="let slide of merchant.banner_slides" class="card bg-off-white d-flex flex-column flex-md-row align-items-md-center mb-3 p-2 pe-4">
  <div class="d-flex align-items-center flex-row me-2">
    <img [src]="slide.image" alt="" class="fit-cover rounded-sm" style="height: 60px; width: 90px;">
    <div class="flex-grow-1 ms-3">
      <div class="title fw-bold font-size-md">{{ slide.title }}</div>
      <small class="title text-muted">{{ slide.subtitle }}</small>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-end mt-2 mt-md-0 ms-auto">
    <button *ngIf="!this.new_banner.id" (click)="editSlide(slide)" class="btn btn-outline-secondary btn-xs">
      <i class="fa fa-pen"></i>
    </button>
    <button (click)="deleteSlide(slide)" class="btn btn-outline-danger btn-xs ms-2" [disabled]="deleting.includes(slide.id)">
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>
