import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-collection-items',
  templateUrl: './settings-collection-items.component.html',
  styleUrls: ['./settings-collection-items.component.css']
})
export class SettingsCollectionItemsComponent implements OnInit, OnChanges {
  @Input() merchant: MerchantProfileDetails;

  items_display = 3;
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const merchant: MerchantProfileDetails = changes.merchant.currentValue;
    const { items_display } = merchant;
    this.items_display = +items_display;
  }

  ngOnInit(): void {
  }

  async saveItemsDisplay() {
    this.loading = true;
    const res = await this._api.updateOnlineStore({ items_display: this.items_display }).toPromise<any>();
    this._general.notify('success', res.message || 'Saved');
    this._auth.updateMerchant({ items_display: this.items_display });
    this.loading = false;
  }
}
