import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-product-comments',
  templateUrl: './product-comments.component.html',
  styleUrls: ['./product-comments.component.scss']
})
export class ProductCommentsComponent implements OnInit {

  @Input() product: any;
  comments = [];
  meta = null;
  loading = false;

  constructor(
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.getComments();
  }

  async getComments(page_number = 1) {
    this.loading = true;
    const { data, ...meta } = await this._api.getProductComments(this.product.id, page_number).toPromise<any>();
    this.comments = data;
    this.meta = meta;
    this.loading = false;
  }
}
