<div class="card mb-4">
  <h5 class="title mb-5">Credit/Interest Settings</h5>

  <form [formGroup]="interestRateForm" (ngSubmit)="updateInterestForm(interestRateForm, $event)" class="mb-0">

    <div class="mb-4">
      <label class="form-label">Monthly installment (months)</label>
      <select formControlName="max_credit_duration" class="form-select">
        <option disabled>Select monthly installment</option>
        <option [value]="installment.value" *ngFor="let installment of installments; index as i">
          {{ installment.text }} {{ installment.unit }}
        </option>
      </select>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="mb-4">
          <label class="form-label">Upfront rate (%)</label>
          <select formControlName="upfront_rate" class="form-select">
            <option disabled>Select upfront rate</option>
            <option [value]="rate" *ngFor="let rate of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]; index as i">
              {{ rate }}%
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-4">
          <label class="form-label">Interest rate (%)</label>
          <select formControlName="monthly_interest_rate" class="form-select">
            <option disabled>Select interest rate</option>
            <option [value]="rate" *ngFor="let rate of [1,2,3,4,5,6,7,8,9,10]; index as i">
              {{ rate }}%
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row border-bottom align-items-center justify-content-between mb-3 pb-3 mt-4">
      <div class="title">Apply interest rate on balance</div>
      <div class="form-check form-switch">
        <input
          class="form-check-input" type="checkbox" id="on-balance"
          [checked]="interestRateForm.value.interest_on_balance == 1" (change)="setInterestOnBalance($event)"
        />
      </div>
    </div>

    <div class="d-flex flex-row align-items-center justify-content-between mb-3 pb-3">
      <div class="title">Apply interest rate on total</div>
      <div class="form-check form-switch">
        <input
          class="form-check-input" type="checkbox" id="on-total"
          [checked]="interestRateForm.value.interest_on_total == 1" (change)="setInterestOnTotal($event)"
        />
      </div>
    </div>

    <div class="mt-5">
      <button type="submit" class="btn-md btn btn-success">Save</button>
    </div>
  </form>
</div>
