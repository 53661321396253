import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { debounceTime, take } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService } from 'src/app/services/store.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search', { static: false, read: ElementRef }) input: ElementRef;

  loading = false;
  fetching = false;
  customers: any[] = [];
  currentCustomer: any = null;
  subscriptions: Subscription[] = [];
  meta = null;
  searched = {
    data: [],
    meta: null
  };

  public get items(): any[] {
    return this.searched.meta ? this.searched.data : this.customers;
  }

  constructor(
    private _api: ApiService,
    public _general: GeneralService,
    private _store: StoreService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const customer = this.customers.find(r => r.id.toString() === params.id.toString());
        if (customer) {
          this.currentCustomer = customer;
        } else {
          this.closeViewCustomer();
        }
      }
    });

    if (!(this._store.customers.items || []).length) this.fetchAllCustomerRequests();

    this.subscriptions.push(
      this._store.$customers.subscribe({
        next: ({ items, meta }) => {
          if (!items) return;
          this.customers = items;
          this.meta = meta;
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(1000)).subscribe((e: any) => {
        const query = e.target.value;
        if (query) this.search(query);
        else this.searched = { data: [], meta: null };
      })
    );
  }

  fetchAllCustomerRequests(page = 1) {
    this[page === 1 ? 'loading' : 'fetching'] = true;
    this.subscriptions[1] = this._api.getAllCustomerRequests(page).pipe(take(1)).subscribe(({ data, ...meta }) => {
      this[page === 1 ? 'loading' : 'fetching'] = false;
      if (page === 1) this._store.setState('customers', { items: data, meta }, true);
      else this._store.setState('customers', { items: [...this._store.customers.items, ...data], meta }, true);
    }, () => {
      this[page === 1 ? 'loading' : 'fetching'] = false;
      this._general.notify('error', 'Oops! Something went wrong. Please reload the page to try again.');
    });
  }

  viewCustomer(customer) {
    this.router.navigate([`/customers`], { queryParams: { id: customer.id } });
  }

  closeViewCustomer() {
    this.currentCustomer = null;
    this.router.navigate([`/customers`]);
  }


  search(query: string, page = 1) {
    const searchParameter = { search: query, page_number: page };
    this[page === 1 ? 'loading' : 'fetching'] = true;
    this._api.searchInCustomers(searchParameter).pipe(take(1)).subscribe(({ data, ...meta }: any) => {
      this[page === 1 ? 'loading' : 'fetching'] = false;
      if (page === 1) this.searched.data = data;
      else this.searched.data = [...this.searched.data, ...data];
      this.searched.meta = meta;
    }, () => {
      this[page === 1 ? 'loading' : 'fetching'] = false;
      this._general.notify('error', `We could not fetch results for ${ query }`);
    });
  }

  loadMore() {
    if (this.searched.data.length) {
      return this.search(this.input.nativeElement.value, this.searched.meta.next);
    }
    this.fetchAllCustomerRequests(this.meta.next);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => element.unsubscribe());
  }
}
