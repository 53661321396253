<header class="header fixed-top" [ngClass]="{ hidden: scrolled && scrollDirection === 'down' }">
  <div class="sticky-wrapper">
    <div class="header-bottom sticky-header d-none d-lg-block">
      <div class="container d-flex justify-content-between" style="height: 70px">
        <div class="d-flex align-items-center flex-grow-1 flex-md-grow-0">
          <div
            *ngIf="!mobile || !currentRoute" (click)="_general.navigateTo('/dashboard')"
            class="d-none d-md-flex align-items-center business-info"
          >
            <div class="overflow-visible">
              <img src="assets/images/logo.png" alt="" style="height: 25px" class="rounded">
            </div>
          </div>

          <div *ngIf="!mobile || !currentRoute" class="d-flex d-md-none align-items-center business-info">
            <div class="overflow-visible">
              <img src="assets/images/alt-logo.png" alt="" style="height: 40px" class="rounded">
            </div>
            <h5 class="m-0 ms-3 business-name">
              Hi, {{ _auth.merchant.name }}
            </h5>
          </div>

          <h5 class="m-0" *ngIf="mobile && currentRoute">
            <button (click)="goBack()" class="btn btn-link px-0 me-3">
              <i class="fa fa-arrow-left"></i>
            </button>
            {{ currentRoute | titlecase }}
          </h5>
          <div class="d-flex align-items-center ms-auto d-md-none">
            <button *ngIf="showSearch" (click)="search.emit()" class="btn icon-button bg-transparent">
              <i class="fa fa-search text-muted"></i>
            </button>
            <button (click)="mobileNavVisible = true" class="btn icon-button ms-2 bg-transparent text-muted">
              <i class="fa fa-bars"></i>
            </button>
          </div>
        </div>

        <nav class="main-nav flex-grow-1 d-none d-md-flex ms-2">
          <ul id="target" class="menu mx-auto" style="touch-action: pan-y;">
            <li (click)="_general.navigateTo('/dashboard')" [class.active]="currentRoute == 'home'">
              <a href="javascript:void(0)">Home</a>
            </li>

            <li
              (click)="_general.navigateTo('/requests')"
              [class.active]="currentRoute == 'requests' || currentRoute == 'repayments' || currentRoute == 'sales'"
            >
              <a href="javascript:void(0)" class="position-relative">Orders</a>
            </li>

            <ng-template [ngIf]="!_auth.whatsappOnly">
              <li
                (click)="_general.navigateTo('/products')"
                [class.active]="currentRoute == 'products'"
              >
                <a href="javascript:void(0)">Products</a>
              </li>

              <li class="dropdown">
                <a
                  class="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"
                >
                  More <i class="fa fa-chevron-down ms-2"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a (click)="launchModalIfProfileComplete('business-loan')" class="py-2 dropdown-item cursor-pointer">
                    Get business loans
                  </a>
                  <a (click)="_general.navigateTo('/payment')" class="py-2 dropdown-item cursor-pointer">
                    Payment
                  </a>
                  <a (click)="_general.navigateTo('/pricing')" class="py-2 dropdown-item cursor-pointer">
                    Pricing
                  </a>
                </div>
              </li>
            </ng-template>
          </ul>

          <ul class="menu">
            <li class="dropdown">
              <a
                class="d-inline-flex align-items-center dropdown-toggle position-relative" type="button"
                id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
              >
                Hi, {{ _auth.merchant.name }} <i class="fa fa-chevron-down ms-2"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right pt-0" aria-labelledby="dropdownMenuButton2"
                style="width: 300px"
              >
                <div class="bg-off-white d-flex align-items-center border-bottom p-4 w-100 mb-2 header-logo">
                  <div *ngIf="_auth.merchant.picture" class="overflow-visible">
                    <img [src]="_auth.merchant.picture" alt="" style="height: 40px" class="rounded border">
                  </div>
                  <div *ngIf="!_auth.merchant.picture" class="avatar bg-light-grey overflow-visible">
                    {{ _auth.merchant.name.substr(0, 2).toUpperCase() }}
                  </div>
                  <h5 class="m-0 ms-3 business-name">{{ _auth.merchant.name }}</h5>
                </div>
                <ng-template [ngIf]="!_auth.whatsappOnly">
                  <a (click)="modal = 'online-store'" class="py-2 dropdown-item cursor-pointer">
                    <i class="text-muted fa fa-globe me-2"></i> Online store
                  </a>
                  <a (click)="modal = 'wallet'" class="py-2 dropdown-item cursor-pointer">
                    <i class="text-muted fa fa-wallet me-2"></i> Wallet
                  </a>
                  <a (click)="modal = 'collection-history'" class="py-2 dropdown-item cursor-pointer">
                    <i class="text-muted fa fa-history me-2"></i> Collections History
                  </a>
                </ng-template>
                <a (click)="modal = 'settings'" class="py-2 dropdown-item cursor-pointer">
                  <i class="text-muted fa fa-cog me-2"></i> Settings
                </a>
                <span class="divider"></span>
                <a (click)="_auth.logout()" class="py-2 dropdown-item cursor-pointer">
                  <i class="text-muted fa fa-power-off me-2"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>

<app-mobile-navigation-drawer
  #nav *ngIf="mobileNavVisible"
  (close)="mobileNavVisible = false"
  (modal)="nav.dismiss(); launchModal($event)"
>
</app-mobile-navigation-drawer>

<app-dialog *ngIf="modal === 'online-store'" (close)="modal = ''" title="Online store">
  <app-online-store></app-online-store>
</app-dialog>

<app-dialog *ngIf="modal === 'notifications'" (close)="modal = ''" title="Notifications" [no-padding]="true">
  <app-notifications></app-notifications>
</app-dialog>

<app-dialog *ngIf="modal === 'wallet'" (close)="modal = ''" title="Wallet">
  <app-wallet></app-wallet>
</app-dialog>

<app-dialog *ngIf="modal === 'collection-history'" (close)="modal = ''" title="Collection history" [no-padding]="true">
  <app-collections-history></app-collections-history>
</app-dialog>

<app-dialog
  *ngIf="modal === 'business-loan'" (close)="modal = ''" [close-button]="false" title="Business Loan" #dialogBL
>
  <app-business-loan (close)="dialogBL.dismiss()"></app-business-loan>
</app-dialog>

<app-dialog *ngIf="modal === 'settings'" (close)="modal = ''" title="Settings">
  <app-settings-request-analysis *ngIf="merchant.partnership !== 1" [merchant]="merchant">
  </app-settings-request-analysis>
  <app-settings-interest *ngIf="merchant.partnership !== 1" [merchant]="merchant"></app-settings-interest>
  <app-settings-bank-partnership [merchant]="merchant"></app-settings-bank-partnership>
  <app-settings-business-details [merchant]="merchant"></app-settings-business-details>
  <app-settings-account-details [merchant]="merchant"></app-settings-account-details>
  <app-settings-change-email [merchant]="merchant"></app-settings-change-email>
  <app-settings-change-phone [merchant]="merchant"></app-settings-change-phone>
  <app-settings-social-accounts [merchant]="merchant"></app-settings-social-accounts>
</app-dialog>

<app-dialog *ngIf="modal === 'connect-whatsapp'" (close)="modal = ''" #cwDialog>
  <app-connect-whatsapp (close)="cwDialog.dismiss()"></app-connect-whatsapp>
</app-dialog>
