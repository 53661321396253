import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductCategories } from 'src/app/models/categories';
import { Merchant } from 'src/app/models/merchant';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';
import platforms from '../../../lib/platforms';

@Component({
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, OnDestroy {
  categories: Array<{ id: number; name: string }> = ProductCategories().data;
  customisedOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoWidth: true,
    navSpeed: 700,
    navText: ['', ''],
    responsiveRefreshRate: 350,
    responsive: {
      0: { items: 1 },
      380: { items: 5 },
      400: { items: 5 },
      740: { items: 3 },
      940: { items: 3 }
    }
  };
  otherPlatforms = platforms.filter(p => p.name !== 'creditclan');

  productForm: FormGroup;
  merchantForm: FormGroup;
  instagram = '';
  whatsapp_no = '';
  selectedPlatforms = [];
  selectedInterests = [];
  selectedCategories = [];

  view = '';
  modal = '';
  upfrontValues = [];
  specifyUpfront = false;
  imageChangedEvent: any;
  termsAccepted = false;
  loading = false;
  link: string;
  croppedImage: any;
  businessInterests: any[];

  history: string[] = [];

  constructor(
    private fb: FormBuilder,
    private _general: GeneralService,
    private _auth: AuthService,
    private _api: ApiService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    this.productForm = this.fb.group({
      name: ['', Validators.required],
      category_id: ['', Validators.required],
      price: ['', Validators.required],
      credit_price: '',
      sell_on_credit: '',
      monthly_amount: '',
      no_of_months: '',
      upfront_amount: '',
      primary_picture: ''
    });

    let publisher = this.activatedRoute.snapshot.queryParamMap.get('publisher');
    if (publisher !== null && publisher === '1') {
      publisher = '1';
    } else {
      publisher = '0';
    }

    this.merchantForm = this.fb.group({
      emailOrPhone: ['', [Validators.required, Validators.pattern(/(^[0]\d{10}$)|(^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$)/)]],
      name: ['', Validators.required],
      password: ['', Validators.required],
      business_type: ['1', Validators.required],
      agent_no: [''],
      online: ['1'],
      publisher
    });

    this.getBusinessInterests();
    this.pushView('do-you-sell-online');
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'auto';
  }

  pushView(view: string) {
    this.history.push(view);
    this.view = view;
  }

  popView() {
    this.history.pop();
    this.view = this.history[this.history.length - 1];
  }

  async getBusinessInterests() {
    const { data } = await this._api.getBusinessInterests();
    this.businessInterests = data;
  }

  selectCategory(e, category) {
    if (this.selectedCategories.includes(category.id)) {
      this.selectedCategories = this.selectedCategories.filter(c => c.id !== category.id);
    } else {
      this.selectedCategories.push(category.id);
    }
  }

  selectPlatform(platform) {
    if (this.selectedPlatforms.includes(platform.name)) {
      this.selectedPlatforms = this.selectedPlatforms.filter(s => s !== platform.name);
    } else {
      this.selectedPlatforms.push(platform.name);
    }
  }

  selectInterest(event: Event, interest) {
    const input = event.target as HTMLInputElement;
    if (input.checked) {
      this.selectedInterests.push({
        id: interest.id,
        interest: interest.interest
      });
    } else {
      const index = this.selectedInterests.findIndex(e => `${ e.id }` === `${ interest.id }`);
      this.selectedInterests.splice(index, 1);
    }
  }

  setOnline(value) {
    this.merchantForm.get('online').setValue(value);
  }

  setSellOnCredit(value: string) {
    this.productForm.get('sell_on_credit').patchValue(value);
    if (value === '1') {
      if (!this.productForm.get('credit_price').value) {
        this.productForm.get('credit_price').setValue(this.productForm.get('price').value);
      }
      this.pushView('setup-credit');
    } else {
      this.pushView('setup-picture');
    }
  }

  populateUpfrontValues() {
    const { no_of_months, credit_price } = this.productForm.value;
    if (!no_of_months && !credit_price) {
      return;
    }
    const values = [];
    if (+no_of_months > 0) {
      for (let i = 1; i <= Array(+no_of_months).slice(0, 2).length; i++) {
        if (i === 1) {
          values.push({
            amount: credit_price / +no_of_months,
            label: 'Pay 1st month'
          });
        } else {
          values.push({
            amount: (credit_price / +no_of_months) * i,
            label: `Pay up to ${ i } months`
          });
        }
      }
    }
    values.push({ amount: credit_price / 2, label: 'Pay Half' });
    values.push({ amount: credit_price / 4, label: 'Pay Quarter' });
    this.upfrontValues = values;
  }

  handleUpfrontChange(value) {
    if (value) {
      if (value === 'specify') {
        this.specifyUpfront = true;
      } else {
        this.specifyUpfront = false;
        this.productForm.get('upfront_amount').setValue(value);
      }
    } else {
      this.productForm.get('upfront_amount').setValue('');
    }
  }

  submitProductForm() {
    const { credit_price, price } = this.productForm.value;
    if (+credit_price > +price) {
      return alert('Credit price cannot be higher than actual price');
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.modal = 'cropping';
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageCroppingDone() {
    this.productForm.get('primary_picture').setValue(this.croppedImage);
    this.modal = '';
  }

  submitDetails() {
    const { emailOrPhone, ...rest } = this.merchantForm.value;
    const payload = {
      ...rest,
      [!!parseInt(emailOrPhone, 10) ? 'phone' : 'email']: emailOrPhone
    };
    if (this.whatsapp_no) payload['whatsapp_no'] = this.whatsapp_no;
    payload['interests'] = this.selectedInterests.map(x => x.id).join(',');
    payload['categories'] = this.selectedCategories.join(',');
    payload['other_platforms'] = this.selectedPlatforms.join(', ');
    this.register(payload);
  }

  register(payload: Partial<Merchant>) {
    this.loading = true;
    this.pushView('creating');
    this._api.register(payload).subscribe(async val => {
      const { completion_details, token } = val;
      this._auth.saveToken(token);
      const { global_catalogue_link } = completion_details;
      this.link = global_catalogue_link;
      if (this.productForm.value['primary_picture']) {
        try {
          await this.createProduct();
          if (this.instagram && this.history.includes('provide-instagram')) this.persistInstagramUsername();
          this.pushView('success');
        } catch (error) {
          this.loading = false;
          this._general.notify('warning', 'Something went wrong! Please try again later.');
          this.popView();
        }
        return;
      }
      this.loading = false;
      if (this.instagram && this.history.includes('provide-instagram')) this.persistInstagramUsername();
      this.pushView('success');
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      const { status, error: e } = err;
      if (status >= 400 && status < 500) {
        this._general.notify('error', e.message);
      } else if (status === 500) {
        this._general.notify('error', e.message);
      } else {
        this._general.notify('error', 'It seems you entered incorrect details. Check what you entered and try again.');
      }
      this.popView();
    });
  }

  async createProduct() {
    const values = this.productForm.value;
    const fd = new FormData();
    Object.keys(values).forEach(key => fd.append(key, values[key]));
    return await this._api.additems(fd).toPromise();
  }

  share() {
    const payload = { info: { description: null, link: this.link } };
    this._general.broadCastSocialShare(payload);
  }

  persistInstagramUsername() {
    const payload = { instagram: `https://www.instagram.com/${ this.instagram }` };
    this._api.socialMediaUpdate(payload).subscribe(() => {
      this._auth.setMerchant({ ...this._auth.merchant, ...payload });
    }, () => {
      this._general.notify('error', 'Could not save instagram handle');
    });
  }
}
