<footer class="footer d-none d-md-block">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="brand">
          <img src="https://creditclan.com/pub/images/logo-home.png" alt="" width="130px" class="logo"/>

          <div class="text-white mt-5 text">
            We help financial Institutions and businesses provide credit to consumers accross
            africa
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="footer-links mt-md-0 mt-5">
          <h5 class="mb-5 text-white">Quick links</h5>
          <a [routerLink]="['/dashboard']" class="mb-3">Dashboard</a>
          <a [routerLink]="['/products']" class="mb-3">My products</a>
          <a [routerLink]="['/requests']" class="mb-3">Recent requests</a>
          <a [routerLink]="['/customers']" class="mb-3">Customers</a>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</footer>
