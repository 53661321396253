<div class="page-wrapper">
  <app-header [currentRoute]="'settings'"></app-header>
  <main class="main nav-margin">
    <div class="container pt-3 align-items-stretch">
      <div class="row">

        <div class="col-md-3">
          <div class="card p-0 mb-4 overflow-hidden sticky-top" style="top: 100px">
            <div class="p-4">
              <h5 class="title">Settings</h5>
            </div>
            <ul class="mobile-menu p-0 m-0">
              <li class="border-bottom m-0">
                <a (click)="changeSection('general')" [ngClass]="{'active-setting': section === 'general'}">
                  <i class="icon fa fa-cog text-info"></i>General
                </a>
              </li>
              <!-- <li class="border-bottom m-0">
                <a (click)="changeSection('store')" [ngClass]="{'active-setting': section === 'store'}">
                  <i class="icon fa fa-store text-warning"></i>Store
                </a>
              </li> -->
              <li class="border-bottom m-0">
                <a (click)="changeSection('social')" [ngClass]="{'active-setting': section === 'social'}">
                  <i class="icon fab fa-facebook text-primary"></i>Social media
                </a>
              </li>
              <li class="m-0">
                <a (click)="changeSection('account')" [ngClass]="{'active-setting': section === 'account'}">
                  <i class="icon fa fa-user-cog text-danger"></i>Account
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-9">
          <ng-container *ngIf="section === 'general'">
            <app-settings-interest [merchant]="merchant"></app-settings-interest>
            <app-settings-bank-partnership [merchant]="merchant"></app-settings-bank-partnership>
          </ng-container>

          <!-- <ng-container *ngIf="section === 'store'">
            <app-settings-profile-picture [merchant]="merchant"></app-settings-profile-picture>
            <app-settings-business-details [merchant]="merchant"></app-settings-business-details>
            <app-settings-tags></app-settings-tags>
            <app-settings-collection-items></app-settings-collection-items>
          </ng-container> -->

          <ng-container *ngIf="section === 'account'">
            <app-settings-account-details [merchant]="merchant"></app-settings-account-details>
            <app-settings-change-email></app-settings-change-email>
          </ng-container>

          <ng-container *ngIf="section === 'social'">
            <app-settings-social-accounts [merchant]="merchant"></app-settings-social-accounts>
          </ng-container>
        </div>

      </div>
    </div>
  </main>
  <app-bottom-nav></app-bottom-nav>
</div>
