<button class="btn btn-link px-0 btn-sm mb-0" (click)="back.emit()">
  <i class="fa fa-arrow-left me-2"></i>Back
</button>

<div class="empty d-flex flex-column text-center ">
  <img src="https://www.flaticon.com/svg/static/icons/svg/3388/3388466.svg" alt="No account number" width="100"
    *ngIf="noCreditInformationFound" />

  <ng-container *ngIf="noCreditInformationFound">
    <h5 class="title fw-bold mb-4 mt-5">No credit history</h5>
    <div style="max-width: 300px; margin: auto;">
      No credit history data was found for this customer.
    </div>

    <button class="btn btn-outline-primary mt-5">
      Try a different contact
    </button>
  </ng-container>
</div>

<div *ngIf="riskAssessed" class="my-4">
  <h5 class="title">Recommended Credit</h5>
  <div class="border-bottom mb-3 pb-3">
    <div class="title fw-bold">
      ₦ {{ request.total_credit }}
    </div>
    <small>Upfront payment</small>
  </div>
  <div class="border-bottom mb-3 pb-3">
    <div class="title fw-bold">
      {{ request.monthly_amt }}/Mo
    </div>
    <small>Installment</small>
  </div>

  <div class="border-bottom mb-3 pb-3">
    <div class="title fw-bold">
      {{ request.no_of_months }} months
    </div>
    <small>duration</small>
  </div>
  <div class="border-bottom mb-3 pb-3 d-flex flex-column justify-content-between">
    <div class="title fw-bold">
      Recollection Mode <i class="far fa-check-circle ms-2 text-success"></i>
    </div>
    <small>send link to customer</small>
  </div>
  <!-- <div class="mb-3 pb-3">
    <div class="title fw-bold d-flex  justify-content-center">
      Recollection mode <i class="far fa-check-circle ms-2 text-success"></i>
    </div>
  </div> -->
  <!-- <button disabled="" class="btn btn-primary mt-4">Confirm payment</button> -->
</div>

<div *ngIf="loading" class="d-flex justify-content-center my-5">
  <div>
    <svg width="73px" height="88px" viewBox="0 0 73 88" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="hourglass">
        <path
          d="M63.8761664,86 C63.9491436,84.74063 64,83.4707791 64,82.1818182 C64,65.2090455 57.5148507,50.6237818 48.20041,44 C57.5148507,37.3762182 64,22.7909545 64,5.81818182 C64,4.52922091 63.9491436,3.25937 63.8761664,2 L10.1238336,2 C10.0508564,3.25937 10,4.52922091 10,5.81818182 C10,22.7909545 16.4851493,37.3762182 25.79959,44 C16.4851493,50.6237818 10,65.2090455 10,82.1818182 C10,83.4707791 10.0508564,84.74063 10.1238336,86 L63.8761664,86 Z"
          id="glass" fill="skyblue"></path>
        <rect id="top-plate" fill="#9A9A9A" x="0" y="0" width="74" height="8" rx="2"></rect>
        <rect id="bottom-plate" fill="#9A9A9A" x="0" y="80" width="74" height="8" rx="2"></rect>

        <g id="top-sand" transform="translate(18, 21)">
          <clipPath id="top-clip-path" fill="white">
            <rect x="0" y="0" width="38" height="21"></rect>
          </clipPath>

          <path fill="#F5A623" clip-path="url(#top-clip-path)"
            d="M38,0 C36.218769,7.51704545 24.818769,21 19,21 C13.418769,21 1.9,7.63636364 0,0 L38,0 Z"></path>
        </g>

        <g id="bottom-sand" transform="translate(18, 55)">
          <clipPath id="bottom-clip-path" fill="white">
            <rect x="0" y="0" width="38" height="21"></rect>
          </clipPath>

          <g clip-path="url(#bottom-clip-path)">
            <path fill="#F5A623"
              d="M0,21 L38,21 C36.1,13.3636364 24.581231,0 19,0 C13.181231,0 1.781231,13.4829545 0,21 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
