import { Component, Input, OnInit } from '@angular/core';
import { MerchantProfileDetails } from '../../models/merchantProfile';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { AuthService } from '../../services/auth.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-settings-change-email',
  templateUrl: './settings-change-email.component.html',
  styleUrls: ['./settings-change-email.component.css']
})
export class SettingsChangeEmailComponent implements OnInit {
  emailForm: FormGroup;
  otpForm: FormGroup;
  @Input() merchant: MerchantProfileDetails;
  change = false;
  view = 'default';
  loading = false;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _general: GeneralService,
    private _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.otpForm = this.fb.group({
      otp: ['', Validators.required],
    });
  }

  async handleEmailFormSubmit() {
    const payload = this.emailForm.value;
    if (payload.email === this._auth.merchant.email) {
      return this._general.notify('error', 'Your new email cannot be the same as your old one');
    }
    this.loading = true;
    try {
      const res = await this._api.sendNewEmailOTP(payload).toPromise();
      this._general.notify('success', res.message);
      this.view = 'otp';
    } catch (e) {
      const message = e?.error?.message ?? 'An error occurred, please try again';
      this._general.notify('error', message);
    }
    this.loading = false;
  }

  async handleOtpFormSubmit() {
    const payload = { ...this.otpForm.value, email: this.emailForm.value.email };
    if (!payload.email) return this.submitEmailVerification();
    this.loading = true;
    try {
      const res = await this._api.changeEmail(payload).toPromise();
      this._general.notify('success', res?.message);
      const completion_details = { ...this._auth.merchant.completion_details, email: payload.email, email_validated: true };
      this._auth.updateMerchant({ email: payload.email, email_validated: true, completion_details });
      this.view = 'default';
    } catch (e) {
      console.log({ e });
      const message = e?.error?.message ?? 'An error occurred, please try again';
      this._general.notify('error', message);
    }
    this.loading = false;
  }

  sendVerificationEmail() {
    this.loading = true;
    this._api.sendVerificationEmail().pipe(timeout(60000)).subscribe(() => {
      this.loading = false;
      this.view = 'otp';
    }, err => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }

  submitEmailVerification() {
    const otp = this.otpForm.value.otp;
    if (otp.length < 4) return;
    this.loading = true;
    this._api.verifyMerchantEmail(otp).pipe(timeout(60000)).subscribe(async () => {
      const res = await this._api.fetchCompletionDetails();
      this._auth.updateMerchant({
        completion_details: { ...this.merchant.completion_details, ...res }, email_validated: true
      });
      this.view = 'default';
      this.loading = false;
    }, err => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }
}
