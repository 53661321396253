import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IRequest } from '../../models/requests';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-request-manual-items',
  templateUrl: './request-manual-items.component.html',
  styleUrls: ['./request-manual-items.component.scss']
})
export class RequestManualItemsComponent implements OnInit {
  @Input() request: IRequest;
  @Output() done = new EventEmitter();
  productForm: FormGroup;
  items = [];
  view = 'add';
  max = Math.max;
  priceMismatch = false;
  croppedImage: any = null;
  imageChangedEvent: any = null;
  modal = '';
  loading = false;

  constructor(private fb: FormBuilder, private _api: ApiService, private _general: GeneralService) {
  }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      primary_picture: ['', Validators.required],
      name: ['', Validators.required],
      price: ['', Validators.required],
      quantity: [1, Validators.required],
      category_id: [7],
      sell_on_credit: [1],
    });
  }

  submitNewProductForm() {
    this.items.push({
      id: this.items.length + 1,
      ...this.productForm.value
    });
    this.productForm.reset();
    this.productForm.get('quantity').setValue(1);
    this.productForm.get('category_id').setValue(7);
    this.productForm.get('sell_on_credit').setValue(1);
    this.view = 'items';
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.modal = 'cropping';
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageCroppingDone() {
    this.productForm.get('primary_picture').setValue(this.croppedImage);
    this.modal = '';
  }


  incrementQuantity(id) {
    let updated = false;
    this.items = this.items.map(item => {
      if (item.id === id) {
        updated = true;
        return { ...item, quantity: +item.quantity + 1 };
      }
      return item;
    });
  }

  decrementQuantity(id) {
    let updated = false;
    this.items = this.items.map(item => {
      if (item.id === id) {
        updated = true;
        return {
          ...item,
          quantity: +item.quantity === 1 ? item.quantity : --item.quantity
        };
      }
      return item;
    });
  }

  removeItem(id) {
    let removed = false;
    this.items = this.items.filter(item => {
      if (item.id === id) {
        removed = true;
        return false;
      }
      return true;
    });
  }

  async submitItems() {
    const price = this.itemsPrice();
    if (price > this.request.cred_plus_upfront) {
      return this._general.notify('error', 'Total price of items cannot be larger than order amount');
    }
    const items = this.items.map(({ id: _, ...item }) => ({ ...item, price: `${ item.price }` }));
    this.loading = true;
    try {
      const res = await this._api.updateRequestItems(this.request.id, items).toPromise<any>();
      this.done.emit(res.data);
    } catch (e) {
      this._general.notify('error', e?.error?.message || 'An error occurred, please try again');
    }
    this.loading = false;
  }

  itemsPrice() {
    return this.items.reduce((acc, item) => acc + (+item.price * +item.quantity), 0);
  }
}
