<div *ngIf="view === 'welcome'" class="h-100">
  <div class="profile-padding d-flex flex-column justify-content-center h-100">
    <h3 class="title">Please take a moment to update your profile</h3>
    <div class="mt-2">
      <button (click)="pushView('bank-details')" class="btn btn-success btn-md mb-1 me-3">
        Continue
      </button>
    </div>
    <br><br><br>
    <img src="assets/images/svg/shared-goals.svg" alt="" style="width: 80%; max-width: 300px;"
         class="mt-5 ms-auto mt-auto">
  </div>
</div>

<div *ngIf="view !== 'welcome'">
  <nav class="custom-nav-container pt-5 pb-4 bg-gray border-bottom">
    <div id="bank_details" class="custom-nav">
      <div *ngIf="merchant.completion_details.bank_details" class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant.completion_details.bank_details" class="icon-container mx-auto mb-1">
        <i class="fas fa-money-check"></i>
      </div>
      <small class="text-muted mt-2">Bank Details</small>
    </div>
    <div id="business_details" class="custom-nav">
      <div *ngIf="merchant.completion_details.business_details"
           class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant.completion_details.business_details" class="icon-container mx-auto mb-1">
        <i class="fas fa-suitcase"></i>
      </div>
      <small class="text-muted mt-2">Store details</small>
    </div>
    <div id="picture" class="custom-nav">
      <div *ngIf="merchant.completion_details.picture" class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant.completion_details.picture" class="icon-container mx-auto mb-1">
        <i class="fas fa-image"></i>
      </div>
      <small class="text-muted mt-2">Picture</small>
    </div>
    <div id="email_validated" class="custom-nav">
      <div *ngIf="merchant.completion_details.email_validated"
           class="bg-success text-white icon-container mx-auto mb-1">
        <i class="fa fa-check"></i>
      </div>
      <div *ngIf="!merchant.completion_details.email_validated" class="icon-container mx-auto mb-1">
        <i class="fas fa-user-lock"></i>
      </div>
      <small class="text-muted mt-2">Verification</small>
    </div>
  </nav>
</div>

<!-- Validate email -->
<div *ngIf="view.startsWith('validate-email')">
  <div *ngIf="view === 'validate-email'" class="product-desc profile-padding">
    <div class="title me-5 mt-4 mb-5">
      We would like to verify your email address. Click the button below to
      send a verification link to {{ merchant.email }}
    </div>

    <div class="mt-2">
      <button [disabled]="loading" (click)="sendVerificationEmail()" class="btn btn-primary">
        Get Verification code
      </button>
      <p class="mt-2">Not your email address?
        <button [disabled]="loading" type="button" class="btn btn-link text-primary px-0"
                (click)="pushView('validate-email-change')">
          Change here
        </button>
      </p>
    </div>
  </div>
  <!-- Verify -->
  <div class="profile-padding" *ngIf="view === 'validate-email-code'">
    <div class="title mb-5 w-75">
      Enter the verification code sent to your email
    </div>
    <div class="form-group required-field">
      <input [(ngModel)]="otp" type="text" class="form-control" placeholder="Enter code here"/>
    </div>
    <div class="mt-5">
      <button (click)="submitEmailVerification()" [disabled]="!otp.length || loading" type="submit"
              class="btn btn-primary">
        Verify
      </button>
      <button (click)="popView()" class="btn btn-outline-danger ms-3">Cancel</button>
    </div>
  </div>
  <!-- Change -->
  <div *ngIf="view === 'validate-email-change'">
    <div class="text-start profile-padding">
      <h5 class="title mb-5">
        Enter new email address
      </h5>
      <form [formGroup]="changeEmailForm" (submit)="changeEmail()">
        <input formControlName="email" class="form-control">
        <div class="d-flex mt-3">
          <button [disabled]="changeEmailForm.invalid || loading" type="submit" class="btn btn-primary">
            Change email
          </button>
          <button (click)="popView()" class="btn btn-outline-danger ms-3">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Validate phone -->
<div *ngIf="view.startsWith('validate-phone')">
  <div *ngIf="view === 'validate-phone'" class="product-desc profile-padding">
    <div class="title me-5 mt-4 mb-5">
      We would like to verify your phone number. Click the button below to
      send a verification link to {{ merchant.phone }}
    </div>

    <div class="mt-2">
      <button [disabled]="loading" (click)="sendVerificationSMS()" class="btn btn-primary">
        Get Verification code
      </button>
      <p class="mt-2">Not your phone number?
        <button [disabled]="loading" type="button" class="btn btn-link text-primary px-0"
                (click)="pushView('validate-phone-change')">
          Change here
        </button>
      </p>
    </div>
  </div>
  <!-- Verify -->
  <div class="profile-padding" *ngIf="view === 'validate-phone-code'">
    <div class="title mb-5 w-75">
      Enter the verification code sent to your phone number
    </div>
    <div class="form-group required-field">
      <input [(ngModel)]="otp" type="text" class="form-control" placeholder="Enter code here"/>
    </div>
    <div class="mt-5">
      <button
        (click)="submitSMSVerification()" [disabled]="!otp.length || loading" type="submit" class="btn btn-primary"
      >
        Verify
      </button>
      <button (click)="popView()" class="btn btn-outline-danger ms-3">Cancel</button>
    </div>
  </div>
  <!-- Change -->
  <div *ngIf="view === 'validate-phone-change'">
    <div class="text-start profile-padding">
      <h5 class="title mb-5">
        Enter new phone number
      </h5>
      <form [formGroup]="changePhoneForm" (submit)="changePhone()">
        <input formControlName="phone" class="form-control">
        <div class="d-flex mt-3">
          <button [disabled]="changePhoneForm.invalid || loading" type="submit" class="btn btn-primary">
            Change phone
          </button>
          <button (click)="popView()" class="btn btn-outline-danger ms-3">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Business details -->
<div *ngIf="view === 'business-details'" class="profile-padding">
  <form [formGroup]="businessDetailsForm" (ngSubmit)="submitBusinessDetailsForm()">
    <div class="form-group required mb-4">
      <label for="name-input">Business Name</label>
      <input type="text" class="form-control" formControlName="name" id="name-input"/>
    </div>

    <div class="form-group required mb-4">
      <label for="whatsapp-input">Whatsapp number</label>
      <input class="form-control" id="whatsapp-input" type="tel" formControlName="whatsapp_no"/>
    </div>

    <div class="form-group required mb-4">
      <label for="address-input">Address</label>
      <textarea formControlName="address" type="text" class="form-control" id="address-input"></textarea>
    </div>

    <div class="form-group required form-group-sm mb-4">
      <label for="type-input">Business type</label>
      <div class="select-custom">
        <select formControlName="business_type" id="type-input" class="form-control form-control-sm">
          <option value="" selected>Choose...</option>
          <option [value]="business.id" *ngFor="let business of businessTypes; index as i">
            {{ business.value | titlecase }}
          </option>
        </select>
      </div>
    </div>

    <div class="mt-5 pb-5">
      <button [disabled]="loading" type="submit" class="btn me-3 btn-md btn-primary">Save</button>
    </div>
  </form>
</div>

<div *ngIf="view === 'picture'" class="profile-padding">
  <div class="img-holder position-relative d-inline-block rounded overflow-hidden" style="width: 300px; height: 300px;">
    <img id="targetImgContainer" [src]="imagePreview || 'assets/images/placeholder-img_2.jpg'" alt="">
    <label for="inputForUpload" class="edit-overlay">
      <i class="fa fa-edit"></i>
    </label>
  </div>
  <div class="mt-5">
    <input hidden (change)="handleImageChange($event)" id="inputForUpload" type="file" accept="image/png, image/jpeg"/>
    <button [disabled]="loading || !imagePreview" class="btn btn-primary" (click)="submitPicture()">Next</button>
  </div>
</div>

<div *ngIf="view === 'bank-details'" class="profile-padding">
  <form [formGroup]="accountInfoForm" (ngSubmit)="submitAccountInfoForm()">
    <h5 class="title mb-5">
      Please fill in your bank account details
    </h5>

    <div class="form-group required mb-4">
      <label for="bankName">Select Bank</label>
      <select id="bankName" formControlName="bank_code" class="form-control">
        <option value="" selected> Choose bank</option>
        <option [value]="bank.bank_code" *ngFor="let bank of nigerianBanks">
          {{ bank.name }}
        </option>
      </select>
    </div>

    <div class="form-group required mb-4" style=" position: relative;">
      <label for="acc-number-input">Account Number</label>
      <input formControlName="account_number" type="tel" pattern="[0-9]{10}" id="acc-number-input"
             placeholder="Account number" class="form-control">
    </div>

    <div *ngIf="accountInfoForm.value.account_name" class="alert alert-success p-4">
      Account name <span class="fw-bold">{{ accountInfoForm.value.account_name }}</span>
    </div>

    <div class="mt-5">
      <button (click)="validateAccount()" *ngIf="!accountInfoForm.value.account_name" type="button"
              [disabled]="!accountInfoForm.valid || loading" class="btn me-3 btn-md btn-primary">
        Validate
      </button>
      <button *ngIf="accountInfoForm.value.account_name" [disabled]="!accountInfoForm.valid || loading"
              class="btn me-3 btn-md btn-primary">
        Save
      </button>
      <button *ngIf="accountInfoForm.value.account_name" (click)="accountInfoForm.get('account_name').reset()"
              [disabled]="loading" class="btn btn-link">Change
      </button>
    </div>
  </form>
</div>

<div *ngIf="view === 'finished'" class="profile-padding">
  <div class="mx-auto text-center d-flex flex-column align-items-center">
    <div class="mt-5 position-relative text-center">
      <h5 class="title mb-2">Profile setup completed!</h5>
      <div class="title">
        You are now ready for business
      </div>
    </div>
    <button (click)="close.emit()" class="btn btn-outline-danger mt-5">Close</button>
  </div>
</div>
