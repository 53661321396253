<div *ngIf="loading !== ''" class="d-flex flex-column align-items-center">
  <br><br><br>
  <div class="d-flex align-items-center">
    <div class="spinner-border spinner-border-sm me-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    {{ countdown }}
  </div>
  <div class="title mt-4 text-center w-75 mx-auto font-italic">
    {{ loading }}
  </div>
  <br><br><br>
</div>

<ng-template [ngIf]="loading === ''">
  <div *ngIf="view === 'username'" class="card shadow-sm">
    <h3 class="title m-0">
      Pull product from instagram
    </h3>
    <p class="mt-1 mb-5">Enter your instagram handle below</p>
    <input
      type="text" autocomplete="off" class="form-control form-control-lg m-0 mb-1"
      placeholder="Enter your instagram username" [(ngModel)]="username"
    />
    <div class="form-group form-check d-flex align-items-center mt-4">
      <input
        type="checkbox" class="form-check-input mt-0" id="confirmHandle" [(ngModel)]="confirmed"
        [ngModelOptions]="{standalone: true}"
      />
      <label class="form-check-label ms-2" for="confirmHandle">
        I confirm this is my handle
      </label>
    </div>
    <div>
      <button
        (click)="handleUsernameChange()" [disabled]="!confirmed || username.length < 2"
        class="btn btn-primary mt-5"
      >
        Search
      </button>
    </div>
    <br><br><br><br><br><br><br><br>
    <div class="d-flex justify-content-end">
      <img src="/assets/images/svg/instagram.svg" alt="No account number" style="width: 100px">
    </div>
  </div>

  <div *ngIf="view === 'change'" class="card shadow-sm">
    <h3 class="title m-0">
      Pull product from instagram
    </h3>
    <p class="mt-1 mb-5">Enter instagram handle below</p>
    <input
      type="text" autocomplete="off" class="form-control form-control-lg m-0 mb-1"
      placeholder="Enter your instagram username" [(ngModel)]="username"
    />
    <div>
      <button (click)="getSavedPosts()" [disabled]="username.length < 2" class="btn btn-primary mt-5">Search</button>
    </div>
  </div>

  <div *ngIf="view === 'fetching'" class="d-flex flex-column align-items-center">
    <br><br><br>
    <div class="d-flex align-items-center">
      <div class="spinner-border spinner-border-sm me-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      {{ countdown }}
    </div>
    <div class="title mt-4 text-center w-75 mx-auto font-italic">
      Fetching most recent posts from <span class="fw-bold">@{{username}}</span>
    </div>
    <br><br><br>
  </div>

  <div *ngIf="view === 'error'" class="empty d-flex flex-column align-items-center text-center">
    <img src="assets/images/svg/crossed.svg" alt="No account number" width="100">
    <h5 class="title fw-bold mb-4 mt-5">An error occurred</h5>
    <div *ngIf="error" style="max-width: 300px; margin: auto;">
      {{ error }}
    </div>
    <button (click)="posts.length ? view = 'list' : view = 'username'" class="btn btn-outline-secondary mt-5">
      <i class="fa fa-arrow-left me-2"></i> Back
    </button>
  </div>

  <div [hidden]="view !== 'list'">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="title m-0">Posts from @{{username}}</h5>
    </div>
    <div class="text-start d-none d-md-flex flex-row flex-wrap mt-3">
      <button (click)="refresh()"
              class="btn btn-outline-secondary btn-xs px-3 py-1 d-inline-flex align-items-center rounded-pill me-2">
        <i class="fa fa-redo-alt me-2"></i>
        <span class="">Load recent posts</span>
      </button>
      <button (click)="change()"
              class="btn btn-outline-secondary btn-xs px-3 py-1 mt-1 mt-md-0 d-inline-flex align-items-center rounded-pill me-2">
        <i class="fa fa-exchange-alt me-2"></i>
        <span>Pull from another account</span>
      </button>
    </div>
    <div class="flex-row align-items-center d-none d-md-flex mt-4">
      <input
        type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
        placeholder="Search" [(ngModel)]="query" (input)="handleSearch()"
      />
    </div>
    <div class="row mt-4" *ngIf="displayPosts.length">
      <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let post of displayPosts">
        <div class="card p-0 cursor-pointer position-relative" (click)="select(post)"
             [ngClass]="{selected: selected?.id === post?.id}">
          <app-square-box>
            <app-proxy-image [src]="post.images[0]"></app-proxy-image>
          </app-square-box>
          <div class="count">{{ post.images.length }}</div>
        </div>
        <div [title]="post.description" class="title mt-2 text-ellipsize">{{ post.description }}</div>
      </div>
    </div>

    <div *ngIf="fetching" class="row">
      <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let i of [1,2,3,4]">
        <div class="card p-0 skeleton fit-content">
          <img [src]="'assets/images/plus.svg'" alt="">
        </div>
        <div class="title skeleton fit-content mt-2">Product name</div>
        <small class="skeleton fit-content">N200,000</small>
      </div>
    </div>

    <div class="mt-4 text-center" *ngIf="!fetching && posts.length && (page.next || page.end_cursor)">
      <button (click)="loadMore()" class="btn btn-link">Load more</button>
    </div>

    <button (click)="modal = 'options'" class="floating-action-button search-fab d-md-none"
            [ngClass]="{show: !search}">
      <i class="fa fa-stream"></i>
    </button>

    <div class="mobile-search" [ngClass]="{show: search}">
      <div class="d-flex align-items-center" id="searchDiv">
        <input type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
               placeholder="Search" (input)="handleSearch()" [(ngModel)]="query"/>
        <button (click)="stopSearch()" class="search-button ms-2">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'create-product' && selected">
    <app-create-product-embedded
      [item]="selected" (close)="closeCreateProduct()" [external_id]="selected.id" external="1"
    ></app-create-product-embedded>
  </div>
</ng-template>

<app-dialog *ngIf="modal === 'options'" (close)="modal = ''" [close-button]="false" #optionsDialog [nested]="true">
  <div class="row text-center">
    <div class="col-4">
      <div (click)="optionsDialog.dismiss(); refresh()"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-redo-alt text-info" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Load recent posts</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="optionsDialog.dismiss(); change()"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-exchange-alt text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Pull from another account</small>
      </div>
    </div>
    <div class="col-4">
      <div (click)="optionsDialog.dismiss(); search = true"
           class="d-flex flex-column align-items-center cursor-pointer py-4">
        <i class="fa fa-search text-warning" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Search</small>
      </div>
    </div>
  </div>
</app-dialog>
