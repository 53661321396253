<div class="mobile-menu-wrapper p-0">
  <nav class="mobile-nav">
    <ul class="mobile-menu">
      <li class="border-bottom">
        <a target="_blank" [href]="facebook">
          <i class="icon fab fa-facebook-f text-primary"></i>Share to facebook
        </a>
      </li>
      <li class="border-bottom">
        <a target="_blank" [href]="twitter">
          <i class="icon fab fa-twitter text-info"></i>Share to twitter
        </a>
      </li>
      <!-- <li class="border-bottom">
        <a target="_blank" [href]="whatsapp">
          <i class="icon fab fa-whatsapp text-success"></i>Share to whatsapp
        </a>
      </li> -->
      <!-- <li class="border-bottom">
        <a target="_blank" [href]="twitter">
          <i class="icon fab fa-instagram text-danger"></i>Share to instagram
        </a>
      </li> -->
      <li>
        <a (click)="shareLink($event)" href="javascript:void(0)">
          <i class="icon fas fa-link text-warning"></i>
          <span id="linkText">
              Copy link
            </span>
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="my-4 px-4">
  I just want to search for a
  <button (click)="search.emit()" class="btn btn-link text-primary d-inline px-0">
    single product
  </button>
</div>
