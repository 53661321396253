import { Component, Input, OnInit } from '@angular/core';
import { MerchantProfileDetails } from '../../models/merchantProfile';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings-item-details',
  templateUrl: './settings-item-details.component.html',
  styleUrls: ['./settings-item-details.component.scss']
})
export class SettingsItemDetailsComponent implements OnInit {

  @Input() merchant: MerchantProfileDetails;
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  handleCommentsChange(e: any) {
    this.save({ show_product_ratings: this._auth.merchant.show_product_ratings, show_product_comments: e.target.checked });
  }

  handleRatingsChange(e: any) {
    this.save({ show_product_comments: this._auth.merchant.show_product_comments, show_product_ratings: e.target.checked });
  }

  async save(data) {
    this.loading = true;
    const res = await this._api.updateProductReviewSettings(data).toPromise<any>();
    this._general.notify('success', res.message || 'Saved');
    this._auth.updateMerchant({ ...data });
    this.loading = false;
  }
}
