import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { debounceTime, take } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService } from 'src/app/services/store.service';
import { IRequest } from 'src/app/models/requests';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-recent-requests',
  templateUrl: './recent-requests.component.html',
  styleUrls: ['./recent-requests.component.scss']
})
export class RecentRequestsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('search') input: ElementRef;

  subscriptions: Subscription[] = [];
  requests: Array<IRequest> = [];
  searched = {
    data: [],
    meta: null
  };
  loading = false;
  fetching = false;
  modal = '';
  currentRequest = null;
  mobile = window.innerWidth < 768;
  meta: any;
  sortedBy = 'recent';
  filteredBy = '';
  filter = {
    data: [],
    meta: null,
    params: null
  };
  filterOptions = {
    creditOrOutright: 'all',
    confirmationStatus: 'none',
    deliveryStatus: 'none',
    payment: 'none'
  };

  constructor(
    private _api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _general: GeneralService,
    public _auth: AuthService,
    public _store: StoreService
  ) {
  }

  public get filtered(): boolean {
    const { confirmationStatus, deliveryStatus, creditOrOutright, payment } = this.filterOptions;
    return !(
      creditOrOutright === 'all' && confirmationStatus === 'none' && deliveryStatus === 'none' && payment === 'none'
    );
  }

  public get items(): any[] {
    if (this.searched.meta) return this.searched.data;
    else if (this.filter.meta) return this.filter.data;
    else return this.requests;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const request = this.requests.find(r => r.id.toString() === params.id.toString());
        if (request) {
          this.currentRequest = request;
          this.modal = 'view';
        } else {
          this.fetchSingleRequest(params.id);
        }
      }
    });

    console.log(this._store.requests.items);

    if (!this._store.requests.items?.length) this.fetchRequests();

    this.subscriptions.push(
      this._store.$requests.subscribe({
        next: ({ items, meta, sortedBy }) => {
          if (!items) {
            return;
          }
          this.requests = items;
          this.meta = meta;
          this.sortedBy = sortedBy;
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(1000)).subscribe((e: any) => {
        const query = e.target.value;
        if (query) {
          this.search(query);
        } else {
          this.searched = { data: [], meta: null };
        }
      })
    );
  }

  search(query, page_number = 1) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.searchOrdersOrRepaymentOrSales({
      search: query, page_number: `${ page_number }`, orders: true
    }).subscribe(({ data, ...meta }: any) => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      if (page_number === 1) {
        this.searched.data = data;
      } else {
        this.searched.data = [...this.searched.data, ...data];
      }
      this.searched.meta = meta;
    }, (err) => {
      console.log({ err });
    });
  }

  async fetchSingleRequest(id) {
    this.currentRequest = await this._api.getSingleRequest(id).toPromise();
    this.modal = 'view';
  }

  fetchRequests(page_number = 1) {
    if (this.sortedBy === 'recent') {
      this.fetchRequestsSortRecent(page_number);
    }
    if (this.sortedBy === 'customer') {
      this._general.notify('info', 'Implementation still under development..');
      setTimeout(() => {
        this.sortedBy = 'recent';
      }, 100);
      // this.fetchRequestsSortCustomer(page_number);
    }
  }

  fetchRequestsSortRecent(page_number) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    const count = this.items.length;
    this._api.getAllRecentRequests(`${ page_number }`).pipe(take(1)).subscribe(({ data, ...meta }) => {
      if (page_number === 1) {
        this._store.setState('requests', { ...this._store.requests, items: data, meta, sortedBy: 'recent' }, true);
      } else {
        this._store.setState('requests', {
          ...this._store.requests, items: [...this.requests, ...data], meta, sortedBy: 'recent'
        }, true);
      }
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      if (count === this.items.length && this.meta.next) {
        this.fetchRequests(this.meta.next);
      }
    }, () => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      this._general.notify('error', 'Unable to fetch requests, please check your network and reload');
    });
  }

  // fetchRequestsSortCustomer(page_number) {
  //   this[page_number === 1 ? 'loading' : 'fetching'] = true;
  //   this._api.getRequestsByCustomer(`${ page_number }`).pipe(take(1)).subscribe(({ data, ...meta }) => {
  //     if (page_number === 1) {
  //       this._store.setState('requests', { ...this._store.requests, items: data, meta, sortedBy: 'customer' }, true);
  //     } else {
  //       this._store.setState('requests', {
  //         ...this._store.requests, items: [...this.requests, ...data], meta, sortedBy: 'customer'
  //       }, true);
  //     }
  //     this[page_number === 1 ? 'loading' : 'fetching'] = false;
  //   }, () => {
  //     this[page_number === 1 ? 'loading' : 'fetching'] = false;
  //     this._general.notify('error', 'Unable to fetch requests, please check your network and reload');
  //   });
  // }

  async viewRequest(request, view = 'details') {
    await this.router.navigate([`/requests`], { queryParams: { id: request.id, view } });
  }

  async closeViewRequest(refresh) {
    if (refresh) {
      this.fetchRequests();
    }
    this.modal = '';
    this.currentRequest = null;
    await this.router.navigate([`/requests`]);
  }

  updateCurrentRequest(request) {
    this.currentRequest = request;
    const items = this._store.requests.items.map(r => r.id === request.id ? request : r);
    this._store.setState('requests', { ...this._store.requests, items }, true);
  }

  loadMore() {
    if (this.searched.data.length) {
      if (this.searched.meta.next) this.search(this.input.nativeElement.value, this.searched.meta.next);
      return;
    } else if (this.filter.data.length) {
      if (this.filter.meta.next) this.filterOrders(this.filter.meta.next);
      return;
    } else {
      this.fetchRequests(this.meta.next);
    }
  }

  launchModalIfProfileComplete(modal) {
    if (!this._auth.canShare) return this._general.showPendingSetup();
    this.modal = modal;
  }

  handleFilterChange() {
    this.modal = '';
    const { creditOrOutright, deliveryStatus, confirmationStatus, payment } = this.filterOptions;
    const params = {} as any;

    if (creditOrOutright === 'all') params.on_credit = 2;
    else if (creditOrOutright === 'credit') params.on_credit = 1;
    else if (creditOrOutright === 'outright') params.on_credit = 0;

    if (deliveryStatus === 'delivered') params.delivered = 1;
    else if (deliveryStatus === 'shipping') params.shipping = 1;

    if (confirmationStatus === 'rejected') params.rejected = 1;

    if (payment === 'paid') params.paid = 1;
    else if (payment === 'upfront_paid') params.upfront_paid = 1;
    else if (payment === 'pending') params.upfront_paid = 0;

    this.filter.params = params;
    this.filterOrders();
  }

  private async filterOrders(page_number = 1) {
    const payload = { ...this.filter.params, page_number };
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    try {
      const { data, ...meta } = await this._api.filterOrders(payload).toPromise();
      if (page_number === 1) {
        this.filter.data = data;
      } else {
        this.filter.data = [...this.filter.data, ...data];
      }
      this.filter.meta = meta;
    } catch (e) {
      console.dir(e);
    }
    this[page_number === 1 ? 'loading' : 'fetching'] = false;
  }

  resetFilter() {
    this.modal = '';
    this.filter.data = [];
    this.filter.meta = null;
    this.filter.params = null;
    this.filterOptions = {
      creditOrOutright: 'all',
      confirmationStatus: 'none',
      deliveryStatus: 'none',
      payment: 'none'
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
