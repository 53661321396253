import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-interest',
  templateUrl: './settings-interest.component.html',
  styleUrls: ['./settings-interest.component.css']
})
export class SettingsInterestComponent implements OnInit {
  @Input() merchant: MerchantProfileDetails;
  interestRateForm: FormGroup;
  installments = [
    { value: 7, text: '7', unit: 'Days' },
    { value: 15, text: '15', unit: 'Days' },
    { value: 30, text: '1', unit: 'Months' },
    { value: 60, text: '2', unit: 'Months' },
    { value: 90, text: '3', unit: 'Months' },
    { value: 120, text: '4', unit: 'Months' },
    { value: 150, text: '5', unit: 'Months' },
    { value: 180, text: '6', unit: 'Months' },
    { value: 210, text: '7', unit: 'Months' },
    { value: 240, text: '8', unit: 'Months' },
    { value: 270, text: '9', unit: 'Months' },
    { value: 300, text: '10', unit: 'Months' },
  ];

  constructor(
    private fb: FormBuilder,
    private _general: GeneralService,
    private _api: ApiService,
    private _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    const { monthly_interest_rate, max_credit_duration, interest_on_total, interest_on_balance, upfront_rate } = this.merchant;
    this.interestRateForm = this.fb.group({
      monthly_interest_rate: [monthly_interest_rate, Validators.required],
      max_credit_duration: [max_credit_duration, Validators.required],
      upfront_rate: [upfront_rate, Validators.required],
      interest_on_total,
      interest_on_balance
    });
  }

  setInterestOnBalance(e) {
    this.interestRateForm.get('interest_on_balance').setValue(e.target.checked ? 1 : 0);
    this.interestRateForm.get('interest_on_total').setValue(e.target.checked ? 0 : 1);
  }

  setInterestOnTotal(e) {
    this.interestRateForm.get('interest_on_total').setValue(e.target.checked ? 1 : 0);
    this.interestRateForm.get('interest_on_balance').setValue(e.target.checked ? 0 : 1);
  }

  updateInterestForm(form: FormGroup, event) {
    const { value } = form;
    const btn = (event.target as HTMLFormElement).querySelector('button');
    const prevText = btn.textContent;
    btn.textContent = 'Saving...';
    btn.disabled = true;
    const formToSend = { ...value };
    this._api.sendMerchantInterestRates(formToSend).subscribe(val => {
      const { message } = val;
      btn.textContent = prevText;
      btn.disabled = false;
      this._general.notify('success', message);
      const merchant = { ...this._auth.merchant, ...value };
      merchant.monthly_interest_rate = value.monthly_interest_rate;
      merchant.interest_on_balance = +value.interest_on_balance;
      merchant.interest_on_total = +value.interest_on_total;
      this._auth.setMerchant(merchant);
    }, () => {
      btn.textContent = prevText;
      btn.disabled = false;
      this._general.notify('warning', 'Something went wrong! Please try again later');
    });
  }
}
