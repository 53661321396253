import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  idsOfUnOpenedNotifications: number[] = [];
  unOpenedNotifications: number;
  nextSetOfMerchantNotifications: any;
  nextSetOfCollections: any;

  constructor() { }
}
