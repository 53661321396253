<div *ngIf="_auth.merchant.sender_id" class="d-flex flex-column align-items-center text-center py-5">
  <i class="fa fa-check-circle fa-6x text-success"></i>
  <div class="mt-4 px-5">
    Whatsapp connected
  </div>
  <button (click)="close.emit()" class="btn btn-outline-danger mt-4">Close</button>
</div>

<div *ngIf="!_auth.merchant.sender_id">
  <div *ngIf="loading !== ''">
    <br><br><br>
    <div class="loader-bounce">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
    <div class="title mt-4 text-center w-75 mx-auto font-italic">
      {{ loading }}
    </div>
    <br><br><br>
  </div>

  <ng-template [ngIf]="loading === ''">
    <div *ngIf="view === 'init'" class="d-flex flex-column align-items-center text-center py-5">
      <i class="fab fa-whatsapp fa-6x text-success"></i>
      <div class="mt-4 px-5 fw-bold fs-4">
        Whatsapp integration
      </div>
      <div class="mt-4 px-5">
        Connect your whatsapp to your store and unlock more features
      </div>
      <button (click)="init()" class="btn btn-primary mt-4">Connect</button>
    </div>

    <div *ngIf="view === 'scan'" class="d-flex flex-column align-items-center text-center py-5">
      <img [src]="image" alt="Scan" class="w-75 rounded-2" style="max-width: 300px"/>
      <div class="mt-4 px-5">
        Scan the QR Code above with the whatsapp connected to {{ _auth.merchant.whatsapp_no }} to enable whatsapp
        integration
      </div>
    </div>

    <div *ngIf="view === 'phone'" class="d-flex flex-column align-items-center text-center py-5">
      <i class="fab fa-whatsapp fa-6x text-success"></i>
      <div class="form-group w-100 text-start mt-5">
        <label for="wn">Enter you whatsapp number</label>
        <input
          type="text" autocomplete="off" class="form-control form-control-lg m-0 mb-1" id="wn"
          placeholder="ex: 09012345678" [(ngModel)]="whatsapp_no"
        />
      </div>
      <button (click)="saveWhatsappNumber()" [disabled]="whatsapp_no.length !== 11" class="btn btn-primary mt-4">
        Continue
      </button>
    </div>
  </ng-template>
</div>
