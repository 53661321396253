<div class="card mb-4">
  <ng-template [ngIf]="view === 'home'">
    <!--    <i *ngIf="merchant.partnership == 1" class="fa fa-check me-2 text-success">-->
    <div *ngIf="merchant.partnership === 0">
      <h5 class="title mb-2">Partner with banks</h5>
      <p class="mb-0 text-muted d-flex">
        Banks funds all credit transactions made by customers.
        <!-- <button (click)="modal = 'terms'" class="btn btn-link text-primary px-0">See terms and conditions</button> -->
      </p>
      <div class="mt-3">
        <button (click)="enablePartnership()" [disabled]="loading" class="btn btn-outline-success">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Activate bank partnership
        </button>
      </div>
      <br><br><br><br><br><br>
      <div class="d-flex justify-content-end">
        <img src="/assets/images/svg/credit-card-payment.svg" alt="Bank partnership" style="width: 200px">
      </div>
    </div>
    <div *ngIf="merchant.partnership == 1">
      <h5 class="title mb-2">Partner with banks</h5>
      <p class="mb-0 text-muted d-flex">
        Banks funds all credit transactions made by customers.
      </p>
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div class="badge bg-success">Bank partnership is activated</div>
        <button (click)="disablePartnership()" [disabled]="loading" class="btn btn-outline-danger btn-sm">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Deactivate
        </button>
      </div>
    </div>
    <!--    <div *ngIf="merchant.partnership == 1" class="d-flex flex-column align-items-center text-center">-->
    <!--      <br><br>-->
    <!--      <img src="/assets/images/svg/credit-card-payment.svg" alt="Activated" style="width: 200px">-->
    <!--      <h5 class="title mt-4 mb-0" style="line-height: 1.5rem">-->
    <!--        Bank partnership will be activated in <br/> {{ timer.days }}d: {{ timer.hours }}h: {{ timer.minutes }}m: {{ timer.seconds }}s-->
    <!--      </h5>-->
    <!--      <button (click)="disablePartnership()" [disabled]="loading" class="btn btn-outline-danger btn-sm mt-4">-->
    <!--        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Deactivate-->
    <!--      </button>-->
    <!--      <br><br>-->
    <!--    </div>-->
  </ng-template>
  <div *ngIf="view === 'verify'">
    <h5 class="title mb-2">Merchant verification</h5>
    <p class="mb-5 text-muted">
      Verification is required to change this setting, enter code sent to your email address {{ _general.maskEmail(_auth.merchant.email) }} below to
      verify
    </p>
    <div class="d-flex align-items-end">
      <div class="form-group m-0">
        <label for="code">Enter code</label>
        <input [(ngModel)]="code" [disabled]="loading" type="number" class="form-control override m-0 mt-1" id="code"/>
      </div>
      <button (click)="verify()" [disabled]="!code || loading" class="btn btn-primary ms-2">
        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Verify
      </button>
    </div>
  </div>
  <div *ngIf="view === 'notifying'" class="d-flex flex-column align-items-center text-center">
    <br><br><br>
    <img src="/assets/images/svg/loading-dual-ball.svg" alt="Loading ball" style="width: 50px;"/>
    <h5 class="title m-0 mt-4">Notifying banks..</h5>
    <p class="mt-3 px-4">We have 200+ financial institutions. They are very eager</p>
    <br><br><br>
  </div>
  <div *ngIf="view === 'activating'" class="d-flex flex-column align-items-center text-center">
    <br><br><br>
    <img src="/assets/images/svg/loading-dual-ball.svg" alt="Loading ball" style="width: 50px;"/>
    <h5 class="title m-0 mt-4">Activating..</h5>
    <p class="mt-3 px-4">Let your customers pay in installments, grow your sales 3x</p>
    <br><br><br>
  </div>
  <div *ngIf="view === 'deactivating'" class="d-flex flex-column align-items-center text-center">
    <br><br><br>
    <img src="/assets/images/svg/loading-dual-ball.svg" alt="Loading ball" style="width: 50px;"/>
    <h5 class="title m-0 mt-4">Deactivating..</h5>
    <br><br><br>
  </div>
</div>

<app-dialog *ngIf="modal === 'terms'" (close)="modal = ''" size="sm">
  <h5 class="title mb-4">Terms and conditions</h5>
  <p class="mb-5 text-muted">
    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis quisquam eos similique deserunt mollitia,
    reprehenderit ipsam ducimus natus vero, sunt perspiciatis. Natus doloribus molestiae animi nesciunt commodi,
    facilis ipsum quos eligendi impedit expedita ducimus omnis fugiat dolores dolor repellat quaerat necessitatibus
    dolorem fuga deleniti laboriosam nisi! Necessitatibus voluptatum architecto dolor repudiandae eaque, eum
    exercitationem reiciendis? Aut beatae ipsum veritatis iure maxime quas sit ipsam quidem veniam neque aliquam est
    nemo quae praesentium at enim error quaerat nihil magni, dolorem perferendis? Iure nulla, ullam quisquam libero,
    harum vel voluptates asperiores totam maiores minima est? Voluptas aliquam, officiis a ipsum laborum quaerat!
  </p>
</app-dialog>
