<div *ngIf="view === 'quickloans'">
  <h5 class="title mb-1">Apply for a business loan</h5>
  <p class="mb-5">Start and grow</p>
  <swiper [slidesPerView]="1.8" [spaceBetween]="10">
    <ng-template swiperSlide *ngFor="let loan of quickLoans">
      <div class="card align-items-start py-5 overflow-hidden bg-off-white"
        [ngClass]="{ disabled: loan.amount > 50000 }">
        <h5 class="text-primary mb-2">{{ loan.amount | currency:'NGN':'₦':'0.0' }}</h5>
        <h6 *ngIf="loan.tenor" class="text-muted">
          <i class="fa fa-calendar-alt me-1"></i> {{ loan.tenor }} month
        </h6>
        <h6 *ngIf="!loan.tenor" class="text-muted">
          <br>
        </h6>
        <button (click)="selectQuickLoan(loan)" class="btn btn-outline-secondary d-flex align-items-center"
          [disabled]="loading">
          Select <i class="fa fa-chevron-right ms-2"></i>
        </button>
        <br><br><br><br><br>
        <div class="watermark-text">Daily<br>Payment</div>
      </div>
    </ng-template>
  </swiper>
  <p class="mt-5">
    <span class="me-2">
      Want something different?
    </span>
    <button (click)="view = 'loan-inputs'" class="btn btn-link text-primary p-0">Click here to specify amount</button>
  </p>
  <div *ngIf="loading" class="pt-5">Please wait..</div>
</div>

<div *ngIf="view === 'confirm'">
  <h6 class="mb-4">Confirm loan details</h6>
  <div class="card bg-off-white p-4">
    <div class="mb-3 pb-3 border-bottom">
      <small class="text-primary d-block mb-1">Loan amount</small> {{ loan.amount | currency:'NGN':'₦':'0.0' }}
    </div>
    <div>
      <small class="text-primary d-block mb-1">Loan duration</small> {{ loan.tenor }} month(s)
    </div>
  </div>
  <div class="d-flex mt-4">
    <button (click)="apply()" class="btn btn-primary">Apply for loan</button>
    <button (click)="setView('quickloans')" class="btn btn-outline-danger ms-2">Cancel</button>
  </div>
</div>

<div *ngIf="view === 'loan-inputs'">
  <h5 class="title mb-1">Apply for a business loan</h5>
  <p class="mb-5">Start and grow</p>
  <div class="card shadow-none bg-off-white p-4 mb-3">
    <div class="form-group m-0">
      <div class="d-flex justify-content-between">
        <label for="loan-amount" class="form-label">Loan amount</label>
        <div class="fw-bold m-0">{{ loan.amount | currency:'NGN':'₦':'0.0' }}</div>
      </div>
      <input [(ngModel)]="loan.amount" type="range" class="form-range mt-2" min="5000" max="50000" step="1000"
        id="loan-amount">
    </div>
  </div>
  <div class="card shadow-none bg-off-white p-4 mb-5">
    <div class="form-group m-0">
      <div class="d-flex justify-content-between">
        <label for="loan-tenor" class="form-label">Loan tenor</label>
        <div class="fw-bold m-0">{{ loan.tenor }} month(s)</div>
      </div>
      <input [(ngModel)]="loan.tenor" type="range" class="form-range mt-2" min="1" max="12" step="1" id="loan-tenor">
    </div>
  </div>
  <button [disabled]="loading" (click)="handleLoanFromInput()" class="btn btn-primary">Continue</button>
</div>

<div *ngIf="view === 'applying'">
  <br><br><br>
  <div class="loader-bounce">
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  </div>
  <div class="title mt-4 text-center w-75 mx-auto font-italic">
    Connecting with finance partners for your request
  </div>
  <br><br><br>
</div>

<div *ngIf="view === 'partners'">
  <div class="avatar avatar-md bg-light">3</div>
  <h6 class="mt-4" style="max-width: 380px; line-height: 1.5;">We have found 3 financial institutions willing to fund
    your purchase</h6>
  <button (click)="initLoan()" class="btn btn-primary mt-4">Continue</button>
</div>

<div *ngIf="view === 'loading'">
  <br><br><br>
  <div class="loader-bounce">
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  </div>
  <div class="title mt-4 text-center w-75 mx-auto font-italic">
    Please wait..
  </div>
  <br><br><br>
</div>

<div *ngIf="view === 'error'" class="empty d-flex flex-column align-items-center text-center">
  <img src="assets/images/svg/crossed.svg" alt="No account number" width="100">
  <h5 class="title fw-bold mb-3 mt-5">An error occurred</h5>
  <div style="max-width: 300px; margin: auto;">An error occurred, please try again</div>
  <button (click)="initLoan()" class="btn btn-outline-secondary mt-4">
    <i class="fa fa-reload me-2"></i> Retry
  </button>
</div>

<div *ngIf="view === 'pending'" class="text-center flex-center flex-column">
  <img src="assets/images/svg/fast-loading.svg" alt="pending" class="w-75">
  <h4 class="title mt-5 mb-3">Request for loan pending</h4>
  <p style="max-width: 350px">Your request for loan is pending and will be granted once you're physically verified</p>
  <button (click)="modal = 'delete'" class="btn btn-outline-danger mt-5" [disabled]="deleting">
    Cancel loan request
  </button>
</div>

<div *ngIf="view === 'analysis'">
  <app-loan-analysis [stages]="stages"></app-loan-analysis>

  <button (click)="modal = 'delete'" class="btn btn-outline-danger mt-5 align-self-start" [disabled]="deleting">
    Cancel loan request
  </button>
</div>

<div *ngIf="view === 'loan-details'">
  <h6 class="mb-4">Current Loan</h6>
  <div class="card bg-off-white p-4">
    <div class="mb-3 pb-3 border-bottom">
      <small class="text-primary d-block mb-1">Loan amount</small>
      {{ _auth.merchant.loan.amount | currency:'NGN':'₦':'0.0' }}
    </div>
    <div class="mb-3 pb-3 border-bottom">
      <small class="text-primary d-block mb-1">Loan duration</small>
      {{ _auth.merchant.loan.duration }} month(s)
    </div>
    <div>
      <small class="text-primary d-block mb-1">Date requested</small>
      {{ _auth.merchant.loan.created_at | date: "shortDate" }}
    </div>
  </div>
  <div *ngIf="_auth.merchant.loan?.creditclan_request_id" class="d-flex mt-4">
    <button (click)="setView('analysis')" class="btn btn-primary me-2">Run analysis</button>
    <button (click)="modal = 'delete'" class="btn btn-outline-danger" [disabled]="deleting">
      Cancel loan request
    </button>
  </div>
</div>

<app-dialog *ngIf="modal === 'delete'" (close)="modal = ''" #modalDelete [closable]="false" [nested]="true"
  [centered]="true" size="sm">
  <div class="text-center">
    <div class="text-center mx-auto mb-3" style="max-width: 350px;">
      Are you sure you want to cancel this loan request? This action is irreversible
    </div>
    <button [disabled]="deleting" (click)="modalDelete.dismiss()" class="btn btn-outline-secondary mb-0 noDelete">
      No, Cancel
    </button>
    <button [disabled]="deleting" class="btn btn-outline-danger ms-2" (click)="cancelLoan()">
      Yes, delete
    </button>
  </div>
</app-dialog>