import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-whatsapp-settings',
  templateUrl: './whatsapp-settings.component.html',
  styleUrls: ['./whatsapp-settings.component.scss']
})
export class WhatsappSettingsComponent implements OnInit {
  @Output() connect = new EventEmitter();
  businessInterests = [];
  selectedInterests = [];
  bot_name = '';
  loading = false;
  view = 'bot-name';

  constructor(
    private _api: ApiService,
    private _general: GeneralService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    const { bot_name } = this._auth.merchant;
    this.bot_name = bot_name;
    this.getBusinessInterests();
  }

  async getBusinessInterests() {
    const { data } = await this._api.getBusinessInterests();
    this.businessInterests = data;
    const { top_category_one, top_category_two, top_category_three } = this._auth.merchant;
    [top_category_one, top_category_two, top_category_three].forEach(interest => {
      this.businessInterests.forEach(b => {
        const found = b.interests.find(i => i.interest === interest);
        if (found) this.selectedInterests.push({ id: found.id, interest });
      });
    });
  }

  selectInterest(e, interest) {
    const input = e.target as HTMLInputElement;
    if (this.selectedInterests.length === 3 && input.checked) {
      input.checked = false;
      return;
    }
    if (input.checked) {
      this.selectedInterests.push({ id: interest.id, interest: interest.interest });
    } else {
      const index = this.selectedInterests.findIndex(e2 => `${ e2.id }` === `${ interest.id }`);
      this.selectedInterests.splice(index, 1);
    }
  }

  isSelected(interest) {
    return this.selectedInterests.find(i => i.id === interest.id);
  }

  async save() {
    if (this.selectedInterests.length !== 3 || !this.bot_name) return;
    this.loading = true;
    const payload = {
      bot_name: this.bot_name,
      top_category_one: this.selectedInterests[0].interest,
      top_category_two: this.selectedInterests[1].interest,
      top_category_three: this.selectedInterests[2].interest
    };
    const data = await this._api.saveWhatsappBotSettings(payload).toPromise<any>();
    if (data.status) {
      this._general.notify('success', data.message || 'Whatsapp bot settings saved');
      this._auth.updateMerchant({ ...payload });
    }
    this.loading = false;
  }
}
