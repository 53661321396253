<div *ngIf="view === 'no-data'">
  <h5 class="title fw-bold mb-4 m4-5">
    <button class="btn btn-link px-0 btn-sm me-2" (click)="back.emit()">
      <i class="fa fa-arrow-left me-2"></i>
    </button>
    Pending from customer</h5>
  <div class="card d-flex align-items-center flex-row cursor-pointer mb-3">
    <div class="icon me-4 text-danger">
      <i class="fa fa-times-circle" style="font-size: 2rem;"></i>
    </div>
    <div class="flex-grow-1">
      <div class="title mb-1 fw-bold">Direct debit not setup</div>
      <div>
        Send link to customer
      </div>
    </div>
  </div>
  <div class="card d-flex align-items-center flex-row cursor-pointer mb-3">
    <div class="icon me-4 text-danger">
      <i class="fa fa-times-circle" style="font-size: 2rem;"></i>
    </div>
    <div class="flex-grow-1">
      <div class="title mb-1 fw-bold">Data submission</div>
      <div>Send link to customer</div>
    </div>
  </div>
</div>

<div *ngIf="view === 'options'" class="position-relative">
  <h5 class="title">
    <span class="d-inline-flex align-items-center">
      <button class="btn btn-link px-0 btn-sm me-2" (click)="back.emit()">
        <i class="fa fa-arrow-left me-2"></i>
      </button>
      Credit Analysis
    </span>
    <small class="mt-1 d-block">
      Select a method below to run credit analyis on this customer and determine if you
      should continue with this request
    </small>
  </h5>

  <div class="pt-4">
    <div (click)="view = 'bank-statement'" class="card d-flex align-items-center flex-row cursor-pointer mb-3">
      <div class="icon me-4 text-primary">
        <i class="fa fa-book" style="font-size: 2rem;"></i>
      </div>
      <div class="flex-grow-1">
        <div class="title mb-1 fw-bold">Bank statement</div>
        <div>
          Perform credit analysis through customer's most recent bank statement
        </div>
      </div>
    </div>
    <div (click)="view = 'credit-beareau'" class="card d-flex align-items-center flex-row cursor-pointer mb-3">
      <div class="icon me-4 text-danger">
        <i class="fa fa-mobile-alt" style="font-size: 2rem;"></i>
      </div>
      <div class="flex-grow-1">
        <div class="title mb-1 fw-bold">Credit beaureau</div>
        <div>See a history of loans taken by your current customer</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="view === 'bank-statement'">
  <app-credit-analysis-bank-statement (back)="back.emit()" (done)="view = 'details'" [request]="request">
  </app-credit-analysis-bank-statement>
</div>

<div *ngIf="view === 'credit-beareau'">
  <app-credit-analysis-credit-beareau [request]="request" (back)="back.emit()"></app-credit-analysis-credit-beareau>
</div>

<div *ngIf="view === 'details'">
  <button class="btn btn-link px-0 btn-sm mb-5" (click)="back.emit()">
    <i class="fa fa-arrow-left me-2"></i>Back
  </button>
  <h5 class="title">Recommend credit</h5>
  <div class="border-bottom mb-3 pb-3">
    <div class="title fw-bold">N40,000</div>
    <small>Upfront payment</small>
  </div>
  <div class="border-bottom mb-3 pb-3">
    <div class="title fw-bold">N23,000/Mo</div>
    <small>Installment</small>
  </div>
  <div class="mb-3 pb-3">
    <div class="title fw-bold">3 months</div>
    <small>Duration</small>
  </div>
  <div class="card pa-4 d-flex flex-row justify-content-between align-items-center bg-light-red">
    <div>
      <h5 class="title m-0">Recollection mode</h5>
      <small>Send link to customer</small>
    </div>
    <i class="fa fa-check"></i>
  </div>
</div>
