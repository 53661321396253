export const clothSizes = [
  { text: 'XS', value: 'XS' },
  { text: 'S', value: 'S' },
  { text: 'M', value: 'M' },
  { text: 'L', value: 'L' },
  { text: 'XL', value: 'XL' },
  { text: 'XXL', value: 'XXL' },
];

const populateShoeSizes = () => {
  const sizes = [];
  for (let i = 36; i <= 50; i++) {
    sizes.push({ text: `${ i }`, value: `${ i }` });
  }
  return sizes;
};

export const shoeSizesEuro = populateShoeSizes();

export const shoeSizesUK = [5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12.5, 13.5, 14.5].map(i => ({
  text: `${ i }`,
  value: `${ i }`
}));

export const genders = [
  { text: 'Teen', value: '0' },
  { text: 'Unisex', value: '1' },
  { text: 'Unisex child', value: '2' },
  { text: 'Men', value: '3' },
  { text: 'Women', value: '4' },
];

export const shippingSources = [
  { text: 'Locally sourced', value: '0' },
  { text: 'Foreign', value: '1' },
];
