<div class="row wrap">
  <div class="col-0 col-sm-6 left-content py-5 d-none d-md-block"></div>
  <div class="col-12 col-md-6 left">
    <div class="content d-flex flex-column">
      <div class="my-auto">
        <h3 class="title mb-2 mt-5">Welcome back</h3>
        <p class="mb-4 subtitle">
          Sign in to continue
        </p>
        <form [formGroup]="loginForm" class="mt-5">
          <div class="form-group required-field">
            <label for="login-email">Email or phone</label>
            <input type="email" class="form-control form-control-lg" name="acc-email" required="" id="login-email"
                   formControlName="email_phone"/>
          </div>

          <div class="form-group required-field mt-3">
            <label for="login-password">Password</label>
            <input type="password" id="login-password" class="form-control form-control-lg"
                   formControlName="password"/>
          </div>

          <a class="btn btn-link text-muted p-0" routerLink="/forgot-password">
            Forgot password? <span class="text-primary">Click here.</span>
          </a>

          <div class="d-flex justify-content-between align-items-center mt-5">
            <button [disabled]="!loginForm.valid || loading" type="submit" class="btn btn-primary btn-lg" (click)="submitForm()">
              <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
              Login
            </button>
          </div>
          <p class="mt-4">Dont have an account? <a class="text-primary" routerLink="/onboard">Register here</a></p>
        </form>
      </div>
      <div
        class="flex-grow-1 d-flex d-md-none flex-column justify-content-end align-items-end"
        style="position: relative; bottom: 0; right: 0;"
      >
        <img class="ms-auto mt-4" src="../../../../assets/images/instagram-phone-half.png" alt="Illustration" style="width: 180px">
      </div>
    </div>
  </div>
</div>
