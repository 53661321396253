<!-- Desktop -->
<div class="d-none d-md-block" style="padding: 2rem">
  <!-- Actions -->
  <div class="card d-flex flex-row justify-content-between text-center mb-4">
    <div (click)="modal = 'items'" class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-list text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">View items</div>
    </div>
    <div
      *ngIf="credit_not_partnered && request.creditclan_request_id" (click)="modal = 'schedule'"
      class="d-flex flex-column align-items-center cursor-pointer"
    >
      <i class="fa fa-book text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Schedule</div>
    </div>
    <div
      *ngIf="not_partnered && !request.creditclan_request_id" (click)="modal = 'edit'"
      class="d-flex flex-column align-items-center cursor-pointer"
    >
      <i class="fa fa-pen text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Edit</div>
    </div>
    <div (click)="modal = 'share'" class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-share-alt text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Share invoice</div>
    </div>
    <div
      *ngIf="not_partnered" (click)="modal = 'customer'"
      class="d-flex flex-column align-items-center cursor-pointer"
    >
      <i class="fa fa-user-circle text-dark-blue font-size-lg"></i>
      <div class="font-size-md mt-2">Customer info</div>
    </div>
    <div (click)="modal = 'delete'" class="d-flex flex-column align-items-center cursor-pointer">
      <i class="fa fa-times text-danger font-size-lg"></i>
      <div class="font-size-md mt-2">Cancel order</div>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center card mb-4 py-3">
    <h5 class="m-0">Invoice</h5>
    <div class="ms-auto">
      <button (click)="askConfirmation()" *ngIf="request?.checkout == 0" class="btn btn-success ms-2">
        Confirm order
      </button>
    </div>
  </div>

  <div class="card overflow-hidden mt-4 p-0">
    <div style="padding: 2rem">
      <div class="d-flex">
        <div class="flex-grow-1">
          <h5 class="title m-0">{{ request?.full_name }}</h5>
          <div class="font-size-sm mt-2 text-muted">
            {{ request?.phone_no || request?.email }}
          </div>
          <div class="font-size-sm mt-2">
            <i class="fa fa-map-marker-alt me-2 text-muted"></i> {{ request.address || 'No address provided' }}
          </div>
        </div>
        <div class="ms-auto text-end">
          <ng-template #paymentSummary>
            <!-- Upfront info -->
            <h5 class="title m-0" style="font-size: 1.2rem">
              {{ request?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}
            </h5>
            <p class="font-size-sm m-0 mt-2">{{ request.items.length }} item(s)</p>
            <small class="font-size-sm mt-3">{{ request?.req_date | timeago }}</small>
          </ng-template>
          <ng-container [ngTemplateOutlet]="paymentSummary"></ng-container>
        </div>
      </div>

      <div
        *ngIf="!request.items.length" (click)="modal = 'manual-items'"
        class="card d-flex flex-row align-items-center justify-content-center mt-5 text-center bg-off-white p-4 cursor-pointer"
      >
        <i class="fa fa-plus me-3"></i> Add items
      </div>

      <table *ngIf="request.items.length" class="table table-bordered mt-5">
        <tr>
          <th class="font-size-md py-2 pe-2 bg-light">S/N</th>
          <th class="font-size-md py-2 pe-2 bg-light">Product</th>
          <th class="font-size-md py-2 pe-2 bg-light">Qty</th>
          <th class="font-size-md py-2 pe-2 bg-light text-end">Amount</th>
        </tr>
        <tr *ngFor="let item of request.items; index as i">
          <td class="font-size-md py-2 pe-2">{{ i + 1 }}</td>
          <td class="font-size-md py-2 pe-2">{{ item.name }}</td>
          <td class="font-size-md py-2 pe-2">{{ item.quantity }}</td>
          <td class="font-size-md py-2 pe-2 text-end">
            {{ (+item.item_price * +item.quantity) | currency:'NGN':'₦':'0.0' }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="text-dark-blue py-2 pe-4 font-size-sm">Subtotal</td>
          <td class="pe-2 text-end">{{ getItemsTotal() | currency:'NGN':'₦':'0.0' }}</td>
        </tr>
        <tr *ngIf="credit_not_partnered">
          <td></td>
          <td></td>
          <td class="text-dark-blue py-2 pe-4 font-size-sm">Interest</td>
          <td class="pe-2 text-end">{{ getInterest() | currency:'NGN':'₦':'0.0' }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="text-dark-blue py-2 pe-4 font-size-sm">Total</td>
          <td class="pe-2 text-end">{{ request.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}</td>
        </tr>
      </table>
    </div>
    <div
      *ngIf="credit_not_partnered"
      class="card border-0 border-top rounded-0 bg-off-white d-flex flex-row justify-content-between align-items-center mt-4"
    >
      <div class="text-center" (click)="modal = 'upfront-info'">
        <h5 class="title m-0">{{ upfront | currency:'NGN':'₦':'0.0' }}</h5>
        <small class="m-0 mt-1">
          Upfront amount
          <span *ngIf="request.req_status >= 2 && request.upfront_paid == 1" class="badge bg-success ms-1">Paid</span>
          <span *ngIf="request.req_status < 2 && request.upfront_paid == 0" class="badge bg-danger ms-1">Not paid</span>
        </small>
      </div>
      <div class="text-center">
        <h5 class="title m-0">{{ request.monthly_amt  | currency:'NGN':'₦':'0.0' }}</h5>
        <small class="m-0 mt-2">
          Monthly repayment
        </small>
      </div>
      <div class="text-center">
        <h5 class="title m-0">{{ request.no_of_months }} month(s)</h5>
        <small class="m-0 mt-2">
          Duration
        </small>
      </div>
    </div>
    <div *ngIf="request.upfront_paid == 0" class="text-center p-4 bg-off-white border-top" style="color: #dc7734">
      <i class="fa fa-exclamation-circle me-2"></i> Pending
    </div>
    <div *ngIf="request.upfront_paid == 1" class="text-center p-4 bg-off-white border-top" style="color: #19a565">
      <i class="fa fa-check-circle me-2"></i> Paid
    </div>
  </div>
</div>

<!-- Mobile -->
<div class="d-md-none flex-grow-1 d-flex flex-column">
  <div class="p-4 border-bottom font-size-sm">
    <h5 class="title m-0 d-flex align-items-center" style="font-size: 1.02rem">
      {{ request?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}
      <span *ngIf="!upfront_paid" class="badge bg-warning ms-2">Pending</span>
      <span *ngIf="upfront_paid" class="badge bg-success ms-2">Paid</span>
    </h5>
    <div class="mt-3">
      <i class="fa fa-user me-2 text-muted"></i> {{ request?.full_name }}
    </div>
    <div class="mt-1">
      <i class="fa fa-phone me-2 text-muted"></i> {{ request?.phone_no || request?.email }}
    </div>
    <div class="mt-1">
      <i class="fa fa-map-marker-alt me-2 text-muted"></i> {{ request.address || 'No address provided' }}
    </div>
  </div>

  <div class="p-4 bg-light flex-grow-1">
    <div
      *ngIf="!request.items.length" (click)="modal = 'manual-items'"
      class="card d-flex flex-row align-items-center justify-content-center text-center bg-off-white p-4 cursor-pointer text-muted"
    >
      <i class="fa fa-plus me-3"></i> Add items
    </div>

    <ng-container *ngIf="request.items.length">
      <div class="pb-3 mb-3 border-bottom d-flex align-items-center justify-content-between font-size-sm">
        <span class="text-muted">Product</span>
        <span class="text-muted">Amount</span>
      </div>
      <div
        *ngFor="let item of request.items"
        class="pb-4 mb-4 border-bottom d-flex align-items-center justify-content-between"
      >
        <div class="font-size-md overflow-hidden">
          <div class="fw-bold text-ellipsize font-size-sm">{{ item.name }}</div>
          <small>{{ item.item_price | currency:'NGN':'₦':'0.0' }} x {{ item.quantity }}</small>
        </div>
        <div class="ms-3 font-size-sm">
          <span class="fw-bold">{{ (+item.item_price * +item.quantity) | currency:'NGN':'₦':'0.0' }}</span>
        </div>
      </div>
      <ng-template [ngIf]="credit_not_partnered">
        <div class="fw-bold d-flex justify-content-between font-size-sm border-bottom pb-3 mb-3">
          <span class="text-muted">Subtotal: </span>
          {{ getItemsTotal() | currency:'NGN':'₦':'0.0' }}
        </div>
        <div
          *ngIf="getInterest()"
          class="fw-bold d-flex justify-content-between font-size-sm border-bottom pb-3 mb-3"
        >
          <span class="text-muted">Interest </span>
          {{ getInterest() | currency:'NGN':'₦':'0.0' }}
        </div>
      </ng-template>
      <div *ngIf="credit_partnered" class="fw-bold d-flex justify-content-between font-size-sm">
        <span class="text-muted">Total: </span>
        {{ getItemsTotal() | currency:'NGN':'₦':'0.0' }}
      </div>
    </ng-container>
  </div>

  <br><br><br>

  <div class="bottom-actions justify-content-center px-3">
    <button (click)="askConfirmation()" class="btn btn-primary ms-2 flex-grow-1">
      Confirm order
    </button>
  </div>
</div>

<button (click)="modal = 'options'" class="floating-action-button d-md-none">
  <i class="fa fa-stream"></i>
</button>

<!-- Modals -->

<app-dialog *ngIf="modal === 'manual-items'" (close)="modal = ''" size="sm" [nested]="true">
  <app-request-manual-items [request]="request" (done)="handleManualItemsDone($event)"></app-request-manual-items>
</app-dialog>

<!-- Mobile option -->
<app-dialog
  *ngIf="modal === 'options'" (close)="modal = ''" [close-button]="false" #optionsDialog size="sm"
  [nested]="true"
>
  <div *ngIf="credit_not_partnered" class="card bg-off-white mb-5 font-size-sm p-3">
    <div class="d-flex flex-row align-items-center justify-content-between" (click)="modal = 'upfront-info'">
      <small class="m-0">
        Upfront amount
        <span *ngIf="upfront_paid" class="badge bg-success ms-1">Paid</span>
        <span *ngIf="!upfront_paid" class="badge bg-danger ms-1">Not paid</span>
      </small>
      <div class="m-0 fw-bold">{{ upfront | currency:'NGN':'₦':'0.0' }}</div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between border-top pt-2 mt-2">
      <small class="m-0">Monthly repayment</small>
      <div class="m-0 fw-bold">{{ request.monthly_amt  | currency:'NGN':'₦':'0.0' }}</div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between border-top pt-2 mt-2">
      <small class="m-0">Duration</small>
      <div class="m-0 fw-bold">{{ request.no_of_months }} month(s)</div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'items'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-list text-info" style="font-size: 1.3rem;"></i>
        <small class="mt-2">View items</small>
      </div>
    </div>
    <div class="col-4" *ngIf="not_partnered && !request.creditclan_request_id">
      <div
        (click)="optionsDialog.dismiss(); modal = 'edit'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-pen text-warning" style="font-size: 1.3rem;"></i>
        <small class="mt-2">Edit</small>
      </div>
    </div>
    <div *ngIf="request.phone_no && not_partnered" class="col-4">
      <a
        [href]="'https://api.whatsapp.com/send?phone=+234' + request.phone_no.substring(1)" target="_blank"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fab fa-whatsapp text-success" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Chat</small>
      </a>
    </div>
    <div *ngIf="credit_not_partnered && request.creditclan_request_id" class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'schedule'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-book text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Schedule {{ request.creditclan_request_id }}</small>
      </div>
    </div>
    <div *ngIf="not_partnered" class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'customer'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-user text-info" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Customer</small>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'share'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-share-alt text-warning"></i>
        <div class="font-size-md mt-2">Share invoice</div>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'delete'"
        class="d-flex flex-column align-items-center cursor-pointer py-4"
      >
        <i class="fa fa-times text-danger" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Reject</small>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Invoice -->
<app-dialog *ngIf="modal === 'invoice'" [nested]="true" (close)="modal = ''" title="Invoice" size="sm">
  <div class="mb-4 pb-4 border-bottom">
    <div>
      <small class="text-primary d-block mb-4 mb-1">Customer</small>
      <i class="fa fa-user me-2 text-muted"></i> {{ request?.full_name }}
    </div>
    <div class="mt-2">
      <i class="fa fa-phone me-2 text-muted"></i> {{ request?.phone_no || request?.email }}
    </div>
    <div class="mt-2">
      <i class="fa fa-map-marker-alt me-2 text-muted"></i> {{ request.address }}
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="paymentSummary"></ng-container>
  <div class="mb-5"></div>
  <ng-container *ngIf="request.items.filter(filterOutright).length">
    <div class="card mb-4 p-4">
      <div class="pb-4 mb-4 border-bottom" *ngFor="let item of request.items.filter(filterOutright)">
        <div class="font-size-md">
          <span class="text-muted">Product name: </span>
          <div class="fw-bold">{{ item.name }}</div>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Quantity: </span>
          <span class="fw-bold">{{ item.quantity }}</span>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Unit price: </span>
          <span class="fw-bold">{{ item.item_price | currency:'NGN':'₦':'0.0' }}</span>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Amount: </span>
          <span class="fw-bold">{{ (+item.item_price * +item.quantity) | currency:'NGN':'₦':'0.0' }}</span>
        </div>
      </div>
      <div class="fw-bold">
        <span class="text-primary">Total amount: </span>
        {{ getItemsTotal() | currency:'NGN':'₦':'0.0' }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="request.items.filter(filterCredit).length">
    <div class="card mb-4 p-4">
      <div class="pb-4 mb-4 border-bottom" *ngFor="let item of request.items.filter(filterCredit)">
        <div class="font-size-md">
          <span class="text-muted">Product name: </span>
          <div class="fw-bold">{{ item.name }}</div>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Quantity: </span>
          <span class="fw-bold">{{ item.quantity }}</span>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Price/month ({{ request.no_of_months }} months): </span>
          <span class="fw-bold">
            {{ ((+item.item_price * +item.quantity) / +request.no_of_months) | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
        <div class="font-size-md">
          <span class="text-muted">Amount: </span>
          <span class="fw-bold">
            {{ (+item.upfront_amount * +item.quantity) | currency:'NGN':'₦':'0.0' }}
          </span>
        </div>
      </div>
      <div class="fw-bold">
        <span class="text-primary">Total credit/month: </span>
        {{ perMonthCreditTotal() | currency:'NGN':'₦':'0.0' }}
      </div>
      <div class="mt-1 fw-bold">
        <span class="text-primary">Total upfront: </span>
        {{ creditUpfrontTotal() | currency:'NGN':'₦':'0.0' }}
      </div>
    </div>
  </ng-container>
  <div (click)="shareToWhatsapp()" class="card flex-row align-items-center p-3 cursor-pointer mb-3">
    <i class="fab fa-whatsapp text-success me-3"></i> Share to whatsapp
  </div>
  <div (click)="copyLink()" class="card flex-row align-items-center p-3 cursor-pointer">
    <i class="fa fa-clipboard text-primary me-3"></i> Copy link
  </div>
</app-dialog>

<!-- Share -->
<app-dialog *ngIf="modal === 'share'" [nested]="true" (close)="modal = ''" title="Share invoice" size="sm">
  <div class="row">
    <div class="col-md-6">
      <div (click)="shareToWhatsapp()" class="card text-center p-5 cursor-pointer mb-4">
        <i class="fab fa-whatsapp text-success fa-4x"></i>
        <div class="mt-4">
          Share to whatsapp
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div (click)="copyLink()" class="card text-center p-5 cursor-pointer">
        <i class="fa fa-clipboard text-primary fa-4x"></i>
        <div class="mt-4">
          Copy link
        </div>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Items -->
<app-dialog *ngIf="modal === 'items'" [nested]="true" (close)="modal = ''" title="Items purchased" size="sm">
  <div class="card mb-4 p-0 deleteParent" *ngFor="let item of request.items; index as i">
    <div class="card-img-top border-bottom">
      <img [src]="item?.primary_picture" alt="" class="w-100 fit-contain" style="height: 230px;"/>
    </div>
    <div class="card-body px-3 py-2">
      <div class="title mb-0 fw-bold text-ellipsize">{{ item?.name }}</div>
      <div class="title">{{ item?.item_price | currency:'NGN':'₦':'0.0' }}</div>
    </div>
    <!-- <button (click)="launchItemDeleteConfirmation(item)" *ngIf="request.items.length > 1" class="deleteBtn">
      <i class="fa fa-times"></i>
    </button> -->
  </div>
</app-dialog>

<!-- Schedule -->
<app-dialog *ngIf="modal === 'schedule'" [nested]="true" (close)="modal = ''" title="Repayment schedule" size="sm">
  <div *ngIf="request?.upfront_paid == 1">
    <div class="card bg-light-blue mb-5" style="max-width: 350px;">
      <div class="row align-items-center">
        <div class="col-3 m-0 py-0" style="font-size: 4rem;">
          <i class="icon-credit-card"></i>
        </div>
        <div *ngIf="request?.source == null" class="col-7">
          <h5 class="title mb-2">{{ card?.card_type }}</h5>
          <div class="h4 m-0">**** **** ****</div>
          <span>No repayment card added</span>
        </div>

        <div *ngIf="request?.source !== null">
          <h5 class="title mb-4">
            <i *ngIf="card?.card_type === 'visa '" class="fab fa-cc-visa"></i>
            <i *ngIf="card?.card_type === 'mastercard '" class="fab fa-cc-mastercard"></i>
          </h5>
          <div class="h4 m-0">**** **** **** {{ card?.last4 }}</div>
          <div class="d-flex mt-4">
            <small class="flex-grow-1">{{ card?.exp_month + "/" + card?.exp_year }}</small>
            <small class="flex-grow-1">{{ card?.bank }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="schedule && !loading">
    <table class="table table-bordered">
      <tbody>
      <tr class="border-bottom">
        <th class="py-2">S/N</th>
        <th class="py-2">Date</th>
        <th class="py-2">Amount</th>
        <th></th>
      </tr>
      <tr *ngFor="let schedule of schedule; index as i" class="border-bottom">
        <td class="py-2">{{ schedule.serial }} of {{ schedule.length }}</td>
        <td class="py-2">{{ schedule.date | date: "shortDate" }}</td>
        <td class="py-2">{{ schedule.amount | currency:'NGN':'₦':'0.0' }}</td>
        <td>
          <img
            *ngIf="schedule.balance == '0.00' && schedule.amount == schedule.amount_paid"
            src="assets/images/check.svg" width="26px" alt=""
          />
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="loading" class="d-flex justify-content-center align-items-center p-5 col-12">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Customer data -->
<app-dialog *ngIf="modal === 'customer'" [nested]="true" (close)="modal = ''" title="Customer details" size="sm">
  <app-view-customer-data [customer]="request"></app-view-customer-data>
</app-dialog>

<!-- Credit analysis -->
<app-dialog *ngIf="modal === 'sell-on-credit'" [nested]="true" (close)="modal = ''" size="sm">
  <app-credit-analysis (back)="view = 'details'" [request]="request"></app-credit-analysis>
</app-dialog>

<!-- Edit request -->
<app-dialog *ngIf="modal === 'edit'" [nested]="true" (close)="modal = ''" title="Edit order" size="sm">
  <app-edit-a-request [aRequestToEdit]="request" (doneEditing)="editingDone()">
  </app-edit-a-request>
</app-dialog>

<!-- Pending from customer -->
<app-dialog
  *ngIf="modal === 'pending'" [nested]="true" (close)="modal = ''" [centered]="true" title="Incomplete data" size="sm"
>
  <div *ngIf="_auth.merchant.partnership !== 1" class="alert alert-warning mb-5 d-flex align-items-center rounded">
    <i class="fa fa-exclamation-circle me-3"></i>Bank partnership not activated
  </div>
  <div class="mb-5">
    The following data which is required to confirm this order has not been provided by the customer.
  </div>
  <div class="border-bottom pb-3 mb-3 d-flex justify-content-between">
    <div class="fw-bold">Application review</div>
    <small *ngIf="!request.customer_data"><i class="fa fa-times me-2 text-danger"></i>Not complete</small>
    <small *ngIf="request.customer_data"><i class="fa fa-check me-2 text-success"></i>Completed</small>
  </div>
  <div class="border-bottom pb-3 mb-3 d-flex justify-content-between align-items-center">
    <div class="fw-bold">Upfront amount</div>
    <small *ngIf="request.req_status < 2 && request.upfront_paid == 0">
      <i class="fa fa-times me-2 text-danger"></i>Not paid
    </small>
    <small *ngIf="request.req_status >= 2 && request.upfront_paid == 1"><i class="fa fa-check me-2 text-success"></i>Paid</small>
    <div *ngIf="request.req_status === 2 && request.upfront_paid == 0">
      <small><i class="fa fa-exclamation-circle me-2 text-warning"></i>Pending</small>
      <button (click)="modal = 'upfront-info'" class="btn btn-outline-primary btn-sm rounded px-3 ms-3">Confirm</button>
    </div>
  </div>
  <div *ngIf="!request.card_exists" class="border-bottom pb-3 mb-3 d-flex justify-content-between">
    <div class="fw-bold">Direct debit</div>
    <small><i class="fa fa-times me-2 text-danger"></i>Not setup</small>
  </div>
  <div *ngIf="request.card_exists" class="border-bottom pb-3 mb-3 d-flex justify-content-between">
    <div class="fw-bold">Direct debit</div>
    <small><i class="fa fa-check me-2 text-success"></i>Done</small>
  </div>
  <button (click)="sendReminderToCustomer()" class="btn btn-outline-secondary mt-5" [disabled]="sendingReminder">
    <i *ngIf="!sendingReminder" class="fa fa-bell me-2"></i>
    <i *ngIf="sendingReminder" class="fa fa-circle-notch fa-spin me-2"></i>
    Send reminder to customer
  </button>
</app-dialog>

<!-- Checkout confirmation modal -->
<app-dialog
  *ngIf="modal === 'confirmation'" [nested]="true" (close)="modal = ''" title="Confirm order" #dialogConfirm size="sm"
>
  <p>Transaction complete and ready for delivery to customer, enter your password below to confirm this order</p>
  <form class="mt-5">
    <div class="form-group required-field">
      <label for="password">Enter password </label>
      <input
        type="password" class="form-control lg" name="acc-password" required="" id="password"
        [(ngModel)]="password"
      />
    </div>
    <div class="form-group form-check d-flex align-items-center">
      <input
        [ngModelOptions]="{standalone: true}" [(ngModel)]="notifyCustomer" type="checkbox"
        class="form-check-input mt-0" id="notifyCustomer"
      />
      <label class="form-check-label ms-2" for="notifyCustomer">Notify customer by email </label>
    </div>

    <div class="d-flex align-items-center mt-5">
      <button
        [disabled]="!password.length" id="loginBtn" type="button"
        class="btn btn-primary me-3 checkoutBtn check_01" (click)="checkout()"
      >
        Checkout
      </button>
      <button (click)="dialogConfirm.dismiss()" type="button" class="btn btn-danger checkoutBtn check_01">
        Cancel
      </button>
    </div>
  </form>
</app-dialog>

<!-- Delete order confirmation modal -->
<app-dialog
  *ngIf="modal === 'delete'" [nested]="true" (close)="modal = ''" [close-button]="false" [confirmation]="true"
  #dialogDelete [closable]="false"
  size="sm"
>
  <div class="title m-0 text-center">
    Are you sure you want to cancel this order?
  </div>
  <div class="d-flex justify-content-center mt-4">
    <button (click)="dialogDelete.dismiss()" class="btn btn-outline-primary checkoutBtn" [disabled]="deleting">No
    </button>
    <button (click)="removeOrder()" class="btn btn-danger checkoutBtn ms-2" [disabled]="deleting">Yes, cancel</button>
  </div>
</app-dialog>

<!-- Upfront payment -->
<app-dialog *ngIf="modal === 'upfront-info'" [nested]="true" (close)="modal = ''" title="Upfront payment" size="sm">
  <div class="border-bottom mb-3 pb-3">
    <small *ngIf="request.req_status < 2 && request.upfront_paid == 0" class="alert alert-danger d-block p-3 rounded">
      Amount of {{ (upfront || request?.cred_plus_upfront) | currency:'NGN':'₦':'0.0' }} not paid
    </small>
    <small
      *ngIf="request.req_status === 2 && request.upfront_paid == 0"
      class="alert alert-warning d-block p-3 rounded"
    >
      Amount of {{ (upfront || request?.cred_plus_upfront) | currency:'NGN':'₦':'0.0' }}
      has been transferred to your bank account, kindly confirm below if you have received it
    </small>
    <small *ngIf="request.req_status >= 2 && request.upfront_paid == 1" class="alert alert-success d-block p-3 rounded">
      Amount of {{ (upfront || request?.cred_plus_upfront) | currency:'NGN':'₦':'0.0' }} has been paid successfully
    </small>
    <div class="d-flex justify-content-between align-items-center">
      <div class="title fw-bold m-0" *ngIf="request?.credit">
        {{ upfront | currency:'NGN':'₦':'0.0' }}
      </div>
      <div class="title fw-bold m-0" *ngIf="!request?.credit">
        {{ request?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}
      </div>
      <span
        *ngIf="request.req_status === 2 && request.upfront_paid == 0"
        class="badge bg-warning ms-2"
      >
        Pending
      </span>
      <span *ngIf="request.req_status >= 2 && request.upfront_paid == 1" class="badge bg-success ms-2">Paid</span>
      <span *ngIf="request.req_status < 2 && request.upfront_paid == 0" class="badge bg-danger ms-2">Not paid</span>
    </div>
    <small *ngIf="request.credit">Upfront payment</small>
    <small *ngIf="!request.credit">Order payment</small>
  </div>
  <button
    [disabled]="confirmingUpfrontPayment" (click)="confirmUpfrontPayment()"
    *ngIf="request.req_status == 2 && request.upfront_paid == 0" class="btn btn-primary mt-3"
  >
    <i *ngIf="confirmingUpfrontPayment" class="fa fa-circle-notch fa-spin me-2"></i>
    Confirm upfront payment
  </button>
</app-dialog>
