import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { clothSizes, genders, shippingSources, shoeSizesEuro, shoeSizesUK } from 'src/app/lib/variations';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { StoreService } from 'src/app/services/store.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-create-product-embedded',
  templateUrl: './create-product-embedded.component.html',
  styleUrls: ['./create-product-embedded.component.scss']
})
export class CreateProductEmbeddedComponent implements OnInit, OnDestroy {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Product description',
    defaultParagraphSeparator: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontSize', 'fontName', 'textColor', 'backgroundColor'],
      ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
      ['subscript', 'superscript', 'heading', 'indent', 'outdent']
    ]
  };
  productForm: FormGroup;
  @Input() item;
  @Input() external_id?: string;
  @Input() external?: string;
  @Output() close = new EventEmitter();
  carouselOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&#8249;', '&#8250;'],
    nav: true,
    margin: 15,
    freeDrag: true,
    items: 3,
  };
  clothSizes = clothSizes;
  shoeSizesEuro = shoeSizesEuro;
  shoeSizesUK = shoeSizesUK;
  genders = genders;
  sources = shippingSources;
  categories: Array<{ id: number; name: string }> = [];
  subcategories: Array<{ id: number; name: string }> = [];
  loading = false;
  images = [];
  upfrontValues = [];
  specifyUpfront: boolean;
  subscriptions: Subscription[] = [];
  selectedSizes = [];
  selectedGenders = [];
  selectedTags = [];
  tagForm: FormGroup;
  addingTag = false;
  tags = [];

  constructor(
    private fb: FormBuilder,
    private _general: GeneralService,
    private _api: ApiService,
    private _store: StoreService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.productForm = this.fb.group({
      name: [this.item.name, Validators.required],
      price: [this.item.price, Validators.required],
      description: [this.item.description, Validators.required],
      category_id: [12, Validators.required],
      sub_category_id: '',
      credit_price: '',
      sell_on_credit: '0',
      monthly_amount: '',
      no_of_months: '',
      upfront_amount_option: '',
      upfront_amount: '',
      discount_price: '',
      discount_expiry_date: '',
      quantity: '1',
      minimum_order: '',
      maximum_order: '',
      sizes: '',
      genders: '',
      tag_id: '',
      shipping_source: '0'
    });

    this.images = this.item.images;

    this.tagForm = this.fb.group({
      name: ['', Validators.required]
    });

    if (this._auth.merchant.partnership === 1) this.resetAndClearCreditValidators();

    this.getTags();
    this.getCategories();
  }

  getCategories() {
    this._api.getCategories().pipe(take(1)).subscribe((res: any) => {
      this.categories = res.data;
    });
  }

  getSubcategories() {
    this._api.getSubcategories(this.productForm.value.category_id).pipe(take(1)).subscribe((res: any) => {
      this.subcategories = res.data;
    });
  }

  handleSellOnCreditChange(e) {
    this.setSellOnCredit(e.target.value);
  }

  handlePriceChange() {
    this.setSellOnCredit(this.productForm.value.sell_on_credit);
  }

  setSellOnCredit(value: string) {
    this.productForm.get('sell_on_credit').patchValue(value);
    if (value === '1') {
      this.setCreditValidators();

      const { max_credit_duration, upfront_rate, monthly_interest_rate: rate = 0 } = this._auth.merchant;
      const { price } = this.productForm.value;

      if (!rate) return;
      const credit_price = +price + ((+rate / 100) * +price);
      this.productForm.get('credit_price').setValue(credit_price);

      if (!max_credit_duration) return;
      const months = max_credit_duration / 30;
      this.productForm.get('no_of_months').setValue(`${ months }`);

      if (!upfront_rate) return;
      const upfront = (+upfront_rate / 100) * +credit_price;
      this.productForm.get('upfront_amount').setValue(upfront);

      this.setMonthlyAmount();
    } else {
      this.resetAndClearCreditValidators();
    }
  }

  setMonthlyAmount() {
    const { credit_price, no_of_months, upfront_amount } = this.productForm.value;
    this.productForm.get('monthly_amount').setValue((+credit_price - +upfront_amount) / +no_of_months);
  }

  setCreditValidators() {
    this.productForm.get('credit_price').setValidators(Validators.required);
    this.productForm.get('credit_price').updateValueAndValidity();
    this.productForm.get('monthly_amount').setValidators(Validators.required);
    this.productForm.get('monthly_amount').updateValueAndValidity();
    this.productForm.get('no_of_months').setValidators(Validators.required);
    this.productForm.get('no_of_months').updateValueAndValidity();
    this.productForm.get('upfront_amount').setValidators(Validators.required);
    this.productForm.get('upfront_amount').updateValueAndValidity();
  }

  resetAndClearCreditValidators() {
    this.productForm.get('credit_price').reset();
    this.productForm.get('monthly_amount').reset();
    this.productForm.get('no_of_months').reset();
    this.productForm.get('upfront_amount').reset();
    this.productForm.get('credit_price').clearValidators();
    this.productForm.get('credit_price').updateValueAndValidity();
    this.productForm.get('monthly_amount').clearValidators();
    this.productForm.get('monthly_amount').updateValueAndValidity();
    this.productForm.get('no_of_months').clearValidators();
    this.productForm.get('no_of_months').updateValueAndValidity();
    this.productForm.get('upfront_amount_option').clearValidators();
    this.productForm.get('upfront_amount_option').updateValueAndValidity();
    this.productForm.get('upfront_amount').clearValidators();
    this.productForm.get('upfront_amount').updateValueAndValidity();
  }

  populateUpfrontValues() {
    const { no_of_months, credit_price } = this.productForm.value;
    if (!no_of_months && !credit_price) {
      return;
    }
    const values = [];
    if (+no_of_months > 0) {
      for (let i = 1; i <= Array(+no_of_months).slice(0, 3).length; i++) {
        if (i === 1) {
          values.push({
            amount: credit_price / +no_of_months,
            label: 'Pay 1st month'
          });
        } else {
          values.push({
            amount: (credit_price / +no_of_months) * i,
            label: `Pay up to ${ i } months`
          });
        }
      }
    }
    values.push({ amount: credit_price / 2, label: 'Pay Half' });
    values.push({ amount: credit_price / 4, label: 'Pay Quarter' });
    this.productForm.get('monthly_amount').setValue(+credit_price / +no_of_months);
    this.upfrontValues = values;
  }

  handleUpfrontChange(value) {
    if (value) {
      if (value === 'specify') {
        this.specifyUpfront = true;
      } else {
        this.specifyUpfront = false;
        this.productForm.get('upfront_amount').setValue(value);
      }
    } else {
      this.productForm.get('upfront_amount').setValue('0');
    }
    this.setMonthlyAmount();
  }

  async createProduct() {
    if (this.productForm.invalid || !this.images.length) return;
    if (this.productForm.value.sell_on_credit.toString() === '1') this.setMonthlyAmount();
    this.loading = true;
    try {
      const payload = {
        ...this.productForm.value,
        external_link: true,
        external: this.external,
        external_id: this.external_id
      };
      for (const [i, image] of this.images.entries()) {
        if (i === 0) payload.primary_picture = await this.resolveImage(image, 'primary_picture.png');
        if (i === 1) payload.secondary_picture = await this.resolveImage(image, 'secondary_picture.png');
        if (i === 2) payload.tertiary_picture = await this.resolveImage(image, 'tertiary_picture.png');
        if (i === 3) payload.fourth_picture = await this.resolveImage(image, 'fourth_picture.png');
        if (i === 4) payload.fifth_picture = await this.resolveImage(image, 'fifth_picture.png');
      }
      const fd = new FormData();
      Object.keys(payload).forEach(key => fd.append(key, payload[key]));
      this.productForm.disable();
      const { message, data } = await this._api.additems(fd).toPromise();
      this._general.notify('success', message);
      this.close.emit();
      this._store.setState('products', {
        ...this._store.products,
        items: [data, ...this._store.products.items],
        total: this._store.products.total + 1
      }, true);
    } catch {
      this._general.notify('error', 'An error occured, please try again');
      this.productForm.enable();
    }
    this.loading = false;
  }

  async resolveImage(image: string, name) {
    if (image && image.includes('data:image/')) {
      return await this._general.dataUrlToFile(image, name);
    } else {
      return image;
    }
  }

  handleCategoryChange() {
    this.productForm.get('sub_category_id').setValue('');
    this.getSubcategories();
    if (this.productForm.value.category_id !== '7') {
      ['sizes', 'genders'].forEach(v => this.productForm.get(v).reset());
    }
  }

  handleSizeChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      [this.clothSizes.map(i => i.value), this.shoeSizesEuro.map(i => i.value), this.shoeSizesUK.map(i => i.value)].forEach((arr) => {
        if (arr.includes(value)) {
          this.selectedSizes = this.selectedSizes.filter(s => arr.includes(s));
          this.selectedSizes.push(value);
        }
      });
    } else {
      this.selectedSizes = this.selectedSizes.filter(s => s !== value);
    }
    this.productForm.get('sizes').setValue(this.selectedSizes.join(','));
  }

  handleGenderChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedGenders.push(value);
    } else {
      this.selectedGenders = this.selectedGenders.filter(s => s !== value);
    }
    this.productForm.get('genders').setValue(this.selectedGenders.join(','));
  }

  handleTagChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedTags.push(value);
    } else {
      this.selectedTags = this.selectedTags.filter(s => s !== value);
    }
    this.productForm.get('tag_id').setValue(this.selectedTags.join(','));
  }

  async addTag() {
    if (this.tagForm.invalid) {
      return;
    }
    this.addingTag = true;
    this.tagForm.disable();
    const res = await this._api.addTag(this.tagForm.value.name).toPromise<any>();
    this.tags.push(res.data);
    this.addingTag = false;
    this.tagForm.enable();
    this.tagForm.reset();
  }

  async getTags() {
    const res = await this._api.getTags().toPromise<any>();
    this.tags = res.data;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
