import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-business-details',
  templateUrl: './settings-business-details.component.html',
  styleUrls: ['./settings-business-details.component.css']
})
export class SettingsBusinessDetailsComponent implements OnInit, OnChanges {

  updateProfileForm: FormGroup;
  @Input() merchant: MerchantProfileDetails;
  public businessTypes: any[] = [
    { id: 1, value: 'fashion' },
    { id: 2, value: 'cosmetics' },
    { id: 3, value: 'jewelries' },
    { id: 4, value: 'electronics' },
    { id: 5, value: 'computers' },
    { id: 6, value: 'furniture' },
    { id: 7, value: 'gaming' },
    { id: 8, value: 'fragrances' },
    { id: 9, value: 'groceries' },
    { id: 10, value: 'beers, wines and spirits' },
    { id: 11, value: 'health' }
  ];
  loading = false;

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private fb: FormBuilder,
    private _auth: AuthService
  ) {
    this.updateProfileForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      business_type: ['', Validators.required],
      whatsapp_no: ['', [Validators.required, Validators.pattern(/^([0]|234|\+234)\d{10}$/)]]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { phone, name, business_type, address, whatsapp_no } = changes.merchant.currentValue;
    this.updateProfileForm.patchValue({ phone, name, address, business_type, whatsapp_no });
  }

  ngOnInit(): void {
  }

  saveProfileData() {
    this.updateProfileForm.disable();
    this.loading = true;
    this._api.updateProfileData(this.updateProfileForm.value).subscribe(({ message }) => {
      this.loading = false;
      this.updateProfileForm.enable();
      this._auth.setMerchant({ ...this.merchant, ...this.updateProfileForm.value });
      this._general.notify('success', message);
    }, err => {
      this.loading = false;
      this.updateProfileForm.enable();
      this._general.notify('error', err.error.message || 'An error occurred!');
    });
  }
}
