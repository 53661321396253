<app-header [currentRoute]="'repayment reports'"></app-header>

<app-default-layout>
  <div class="d-none d-md-flex align-items-center mb-4">
    <span *ngIf="makingAnApiCall">Loading...</span>
    <div class="dropdown ms-auto">
      <button
        class="dropdown-toggle btn btn-outline-secondary btn-sm" type="button" id="dropdownMenuButton"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      >
        <i class="fa fa-bars"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a *ngFor="let option of summaryFilterOptions" (click)="fetchStats(option.id)" class="dropdown-item cursor-pointer">
          {{option.text}}
        </a>
      </div>
    </div>
  </div>

  <span *ngIf="makingAnApiCall" class="mb-3 d-block d-md-none">Loading...</span>

  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-danger"><i class="fas fa-tags"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_sales_amount | currency:'NGN':'₦':'0.0' }}
              </span>
              <div class="text-center mt-2">
                <div>Total Sales</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-primary"><i class="fas fa-credit-card"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_credit_orders_amount | currency:'NGN':'₦':'0.0' }}
              </span>
              <div class="text-center mt-2">
                <div>Total Credit</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-success"><i class="fas fa-users"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.customer_count ? merchantDashBoardData?.customer_count : '0' }}
              </span>
              <div class="text-center mt-2">
                <div>Customers</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-warning"><i class="fas fa-shopping-bag"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.orders_count ? merchantDashBoardData?.orders_count : '0' }}
              </span>
              <div class="text-center mt-2">
                <div>Orders</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-danger"><i class="fas fa-cart-arrow-down"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_credit_orders_amount | currency:'NGN':'₦':'0.0' }}
              </span>
              <div class="text-center mt-2">
                <div>Credit Orders</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-primary"><i class="fas fa-thumbs-up"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_likes ? merchantDashBoardData?.total_likes : '0' }}
              </span>
              <div class="text-center mt-2">
                <div>Total Likes</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-success"><i class="fas fa-comment"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_comments ? merchantDashBoardData?.total_comments : '0' }}
              </span>
              <div class="text-center mt-2">
                <div>Comments</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card outlined p-0 mb-3 shadow-sm d-flex flex-column align-items-stretch justify-content-center text-center">
            <div class="p-4 py-5 d-flex flex-column">
              <span class="icon-cus mb-1 text-warning"><i class="fas fa-eye"></i></span>
              <span class="d-price mt-3">
                {{ merchantDashBoardData?.total_views ? merchantDashBoardData?.total_views : '0' }}
              </span>
              <div class="text-center mt-2">
                <div>Total Views</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button (click)="modal = 'filter'" class="floating-action-button d-md-none">
    <i class="fa fa-filter"></i>
  </button>
</app-default-layout>

<app-dialog *ngIf="modal === 'filter'" (close)="modal = ''" [close-button]="false" title="Filter stats">
  <ul class="list-group list-group-flush">
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('1')" class="btn btn-link px-0">Today</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('2')" class="btn btn-link px-0">Last 24 hours</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('3')" class="btn btn-link px-0">Yesterday</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('4')" class="btn btn-link px-0">Last 7 days</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('6')" class="btn btn-link px-0">This Week</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('7')" class="btn btn-link px-0">This Month</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('5')" class="btn btn-link px-0">Last 30 days</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('8')" class="btn btn-link px-0">This Year</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
    <li class="list-group-item px-0 py-2 d-flex justify-content-between align-items-center">
      <a (click)="fetchStats('9')" class="btn btn-link px-0">Lifetime</a>
      <i class="fa fa-arrow-right text-muted"></i>
    </li>
  </ul>
</app-dialog>
