<div class="row">
  <div class="col-12">
    <div class="card mb-4">
      <div class="balance d-flex flex-column align-items-center text-center py-5">
        <div class="h2 m-0 fw-bold" style="color: #425165">
          {{ 0 | currency:'NGN':'₦':'0.0' }}
        </div>
        <small class="mt-2 text-primary">Balance</small>
      </div>
    </div>

    <div class="d-flex mb-4">
      <div class="card bg-light-blue px-4 py-4 text-center flex-grow-1 me-2">
        <i class="fa fa-folder-plus font-size-xl text-primary"></i>
        <small class="mt-2 text-muted fw-bold">Fund</small>
      </div>
      <div class="card bg-light-red px-4 py-4 text-center flex-grow-1 ms-2">
        <i class="fa fa-wallet font-size-xl text-danger"></i>
        <small class="mt-2 text-muted fw-bold">Withdraw</small>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card">
      <div class="title mb-5">Transaction history</div>
      <p class="text-muted my-10">No transactions yet, your wallet transactions will show here</p>
      <!--            <div class="mb-3 pb-3 border-bottom d-flex align-items-center justify-content-between">-->
      <!--                <span class="fw-bold text-danger">-->
      <!--                    <i class="fa fa-minus"></i>-->
      <!--                    {{ 20000 | currency:'NGN':'₦':'0.0' }}-->
      <!--                </span>-->
      <!--                <small class="text-muted">Today</small>-->
      <!--            </div>-->
      <!--            <div class="d-flex align-items-center justify-content-between">-->
      <!--                <span class="fw-bold text-success">-->
      <!--                    <i class="fa fa-plus"></i>-->
      <!--                    {{ 20000 | currency:'NGN':'₦':'0.0' }}-->
      <!--                </span>-->
      <!--                <small class="text-muted">Today</small>-->
      <!--            </div>-->
    </div>
    <br><br><br><br><br><br><br><br><br><br>
  </div>
</div>
