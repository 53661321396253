import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IRequest } from '../../models/requests';
import { GeneralService } from 'src/app/services/general.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, pluck } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-a-request',
  templateUrl: './edit-a-request.component.html',
  styleUrls: ['./edit-a-request.component.css']
})
export class EditARequestComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() aRequestToEdit: any;
  @Output() doneEditing = new EventEmitter();
  orderToBeEdited: IRequest;
  calculationResults = {
    upfrontPaymentOnPurchase: null,
    balanceSpread: null,
    total: null
  };
  editRequestForm: FormGroup;
  priceToBeDeleted = 0;
  calculationCompleted = false;
  destroyObservables: Array<Subscription> = [];
  creditOptionTurnedOnByMerchant = false;

  constructor(
    private fb: FormBuilder,
    public _general: GeneralService,
    private _api: ApiService
  ) {
    this.editRequestForm = this.fb.group({
      cred_plus_upfront: ['', Validators.required],
      no_of_months: '',
      upfront_amount: ''
    });
  }

  ngOnInit(): void {
    this.editRequestForm.valueChanges.pipe(debounceTime(200)).subscribe(() => {
      // let prevUpfront = this.upfront_amount.value;
      if (Number(this.upfront_amount.value) > Number(this.cred_plus_upfront.value)) {
        return this.upfront_amount.patchValue(this.orderToBeEdited.upfront_amt);
      }
      this.carryOutCalculation();
    });

    // TODO: remove
    // this.destroyObservables[0] = this._general.calculatePrice$.subscribe(
    //   val => {
    //     if (val === 'calculatePriceToBeDeleted') {
    //       setTimeout(() => {
    //         if (
    //           this.orderToBeEdited &&
    //           this.orderToBeEdited.itemAboutToBeDeleted // TODO
    //         ) {
    //           const temp = this.orderToBeEdited.items.find(
    //             element => element.item_id === this.orderToBeEdited.itemAboutToBeDeleted
    //           );
    //           this.orderToBeEdited.itemPriceToBeDeleted = temp['item_price'];
    //           this.priceToBeDeleted = Number(
    //             this.orderToBeEdited.itemPriceToBeDeleted
    //           );
    //           if (this.orderToBeEdited && this.orderToBeEdited.credit) {
    //             this.creditOptionTurnedOnByMerchant = this.orderToBeEdited.credit;
    //           }
    //           this.prefillForm(this.orderToBeEdited);
    //           const newPrice =
    //             this.cred_plus_upfront.value - Number(this.priceToBeDeleted);
    //           this.cred_plus_upfront.patchValue(newPrice);
    //         }
    //       }, 200);
    //     } else {
    //       this.priceToBeDeleted = 0;
    //     }
    //   }
    // );
  }

  ngOnChanges(changes: SimpleChanges) {
    const { currentValue } = changes.aRequestToEdit;
    this.creditOptionTurnedOnByMerchant = (currentValue as IRequest).credit;
    this.prefillForm(currentValue);
    this.orderToBeEdited = currentValue;
  }

  ngAfterViewInit() {
    const totalPrice = document.querySelector(
      '.totalPrice'
    ) as HTMLInputElement;

    this.destroyObservables[2] = fromEvent(totalPrice, 'blur').pipe(
      pluck('target'),
      debounceTime(500)
    ).subscribe((input: HTMLInputElement) => {
      const res = this._general.putCommasInNumbers(
        input.value,
        this.cred_plus_upfront.value
      );
      input.type = 'text';
      input.value = res;
    });

    const upfront = document.querySelector('.upfront') as HTMLInputElement;

    this.destroyObservables[3] = fromEvent(upfront, 'blur').pipe(
      pluck('target'),
      debounceTime(500)
    ).subscribe((input: HTMLInputElement) => {
      const res = this._general.putCommasInNumbers(input.value);
      input.type = 'text';
      input.value = res;
    });

    // javascript implementation to the problem;
    // document
    //   .getElementById('generalRequestCloseBtn')
    //   .addEventListener('click', e => {
    //     this.orderToBeEdited = undefined;
    //     this.aRequestToEdit = undefined;
    //   });
  }

  //  do calculation
  carryOutCalculation() {
    //  start calculation here
    if (this.creditOptionTurnedOnByMerchant) {
      let bal = this.cred_plus_upfront.value - this.upfront_amount.value;
      bal = bal / this.no_of_months.value;
      this.calculationResults.upfrontPaymentOnPurchase = this._general.putCommasInNumbers(
        `${ this.upfront_amount.value }`
      );
      this.calculationResults.total = this._general.putCommasInNumbers(
        `${ this.cred_plus_upfront.value }`
      );
      this.calculationResults.balanceSpread = this._general.putCommasInNumbers(
        `${ bal }`
      );
      // look into this soon
      //  console.log(this.orderToBeEdited)
      // if (this.priceToBeDeleted && this.orderToBeEdited.itemPriceToBeDeleted) {
      //   this.cred_plus_upfront.disable();
      //   document.getElementById('newTotalPrice').classList.add('disabled');
      // } else {
      //   this.cred_plus_upfront.enable();
      //   document.getElementById('newTotalPrice').classList.remove('disabled');
      // }

      //  if(this.priceToBeDeleted){
      //    const temp = this.cred_plus_upfront.value - this.priceToBeDeleted;
      //    this.cred_plus_upfront.patchValue(temp);
      //    this.calculationResults.total = this.generalservice.putCommasInNumbers(temp);
      //  }

      this.calculationCompleted = true;
      return;
    }

      // else if (this.creditOptionTurnedOnByMerchant) {
      //   let bal = this.cred_plus_upfront.value - this.upfront_amount.value;
      //   bal = bal / this.no_of_months.value;
      //   this.calculationResults.upfrontPaymentOnPurchase = this.generalservice.putCommasInNumbers(
      //     `${this.upfront_amount.value}`
      //   );
      //   this.calculationResults.total = this.generalservice.putCommasInNumbers(
      //     `${this.cred_plus_upfront.value}`
      //   );
      //   this.calculationResults.balanceSpread = this.generalservice.putCommasInNumbers(
      //     `${bal}`
      //   );

      //   this.calculationCompleted = true;
    // }
    else {
      this.calculationResults.upfrontPaymentOnPurchase = undefined;
      this.calculationResults.total = undefined;
      this.calculationResults.balanceSpread = undefined;
      this.calculationCompleted = false;
    }
  }

  get cred_plus_upfront() {
    return this.editRequestForm.get('cred_plus_upfront');
  }

  get no_of_months() {
    return this.editRequestForm.get('no_of_months');
  }

  get upfront_amount() {
    return this.editRequestForm.get('upfront_amount');
  }

  prefillForm(item: IRequest) {
    if (item) {
      const { cred_plus_upfront, no_of_months, upfront_amt } = item;
      // console.log(item);
      this.cred_plus_upfront.patchValue(cred_plus_upfront);
      this.no_of_months.patchValue(
        this.creditOptionTurnedOnByMerchant ? no_of_months : ''
      );
      this.upfront_amount.patchValue(
        this.creditOptionTurnedOnByMerchant ? upfront_amt : ''
      );

      // make these fields human readable
      const totalPrice = document.querySelector('.totalPrice') as HTMLInputElement;
      const upfront = document.querySelector('.upfront') as HTMLInputElement;
      if (totalPrice) {
        totalPrice.type = 'text';
        totalPrice.value = this._general.putCommasInNumbers(cred_plus_upfront);
      }
      if (upfront) {
        upfront.type = 'text';
        upfront.value = this._general.putCommasInNumbers(this.creditOptionTurnedOnByMerchant ? upfront_amt : undefined);
      }
      if (item['upfront_paid'] === 1) {
        this.upfront_amount.disable();
      }
      // this.aRequestToEdit = item;
    }

    const input = document.getElementById('turnCreditOnOrOff') as HTMLInputElement;
    if (input) {
      input.checked = this.creditOptionTurnedOnByMerchant;
    }
  }

  submitChanges(form: FormGroup, event: Event) {
    const formToSubmit = {};
    const btn = (event.target as HTMLFormElement).querySelector(
      'button[type="submit"]'
    ) as HTMLButtonElement;
    const {
      cred_plus_upfront,
      no_of_months,
      upfront_amount
    } = form.getRawValue();

    // console.log(cred_plus_upfront);
    // console.log(no_of_months);
    // console.log(upfront_amount);

    if (
      no_of_months.toString().length < 1 &&
      (this.creditOptionTurnedOnByMerchant || this.orderToBeEdited.credit)
    ) {
      this._general.notify('warning',
        'Please fill in the number of months'
      );
      return;
    }
    btn.innerText = 'Submitting changes..';
    formToSubmit['no_of_months'] =
      no_of_months.toString().length > 0 ? Number(no_of_months) : null;
    formToSubmit['upfront_amount'] =
      upfront_amount.toString().length > 0 ? Number(upfront_amount) : null;
    formToSubmit['cred_plus_upfront'] = `${ cred_plus_upfront }`;
    formToSubmit['edit_is_credit'] = this.creditOptionTurnedOnByMerchant;

    if (formToSubmit['edit_is_credit'] === false) {
      delete formToSubmit['upfront_amount'];
      delete formToSubmit['no_of_months'];
      formToSubmit['removed_item_id'] = [];
    }

    if (this.orderToBeEdited.itemAboutToBeDeleted) {
      formToSubmit['removed_item_id'] = [];
      (formToSubmit['removed_item_id'] as any[]).push(
        this.orderToBeEdited.itemAboutToBeDeleted
      );
    }

    setTimeout(() => {
      btn.innerText = 'Submit changes';
    }, 1000);
    this._api.modifyTotalPriceOfRequest(formToSubmit, this.orderToBeEdited.request_id.toString()).subscribe(val => {
      const data = val['data'];
      btn.innerText = 'Submit changes';
      const itemToRemove = this.aRequestToEdit.items.findIndex(
        element =>
          element.item_id === this.aRequestToEdit.itemAboutToBeDeleted
      );
      this.aRequestToEdit.items.splice(itemToRemove, 1);
      this.successFulEditing(data);
      this.calculationCompleted = false;
      this.editRequestForm.reset();
      for (const key in this.calculationResults) {
        if (this.calculationResults.hasOwnProperty(key)) {
          this.calculationResults[key] = undefined;
        }
      }
    }, () => {
      btn.innerText = 'Submit changes';
    });
  }

  successFulEditing(data) {
    this.doneEditing.emit(data);
  }

  toggleCreditOption(event: Event) {
    const input = event.target as HTMLInputElement;
    this.creditOptionTurnedOnByMerchant = input.checked;
    if (
      !this.creditOptionTurnedOnByMerchant &&
      this.orderToBeEdited.itemAboutToBeDeleted
    ) {
      // this.priceToBeDeleted = 0;
      // this.orderToBeEdited.itemAboutToBeDeleted = null;
      // this.orderToBeEdited.itemPriceToBeDeleted = null;
      for (const key in this.calculationResults) {
        if (this.calculationResults.hasOwnProperty(key)) {
          this.calculationResults[key] = undefined;
        }
      }
      this.calculationCompleted = false;
      return;
    }
    for (const key in this.calculationResults) {
      if (this.calculationResults.hasOwnProperty(key)) {
        this.calculationResults[key] = undefined;
      }
    }
    this.calculationCompleted = false;
  }

  ngOnDestroy() {
    if (this.destroyObservables.length > 0) {
      this.destroyObservables.forEach(element => {
        element.unsubscribe();
      });
    }
  }
}
