<div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
  <div class="container px-0 position-relative">
    <div class="notification d-flex flex-row align-items-center"
      (click)="viewNotification(notification?.request_id,notification?.type,notification)"
      [ngClass]="{ 'unread': notification?.is_read == 0 }" *ngFor="let notification of notificationsData; index as i">
      <div class="me-3 avatar avatat-md">
        <img src="assets/images/placeholder-profile.png" alt="" />
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex font-size-md justify-content-between align-items-center mb-1">
          {{ notification?.action_happened }}
          <small *ngIf="notification.type == 1" class="fw-bold text-danger">Order</small>
          <small *ngIf="notification.type != 1" class="fw-bold text-warning">Payment</small>
        </div>
        <small class="text-muted">{{ notification?.message }}</small>
      </div>
    </div>
    <div *ngIf="loadingNotifications"
      class="loading-notifications position-absolute d-flex justify-content-center align-items-center p-0">
      <div class="">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
