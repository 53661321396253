import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  breakpoints = {
    0: { slidesPerView: 1.2, spaceBetween: 10 },
    600: { slidesPerView: 2.2, spaceBetween: 10 },
    1000: { slidesPerView: 3.5, spaceBetween: 10 }
  };

  constructor() {
  }

  ngOnInit(): void {
  }

}
