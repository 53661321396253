<div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()">
  <div class="container px-0">
    <div style="cursor: pointer" class="collection d-flex flex-row align-items-center"
         *ngFor="let collectionHistory of collectionsHistoryData; index as i">
      <div class="me-3 avatar avatat-md">
        <img src="assets/images/placeholder-profile.png" alt=""/>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <div class="title fw-bold d-flex justify-content-between align-items-center mb-1">
          {{ collectionHistory?.payment_made }}
          <span class="badge bg-danger fw-normal">Repayment</span>
        </div>
        <small class="">
          {{ collectionHistory?.full_name }} made a payment of
          ₦{{ collectionHistory?.amount_paid | currency:'NGN':'₦':'0.0' }}
        </small>
      </div>
    </div>

    <div
      *ngIf="loadingCollections"
      class="loading-notifications position-absolute d-flex justify-content-center align-items-center p-0"
    >
      <div class="">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
