import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-connect-whatsapp',
  templateUrl: './connect-whatsapp.component.html',
  styleUrls: ['./connect-whatsapp.component.scss']
})
export class ConnectWhatsappComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Output() done = new EventEmitter();
  loading = '';
  image: string;
  slug: string;
  view = 'init';
  whatsapp_no = '';
  saving = false;
  private timeout = null;

  constructor(
    public _auth: AuthService,
    private _api: ApiService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
    if (this._auth.merchant.sender_id) this.done.emit();
  }

  ngOnDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  async init() {
    const { whatsapp_no } = this._auth.merchant;
    if (!whatsapp_no) this.view = 'phone';
    else await this.getCode();
  }

  async getCode() {
    try {
      const { name, whatsapp_no } = this._auth.merchant;
      const payload = { name, phone: whatsapp_no.toString().replace('234', '0'), merchant: true };
      this.loading = 'Getting QR Code..';
      const data = await this._api.getWhatsappQRCode(payload).toPromise<any>();
      this.loading = '';
      this.image = data.data.image;
      this.slug = data.data.slug;
      this.view = 'scan';
      await this.checkStatus();
    } catch (e) {
      console.log({ e });
      this._general.notify('error', e?.error?.message || 'An error occurred, please try again');
      this.loading = '';
    }
  }

  async checkStatus() {
    const data = await this._api.checkWhatsappQRCodeStatus(this.slug).toPromise<any>();
    const { image, qrStatus } = data.response;
    this.image = image;
    if (qrStatus === 0) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.checkStatus(), 2000);
      return;
    }
    if (qrStatus === -1) {
      this.view = 'init';
      this._general.notify('error', `Could not connect, please use the whatsapp connected to ${ this._auth.merchant.whatsapp_no }`);
      return;
    }
    await this._auth.refreshMerchantData();
    this.done.emit();
  }

  async saveWhatsappNumber() {
    this.saving = true;
    const { name } = this._auth.merchant;
    const data = await this._api.updateProfileData({ name, whatsapp_no: this.whatsapp_no }).toPromise();
    if (data.status) {
      this.saving = false;
      this._auth.updateMerchant({ whatsapp_no: this.whatsapp_no });
      return await this.getCode();
    }
    this.saving = false;
  }
}
