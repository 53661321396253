<div class="row">
  <div class="col-6 col-md-4">
    <div
      (click)="open.emit('create-product')"
      class="card d-flex flex-column align-items-center mb-3 justify-content-center cursor-pointer text-center p-2"
      style="min-height: 140px;"
    >
      <img src="assets/images/svg/camera.svg" alt="" style="width: 60px" class="my-auto">
      <small class="fw-normal text-center text-muted border-top w-100 pt-2 mt-1">From device</small>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div
      (click)="open.emit('whatsapp')"
      class="card d-flex flex-column align-items-center mb-3 justify-content-center cursor-pointer text-center p-2"
      style="min-height: 140px;"
    >
      <img src="assets/images/logos/whatsapp.svg" alt="" style="width: 50px" class="my-auto">
      <small class="fw-normal text-center text-muted border-top w-100 pt-2 mt-1">From whatsapp</small>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div
      (click)="open.emit('instagram')"
      class="card d-flex flex-column align-items-center mb-3 justify-content-center cursor-pointer text-center p-2"
      style="min-height: 140px;"
    >
      <img src="assets/images/svg/instagram.svg" alt="" style="width: 50px" class="my-auto">
      <small class="fw-normal text-center text-muted border-top w-100 pt-2 mt-1">From instagram</small>
    </div>
  </div>
  <div *ngFor="let option of options" class="col-6 col-md-4">
    <div
      (click)="open.emit(option.name)"
      class="card d-flex flex-column align-items-center mb-3 justify-content-center cursor-pointer text-center p-2"
      style="min-height: 140px;"
    >
      <img [src]="option.image" alt="" style="width: 90px" class="my-auto">
      <small class="fw-normal text-center text-muted border-top w-100 pt-2 mt-1">From {{ option.name }}</small>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div
      (click)="open.emit('popular')"
      class="card d-flex flex-column align-items-center mb-3 justify-content-center cursor-pointer text-center p-2"
      style="min-height: 140px;"
    >
      <img src="assets/images/svg/popularity.svg" alt="" style="width: 60px" class="my-auto">
      <small class="fw-normal text-center text-muted border-top w-100 pt-2 mt-1">From popular products</small>
    </div>
  </div>
</div>
