import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { StoreService as GlobalStoreService } from '../../services/store.service';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import platforms from '../../lib/platforms';

@Component({
  selector: 'app-dashboard-add-product-options',
  templateUrl: './dashboard-add-product-options.component.html',
  styleUrls: ['./dashboard-add-product-options.component.scss']
})
export class DashboardAddProductOptionsComponent implements OnInit {
  @Output() modal = new EventEmitter();

  activating: boolean;

  addProductMethods = platforms;

  constructor(
    private router: Router,
    public _general: GeneralService,
    public _store: GlobalStoreService,
    public _auth: AuthService,
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
  }

  launchModalIfProfileComplete(modal) {
    if (!this._auth.canShare) return this._general.showPendingSetup();
    this.modal.emit(modal);
  }
}
