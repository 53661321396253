<div class="card mb-4">
  <h5 class="title mb-2">Phone number</h5>
  <p class="mb-5">Update primary phone number</p>

  <ng-template [ngIf]="view === 'default'">
    <div *ngIf="merchant.phone" class="form-group required mt-3">
      <label class="form-label d-flex align-items-center" for="phone">
        Primary phone number
        <span class="badge bg-warning ms-2" *ngIf="!merchant.phone_validated">Not verified</span>
        <span class="badge bg-success ms-2" *ngIf="merchant.phone_validated">Verified</span>
      </label>
      <input type="tel" class="form-control override" id="phone" placeholder="Phone number" readonly [value]="merchant.phone">
      <div class="mt-3 d-flex justify-content-end">
        <button
          *ngIf="!merchant.phone_validated" (click)="sendVerificationSMS()" class="btn btn-outline-secondary rounded-full btn-xs px-3 me-2"
          [disabled]="loading"
        >
          <i class="fa fa-redo-alt font-size-xs me-2" [ngClass]="{'fa-spin': loading}"></i> Resend otp code
        </button>
        <button (click)="view = 'change'" class="btn btn-outline-secondary rounded-full btn-xs px-3" [disabled]="loading">
          <i class="fa fa-pen font-size-xs me-2"></i> Change
        </button>
      </div>
    </div>
    <div *ngIf="!merchant.phone" class="d-flex justify-content-between align-items-center">
      <small class="text-danger">No phone number added</small>
      <button (click)="view = 'change'" class="btn btn-outline-secondary rounded-full btn-xs px-3">
        <i class="fa fa-plus font-size-xs me-2"></i> Add phone number
      </button>
    </div>
  </ng-template>

  <ng-template [ngIf]="view === 'change'">
    <form [formGroup]="phoneForm" (ngSubmit)="handlePhoneFormSubmit()">
      <label class="form-group required mt-3 d-block">
        <span class="form-label">Enter new phone number</span>
        <input type="tel" class="form-control override" formControlName="phone"/>
      </label>
      <label class="form-group required mt-3 d-block mb-3">
        <span class="form-label">Enter your password</span>
        <input type="password" class="form-control override" formControlName="password"/>
      </label>
      <div class="d-flex align-items-center mt-4">
        <button [disabled]="phoneForm.invalid || loading" type="submit" class="btn btn-success">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
          Continue
        </button>
        <button (click)="view = 'default'" [disabled]="loading" class="btn btn-outline-danger ms-2">Cancel</button>
      </div>
    </form>
  </ng-template>

  <ng-template [ngIf]="view === 'otp'">
    <form [formGroup]="otpForm" (ngSubmit)="handleOtpFormSubmit()">
      <label class="form-group required mt-3 d-block">
        <span class="form-label">Enter OTP code</span>
        <input type="number" class="form-control override" formControlName="otp"/>
      </label>
      <div class="mt-4">
        <button [disabled]="otpForm.invalid || loading" type="submit" class="btn btn-success">
          <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
          {{ phoneForm.value.phone ? 'Change phone number' : 'Submit' }}
        </button>
      </div>
    </form>
  </ng-template>
</div>
