<div *ngIf="view === 'auth'" class="card shadow-sm">
  <h3 class="title m-0">
    Pull from JIJI
  </h3>
  <p class="mb-5">Enter your Jiji store ID</p>
  <form [formGroup]="authForm">
    <div class="form-group mb-4">
      <input formControlName="shop" type="text" class="form-control" id="id" placeholder="Enter store id"/>
    </div>
  </form>
  <div class="form-group form-check d-flex align-items-center mb-4">
    <input
      type="checkbox" class="form-check-input mt-0 override" id="confirmHandle" [(ngModel)]="confirmed"
      [ngModelOptions]="{standalone: true}"
    />
    <label class="form-check-label ms-2" for="confirmHandle">
      I confirm this is my store
    </label>
  </div>
  <div>
    <button
      (click)="authenticate()" [disabled]="!confirmed || authForm.invalid || loading"
      class="btn btn-primary mt-4"
    >
      Continue
    </button>
  </div>
  <div class="mt-2">
    <button class="btn btn-link px-0">See example here</button>
  </div>
  <br><br><br><br><br><br><br><br>
  <div class="d-flex justify-content-end">
    <img src="/assets/images/logos/jiji.png" alt="No account number" style="width: 100px">
  </div>
</div>

<div *ngIf="view === 'fetching'" class="d-flex flex-column align-items-center">
  <br><br><br>
  <div class="d-flex align-items-center">
    <div class="spinner-border spinner-border-sm me-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    {{ countdown }}
  </div>
  <div class="title mt-4 text-center w-75 mx-auto font-italic">
    Fetching products from Jiji, this may take a while
  </div>
  <br><br><br>
</div>

<div *ngIf="view === 'error'" class="empty d-flex flex-column align-items-center text-center">
  <img src="assets/images/svg/crossed.svg" alt="Error icon" width="100">
  <h5 class="title fw-bold mb-4 mt-5">An error occurred</h5>
  <div style="max-width: 300px; margin: auto;">
    {{ error }}
  </div>
  <button (click)="items.length ? view = 'list' : view = 'auth'" class="btn btn-outline-secondary mt-5">
    <i class="fa fa-arrow-left me-2"></i> Back
  </button>
</div>

<div *ngIf="view === 'list'">
  <div class="d-flex justify-content-between align-items-center mb-5">
    <h5 class="title m-0">
      <button (click)="view = 'auth'" class="btn btn-link px-0 me-3"><i class="fa fa-arrow-left"></i></button>
      Products from Jiji
    </h5>
  </div>
  <div class="flex-row align-items-center mb-4 d-none d-md-flex">
    <input type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
           placeholder="Search" [(ngModel)]="query" (input)="handleSearch()"/>
  </div>
  <div class="row" *ngIf="displayItems.length">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let item of displayItems">
      <app-square-box (click)="select(item)" [ngClass]="{selected: selected?.id === item?.id}"
                      class="cursor-pointer">
        <img class="rounded w-100 fit-cover" [src]="item.images[0]" alt="">
        <div class="count">{{ item.images.length }}</div>
      </app-square-box>
      <div [title]="item.name" class="title mt-2 text-ellipsize">{{ item.name }}</div>
      <div class="mt-1">{{ item.price }}</div>
    </div>
  </div>

  <div *ngIf="loading" class="row">
    <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let i of [1,2,3,4]">
      <div class="card p-0 skeleton fit-content">
        <img [src]="'assets/images/plus.svg'" alt="">
      </div>
      <div class="title skeleton fit-content mt-2">Product name</div>
      <small class="skeleton fit-content">N200,000</small>
    </div>
  </div>

  <div class="mt-4 text-center" *ngIf="!loading && items.length && items.length === displayItems.length && !end">
    <button (click)="loadMore()" class="btn btn-link">Load more</button>
  </div>

  <button (click)="search = true" class="floating-action-button search-fab d-md-none" [ngClass]="{show: !search}">
    <i class="fa fa-search"></i>
  </button>

  <div class="mobile-search" [ngClass]="{show: search}">
    <div class="d-flex align-items-center" id="searchDiv">
      <input type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
             placeholder="Search" (input)="handleSearch()" [(ngModel)]="query"/>
      <button (click)="stopSearch()" class="search-button ms-2">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>

<div *ngIf="view === 'create-product' && selected">
  <app-create-product-embedded
    [item]="selected" (close)="closeCreateProduct()" [external_id]="selected.id" external="2"
  ></app-create-product-embedded>
</div>
