<div class="row wrap">
  <div class="col-0 col-sm-6 left-content py-5 d-none d-md-block"></div>
  <div class="col-12 col-md-6 left">
    <div class="content d-flex flex-column">
      <div *ngIf="view === 'loading'" class="my-auto d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="title mt-4 text-center w-75 mx-auto font-italic">
          Please wait..
        </div>
      </div>
      <form *ngIf="view === 'identity'" [formGroup]="forgotPasswordForm" class="my-auto">
        <h3 class="title mb-3 mt-5">Reset password</h3>
        <p class="mb-4">
          Enter your email address or phone number below, you will receive a code to reset your password.
        </p>

        <div class="form-group required-field mt-5">
          <label for="phone-email">Phone Number or Email</label>
          <input formControlName="emailOrPhone" type="text" class="form-control" placeholder="Phone Number or Email" id="phone-email"/>
        </div>

        <div class="mt-5">
          <button
            [disabled]="!forgotPasswordForm.valid || loading" (click)="submitForgotPassword()" type="submit" class="btn btn-primary"
          >
            <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
            Send Request
          </button>
        </div>

        <p class="mt-4">Back to <a class="text-primary" routerLink="/login">Login</a></p>
      </form>

      <form *ngIf="view === 'password'" [formGroup]="resetForm" class="my-auto">
        <h3 class="title mb-3 mt-5">Enter new password</h3>
        <p class="mb-5">
          A token has been sent to {{ forgotPasswordForm.value.emailOrPhone }}, enter token and your new password below to continue.
        </p>

        <div class="form-group required-field mt-4">
          <label for="token">Token</label>
          <input formControlName="token" type="number" class="form-control" required placeholder="Code" id="token"/>
        </div>

        <div class="form-group required-field">
          <label for="password">New Password</label>
          <input formControlName="new_password" type="password" class="form-control" required placeholder="New password" id="password"/>
        </div>

        <div class="form-group required-field">
          <label for="confirm_password">Confirm new Password</label>
          <input
            formControlName="confirm_new_password" type="password" class="form-control" required placeholder="New password"
            id="confirm_password"
          />
        </div>

        <div class="mt-5">
          <button [disabled]="resetForm.invalid || loading" (click)="submitResetForm()" type="submit" class="btn btn-primary">
            <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>
            Reset password
          </button>
        </div>

        <p class="mt-4">
          Not your {{ isEmail(forgotPasswordForm.value.emailOrPhone) ? 'email address' : 'phone number' }}?
          <a class="text-primary cursor-pointer" (click)="view = 'identity'">Change</a>
        </p>
      </form>
    </div>
  </div>
</div>
