import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.css']
})
export class SocialShareComponent implements OnInit {
  @Input() link: any;
  @Input() description: any;
  @Output() search = new EventEmitter();
  twitter: string | null;
  facebook: string | null;
  instagram: string | null;
  whatsapp: string;

  constructor() {
  }

  ngOnInit(): void {
    const { description, link } = this;
    this.twitter = `https://twitter.com/share?text=${ description ? description : 'no description' }&url=${ link }`;
    this.facebook = `https://www.facebook.com/sharer/sharer.php?app_id=1217981644879628&u=${ link }`;
    // this.whatsapp = info.whatsapp_link;
  }

  shareLink(event: Event) {
    let pseudoElement: HTMLElement;
    (event.target as HTMLElement).matches('#linkText')
      ? (pseudoElement = event.target as HTMLElement)
      : (pseudoElement = document.getElementById('linkText'));
    const input = document.createElement('input');
    input.value = this.link;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    pseudoElement.innerText = 'Copied!';
    setTimeout(() => {
      pseudoElement.innerText = 'Copy Link';
    }, 2000);
  }
}
