import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-mobile-navigation-drawer',
  templateUrl: './mobile-navigation-drawer.component.html',
  styleUrls: ['./mobile-navigation-drawer.component.scss'],
  animations: [
    trigger('backdrop', [
      state('void', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition(':enter', animate(300)),
      transition('visible => void', [
        animate(300), style({ display: 'none' })
      ])
    ]),
    trigger('dialogDesktop', [
      state('void', style({ transform: 'translateX(-100%)', opacity: 0 })),
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition(':enter', animate('200ms ease-in-out')),
      transition('visible => void', animate('200ms ease-in-out'))
    ]),
  ]
})
export class MobileNavigationDrawerComponent implements OnInit {
  state = 'visible';
  @Output() close = new EventEmitter<any>();
  @Output() modal = new EventEmitter<any>();

  constructor(
    public _auth: AuthService,
    public _general: GeneralService,
    public _store: StoreService,
  ) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.state = 'void';
  }

  done(event) {
    if (event.toState === 'void') this.close.emit();
  }
}
