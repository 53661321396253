import { Component, OnInit } from '@angular/core';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-activate-bank-partnership',
  templateUrl: './activate-bank-partnership.component.html',
  styleUrls: ['./activate-bank-partnership.component.css']
})
export class ActivateBankPartnershipComponent implements OnInit {
  termsAccepted = false;
  loading = false;
  merchant: MerchantProfileDetails = null;

  constructor(
    private _api: ApiService,
    private _general: GeneralService,
    private _auth: AuthService
  ) {
    this._auth.$merchant.subscribe({
      next: merchant => {
        this.merchant = merchant;
      }
    });
  }

  ngOnInit(): void {}

  async enablePartnership() {
    this.loading = true;
    this._api.activatePartnership().subscribe(val => {
      this.loading = false;
      this._auth.setMerchant({ ...this._auth.merchant, partnership: 1 });
      return this._general.notify('success', val.message);
    });
  }

  async disablePartnership() {
    this.loading = true;
    this._api.deactivatePartnership().subscribe(val => {
      this.loading = false;
      this._auth.setMerchant({ ...this.merchant, partnership: 0 });
      return this._general.notify('success', val.message);
    });
  }
}
