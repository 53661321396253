<div *ngIf="view === 'search'">

  <h3 *ngIf="!embedded" class="title mb-5">
    <i class="fa fa-globe me-2"></i> Add from popular products
  </h3>

  <div class="d-flex align-items-center" id="searchDiv">
    <input
      type="text" id="searchInput" (keydown.enter)="search()" autocomplete="off"
      class="form-control override rounded-full form-control-lg m-0 mb-1" placeholder="Search..." [(ngModel)]="searchString"
    />
    <button (click)="search()" class="search-button ms-2">
      <i class="fa fa-search text-primary"></i>
    </button>
  </div>

  <small *ngIf="searching" class="text-muted ms-2 mt-2 d-block">Searching..</small>
  <small *ngIf="searchResults.length > 0 && !searching" class="text-muted ms-2 mt-2 d-block">
    {{ searchResults.length }}
    {{ searchResults.length == 1 ? "product" : "products" }} found
  </small>

  <div *ngIf="(!searchResults.length || noData) && !embedded">
    <br><br><br><br><br><br><br><br>
    <img class="w-50 ms-auto" src="assets/images/svg/file-searching.svg" alt="Illustration" style="max-width: 300px;">
  </div>

  <div class="p-5 text-center mt-4" *ngIf="noData">
    We could not fetch results for '{{ searchString }}'
  </div>

  <div class="row mt-5" *ngIf="searchResults.length">
    <div class="col-6 col-md-4 mb-3" *ngFor="let item of searchResults">
      <app-square-box (click)="select(item)" class="cursor-pointer">
        <img class="rounded w-100 fit-cover" [src]="item.primary_picture" alt="">
      </app-square-box>
      <div [title]="item.name" class="title mt-2 text-ellipsize">{{ item.name }}</div>
      <div class="mt-1 fw-bold">{{ item.price | currency:'NGN':'₦':'0.0' }}</div>
    </div>
  </div>

  <div class="mt-4 text-center" *ngIf="end">
    <button (click)="loadMoreGlobalProducts($event)" class="btn btn-link">Load more</button>
  </div>
</div>

<div *ngIf="view === 'create-product' && selected">
  <app-create-product-embedded [item]="selected" (close)="closeCreateProduct()"></app-create-product-embedded>
</div>
