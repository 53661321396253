<!-- DESKTOP -->
<div class="row d-none d-md-flex">
  <div class="col-12 pb-5 mb-5 mb-md-0 pb-md-0">
    <div class="row mt-4 mb-5">
      <div class="col-sm-5 pb-5 position-relative">
        <div class="text-align avatar-container">
          <img [src]="repayment?.picture ? repayment?.picture : 'assets/images/placeholder-profile.png'"
               class="display-sm-inline mod-avatar w-100 h-100 fit-cover" alt=""/>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="border-bottom mb-3 pb-3">
          <div class="title fw-bold mb-1">Contact Information</div>
          <div class="d-flex justify-content-between align-items-center">
            <div>{{ repayment?.full_name }} / {{ repayment?.phone }}</div>
            <button (click)="viewCustomerInfo()" class="btn btn-outline-secondary btn-sm ms-2">View</button>
          </div>
        </div>

        <div class="border-bottom mb-3 pb-3">
          <div class="title fw-bold mb-1">Total Amount</div>
          <div class="d-flex justify-content-between align-items-center">
            <div *ngIf="repayment?.upfront_amt; else noCredit">
              {{ _general.formatCurrency(repayment?.total_repayment_amount)}}
              (Upfront Amount: {{ _general.formatCurrency(repayment?.upfront_amt) }})
            </div>

            <ng-template #noCredit>
              <div>{{ _general.formatCurrency(repayment?.total_repayment_amount) }}</div>
            </ng-template>
            <span *ngIf="repayment?.amount_paid == 0; else Paid" class="badge bg-danger">Not paid</span>
            <ng-template #Paid>
              <span class="badge bg-success">Paid</span>
            </ng-template>
          </div>
        </div>

        <div [style.display]="repayment?.credit ? 'none' : 'block'" class="border-bottom mb-3 pb-3">
          <div class="title fw-bold mb-1">Total Paid</div>
          <div>
            {{ repayment?.amount_paid == 0 ? 'No Payment made' : ' ' + _general.formatCurrency(repayment?.amount_paid) }}
            (Balance: {{_general.formatCurrency(repayment?.amount_left)}})
          </div>
        </div>

        <div [style.display]="repayment?.credit ? 'none' : 'block'" class="border-bottom mb-3 pb-3">
          <div class="title fw-bold mb-1">Repayment plan</div>
          <div>
            {{_general.formatCurrency(repayment?.repaymentObj?.amount) }}/Mo. For
            {{ repayment?.repaymentObj?.months }}
            {{ repayment?.repaymentObj?.months > 1 ? "Months" : "Month" }}
          </div>
        </div>

        <div class="border-bottom mb-3 pb-3">
          <div class="title fw-bold mb-1">Order Date</div>
          <div>{{ repayment?.created_at | date: 'mediumDate' }}</div>
        </div>

        <div class="mt-4">
          <button (click)="recordPayment()" class="btn btn-md btn-primary checkoutBtn">
            Record payment
          </button>
          <button (click)="viewCustomerInfo()" class="btn btn-md btn-outline-secondary ms-2 checkoutBtn">
            Contact Info
          </button>
        </div>
      </div>
    </div>

    <!-- Items purchased -->
    <owl-carousel-o [options]="itemsCarouselOptions">
      <ng-template carouselSlide *ngFor="let item of repayment.items; index as i">
        <div class="card p-0 deleteParent">
          <div class="card-img-top border-bottom">
            <img [src]="item?.primary_picture" alt="" style="height: 230px;"/>
          </div>
          <div class="card-body px-3 py-3">
            <div class="title mb-0 fw-bold text-ellipsize">{{ item?.name }}</div>
            <div class="title">{{ item?.upfront_amount | currency:'NGN':'₦':'0.0' }}</div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide *ngFor="let item of padding; index as i">
        <div class="card p-0">
          <div class="card-img-top border-bottom">
            <img src="/assets/images/svg/no-product.svg" style="height: 230px;" alt="" class="modal-product-item"/>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Repayment schedule -->
    <div class="row mt-md-5">
      <div class="col-12">
        <h5 class="title mb-4">Repayment Schedule</h5>
        <table style="width: 100%;" class="table table-bordered">
          <tbody>
          <tr>
            <th class="py-2">Date</th>
            <th class="py-2">AMOUNT</th>
            <th class="py-2"> Status</th>
          </tr>
          <tr *ngFor="let schedule of repayment?.schedule; index as i">
            <td class="py-2">{{ schedule?.date | date: "shortDate" }}</td>
            <td class="py-2">{{ _general.formatCurrency(schedule?.amount) }}</td>
            <td class="py-2" *ngIf="schedule?.amount == schedule?.amount_paid; else NotPaid">
              <i class="fa fa-check text-success"></i>
            </td>
            <ng-template #NotPaid>
              <td class="py-2">
                  <span class="badge bg-danger">
                    Not Paid
                  </span>
              </td>
            </ng-template>
          </tr>
          </tbody>
        </table>


        <div *ngIf="loading" class="loading col-12">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Payment history -->
    <div class="row mt-md-5">
      <div class="col-sm-6 mb-3">
        <div class="card bg-light-orange">
          <h5 class="title mb-2">Payment History</h5>
          <span class="d-block w-75">Click to see the Payment History for this customer
            <button (click)="fetchPaymentHistory($event)" class="btn btn-outline-secondary btn-xs ms-2 mt-1">
              View
            </button>
          </span>
        </div>
      </div>

      <div class="col-sm-6 mb-3">
        <div class="card bg-light-blue">
          <h5 class="title mb-2">Generate a link for payment</h5>
          <div class="d-flex flex-column flex-md-row">
            <a (click)="generatePaymentLink($event)" class="btn btn-sm btn-outline-secondary mb-3 me-md-3">Generate link
              <i *ngIf="generating" class="fa fa-circle-notch fa-spin ms-1"></i> </a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 mb-3">
        <div class="card bg-light-blue">
          <h5 class="title mb-2">Courier Information</h5>
          <span class="d-block w-75">{{ repayment.address }}</span>
        </div>
      </div>

      <div *ngIf="card" class="col-sm-6 mb-3">
        <div class="card bg-light-blue p-4">
          <h5 class="title mb-2">
            <i *ngIf="card?.card_type === 'visa '" class="fab fa-cc-visa"></i>
            <i *ngIf="card?.card_type === 'mastercard '" class="fab fa-cc-mastercard"></i> <!-- TODO -->
          </h5>
          <div class="h4 m-0">**** **** **** {{ card?.last4 }}</div>
          <div class="d-flex mt-2">
            <small class="flex-grow-1">{{ card?.exp_month + "/" + card?.exp_year }}</small>
            <small class="flex-grow-1">{{ card?.bank }}</small>
          </div>
        </div>
      </div>

    </div>

    <div class="d-none d-md-flex justify-content-end mt-5">
      <button (click)="recordPayment()" class="btn btn-primary checkoutBtn">
        Record Payment
      </button>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="d-md-none pb-5">
  <div *ngIf="view === 'details'">
    <div class="card d-flex flex-row px-3 py-4 mb-4">
      <div class="avatar avatar-md">
        <img [src]="repayment?.picture ? repayment?.picture : 'assets/images/placeholder-profile.png'"
             class="display-sm-inline mod-avatar" alt=""/>
      </div>

      <div class="flex-grow-1 ms-3">
        <div class="fw-bold mb-1">{{ repayment?.full_name }}</div>
        <div>{{ repayment?.phone }}</div>
        <button (click)="viewCustomerInfo()" class="btn btn-outline-secondary btn-sm mt-2">View</button>
      </div>
    </div>

    <div class="mb-4 carousel-hack">
      <owl-carousel-o [options]="carouselOption">
        <ng-template carouselSlide>
          <div class="card bg-light-red d-flex flex-row align-items-center">
            <div class="me-auto">
              <div class="fw-bold mb-1">
                {{ repayment?.total_repayment_amount | currency:'NGN':'₦':'0.0' }}
                on {{ repayment?.created_at | date: 'mediumDate' }}
              </div>
              <small>
                Total repayment
              </small>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>

    <div class="d-flex flex-row justify-content-around text-center d-md-none card bg-off-white mb-4">
      <div (click)="modal = 'schedule'" class="d-flex flex-column align-items-center cursor-pointer">
        <i class="fa fa-stream text-danger" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Schedule </small>
      </div>
      <!-- <div (click)="modal = 'invoice'" class="d-flex flex-column align-items-center cursor-pointer">
        <i class="fa fa-receipt text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Invoice</small>
      </div> -->
      <a class="d-flex flex-column align-items-center cursor-pointer"
         [href]="'https://api.whatsapp.com/send?phone=+234' + repayment.phone.substring(1)" target="_blank">
        <i class="fab fa-whatsapp text-success" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Chat customer</small>
      </a>
    </div>

    <div class="card p-0">
      <ul class="mobile-menu">
        <li class="border-bottom">
          <a (click)="modal = 'items'" class="font-size-md">
            <i class="icon fa fa-list text-warning"></i>View items
          </a>
        </li>
        <li class="border-bottom">
          <a (click)="viewCustomerInfo()" class="font-size-md">
            <i class="icon fa fa-user text-info"></i>Customer info
          </a>
        </li>
        <li>
          <a (click)="fetchPaymentHistory()" class="font-size-md">
            <i class="icon fa fa-receipt text-danger"></i>Payment history
          </a>
        </li>
      </ul>
    </div>

    <!-- <div class="card bg-light-blue">
      <div class="fw-bold mb-2">Generate a link for payment</div>
      <div>
        <button (click)="generatePaymentLink($event)" class="btn btn-sm btn-outline-primary mt-1">
          Generate link
          <i *ngIf="generating" class="fa fa-circle-notch fa-spin ms-1"></i>
        </button>
      </div>
    </div> -->
  </div>

  <div class="mt-4 bottom-actions px-4">
    <button (click)="recordPayment()" class="btn btn-primary checkoutBtn flex-grow-1 ms-1">
      Record Payment
    </button>
  </div>
</div>

<app-dialog *ngIf="modal === 'schedule'" title="Repayment schedule" (close)="modal = ''">
  <table class="table table-bordered w-100">
    <tbody>
    <tr>
      <th class="py-2">Date</th>
      <th class="py-2">Amount</th>
      <th class="py-2">Status</th>
    </tr>
    <tr *ngFor="let schedule of repayment?.schedule; index as i">
      <td>{{ schedule?.date | date: "shortDate" }}</td>
      <td>{{ schedule?.amount | currency:'NGN':'₦':'0.0' }}</td>
      <td *ngIf="schedule?.amount == schedule?.amount_paid; else NotPaid">
        <i class="fa fa-check text-success"></i>
      </td>
      <ng-template #NotPaid>
        <td>
            <span class="badge bg-danger">
              Not Paid
            </span>
        </td>
      </ng-template>
    </tr>
    </tbody>
  </table>

  <div *ngIf="loading" class="loading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'items'" title="Items purchased" (close)="modal = ''">
  <div class="card p-0 mb-4" *ngFor="let item of repayment.items; index as i">
    <div class="card-img-top border-bottom">
      <img [src]="item?.primary_picture" alt="" style="height: 227px;" class="fit-contain">
    </div>
    <div class="card-body">
      <div class="title fw-bold mb-1">{{ item?.name}}</div>
      <div class="title">
        {{ _general.formatCurrency(item?.upfront_amount) }}
      </div>
    </div>
  </div>
</app-dialog>

<!-- MODALS -->
<app-dialog *ngIf="modal === 'record-payment'" (close)="modal = ''" [nested]="true">
  <div *ngIf="partPayment == 0">
    <h5 class="title mb-5">Record payment</h5>
    <div class="card d-flex flex-row align-items-center mb-3 px-4 py-3"
         *ngFor="let repaymentSchedule of repayment?.schedule; index as i">
      <div class="flex-grow-1">
        <div class="title fw-bold">{{ repaymentSchedule?.amount | currency:'NGN':'₦':'0.0' }}</div>
        <small class="text-muted">{{repaymentSchedule?.date | date:'mediumDate' }}</small>
      </div>

      <div *ngIf="repaymentSchedule?.is_paid == 0; else hasPaid" class="custom-control custom-switch position-relative">
        <input [attr.id]="'change-pass-checkbox-' + repaymentSchedule?.id" [value]="repaymentSchedule?.id"
               type="checkbox" class="custom-control-input">
        <label (click)="checkThisInput($event, repaymentSchedule?.id, repaymentSchedule)"
               class="custom-control-label cursor-pointer" for="onBalance"></label>
        <span class="position-absolute cover"></span>
      </div>

      <ng-template #hasPaid>
        <i class="fa fa-check text-success me-3"></i>
      </ng-template>
    </div>
    <div class="mt-5">
      <button [disabled]="schedule_ids.length < 1" (click)="markAsPaid($event)" class="btn btn-success">
        Make Payment
      </button>
    </div>
  </div>

  <div *ngIf="partPayment == 1">
    <form [formGroup]="partPaymentForm" (ngSubmit)="submitPartPayment(partPaymentForm, $event)"
          class="product-single-details">
      <h5 class="title mb-5">Part Payment</h5>
      <div class="form-group required mt-2">
        <label for="acc-mname">Amount Paid ?</label>
        <input type="tel" onfocus="this.type='tel'" (blur)="modifyAmount($event)" formControlName="amount_paid"
               class="form-control" placeholder="ex: 55000" id="amountPaid" name="acc-mname">
      </div>
      <div class="form-group required mt-2">
        <label for="acc-mname">Date Paid?</label>
        <input onfocus="this.type='date'" onblur="this.type='text'" formControlName="date_paid" type="text"
               class="form-control" placeholder="ex: 55000" id="acc-mname" name="acc-mname">
      </div>

      <button type="submit" [disabled]="!partPaymentForm.valid" class="mt-4 btn btn-success btn-lg">
        Save
      </button>
    </form>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'customer'" (close)="modal = ''" [nested]="true" title="Customer details">
  <app-view-customer-data [customer]="repayment">
  </app-view-customer-data>
</app-dialog>

<app-dialog *ngIf="modal === 'repayment-history'" (close)="modal = ''" [nested]="true">
  <h5 class="title">Repayment History</h5>
  <table class="table table-bordered table-striped">
    <tr class="border-bottom">
      <th class="py-2">Date</th>
      <th class="py-2">Amount</th>
      <th class="py-2">Source</th>
    </tr>
    <tr *ngFor="let history of paymentHistoryForCurrentRepayment; index as i" class="border-bottom">
      <td class="py-2">{{ history?.date_paid | date: "dd/MM/yyyy" }}</td>
      <td class="py-2">{{ _general.formatCurrency(history?.amount_paid) }} </td>
      <td class="py-2" *ngIf="history?.source == null">No Card added</td>
      <td class="py-2" *ngIf="history?.source == 0">Card</td>
      <td class="py-2" *ngIf="history?.source == 1">Bank</td>
    </tr>
  </table>
</app-dialog>

<app-dialog *ngIf="modal === 'payment-link'" (close)="modal = ''" [nested]="true">
  <div class="text-center">
    <h5 class="title mb-4">Payment Link:</h5>
    <h6 class="text-success link">{{ paymentLink }}</h6>
    <button (click)="copyLink($event)" class="btn btn-outline-secondary btn-sm">Copy</button>
  </div>
</app-dialog>
