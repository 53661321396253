import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { LoginComponent } from './components/auth/login/login.component';
import { IsLoggedInGuard } from './is-logged-in.guard';
import { OnboardingComponent } from './components/auth/onboarding/onboarding.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { ProductsComponent } from './components/allitems/products.component';
import { RepaymentsComponent } from './components/repayments/repayments.component';
import { SalesComponent } from './components/sales/sales.component';
import { CustomersComponent } from './components/customers/customers.component';
import { RepaymentReportComponent } from './components/repayment-report/repayment-report.component';
import { RepaymentsReportsTablesComponent } from './components/repayments-reports-tables/repayments-reports-tables.component';
import { RecentRequestsComponent } from './components/recent-requests/recent-requests.component';
import { MoreLinksComponent } from './components/more-links/more-links.component';
import { SettingsComponent } from './components/settings-component/settings.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { OnboardingWhatsappComponent } from './components/auth/onboarding-whatsapp/onboarding-whatsapp.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PricingComponent } from './components/pricing/pricing.component';

const routes: Routes = [
  {
    path: '',
    component: SplashScreenComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'onboard',
    component: OnboardingComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'onboard/whatsapp',
    component: OnboardingWhatsappComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'repayments',
    component: RepaymentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'repayment-reports',
    component: RepaymentReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'repayment-reports/:id',
    component: RepaymentsReportsTablesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'requests',
    component: RecentRequestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'more',
    component: MoreLinksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/:section',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
