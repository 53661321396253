<app-header></app-header>

<main class="nav-margin">
  <div class="container pt-3 align-items-stretch d-none d-md-block">
    <div class="row align-items-stretch">
      <div class="col-lg-6">
        <div class="card big-card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'default'">
          <h3 class="title mb-2">Add products</h3>
          <div class="text-muted mb-3">Pull from instagram</div>
          <div>
            <button (click)="modal = 'create-product-options'" class="btn btn-outline-primary">
              <i class="fa fa-plus me-2 fs-6"></i>
              Add products
            </button>
          </div>
          <img class="ms-auto mt-4 overflow-card-image" src="assets/images/instagram-phone.png" alt="Illustration">
          <br><br><br><br><br><br><br><br>
        </div>

        <div class="card big-card mb-4 overflow-hidden bg-light-green" *ngIf="top === 'whatsapp'">
          <h3 class="title mb-2">Sell directly on whatsapp</h3>
          <div class="text-muted mb-3">Allow customers to buy on credit directly on WhatsApp</div>
          <div>
            <button (click)="copyWhatsappLink()" class="btn btn-outline-success">
              <i class="fa fa-copy me-2 fs-6"></i>
              Copy link
            </button>
          </div>
          <br><br><br><br>
          <i class="fab fa-whatsapp ms-auto text-success fa-6x"></i>
        </div>

        <div class="card big-card mb-4 overflow-hidden bg-light-orange" *ngIf="top === 'other-platforms'">
          <h3 class="title mb-2">Add your first products</h3>
          <div class="text-muted mb-3">Pull products from your existing stores, we got you covered</div>
          <div>
            <button (click)="modal = 'create-product-options'" class="btn btn-outline-primary">
              <i class="fa fa-plus me-2 fs-6"></i> Add products
            </button>
          </div>
          <br><br><br><br>
          <div class="d-flex flex-wrap">
            <img
              *ngFor="let name of _auth.merchant.other_platforms.split(', ')" class="ms-3 fit-contain" width="100"
              [src]="getProductMethod(name).image" [alt]="name"
            />
          </div>
        </div>

        <div class="card big-card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'setup-order'">
          <h3 class="title mb-2">Sell on credit In-store</h3>
          <div class="text-muted mb-3">Create an in-store order</div>
          <div>
            <button (click)="launchModalIfProfileComplete('create-order')" class="btn btn-outline-primary">
              Setup an order <i class="fa fa-arrow-right ms-2 fs-6"></i>
            </button>
          </div>
          <img class="w-50 ms-auto mt-4" src="assets/images/svg/online-shopping.svg" alt="Illustration">
        </div>

        <div class="card big-card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'instagram'">
          <h3 class="title mb-2">Sell from instagram</h3>
          <div class="text-muted mb-3">Pull your instagram posts</div>
          <div>
            <button (click)="modal = 'instagram'" class="btn btn-outline-primary">
              <i class="fa fa-plus me-2 fs-6"></i> Add products
            </button>
          </div>
          <img class="ms-auto mt-4 overflow-card-image" src="assets/images/instagram-phone.png" alt="Illustration">
          <br><br><br><br><br><br><br><br>
        </div>

        <div class="card big-card bg-light-blue mb-4" *ngIf="!top || top === 'share'">
          <h3 class="title mb-2">Sell on credit</h3>
          <div class="mb-4">Share link to store</div>
          <div>
            <button (click)="modal = 'share'" class="btn btn-outline-secondary">
              <i class="fa fa-share-alt me-2 fs-6"></i> Share
            </button>
          </div>
          <img class="w-50 ms-auto mt-4" src="assets/images/svg/social-girl.svg" alt="Illustration">
        </div>

        <div class="card big-card bg-light-blue mb-4" *ngIf="top === 'single-product' && _store.products?.items[0]">
          <h3 class="title mb-0" *ngIf="_store.products.items[0].no_of_months">
            {{ _store.products.items[0].price | currency:'NGN':'₦':'0.0' }}
            for {{ _store.products.items[0].no_of_months}} month(s)
          </h3>
          <h3 class="title mb-0" *ngIf="!_store.products.items[0].no_of_months">
            {{ _store.products.items[0].price | currency:'NGN':'₦':'0.0' }} outright
          </h3>
          <div class="mb-3">{{ _store.products.items[0].name }}</div>
          <div>
            <button (click)="shareProductLink(_store.products.items[0])" class="btn btn-outline-secondary">
              <i class="fa fa-share-alt me-2 fs-6"></i> Share
            </button>
          </div>
          <div style="width: 150px" class="ms-auto mt-5">
            <app-square-box>
              <img
                class="w-100 h-100 rounded-circle fit-cover" [src]="_store.products.items[0].primary_picture"
                alt="Illustration"
              />
            </app-square-box>
          </div>
        </div>

        <app-loan-status></app-loan-status>

        <app-incomplete-profile-display></app-incomplete-profile-display>
      </div>

      <div class="col-lg-6">
        <app-dashboard-add-product-options (modal)="modal = $event"></app-dashboard-add-product-options>

        <!--        <app-dashboard-whatsapp-integration (modal)="modal = $event"></app-dashboard-whatsapp-integration>-->

        <ng-template [ngIf]="!_auth.whatsappOnly">
          <div class="card mb-4">
            <h5 class="title m-0">Your online credit store</h5>
            <p class="mt-1">Choose from beautiful templates</p>
            <div class="d-flex">
              <button (click)="modal = 'online-store'" class="btn btn-outline-secondary">
                <i class="fa fa-paint-brush me-2"></i>
                Customize
              </button>
              <button (click)="copyStoreLink()" class="btn btn-outline-secondary ms-2">
                <i class="fa fa-clone me-2"></i>
                Copy store link
              </button>
            </div>
            <div class="d-flex mt-4">
              <img src="assets/images/online-shopping-illustration.jpg" alt="" style="width: 300px" class="ms-auto">
            </div>
          </div>

          <div class="card mb-4 bg-off-white" *ngIf="_auth.merchant.partnership == 0">
            <h5 class="title mb-1">Activate bank partnership</h5>
            <p class="mb-0">We can help get started</p>
            <div class="mt-4">
              <button (click)="modal = 'activate-partnership'" class="btn btn-outline-primary btn-sm">
                Activate
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <!-- Floating action button -->
  <button (click)="openSupportWhatsappLink()" class="floating-action-button d-md-none">
    <i class="fab fa-whatsapp fa-2x"></i>
  </button>

  <div class="d-md-none">
    <div class="container">
      <!-- Top -->
      <div class="card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'default'">
        <h5 class="title mb-1">Add products</h5>
        <div class="text-muted mb-3">Pull from instagram</div>
        <div>
          <button (click)="modal = 'create-product-options'" class="btn btn-outline-primary btn-sm">
            <i class="fa fa-plus me-2 fs-6"></i> Add products
          </button>
        </div>
        <br><br><br><br>
        <img class="ms-auto mt-4 overflow-card-image" src="assets/images/instagram-phone.png" alt="Illustration">
      </div>

      <div class="card mb-4 overflow-hidden bg-light-green" *ngIf="top === 'whatsapp'">
        <h5 class="title mb-2">Shop via whatsapp</h5>
        <div class="text-muted mb-3">Allow customers pay up to thrice on WhatsApp</div>
        <div>
          <button (click)="launchModal('whatsapp-options')" class="btn btn-outline-success">
            Explore options <i class="fa fa-long-arrow-alt-right ms-2 fs-6"></i>
          </button>
        </div>
        <br><br><br><br>
        <i class="fab fa-whatsapp ms-auto text-success fa-4x"></i>
      </div>

      <div class="card mb-4 overflow-hidden bg-light-orange" *ngIf="top === 'other-platforms'">
        <h5 class="title mb-2">Add your first products</h5>
        <div class="text-muted mb-3">Pull products from your existing stores, we got you covered</div>
        <div>
          <button (click)="modal = 'create-product-options'" class="btn btn-outline-primary">
            <i class="fa fa-plus me-2 fs-6"></i>
            Add products
          </button>
        </div>
        <br><br><br><br>
        <div class="d-flex flex-wrap">
          <img
            *ngFor="let name of _auth.merchant.other_platforms.split(', ')" class="ms-3 fit-contain" width="100"
            [src]="getProductMethod(name).image" [alt]="name"
          />
        </div>
      </div>

      <div class="card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'setup-order'">
        <h5 class="title mb-2">Sell on credit In-store</h5>
        <div class="text-muted mb-3">Create an in-store order</div>
        <div>
          <button (click)="launchModalIfProfileComplete('create-order')" class="btn btn-outline-primary">
            Setup an order <i class="fa fa-arrow-right ms-2 fs-6"></i>
          </button>
        </div>
        <img class="w-50 ms-auto mt-4" src="assets/images/svg/online-shopping.svg" alt="Illustration">
      </div>

      <div class="card mb-4 overflow-hidden bg-light-blue" *ngIf="top === 'instagram'">
        <h5 class="title mb-1">Sell from instagram</h5>
        <div class="text-muted mb-3">Pull your instagram posts</div>
        <div>
          <button (click)="modal = 'instagram'" class="btn btn-outline-primary btn-sm">
            <i class="fa fa-plus me-2 fs-6"></i> Add products
          </button>
        </div>
        <br><br><br><br>
        <img class="ms-auto mt-4 overflow-card-image" src="assets/images/instagram-phone.png" alt="Illustration">
      </div>

      <div class="card bg-light-blue mb-4" *ngIf="!top || top === 'share'">
        <h5 class="title mb-0">Sell on credit</h5>
        <div class="mb-3">Share link to store</div>
        <div>
          <button (click)="modal = 'share'" class="btn btn-outline-secondary mt-2">
            <i class="fa fa-share-alt me-2 fs-6"></i> Share
          </button>
        </div>
        <img class="w-50 ms-auto mt-4" src="assets/images/svg/social-girl.svg" alt="Illustration">
      </div>

      <div class="card bg-light-blue mb-4" *ngIf="top === 'single-product' && _store.products?.items[0]">
        <h5 class="title mb-0" *ngIf="_store.products.items[0].no_of_months">
          {{ _store.products.items[0].price | currency:'NGN':'₦':'0.0' }} for {{ _store.products.items[0].no_of_months}}
          month(s)
        </h5>
        <h5 class="title mb-0" *ngIf="!_store.products.items[0].no_of_months">
          {{ _store.products.items[0].price | currency:'NGN':'₦':'0.0' }} outright
        </h5>
        <div class="mb-3">{{ _store.products.items[0].name }}</div>
        <div>
          <button
            (click)="shareProductLink(_store.products.items[0])"
            class="btn btn-outline-secondary"
          >
            <i class="fa fa-share-alt me-2 fs-6"></i>
            Share
          </button>
        </div>
        <div style="width: 100px" class="ms-auto mt-5">
          <app-square-box>
            <img
              class="w-100 h-100 rounded-circle fit-cover" [src]="_store.products.items[0].primary_picture"
              alt="Illustration"
            />
          </app-square-box>
        </div>
      </div>

      <div class="container">
        <app-dashboard-add-product-options (modal)="modal = $event"></app-dashboard-add-product-options>
      </div>

      <!--      <app-dashboard-whatsapp-integration (modal)="modal = $event"></app-dashboard-whatsapp-integration>-->

      <div *ngIf="!_auth.whatsappOnly" class="card mb-4 d-none d-md-flex flex-column">
        <div class="d-flex align-items-center">
          <i class="fa fa-store fa-2x text-primary"></i>
          <h5 class="title m-0 ms-3">Online store</h5>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-4">
          <button (click)="_general.copyLink(_auth.merchant.global_catalogue_link)" class="btn btn-link ms-auto">
            <i class="fa fa-clone"></i>
          </button>
          <a
            [href]="_auth.merchant.global_catalogue_link" target="_blank"
            class="btn btn-outline-secondary ms-2"
          >
            View store
          </a>
          <button (click)="launchModalIfProfileComplete('online-store')" class="btn btn-outline-primary ms-2">
            Goto settings
          </button>
        </div>
      </div>

      <div *ngIf="!_auth.whatsappOnly" class="card mb-4">
        <h5 class="title m-0">Your online credit store</h5>
        <p class="mt-1">Choose from beautiful templates</p>
        <div class="d-flex">
          <button (click)="modal = 'online-store'" class="btn btn-outline-secondary">
            <i class="fa fa-paint-brush me-2"></i>
            Customize
          </button>
          <button (click)="copyStoreLink()" class="btn btn-outline-secondary ms-2">
            <i class="fa fa-clone me-2"></i>
            Copy store link
          </button>
        </div>
        <div class="d-flex mt-4">
          <img src="assets/images/online-shopping-illustration.jpg" alt="" style="width: 200px" class="ms-auto">
        </div>
      </div>

      <app-loan-status *ngIf="!_auth.whatsappOnly"></app-loan-status>
    </div>

    <div class="container">
      <app-incomplete-profile-display></app-incomplete-profile-display>
    </div>
  </div>
</main>

<app-bottom-nav></app-bottom-nav>

<!-- Share -->
<app-dialog *ngIf="modal === 'share'" [nested]="true" (close)="modal = ''" title="Share" size="sm">
  <div class="row">
    <div class="col-md-6">
      <div (click)="copyWhatsappLink()" class="card text-center p-5 cursor-pointer mb-4">
        <i class="fab fa-whatsapp text-success fa-4x"></i>
        <div class="mt-4">
          Copy whatsapp link
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div (click)="copyStoreLink()" class="card text-center p-5 cursor-pointer">
        <i class="fa fa-store text-primary fa-4x"></i>
        <div class="mt-4">
          Copy store link
        </div>
      </div>
    </div>
  </div>
</app-dialog>

<app-dialog
  *ngIf="modal === 'business-loan'" (close)="modal = ''" [close-button]="false" title="Business Loan" #dialogBL
>
  <app-business-loan (close)="dialogBL.dismiss()"></app-business-loan>
</app-dialog>

<app-dialog
  *ngIf="modal === 'activate-partnership'" (close)="modal = ''" [close-button]="false"
  title="Activate bank partnership"
>
  <app-settings-bank-partnership [merchant]="_auth.merchant"></app-settings-bank-partnership>
</app-dialog>

<app-dialog *ngIf="modal === 'create-order'" (close)="modal = ''" [fullscreen]="true" title="Create order">
  <app-create-order></app-create-order>
</app-dialog>

<app-dialog *ngIf="modal === 'reg-create-product'" (close)="this.modal = ''" [close-button]="false">
  <div class="px-5 pt-5 text-center">
    <img src="/assets/images/svg/deliveries.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
    <h5 class="title mt-5">
      Welcome to your store
    </h5>
    <p style="max-width: 400px; margin: auto;">
      Create products, manage orders, repayments, sales and customers,
      click add products below to begin adding your products
    </p>
    <button (click)="finishOnboarding()" class="btn btn-primary mt-5 mb-3">
      Add products
    </button>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'reg-share-store'" (close)="this.modal = ''" [close-button]="false">
  <div class="px-5 pt-5 text-center">
    <img src="/assets/images/svg/social-girl.svg" style="max-width: 300px;" alt="illustration" class="mx-auto">
    <h5 class="title mt-5">
      Share your store
    </h5>
    <p style="max-width: 400px; margin: auto;">
      Click the button below to share your store online
    </p>
    <button class="btn btn-primary mt-5 mb-4">
      Share store
    </button>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'reg-share-product'" (close)="this.modal = ''" [close-button]="false">
  <div class="text-center">
    <h5 class="mb-4">You have one product</h5>
    <img
      [src]="_store.registration.product.primary_picture" style="max-width: 150px;border: 1px solid #8ea5e180;"
      alt="illustration" class="mx-auto rounded-circle"
    />
    <h5 class="mb-1 mt-4">{{ _store.registration.product.name }}</h5>
    <p>{{ _store.registration.product.price | currency:'NGN':'₦':'0.0' }}</p>
    <button (click)="shareProductLink(_store.registration.product)" class="btn btn-primary">
      Share product
    </button>
  </div>
</app-dialog>

<app-dialog *ngIf="modal === 'create-product-options'" (close)="modal = ''" [fullscreen]="true" title="Setup a product">
  <app-create-product-options (open)="modal = $event"></app-create-product-options>
</app-dialog>

<app-dialog *ngIf="modal === 'create-product'" (close)="modal = ''" [close-button]="false" #dialogCreate>
  <app-create-item
    [inputFromAllItems]="inputToCreateItemComponent" (shareAProductLink)="shareProductLink($event)"
    (done)="dialogCreate.dismiss(); createProductDone($event)"
  >
  </app-create-item>
</app-dialog>

<app-dialog *ngIf="modal === 'instagram'" (close)="modal = ''" [close-button]="false" #dialogIG>
  <app-instagram [merchant]="_auth.merchant" (close)="dialogIG.dismiss()"></app-instagram>
</app-dialog>

<app-dialog *ngIf="modal === 'konga'" (close)="modal = ''">
  <app-add-products-from-konga></app-add-products-from-konga>
</app-dialog>

<app-dialog *ngIf="modal === 'jiji'" (close)="modal = ''">
  <app-add-products-from-jiji></app-add-products-from-jiji>
</app-dialog>

<app-dialog *ngIf="modal === 'jumia'" (close)="modal = ''">
  <app-add-products-from-jumia></app-add-products-from-jumia>
</app-dialog>

<app-dialog *ngIf="modal === 'flutterwave'" (close)="modal = ''">
  <app-add-products-from-flutterwave></app-add-products-from-flutterwave>
</app-dialog>

<app-dialog *ngIf="modal === 'creditclan'" (close)="modal = ''">
  <app-add-products-from-creditclan></app-add-products-from-creditclan>
</app-dialog>

<app-dialog *ngIf="modal === 'online-store'" (close)="modal = ''" title="Online store">
  <app-online-store></app-online-store>
</app-dialog>

<app-dialog *ngIf="modal === 'whatsapp'" (close)="modal = ''">
  <app-add-products-from-whatsapp (connect)="modal = 'connect-whatsapp'"></app-add-products-from-whatsapp>
</app-dialog>

<app-dialog *ngIf="modal === 'connect-whatsapp'" (close)="modal = ''" #cwDialog>
  <app-connect-whatsapp (close)="cwDialog.dismiss()" (done)="modal = 'manage-whatsapp'"></app-connect-whatsapp>
</app-dialog>

<app-dialog *ngIf="modal === 'manage-whatsapp'" (close)="modal = ''">
  <app-manage-whatsapp-connection
    (settings)="modal = 'whatsapp-settings'"
    (connect)="modal = 'connect-whatsapp'"
  ></app-manage-whatsapp-connection>
</app-dialog>

<app-dialog *ngIf="modal === 'whatsapp-settings'" (close)="modal = ''" title="Whatsapp bot settings">
  <app-whatsapp-settings (connect)="modal = 'connect-whatsapp'"></app-whatsapp-settings>
</app-dialog>


<app-dialog *ngIf="modal === 'whatsapp-options'" (close)="modal = ''" title="Explore options" #dialogOptions>
  <div class="d-flex flex-column h-100">
    <div
      (click)="copyWhatsappLink()"
      class="card py-4 px-4 d-flex align-items-center flex-row cursor-pointer mb-3"
    >
      <i class="fa fa-copy text-primary me-4" style="font-size: 1.2rem"></i>
      <div class="flex-grow-1">
        <div class="title mb-0 fw-bold">Copy whatsapp link</div>
        <small>Share whatsapp bot link to customers</small>
      </div>
    </div>

    <div
      (click)="dialogOptions.dismiss(launchModal, this, ['manage-whatsapp'])"
      class="card py-4 px-4 d-flex align-items-center flex-row cursor-pointer mb-3"
    >
      <i class="fa fa-tools text-danger me-4" style="font-size: 1.2rem"></i>
      <div class="flex-grow-1">
        <div class="title mb-0 fw-bold">Manage connection</div>
        <small>Enable/disable Whatsapp connection</small>
      </div>
    </div>

    <div
      (click)="dialogOptions.dismiss(launchModal, this, ['whatsapp-settings'])"
      class="card py-4 px-4 d-flex align-items-center flex-row cursor-pointer mb-3"
    >
      <i class="fa fa-cog text-warning me-4" style="font-size: 1.2rem"></i>
      <div class="flex-grow-1">
        <div class="title mb-0 fw-bold">Manage bot settings</div>
        <small>Change bot name and categories</small>
      </div>
    </div>
  </div>
</app-dialog>
