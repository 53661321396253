<div style="min-height: 300px;">
  <h5 class="title mb-5">
    Search orders
  </h5>

  <div class="d-flex align-items-center" id="searchDiv">
    <input
      type="text" id="searchInput" (keydown.enter)="search()" autocomplete="off"
      class="form-control override rounded-full form-control-lg m-0 mb-1" placeholder="Search..." [(ngModel)]="query"
    />
    <button (click)="search()" class="search-button ms-2">
      <i class="fa fa-search text-primary"></i>
    </button>
  </div>

  <div *ngIf="!searching && !searchResults.length">
    <br><br><br><br><br><br><br><br>
    <img class="w-50 ms-auto" src="/assets/images/svg/file-searching.svg" alt="Illustration"
         style="max-width: 300px;">
  </div>

  <div *ngIf="searching" class="row mt-4">
    <div class="col-12 mb-3 d-flex flex-row align-items-center" *ngFor="let i of [1,2]">
      <div class="card p-0 skeleton fit-content" style="max-width: 60px;">
        <img [src]="'assets/images/plus.svg'" alt="">
      </div>
      <div class="flex-grow-1 px-3">
        <div class="title skeleton mb-1">Product name xxx</div>
        <small class="skeleton">N200,000</small>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="!searching && searchResults.length">
    <div class="w-100" *ngFor="let request of searchResults">
      <div (click)="view.emit(request)"
           class="d-flex flex-row align-items-center border-bottom pb-3 mb-3 cursor-pointer">
        <img [src]="request?.items[0]?.primary_picture" alt="product" class="ms-2 me-3 rounded img-mobile"
             style="max-width: 80px;"/>
        <div class="flex-grow-1">
          <h5 class="product-title mb-1 d-flex align-items-center">
            <span>{{ request?.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}</span>
          </h5>
          <h5 class="product-title fw-normal mb-1">
            <i class="fa fa-user-circle text-primary me-2 d-none d-md-inline-block"></i>
            {{ request?.full_name ? request?.full_name + " • " : "" }}
            {{ request?.phone_no || request?.email }}
          </h5>
          <div class="mt-2">
            <span *ngIf="!request?.no_of_months" class="badge bg-primary px-2 me-2">Outright</span>
            <span *ngIf="request?.no_of_months" class="badge bg-danger px-2 me-2">Credit</span>
            <span class="badge bg-secondary me-2">{{ request?.items.length }} Items</span>
            <span *ngIf="request?.upfront_paid" class="badge bg-success">
                <i class="fa fa-check me-2"></i> Upfront paid
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
