import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthResponse } from 'src/app/models/auth';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  private from: string;

  constructor(
    private fb: FormBuilder,
    private _general: GeneralService,
    private _auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      const { from } = params;
      this.from = from;
    });
    this.loginForm = this.fb.group({
      email_phone: ['', [Validators.required, Validators.pattern(/(^[0]\d{10}$)|(^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$)/)]],
      password: ['', Validators.required]
    });
  }

  async submitForm() {
    const { email_phone, ...rest } = this.loginForm.value;
    const payload = {
      ...rest,
      [!!parseInt(email_phone, 10) ? 'phone' : 'email']: email_phone
    };
    this.loading = true;
    try {
      const data: AuthResponse = await this._auth.login(payload);
      this._auth.saveToken(data.token);
      this._auth.merchantID = data.merchant.toString();
      await this.router.navigate([this.from || '/dashboard']);
    } catch (e) {
      this.loading = false;
      this._general.notify('error', e?.error?.message || 'Unable to login');
    }
  }
}
