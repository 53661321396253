<app-settings-homepage-template [merchant]="merchant" (options)="modal = 'options'" class="d-block mb-4"></app-settings-homepage-template>

<app-dialog *ngIf="modal === 'banner'" (close)="modal = 'options'" title="Customize Homepage Banner" size="sm" [nested]="true">
  <app-settings-homepage-banner [merchant]="merchant" class="d-block mb-5"></app-settings-homepage-banner>
</app-dialog>

<app-dialog *ngIf="modal === 'sections'" (close)="modal = 'options'" title="Customize Homepage Sections" size="sm" [nested]="true">
  <app-settings-homepage-sections [merchant]="merchant" class="d-block"></app-settings-homepage-sections>
</app-dialog>

<app-dialog *ngIf="modal === 'collections'" (close)="modal = 'options'" title="Customize Collections Page" size="sm" [nested]="true">
  <app-settings-collection-items [merchant]="merchant" class="d-block mb-4"></app-settings-collection-items>
  <app-settings-collection-filter [merchant]="merchant" class="d-block mb-4"></app-settings-collection-filter>
  <app-settings-collection-banner [merchant]="merchant" class="d-block"></app-settings-collection-banner>
</app-dialog>

<app-dialog *ngIf="modal === 'details'" (close)="modal = 'options'" title="Customize Item Details Page" size="sm" [nested]="true">
  <app-settings-item-details [merchant]="merchant" class="d-block mb-4"></app-settings-item-details>
  <app-settings-item-details-template [merchant]="merchant" class="d-block"></app-settings-item-details-template>
</app-dialog>

<app-dialog *ngIf="modal === 'color'" (close)="modal = 'options'" title="Customize Theme Color" size="sm" [nested]="true">
  <app-settings-theme-color [merchant]="merchant"></app-settings-theme-color>
</app-dialog>

<app-dialog *ngIf="modal === 'options'" (close)="modal = ''" title="Customize your template" #optionsDialog size="sm" [nested]="true">
  <div class="row">
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'banner'"
        class="d-flex flex-column align-items-center cursor-pointer py-4 bg-light rounded-3 border mb-4"
      >
        <i class="fa fa-image text-info" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Banner</small>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'sections'"
        class="d-flex flex-column align-items-center cursor-pointer py-4 bg-light rounded-3 border mb-4"
      >
        <i class="fa fa-th-list text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Sections</small>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'collections'"
        class="d-flex flex-column align-items-center cursor-pointer py-4 bg-light rounded-3 border mb-4"
      >
        <i class="fa fa-th-large text-warning" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Collections</small>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'details'"
        class="d-flex flex-column align-items-center cursor-pointer py-4 bg-light rounded-3 border mb-4"
      >
        <i class="fa fa-th text-danger" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Item Details</small>
      </div>
    </div>
    <div class="col-4">
      <div
        (click)="optionsDialog.dismiss(); modal = 'color'"
        class="d-flex flex-column align-items-center cursor-pointer py-4 bg-light rounded-3 border mb-4"
      >
        <i class="fa fa-paint-brush text-primary" style="font-size: 1.4rem;"></i>
        <small class="mt-2">Theme color</small>
      </div>
    </div>
  </div>
</app-dialog>

<!--<div class="accordion" id="online-store-accordion">-->
<!--  <div class="accordion-item">-->
<!--    <h2 class="accordion-header" id="home-page-heading">-->
<!--      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#home-page" aria-controls="home-page">-->
<!--        Home page-->
<!--      </button>-->
<!--    </h2>-->
<!--    <div id="home-page" class="accordion-collapse collapse show" aria-labelledby="home-page-heading" data-bs-parent="#online-store-accordion">-->
<!--      <div class="accordion-body">-->
<!--        <app-settings-homepage-banner [merchant]="merchant" class="d-block mb-5"></app-settings-homepage-banner>-->
<!--        <app-settings-homepage-template [merchant]="merchant" class="d-block mb-4"></app-settings-homepage-template>-->
<!--        <app-settings-homepage-sections [merchant]="merchant" class="d-block"></app-settings-homepage-sections>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="accordion-item">-->
<!--    <h2 class="accordion-header" id="collections-header">-->
<!--      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collections" aria-controls="collections">-->
<!--        Collections page-->
<!--      </button>-->
<!--    </h2>-->
<!--    <div id="collections" class="accordion-collapse collapse" aria-labelledby="collections-header" data-bs-parent="#online-store-accordion">-->
<!--      <div class="accordion-body">-->
<!--        <app-settings-collection-items [merchant]="merchant" class="d-block mb-4"></app-settings-collection-items>-->
<!--        <app-settings-collection-filter [merchant]="merchant" class="d-block mb-4"></app-settings-collection-filter>-->
<!--        <app-settings-collection-banner [merchant]="merchant" class="d-block"></app-settings-collection-banner>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="accordion-item">-->
<!--    <h2 class="accordion-header" id="item-details-header">-->
<!--      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#item-details" aria-controls="item-details">-->
<!--        Item details page-->
<!--      </button>-->
<!--    </h2>-->
<!--    <div id="item-details" class="accordion-collapse collapse" aria-labelledby="item-details-header" data-bs-parent="#online-store-accordion">-->
<!--      <div class="accordion-body">-->
<!--        <app-settings-item-details [merchant]="merchant" class="d-block mb-4"></app-settings-item-details>-->
<!--        <app-settings-item-details-template [merchant]="merchant" class="d-block"></app-settings-item-details-template>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="accordion-item">-->
<!--    <h2 class="accordion-header" id="theme-color-heading">-->
<!--      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#theme-color" aria-controls="theme-color">-->
<!--        Theme color-->
<!--      </button>-->
<!--    </h2>-->
<!--    <div id="theme-color" class="accordion-collapse collapse" aria-labelledby="theme-color-heading" data-bs-parent="#online-store-accordion">-->
<!--      <div class="accordion-body">-->
<!--        <app-settings-theme-color [merchant]="merchant"></app-settings-theme-color>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<app-settings-tags></app-settings-tags>-->
