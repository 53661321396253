import { Component, OnInit } from '@angular/core';
import { clothSizes, genders, shippingSources } from '../../lib/variations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { ProductCategories } from '../../models/categories';
import { MerchantItems } from '../../models/items';
import { GeneralService } from '../../services/general.service';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-add-products-from-creditclan',
  templateUrl: './add-products-from-creditclan.component.html',
  styleUrls: ['./add-products-from-creditclan.component.scss']
})
export class AddProductsFromCreditclanComponent implements OnInit {
  searchForm: FormGroup;
  loading = false;
  search = false;
  query = '';
  slug = '';
  keyword = '';
  items: MerchantItems[] = [];
  displayItems: MerchantItems[] = [];
  selected = [];
  pagination = {
    page: 1, next: 0, end: false, pages: 0, count: 0
  };
  history: string[] = [];

  selectedSizes = [];
  selectedGenders = [];

  categories = ProductCategories().data;
  genders = genders;
  sizes = clothSizes;
  sources = shippingSources;
  adding = false;


  public get view(): string {
    return this.history[this.history.length - 1];
  }

  constructor(
    private fb: FormBuilder,
    public _auth: AuthService,
    private _api: ApiService,
    private _general: GeneralService,
    private _store: StoreService
  ) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      min_price: [0],
      max_price: [5000000],
      on_credit: [1],
      min_no_of_months: [1],
      max_no_of_months: [0],
      min_monthly_amount: [1],
      max_monthly_amount: [0],
      shipping_source: ['0'],
      discount: [0],
      sizes: [''],
      genders: [''],
      category_id: ['7']
    });

    this.pushView('options');
  }


  pushView(view: string) {
    this.history.push(view);
  }

  popView() {
    this.items = [];
    this.displayItems = [];
    this.loading = false;
    this.pagination = {
      page: 1, next: 0, end: false, pages: 0, count: 0
    };
    this.query = '';
    this.history.pop();
  }

  handleSizesChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedSizes.push(value);
    } else {
      this.selectedSizes = this.selectedSizes.filter(s => s !== value);
    }
    this.searchForm.get('sizes').setValue(this.selectedSizes.join(','));
  }

  handleGenderChange(e) {
    const { checked, dataset: { value } } = e.target;
    if (checked) {
      this.selectedGenders.push(value);
    } else {
      this.selectedGenders = this.selectedGenders.filter(s => s !== value);
    }
    this.searchForm.get('genders').setValue(this.selectedGenders.join(','));
  }

  searchStore(page = 1) {
    if (!this.keyword) return;
    this.loading = true;
    this._api.filterCCStore(this.keyword, page).toPromise<any>().then(this.handleResponse.bind(this)).catch(e => {
      console.log({ e });
      this.loading = false;
    });
  }

  searchBySlug(page = 1) {
    if (!this.slug) return;
    this.loading = true;
    this._api.filterCCStoreBySlug(this.slug, page).toPromise().then(this.handleResponse.bind(this)).catch(e => {
      this.loading = false;
      console.log({ e });
    });
  }

  handleResponse({ data, ...pagination }: any) {
    this.loading = false;
    if (!data.length) return this._general.notify('info', 'No items found');
    this.items = [...this.items, ...data];
    this.displayItems = this.items;
    this.pagination = pagination;
    this.pushView('list');
  }

  handleSearch() {
    const query = this.query;
    if (query.length) {
      this.displayItems = this.items.filter(p => {
        return p.name && p.name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.displayItems = this.items;
    }
  }

  select(item: MerchantItems) {
    if (this.adding) return;
    const exists = this.selected.find(i => i.id === item.id);
    if (exists) {
      this.selected = this.selected.filter(i => i.id !== item.id);
    } else {
      this.selected.push(item);
    }
  }

  isSelected(item: MerchantItems) {
    return this.selected.some(i => i.id === item.id);
  }

  stopSearch() {
    this.query = '';
    this.search = false;
    this.displayItems = this.items;
  }

  loadMore() {
    if (this.history.includes('slug')) return this.searchBySlug(this.pagination.next);
    this.searchStore(this.pagination.next);
  }

  addItems() {
    this.adding = true;
    const ids = this.selected.map(item => item.id);
    this._api.addCCItemsBulk(ids).toPromise().then(res => {
      this.adding = false;
      this._general.notify('success', res.message);
      this._store.setState('products', {
        ...this._store.products,
        items: [...res.items, ...this._store.products.items],
        total: this._store.products.total + res.items.length
      }, true);
      this.selected = [];
    }).catch(err => {
      this.adding = false;
      console.log(err);
    });
  }
}
