import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MerchantProfileDetails } from '../../models/merchantProfile';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { AuthService } from '../../services/auth.service';
import { take, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-settings-change-phone',
  templateUrl: './settings-change-phone.component.html',
  styleUrls: ['./settings-change-phone.component.scss']
})
export class SettingsChangePhoneComponent implements OnInit {
  phoneForm: FormGroup;
  otpForm: FormGroup;
  @Input() merchant: MerchantProfileDetails;
  change = false;
  view = 'default';
  loading = false;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService,
    private _general: GeneralService,
    private _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.phoneForm = this.fb.group({
      phone: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.otpForm = this.fb.group({
      otp: ['', Validators.required],
    });
  }

  async handlePhoneFormSubmit() {
    const payload = this.phoneForm.value;
    if (payload.phone === this._auth.merchant.phone) {
      return this._general.notify('error', 'Your new phone number cannot be the same as your old one');
    }
    this.loading = true;
    try {
      const res = await this._api.sendNewPhoneOTP(payload).toPromise();
      this._general.notify('success', res.message);
      this.view = 'otp';
    } catch (e) {
      const message = e?.error?.message ?? 'An error occurred, please try again';
      this._general.notify('error', message);
    }
    this.loading = false;
  }

  async handleOtpFormSubmit() {
    const payload = { ...this.otpForm.value, phone: this.phoneForm.value.phone };
    if (!payload.phone) return this.submitSMSVerification();
    this.loading = true;
    try {
      const res = await this._api.changePhone(payload).toPromise();
      this._general.notify('success', res?.message);
      const completion_details = { ...this._auth.merchant.completion_details, phone: payload.phone, phone_validated: true };
      this._auth.updateMerchant({ phone: payload.phone, phone_validated: true, completion_details });
      this.view = 'default';
    } catch (e) {
      console.log({ e });
      const message = e?.error?.message ?? 'An error occurred, please try again';
      this._general.notify('error', message);
    }
    this.loading = false;
  }

  sendVerificationSMS() {
    this.loading = true;
    this._api.sendVerificationSMS().pipe(timeout(60000)).subscribe(() => {
      this.loading = false;
      this.view = 'otp';
    }, err => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }

  submitSMSVerification() {
    const otp = this.otpForm.value.otp;
    if (otp.length < 4) return;
    this.loading = true;
    this._api.sendVerificationCodeFromPhoneToServer({ code: otp }).pipe(take(1)).subscribe(async () => {
      this._auth.updateMerchant({
        completion_details: { ...this.merchant.completion_details, phone_validated: true }, phone_validated: true
      });
      this.view = 'default';
      this.loading = false;
    }, err => {
      this.loading = false;
      this._general.notify('error', err?.error?.message || 'An error occurred, please try again');
    });
  }
}
