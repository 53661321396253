<swiper [spaceBetween]="20" [breakpoints]="breakpoints">
  <ng-template swiperSlide *ngFor="let template of templates">
    <div
      class="card flex-column p-4 p-md-5 bg-off-white mb-4"
      [ngClass]="{ 'bg-dark2 text-white': (merchant.template || 3) === template.id, 'disabled': !template.enabled }" style="min-height: 400px"
    >
      <h3 class="title m-0" [ngClass]="{ 'text-white': (merchant.template || 3) === template.id }" style="font-size: 1.4rem">
        {{ template.name }}
      </h3>
      <p class="mt-1 mb-0">Beautiful and simple</p>
      <div class="mt-3 d-flex flex-column align-items-start">
        <div *ngIf="template.enabled && (merchant.template || 3) === template.id" class="text-success">
          <i class="fa fa-check-circle me-1"></i> In use
        </div>
        <button
          *ngIf="template.enabled && (merchant.template || 3) !== template.id"
          (click)="saveTemplate(template.id)" class="btn btn-outline-primary"
        >
          Change theme
        </button>
        <div *ngIf="!template.enabled" class="text-red">Coming soon..</div>
      </div>
      <div class="mt-auto d-flex justify-content-end">
        <i class="fa fa-5x mb-2" [ngClass]="template.class"></i>
      </div>
    </div>
  </ng-template>
</swiper>
<div>
  <button (click)="options.emit()" class="btn btn-link px-0">Update other settings here</button>
</div>
