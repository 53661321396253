import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GeneralService } from 'src/app/services/general.service';
import { debounceTime, take } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-repayments',
  templateUrl: './repayments.component.html',
  styleUrls: ['./repayments.component.css']
})
export class RepaymentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search') input: ElementRef;
  searchBox: ElementRef;
  subscriptions: Subscription[] = [];
  repayments: any[] = [];
  oldDataBeforeSearch = [];
  ARepayment: any;
  partPaymentForm: FormGroup;
  count = 12;
  loading = false;
  fetching = false;
  partPaymentScheduleId: any;
  working: any;
  inputCustomerDetails: any;

  // pagination buttons
  end = false;
  next: number | null;
  prev: number | null;

  //  carousel options
  widthNumber = 98;
  heightNumber = 98;
  myRepaymentCarouseloptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoWidth: true,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    responsiveRefreshRate: 350,
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    }
  };
  modal = '';
  currentRepayment: any;
  meta: any;
  searched = {
    data: [],
    meta: null
  };

  constructor(
    private _api: ApiService,
    public _general: GeneralService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _store: StoreService
  ) {
  }

  public get items(): any[] {
    return this.searched.meta ? this.searched.data : this.repayments;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const request = this.repayments.find(r => r.request_id.toString() === params.id.toString());
        if (request) {
          this.currentRepayment = request;
        } else {
          this.fetchSingleRepayment(params.id);
        }
      }
    });

    if (!(this._store.repayments.items || []).length) this.fetchRepayments();

    this.subscriptions.push(
      this._store.$repayments.subscribe({
        next: ({ items, meta }) => {
          if (!items) return;
          this.repayments = items;
          this.meta = meta;
        }
      })
    );
  }

  async fetchSingleRepayment(id) {
    const { data } = await this._api.getSingleRepayment(id).toPromise<any>();
    this.currentRepayment = data;
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(1000)).subscribe((e: any) => {
        const query = e.target.value;
        if (query) this.search(query);
        else this.searched = { data: [], meta: null };
      })
    );
  }

  search(value: string, page_number = 1) {
    const searchParameter = { search: value, repayments: true, page_number: 1 };
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.searchOrdersOrRepaymentOrSales(searchParameter).pipe(take(1)).subscribe(({ data, ...meta }: any) => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      const items = this.transformData(data);
      this.searched = { data: items, meta };
    }, err => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      if (err instanceof HttpErrorResponse && err.status.toString().startsWith('5')) {
        this._general.notify('error', 'Could not initiate search. Please try again later');
      }
    });
  }

  fetchRepayments(page_number = 1) {
    this[page_number === 1 ? 'loading' : 'fetching'] = true;
    this._api.getAllMerchantRepaymens(page_number).subscribe(({ data, ...meta }) => {
      const items = this.transformData(data);
      if (page_number === 1) this._store.setState('repayments', { ...this._store.repayments, items, meta }, true);
      else this._store.setState('repayments', { ...this._store.repayments, items: [...this._store.repayments.items, ...data], meta }, true);
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
    }, err => {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      if (err instanceof HttpErrorResponse && err.status.toString().startsWith('5')) {
        this._general.notify('error', 'Could not initiate search. Please try again later');
      }
    });
  }

  loadMore() {
    if (this.searched.data.length) {
      return this.search(this.input.nativeElement.value, this.searched.meta.next);
    }
    this.fetchRepayments(this.meta.next);
  }

  transformData(data: Array<any>): any[] {
    return data.map(element => {
      const { schedule } = element;
      const repaymentObj = (schedule as Array<any>).reduce((acc, current) => {
        acc.amount += Number(current.amount);
        acc.months += 1;
        return acc;
      }, { amount: 0, months: 0 });
      return { ...element, repaymentObj };
    });
  }

  viewARepaymentSchedule(repayment) {
    this.router.navigate([`/repayments`], { queryParams: { id: repayment.request_id } });
  }

  closeRepaymentDetails(refresh = false) {
    if (refresh) this.fetchRepayments();
    this.currentRepayment = null;
    this.router.navigate([`/repayments`]);
  }

  get displayPercentageWidth() {
    if (this.ARepayment) {
      return `${ this.ARepayment.percentage_repayment_completion }%`;
    }
  }

  triggerPartPayment(id?: any, balance?: string) {
    // this.partPayment = 1;
    this.partPaymentScheduleId = id;
    if (balance && balance !== '0.00') {
      this.partPaymentForm.get('amount_paid').setValue(parseFloat(balance));
      const input = document.getElementById('amountPaid') as HTMLInputElement;
      const event = new Event('blur');
      input.dispatchEvent(event);
    }
  }

  // modifyAmount(event: Event) {
  //   let input = event.target as HTMLInputElement;
  //   input.type = 'text';
  //   let res = this.generalservice.putCommasInNumbers(input.value);
  //   input.value = res == 0 ? '' : res;
  // }

  isPartPayment(schedule): boolean {
    if (!schedule) return false;
    if (schedule.amount_paid) {
      return (
        parseFloat(schedule.amount) > parseFloat(schedule.amount_paid) &&
        schedule.amount_paid !== '0.00'
      );
    }
  }

  checkIfPartPaymentHasOccuredOnSchedule(schedule): boolean {
    if (schedule.amount_paid === '0.00') return false;
    if (schedule.amount_paid == null) return false;
    const amount = parseInt(schedule.amount, 10);
    const amountPaid = parseInt(schedule.amount_paid, 10);
    if (amount === amountPaid) return false;
    if (amount > amountPaid) return true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
