import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-credit-analysis',
  templateUrl: './credit-analysis.component.html',
  styleUrls: ['./credit-analysis.component.css']
})
export class CreditAnalysisComponent implements OnInit {

  @Output() back = new EventEmitter()
  @Input() request;
  view = 'options';
  loading: boolean;

  constructor() { }

  async ngOnInit() {
    if (+this.request.cred_plus_upfront > 50000) {
      this.view = 'bank-statement';
    } else {
      this.view = 'credit-beareau';
    }
  }
}
