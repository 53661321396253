<form [formGroup]="productForm" class="product-single-details m-0">
  <div *ngIf="view === 'picture'">
    <h5 class="title mb-2">Select pictures for product</h5>

    <input id="insertPicture" hidden type="file" (change)="startCropping($event)" accept="image/png, image/jpeg"
           #pictureInput/>

    <div (click)="addPicture('primary_picture')" class="mb-4 mt-5 w-75" *ngIf="!primary_picture">
      <span *ngIf="primary_picture" class="close-btn">
        <i (click)="primary_picture = ''" class="fa fa-window-close"></i>
      </span>
      <img [src]="primary_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
    </div>

    <div class="row mt-5" *ngIf="primary_picture">
      <div (click)="addPicture('primary_picture')" class="col-4 col-md-4 mb-4">
        <span *ngIf="primary_picture" class="close-btn">
          <i (click)="primary_picture = ''" class="fa fa-window-close"></i>
        </span>
        <img id="0" [src]="primary_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
      </div>
      <div (click)="addPicture('secondary_picture')" class="col-4 col-md-4 mb-4" *ngIf="primary_picture">
        <span *ngIf="secondary_picture" class="close-btn">
          <i (click)="secondary_picture = ''" class="fa fa-window-close"></i>
        </span>
        <img id="1" [src]="secondary_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
      </div>
      <div (click)="addPicture('tertiary_picture')" class="col-4 col-md-4 mb-4" *ngIf="secondary_picture">
        <span *ngIf="tertiary_picture" class="close-btn">
          <i (click)="tertiary_picture = ''" class="fa fa-window-close"></i>
        </span>
        <img id="2" [src]="tertiary_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
      </div>
      <div (click)="addPicture('fourth_picture')" class="col-4 col-md-4 mb-4" *ngIf="tertiary_picture">
        <span *ngIf="fourth_picture" class="close-btn">
          <i (click)="fourth_picture = ''" class="fa fa-window-close"></i>
        </span>
        <img [src]="fourth_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
      </div>
      <div (click)="addPicture('fifth_picture')" class="col-4 col-md-4 mb-4" *ngIf="fourth_picture">
        <span *ngIf="fifth_picture" class="close-btn">
          <i (click)="fifth_picture = ''" class="fa fa-window-close"></i>
        </span>
        <img [src]="fifth_picture || 'assets/images/plus.svg'" class="rounded border" alt="product"/>
      </div>
    </div>

    <div class="mt-4">
      <button (click)="pushView('product-details')" [disabled]="!primary_picture" type="button" class="btn btn-primary">
        Next
      </button>
    </div>
  </div>

  <div *ngIf="view === 'product-details'">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      Enter product details
    </h5>

    <div class="form-group mb-4">
      <label for="product-name">Name of Product</label>
      <input formControlName="name" placeholder="eg: Akido shoes" type="text" class="form-control" id="product-name"/>
    </div>

    <div class="form-group mb-4">
      <label for="product-group">Product Group</label>
      <select
        formControlName="category_id" class="form-control" required (change)="handleCategoryChange()" id="product-group"
      >
        <option value="" selected>Select product group</option>
        <option [value]="productCategory?.id" *ngFor="let productCategory of productCategories; index as i">
          {{ productCategory?.name | titlecase }}
        </option>
      </select>
    </div>

    <div class="form-group mb-4">
      <label for="product-subgroup">Product Subgroup</label>
      <select formControlName="sub_category_id" class="form-control" required id="product-subgroup">
        <option value="" selected>Select product subgroup</option>
        <option [value]="item?.id" *ngFor="let item of subcategories">
          {{ item?.name | titlecase }}
        </option>
      </select>
    </div>

    <div class="form-group mb-4 modifiedInputParent">
      <label for="product-price">Product Price</label>
      <input onfocus="this.type = 'number'" formControlName="price" class="form-control ps-4 productPrice" type="text"
             id="product-price" name="acc-mname" autocomplete="false"/>
      <small class="money">&#8358;</small>
    </div>

    <div class="mt-4">
      <button (click)="submitProductDetails()" [disabled]="!productForm.valid" type="button" class="btn btn-primary">
        Next
      </button>
    </div>
  </div>

  <div *ngIf="view.startsWith('credit-option')">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      Do you want to sell on credit?
    </h5>

    <div
      (click)="setSellOnCredit('1')"
      class="card flex-row align-items-center justify-content-between px-4 py-4 cursor-pointer"
      [ngClass]="{'selected': productForm.get('sell_on_credit').value === '1'}"
    >
      <span>Yes, sell on credit</span>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
    <div
      (click)="setSellOnCredit('0')"
      class="card flex-row align-items-center justify-content-between px-4 py-4 cursor-pointer mt-2"
      [ngClass]="{'selected': productForm.get('sell_on_credit').value === '0'}"
    >
      <span>No, outright payment</span>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
  </div>

  <div *ngIf="view === 'credit-price'">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      Enter credit information
    </h5>

    <div class="extra-info">
      <div class="form-group mb-4 modifiedInputParent">
        <span
          *ngIf="_auth.merchant.monthly_interest_rate > 0"
          class="position-absolute text-white bg-success border interestRate px-1"
          style="right: 2%;top: 51%;border-radius: 10px;"
        >
          @{{ _auth.merchant.monthly_interest_rate }}%
        </span>
        <label for="credit_price">Credit Price</label>
        <input
          (change)="handleCreditChange()" type="number" formControlName="credit_price" class="form-control ps-4"
          id="credit_price" placeholder="eg N3,500"
        />
        <small class="money">&#8358;</small>
        <small
          *ngIf="productForm.value.credit_price && (+productForm.value.credit_price < +productForm.value.price)"
          class="text-danger"
        >
          Credit price cannot be lower than current price
        </small>
      </div>

      <div class="mt-4">
        <button
          (click)="pushView('credit-installment')" class="btn btn-primary"
          [disabled]="+productForm.value.credit_price < +productForm.value.price" type="button"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="view.startsWith('credit-installment')">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      How long is credit for?
    </h5>

    <div class="row mb-4">
      <div (click)="selectInstallment(installment.value)" *ngFor="let installment of installments" class="col-4">
        <div
          class="card p-3 mb-3 bg-off-white cursor-pointer text-center"
          (click)="selectInstallment(installment.value)"
        >
          <div class="h4 m-0">{{ installment.text }}</div>
          <small>{{ installment.unit }}</small>
        </div>
      </div>
    </div>
    <button (click)="pushView('credit-installment-specify')" *ngIf="view === 'credit-installment'"
            class="btn btn-link px-0">
      Enter specific month
    </button>
    <div *ngIf="view === 'credit-installment-specify'" class="form-group">
      <label for="no_of_months">Number of installments (months)</label>
      <input
        type="number" formControlName="no_of_months" class="form-control" id="no_of_months"
        (change)="handleCreditChange()" max="12" min="1"
      >
      <small *ngIf="+productForm.value.no_of_months > 12" class="mt-2 text-danger">
        Number of installments cannot be more than 12 months
      </small>
      <div class="d-flex mt-4">
        <button
          [disabled]="!productForm.value.no_of_months || +productForm.value.no_of_months > 12"
          (click)="pushView('credit-upfront')" class="btn btn-primary"
        >
          Next
        </button>
        <button (click)="pushView('credit-installment')" class="btn btn-outline-danger ms-2">Cancel</button>
      </div>
    </div>
  </div>

  <div *ngIf="view.startsWith('credit-upfront')">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      How much is paid upfront?
    </h5>

    <div
      *ngFor="let value of upfrontValues" (click)="selectUpfront(value.amount)"
      class="card d-flex flex-row justify-content-between align-items-center bg-off-white cursor-pointer px-4 py-3 mb-2"
    >
      <div class="title">{{ value.label }}</div>
      <i class="fa fa-arrow-right text-muted"></i>
    </div>
    <button
      (click)="pushView('credit-upfront-specify')" *ngIf="view === 'credit-upfront'"
      class="btn btn-link px-0"
    >
      Enter specific amount
    </button>
    <div *ngIf="view === 'credit-upfront-specify'" class="form-group mt-4">
      <label for="upfront_amount">Upfront amount</label>
      <input
        formControlName="upfront_amount" class="form-control" type="number" id="upfront_amount"
        [max]="+productForm.value.credit_price" min="1"
      >
      <small *ngIf="+productForm.value.upfront_amount > +productForm.value.credit_price" class="mt-2 text-danger">
        Upfront amount cannot be more than credit amount
      </small>
      <div class="d-flex mt-4">
        <button
          (click)="validateCredit()" class="btn btn-primary"
          [disabled]="!productForm.value.upfront_amount || +productForm.value.upfront_amount > +productForm.value.credit_price"
        >
          Next
        </button>
        <button (click)="pushView('credit-upfront')" class="btn btn-outline-danger ms-2">Cancel</button>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'product-details-more'">
    <h5 class="title mb-5">
      <button class="btn btn-link px-0 me-2" type="button" (click)="popView()">
        <i class="fas fa-arrow-left"></i>
      </button>
      More product details
    </h5>

    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Product description
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse"
                data-bs-target="#collapse-description">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse show" id="collapse-description">
        <div class="mt-4">
          <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
        </div>
      </div>
    </div>

    <!-- Quantity -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Quantity
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-quantity">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse show" id="collapse-quantity">
        <div class="form-group mb-4 mt-5">
          <label for="available_quantity">Available quantity</label>
          <input type="number" class="form-control" formControlName="quantity" id="available_quantity"/>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group mb-4">
              <label for="min_order">Minimum order</label>
              <input type="number" class="form-control" formControlName="minimum_order" id="min_order"/>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-0">
              <label for="max_order">Maximum order</label>
              <input type="number" class="form-control" formControlName="maximum_order" id="max_order"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tags -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Tags
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-tags">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse show" id="collapse-tags">
        <div class="text-end">
          <button type="button" class="btn btn-outline-secondary btn-sm ms-auto my-3" data-bs-toggle="collapse"
                  data-bs-target="#addtag">
            <i class="fa fa-plus me-2"></i> Add tag
          </button>
        </div>
        <div class="collapse" id="addtag">
          <form [formGroup]="tagForm" class="d-flex flex-row align-items-center" (submit)="addTag()">
            <div class="form-group m-0">
              <input type="text" class="form-control override" formControlName="name" id="tag-name"
                     placeholder="Tag name">
            </div>
            <button [disabled]="addingTag" type="submit" class="btn btn-primary ms-2 btn-sm">Add</button>
          </form>
        </div>
        <div class="row mt-4">
          <div class="col-6 mb-2" *ngFor="let tag of tags">
            <div class="form-check">
              <input
                class="form-check-input" type="checkbox" value="" [id]="tag.name + tag.id" [attr.data-value]="tag.id"
                (change)="handleTagChange($event)" [checked]="selectedTags.includes(tag.id.toString())"
              >
              <label class="form-check-label" [for]="tag.name + tag.id">
                {{ tag.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Discount -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Discount
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-discount">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse" id="collapse-discount">
        <div class="mt-5">
          <div class="form-group required mb-4 modifiedInputParent">
            <label for="discount_price">Discount Price</label>
            <input type="number" class="form-control ps-4 productPrice" id="discount_price"
                   formControlName="discount_price"/>
            <small class="money">&#8358;</small>
          </div>
          <div class="form-group" *ngIf="productForm.value.discount_price">
            <label for="discount_expiry_date">Discount expiry date</label>
            <input type="date" formControlName="discount_expiry_date" id="discount_expiry_date" class="form-control">
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="productForm.value.category_id == '7'">
      <!-- Size -->
      <div class="card mb-4">
        <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
          Size
          <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-size">
            <i class="fa fa-chevron-down"></i>
          </button>
        </h6>
        <div class="collapse" id="collapse-size">
          <div class="row mt-5">
            <div class="col-3 mb-2" *ngFor="let size of clothSizes">
              <div class="form-check">
                <input
                  type="checkbox" class="form-check-input" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">
                  {{ size.text }}
                </label>
              </div>
            </div>
          </div>
          <hr>
          <div class="mt-4 font-size-md text-muted">Euro</div>
          <div class="row mt-4">
            <div class="col-3 mb-2" *ngFor="let size of shoeSizesEuro">
              <div class="form-check">
                <input
                  type="checkbox" class="form-check-input" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">
                  {{ size.text }}
                </label>
              </div>
            </div>
          </div>
          <hr>
          <div class="mt-4 font-size-md text-muted">UK</div>
          <div class="row mt-4">
            <div class="col-3 mb-2" *ngFor="let size of shoeSizesUK">
              <div class="form-check">
                <input
                  class="form-check-input" type="checkbox" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">{{ size.text }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gender -->
      <div class="card mb-4">
        <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
          Gender
          <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-gender">
            <i class="fa fa-chevron-down"></i>
          </button>
        </h6>
        <div class="collapse" id="collapse-gender">
          <div class="row mt-5">
            <div class="col-6 mb-2" *ngFor="let gender of genders">
              <div class="form-check">
                <input
                  class="form-check-input" type="checkbox" [id]="gender.text + gender.value"
                  [attr.data-value]="gender.value" (change)="handleGenderChange($event)"
                  [checked]="selectedGenders.includes(gender.value)"
                >
                <label class="form-check-label" [for]="gender.text + gender.value">{{ gender.text }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <!-- Shipping source -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Shipping source
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-shipping">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse" id="collapse-shipping">
        <div class="row mt-5">
          <div class="col-6 mb-2" *ngFor="let source of sources">
            <div class="form-check">
              <input
                class="form-check-input" type="radio" [id]="'check'+source.value"
                [value]="source.value.toString()" formControlName="shipping_source"
              >
              <label class="form-check-label" [for]="'check'+source.value">
                {{ source.text }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-5">
      <button (click)="createItem(productForm)" type="button" class="btn btn-primary">
        Create product
      </button>
    </div>
  </div>
</form>

<div *ngIf="creating" class="mini-modal">
  <br><br><br><br><br>
  <div class="loader-bounce">
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
  </div>
  <div class="title mt-3 text-center w-75 mx-auto font-italic">
    Creating product
  </div>
  <br><br><br><br><br>
</div>

<app-dialog *ngIf="modal === 'crop'" (close)="modal = ''" [closable]="false" [nested]="true">
  <div class="row col-12 mx-auto pt-2" style="max-width: 500px;">
    <image-cropper
      style="width: 512px" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
      [aspectRatio]="1" [resizeToWidth]="500" [resizeToHeight]="500" [cropperMinWidth]="510"
      [cropperMinHeight]="510" format="jpeg" [imageQuality]="92" (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>
  <div class="d-flex justify-content-center mt-5">
    <button (click)="croppingDone()" type="button" class="btn btn-success">Add</button>
    <button (click)="cancelCropping()" type="button" class="btn btn-danger ms-3">Cancel</button>
  </div>
</app-dialog>
