<div *ngIf="!_auth.merchant.sender_id" class="d-flex flex-column align-items-center text-center py-5">
  <img src="/assets/images/svg/no-connection.svg" alt="no connection" class="w-25">
  <h4 class="mt-5 mb-0">Enable Whatsapp integration</h4>
  <p class="mt-3">Connect your whatsapp to enable whatsapp shopping and add products from your whatsapp status</p>
  <button (click)="connect.emit()" class="btn btn-primary mt-4">Connect whatsapp</button>
</div>

<div *ngIf="_auth.merchant.sender_id" class="card shadow-sm">

  <div *ngIf="view === 'bot-name'" class="mb-3">
    <h5 class="title mb-5">
      Enter bot name
    </h5>
    <input [(ngModel)]="bot_name" type="text" class="form-control" id="bot-name" [disabled]="loading"/>
    <div class="mt-5">
      <button
        (click)="!!bot_name ? view = 'categories' : null" [disabled]="!bot_name"
        class="btn btn-primary"
      >
        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i> Next
      </button>
    </div>
  </div>

  <div *ngIf="view === 'categories'" class="mt-4">
    <h5 class="title mb-5 d-flex align-items-center">
      <button
        (click)="view = 'bot-name'"
        class="btn btn-outline-secondary btn-sm rounded-full me-3"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      Select 3 major categories of your business
    </h5>

    <div class="d-flex justify-content-between align-items-center mb-4">
      <div *ngIf="selectedInterests.length" class="badge bg-danger px-3 py-2 font-weight-light">
        {{ selectedInterests.length }}/3 interest(s) selected
      </div>
    </div>
    <swiper [slidesPerView]="1.2" [spaceBetween]="10">
      <template *ngFor="let slide of businessInterests; index as i">
        <ng-template swiperSlide>
          <div class="border p-4 rounded position-relative h-100">
            <div class="title mb-3">{{ slide?.title }}</div>
            <div class="form-check" *ngFor="let interest of slide.interests; index as i">
              <input
                (click)="selectInterest($event, interest)" [id]="interest?.interest"
                [checked]="isSelected(interest)"
                class="form-check-input" type="checkbox" [disabled]="loading"
              />
              <label class="form-check-label" [attr.for]="interest?.interest">
                {{ interest?.interest }}
              </label>
            </div>
          </div>
        </ng-template>
      </template>
    </swiper>
    <div class="mt-5">
      <button
        (click)="save()" [disabled]="selectedInterests.length !== 3 || !bot_name || loading"
        class="btn btn-primary"
      >
        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i> Save
      </button>
    </div>
  </div>

</div>
