import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { MerchantNotifications, NotificationData } from 'src/app/models/merchant';
import { ApiService } from 'src/app/services/api.service';
import { StoreService } from 'src/app/services/mockstore/store.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  refreshing = false;
  notificationsData: NotificationData[] = [];
  loadingNotifications = false;

  constructor(
    private router: Router,
    private _general: GeneralService,
    private mockstore: StoreService,
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this._general.receiveNotificationsData$.subscribe(
      (val: NotificationData[]) => {
        this.notificationsData = [...this.notificationsData, ...val];
      }
    );
  }

  onScroll() {
    this.loadingNotifications = true;
    if (this.mockstore.nextSetOfMerchantNotifications) {
      this.refreshingNotifications(
        this.mockstore.nextSetOfMerchantNotifications.toString()
      );
    } else {
      this.loadingNotifications = false;
    }
  }

  async viewNotification(
    notificationId: string,
    type: any,
    notification: NotificationData
  ) {
    switch (type) {
      case 1:
        this.router.navigate(['/requests'], { queryParams: { id: notificationId } });
        await this._api.messagesHasBeenRead(notification.id.toString());
        const currentnotification = this.notificationsData.find(el => el.id === notification.id);
        currentnotification.is_read = 1;
        break;
      case 2:
        this.router.navigate(['/repayments'], { queryParams: { id: notificationId } });
        break;
    }
  }

  async refreshingNotifications(page_number?: string) {
    this.refreshing = true;
    const res = await this._api.getMerchantNotifications(page_number);
    const newRes: MerchantNotifications = await res.json();
    const { data, next } = newRes;
    this._general.sendNotificationsToNotificationsComp(data);
    this.mockstore.nextSetOfMerchantNotifications = next;
    this.refreshing = false;
    if (this.loadingNotifications) {
      this.loadingNotifications = false;
    }
  }
}
