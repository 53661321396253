<form [formGroup]="editRequestForm" (ngSubmit)="submitChanges(editRequestForm, $event)"
  class="product-single-details m-0">
  <!-- <h5 class="title mb-5">
    Modify Price
    <small class="text-danger priceToBeSubstracted">
      {{
            priceToBeDeleted
              ? "-" + generalservice.changeToHumanReadable(priceToBeDeleted)
              : ""
          }}
    </small>
  </h5> -->

  <div class="form-group required mt-2 modifiedInputParent">
    <label for="acc-mname">New Total Price?</label>
    <input type="number" onfocus="this.type = 'number'" class="form-control totalPrice ps-4" id="newTotalPrice"
      formControlName="cred_plus_upfront" name="acc-mname" />
    <small *ngIf="cred_plus_upfront.value" class="money">&#8358;</small>
  </div>

  <!-- show when order is not credit -->
  <div class="form-group">
    <input (input)="toggleCreditOption($event)" type="checkbox" name="price[1]" id="turnCreditOnOrOff" value="1"
      class="radio credit-input me-2" />
    <label *ngIf="!creditOptionTurnedOnByMerchant" for="credit-p" class="fw-normal">
      Make this order a credit order
    </label>

    <label *ngIf="creditOptionTurnedOnByMerchant" for="credit-p" class="fw-normal">
      Make this order an outright order
    </label>
  </div>
  <!--  ends here -->

  <div [style.display]="creditOptionTurnedOnByMerchant ? 'block' : 'none'"
    class="form-group required modifiedInputParent">
    <label for="upf">How much does buyer contribute upfront?</label>
    <input type="number" onfocus="this.type = 'number'" class="form-control upfront ps-4" id="upf"
      formControlName="upfront_amount" name="acc-mname" />
    <small *ngIf="upfront_amount.value" class="money">&#8358;</small>
  </div>
  <!-- End .form-group -->

  <div [style.display]="creditOptionTurnedOnByMerchant ? 'block' : 'none'" class="form-group required">
    <label>How many months?</label>
    <select formControlName="no_of_months" class="form-control">
      <option value="" selected>Select number of months</option>
      <option value="1">1 month</option>
      <option value="2">2 months</option>
      <option value="3">3 months</option>
      <option value="4">4 months</option>
      <option value="5">5 months</option>
      <option value="6">6 months</option>
      <option value="7">7 months</option>
      <option value="8">8 months</option>
      <option value="9">9 months</option>
      <option value="10">10 months</option>
      <option value="11">11 months</option>
      <option value="12">12 months</option>
    </select>
    <!-- End .select-custom -->
  </div>
  <!-- End .form-group -->

  <!-- End .form-group -->

  <div id="m-container" [style.display]="calculationCompleted ? 'block' : 'none'" class="container mb-4 mt-2">
    <div class="card d-flex flex-row flex-wrap p-4">
      <div>
        <h5 class="title m-0">
          <span class="currency">₦{{ calculationResults?.upfrontPaymentOnPurchase }}</span>
        </h5>
        <small>
          Upfront payment at purchase
        </small>
      </div>

      <div class="h4 m-0 px-4 d-inline-flex justify-content-center align-items-center mb-4">+</div>

      <div>
        <h5 class="title m-0">
          <span class="currency">₦{{ calculationResults?.balanceSpread }}</span>
        </h5>
        <small>Balance over {{ no_of_months.value }} months </small>
      </div>

      <div class="h4 m-0 px-4 d-inline-flex justify-content-center align-items-center mb-4">=</div>

      <div class="flex-grow-1">
        <h5 class="title m-0">
          <span class="currency ">₦{{ calculationResults?.total }}</span>
        </h5>
        <small>Total</small>
      </div>
    </div>
  </div>

  <button type="submit" [disabled]="!editRequestForm.valid" class="btn btn-success btn-lg mt-4">
    Save
  </button>

  <!-- <button type="button" class="mt-3 mb-3 btn btn-primary btn-block">
        checkout
      </button> -->
</form>
