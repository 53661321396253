<div style="min-height: 300px;">
  <h5 class="title mb-5">
    Search from store
  </h5>

  <div class="d-flex align-items-center" id="searchDiv">
    <input
      type="text" id="searchInput" (keydown.enter)="search()" autocomplete="off"
      class="form-control override rounded-full form-control-lg m-0 mb-1" placeholder="Search..." [(ngModel)]="query"
    />
    <button (click)="search()" class="search-button ms-2">
      <i class="fa fa-search text-primary"></i>
    </button>
  </div>

  <div *ngIf="!searching && !searchResults.length">
    <br><br><br><br><br><br><br><br>
    <img class="w-50 ms-auto" src="assets/images/svg/file-searching.svg" alt="Illustration" style="max-width: 300px;">
  </div>

  <div *ngIf="searching" class="row mt-4">
    <div class="col-6 mb-3" *ngFor="let i of [1,2]">
      <div class="card p-0 skeleton fit-content">
        <img [src]="'assets/images/plus.svg'" alt="">
      </div>
      <div class="title skeleton fit-content mt-2">Product name</div>
      <small class="skeleton fit-content">N200,000</small>
    </div>
  </div>

  <div class="row mt-4" *ngIf="!searching && searchResults.length">
    <div class="col-6 mb-3" *ngFor="let item of searchResults" (click)="viewProduct(item)">
      <div class="card p-0 cursor-pointer">
        <img [src]="item.primary_picture" alt="">
      </div>
      <div class="title mt-2 text-ellipsize">{{ item.name }}</div>
      <div class="d-flex justify-content-between align-items-center">
        <small>{{ item.price }}</small>
        <button class="icon-button btn ms-2 mt-2" (click)="shareItem(item)"><i
          class="fa fa-share-alt"></i></button>
      </div>
    </div>
  </div>
</div>
