<h5 class="title mb-2">
  <button class="btn btn-link me-3 px-0" (click)="close.emit()">
    <i class="fas fa-arrow-left"></i>
  </button>
  Setup product
</h5>
<small class="d-block mb-5">Provide the following information to finish creating this product</small>
<form [formGroup]="productForm">
  <div class="card mb-4">
    <h6 class="section-title text-primary">Basic information <span class="badge bg-danger ms-3">Required</span></h6>

    <div class="form-group mb-4">
      <label for="name">Name of Product</label>
      <input formControlName="name" type="text" class="form-control" id="name"/>
    </div>

    <div class="form-group mb-4">
      <label>Product group</label>
      <select formControlName="category_id" class="form-control" required (change)="handleCategoryChange()">
        <option value="" selected>Select product group</option>
        <option [value]="category.id" *ngFor="let category of categories; index as i">
          {{ category.name | titlecase }}</option>
      </select>
    </div>

    <div class="form-group mb-4">
      <label for="product-subgroup">Product subgroup</label>
      <select formControlName="sub_category_id" class="form-control" required id="product-subgroup">
        <option value="" selected>Select product subgroup</option>
        <option [value]="item?.id" *ngFor="let item of subcategories">
          {{ item?.name | titlecase }}
        </option>
      </select>
    </div>

    <div class="form-group mb-4 modifiedInputParent">
      <label for="acc-p-price">Product Price</label>
      <input (input)="handlePriceChange()" formControlName="price" class="form-control ps-4" id="acc-p-price"/>
      <small class="money">&#8358;</small>
    </div>
  </div>

  <div class="card mb-4">
    <h6 class="section-title text-primary">
      Images
      <span class="badge bg-secondary px-2 py-1 rounded-full ms-2">{{ images.length }}</span>
      <span class="badge bg-danger ms-3">Required</span>
    </h6>

    <div *ngIf="!images.length" class="d-flex skeleton-children fit-content">
      <div *ngFor="let i of [1,2,3]" class="me-2" style="width: 100px; height: 100px;"></div>
    </div>

    <owl-carousel-o *ngIf="images.length" [options]="carouselOption">
      <ng-template carouselSlide *ngFor="let image of images">
        <div style="max-width: 300px;">
          <app-square-box>
            <app-proxy-image [src]="image"></app-proxy-image>
          </app-square-box>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>

  <hr class="mt-5 mb-0"/>
  <p class="text-center" style="margin-top: -17px;">
    <button
      class="btn btn-link bg-white position-relative" type="button" data-bs-toggle="collapse"
      data-bs-target="#moreProductInfo"
    >
      More product details <i class="fa fa-chevron-down ms-2"></i>
    </button>
  </p>

  <div class="collapse" id="moreProductInfo">
    <div *ngIf="_auth.merchant.partnership === 1" class="alert alert-info rounded mb-4">
      <i class="fa fa-exclamation-circle me-3"></i>Credit covered by bank
    </div>

    <div *ngIf="_auth.merchant.partnership !== 1" class="card mb-4">
      <h6 class="section-title text-primary">Credit information</h6>

      <div class="form-group mb-4">
        <label>Do you want to sell on credit?</label>
        <div class="row mt-2">
          <div class="col-12 col-sm-6 col-md-5 d-flex align-items-center">
            <div class="form-check">
              <input
                class="form-check-input" type="radio" formControlName="sell_on_credit" id="outright-p"
                (change)="handleSellOnCreditChange($event)"
                value="0"
              >
              <label class="form-check-label" for="outright-p">No, Outright payment</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-md-5 d-flex align-items-center mt-2 mt-md-0">
            <div class="form-check">
              <input
                class="form-check-input" type="radio" formControlName="sell_on_credit" id="credit-p"
                (change)="handleSellOnCreditChange($event)"
                value="1"
              >
              <label class="form-check-label" for="credit-p">Yes, Sell on credit</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="productForm.value.sell_on_credit === '1'" class="extra-info">
        <div *ngIf="!_auth.merchant.monthly_interest_rate" class="form-group mb-4 modifiedInputParent">
          <label for="credit_price">Credit Price</label>
          <input
            (change)="populateUpfrontValues()" type="number" formControlName="credit_price"
            class="form-control ps-4" id="credit_price"
          />
          <small class="money">&#8358;</small>
          <small
            *ngIf="productForm.value.credit_price && (+productForm.value.credit_price < +productForm.value.price)"
            class="text-danger">Credit price cannot be lower than current price</small>
        </div>

        <div *ngIf="!_auth.merchant.max_credit_duration" class="form-group mb-4">
          <label for="noOfMonths">How many months?</label>
          <select
            (change)="populateUpfrontValues()" id="noOfMonths" formControlName="no_of_months"
            class="form-control"
          >
            <option value="" selected>Enter number of months</option>
            <option value="1">1 month</option>
            <option value="2">2 months</option>
            <option value="3">3 months</option>
            <option value="4">4 months</option>
            <option value="5">5 months</option>
            <option value="6">6 months</option>
            <option value="7">7 months</option>
            <option value="8">8 months</option>
            <option value="9">9 months</option>
            <option value="10">10 months</option>
            <option value="11">11 months</option>
            <option value="12">12 months</option>
          </select>
        </div>

        <ng-container *ngIf="!_auth.merchant.upfront_rate">
          <div class="form-group mb-4 required-field">
            <label>How much does buyers contribute upfront?</label>
            <select
              formControlName="upfront_amount_option" class="form-control"
              (change)="handleUpfrontChange($event.target.value)"
            >
              <option value="0">No upfront</option>
              <option [value]="item.amount" *ngFor="let item of upfrontValues">{{ item.label }}</option>
              <option value="specify">Specify amount</option>
            </select>
          </div>

          <div *ngIf="specifyUpfront" class="form-group mb-4 modifiedInputParent">
            <label for="calc">Specify upfront amount</label>
            <input type="number" formControlName="upfront_amount" class="form-control ps-4" id="calc"/>
            <small class="money">&#8358;</small>
          </div>
        </ng-container>

        <div class="card bg-light-red flex-row flex-wrap mb-4">
          <div class="flex-grow-1 my-2 border-right pe-4 me-4">
            <h5 class="title m-0">
              {{ (productForm.value.upfront_amount || 0) | currency:'NGN':'₦':'0.0' }}
            </h5>
            <small>Upfront payment of purchase</small>
          </div>
          <div class="flex-grow-1 my-2 border-right pe-4 me-4">
            <h5 class="title m-0">
              {{ ((+productForm.value.credit_price - +productForm.value.upfront_amount) || 0)
              | currency:'NGN':'₦':'0.0' }}
            </h5>
            <small>Balance over {{ productForm.value.no_of_months }} Months</small>
          </div>
          <div class="flex-grow-1 my-2">
            <h5 class="title m-0">
              {{ (productForm.value.credit_price || 0) | currency:'NGN':'₦':'0.0' }}
            </h5>
            <small>Total</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        <span class="d-inline-flex align-items-center">
          Product description
          <span class="badge bg-danger ms-3">Required</span>
        </span>
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse"
                data-bs-target="#collapse-description">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse" id="collapse-description">
        <div class="mt-4">
          <angular-editor class="mt-4" formControlName="description" [config]="editorConfig"></angular-editor>
        </div>
      </div>
    </div>


    <!-- Quantity -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Quantity
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse"
                data-bs-target="#collapse-quantity">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse show" id="collapse-quantity">
        <div class="form-group mb-4 mt-5">
          <label for="quantity">Available quantity</label>
          <input type="number" class="form-control" formControlName="quantity" id="quantity"/>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group mb-4">
              <label for="min-order">Minimum order</label>
              <input type="number" class="form-control" formControlName="minimum_order" id="min-order"/>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-0">
              <label for="max-order">Maximum order</label>
              <input type="number" class="form-control" formControlName="maximum_order" id="max-order"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tags -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Tags
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-tags">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse show" id="collapse-tags">
        <div class="text-end">
          <button type="button" class="btn btn-outline-secondary btn-sm ms-auto my-3" data-bs-toggle="collapse"
                  data-bs-target="#addtag">
            <i class="fa fa-plus me-2"></i> Add tag
          </button>
        </div>
        <div class="collapse" id="addtag">
          <form [formGroup]="tagForm" class="d-flex flex-row align-items-center" (submit)="addTag()">
            <div class="form-group m-0">
              <input type="text" class="form-control override" formControlName="name" id="tag-name"
                     placeholder="Tag name">
            </div>
            <button [disabled]="addingTag" type="submit" class="btn btn-primary ms-2 btn-sm">Add</button>
          </form>
        </div>
        <div class="row mt-4">
          <div class="col-6 mb-2" *ngFor="let tag of tags">
            <div class="form-check">
              <input
                class="form-check-input" type="checkbox" value="" [id]="tag.name + tag.id" [attr.data-value]="tag.id"
                (change)="handleTagChange($event)" [checked]="selectedTags.includes(tag.id.toString())"
              >
              <label class="form-check-label" [for]="tag.name + tag.id">
                {{ tag.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Discount -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Discount
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-discount">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse" id="collapse-discount">
        <div class="mt-5">
          <div class="form-group required mb-4 modifiedInputParent">
            <label for="discount_price">Discount Price</label>
            <input type="number" class="form-control ps-4 productPrice" id="discount_price"
                   formControlName="discount_price"/>
            <small class="money">&#8358;</small>
          </div>
          <div class="form-group" *ngIf="productForm.value.discount_price">
            <label for="discount_expiry_date">Discount expiry date</label>
            <input type="date" formControlName="discount_expiry_date" id="discount_expiry_date"
                   class="form-control">
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="productForm.value.category_id == '7'">
      <!-- Size -->
      <div class="card mb-4">
        <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
          Size
          <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-size">
            <i class="fa fa-chevron-down"></i>
          </button>
        </h6>
        <div class="collapse" id="collapse-size">
          <div class="row mt-5">
            <div class="col-3 mb-2" *ngFor="let size of clothSizes">
              <div class="form-check">
                <input
                  type="checkbox" class="form-check-input" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">
                  {{ size.text }}
                </label>
              </div>
            </div>
          </div>
          <hr>
          <div class="mt-4 font-size-md text-muted">Euro</div>
          <div class="row mt-4">
            <div class="col-3 mb-2" *ngFor="let size of shoeSizesEuro">
              <div class="form-check">
                <input
                  type="checkbox" class="form-check-input" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">
                  {{ size.text }}
                </label>
              </div>
            </div>
          </div>
          <hr>
          <div class="mt-4 font-size-md text-muted">UK</div>
          <div class="row mt-4">
            <div class="col-3 mb-2" *ngFor="let size of shoeSizesUK">
              <div class="form-check">
                <input
                  class="form-check-input" type="checkbox" [id]="size.text + size.value"
                  [attr.data-value]="size.value" (change)="handleSizeChange($event)"
                  [checked]="selectedSizes.includes(size.value)"
                >
                <label class="form-check-label" [for]="size.text + size.value">{{ size.text }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Gender -->
      <div class="card mb-4">
        <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
          Gender
          <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-gender">
            <i class="fa fa-chevron-down"></i>
          </button>
        </h6>
        <div class="collapse" id="collapse-gender">
          <div class="row mt-5">
            <div class="col-6 mb-2" *ngFor="let gender of genders">
              <div class="form-check">
                <input
                  class="form-check-input" type="checkbox" [id]="gender.text + gender.value"
                  [attr.data-value]="gender.value" (change)="handleGenderChange($event)"
                  [checked]="selectedGenders.includes(gender.value)"
                >
                <label class="form-check-label" [for]="gender.text + gender.value">{{ gender.text }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <!-- Shipping source -->
    <div class="card mb-4">
      <h6 class="section-title text-primary m-0 d-flex align-items-center justify-content-between">
        Shipping source
        <button type="button" class="btn icon-button sm" data-bs-toggle="collapse" data-bs-target="#collapse-shipping">
          <i class="fa fa-chevron-down"></i>
        </button>
      </h6>
      <div class="collapse" id="collapse-shipping">
        <div class="row mt-5">
          <div class="col-6 mb-2" *ngFor="let source of sources">
            <div class="form-check">
              <input
                class="form-check-input" type="radio" [id]="'check'+source.value"
                [value]="source.value.toString()" formControlName="shipping_source"
              >
              <label class="form-check-label" [for]="'check'+source.value">
                {{ source.text }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex mt-5">
    <button (click)="createProduct()" [disabled]="productForm.invalid || !images.length || loading" type="submit"
            class="btn btn-primary">
      Create product
    </button>
  </div>
</form>
