import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MerchantItems } from 'src/app/models/items';
import { GeneralService } from 'src/app/services/general.service';

// TODO:Remove

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit, AfterViewInit {
  @Input() embedded = false;
  searchResults: any[] = [];
  searchString = '';
  searching = false;
  selected: any;
  noData = false;
  pageNumber = 1;
  end: boolean;
  view = 'search';

  constructor(
    private _api: ApiService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const input = document.getElementById('searchInput');
    fromEvent(input, 'input').pipe(debounceTime(300)).subscribe(() => {
      if (this.searchString.length < 1) {
        this.searchResults = [];
        this.pageNumber = 1;
        this.noData = false;
        document.querySelector('.translateSearch').classList.remove('moveUp');
      }
    });
  }

  loadMoreGlobalProducts(event: Event) {
    const span = event.target as HTMLElement;
    const previousText = span.textContent;
    span.textContent = 'Loading...';
    const arr = [span, previousText];
    this.pageNumber = this.pageNumber + 1;
    this.fetchSearchAllOption(this.pageNumber.toString(), arr);
  }

  search() {
    this.searching = true;
    this.noData = false;
    if (this.searchString.length > 1) {
      // const translateDiv = document.querySelector(
      //   '.translateSearch'
      // ) as HTMLDivElement;
      // translateDiv.classList.contains('moveUp')
      //   ? null
      //   : translateDiv.classList.add('moveUp');
      this.fetchSearchAllOption();
    }
  }

  async fetchSearchAllOption(page_number?: string, htmlElemnetAndText?: any[]) {
    const obj = {
      search: this.searchString,
      page_number: page_number ? page_number : '1',
      global: true
    };
    try {
      const val = await this._api.fetchGlobalCatalogueBySearch(obj).toPromise();
      const { data, end } = val;
      this.end = end;
      this.searchResults = [...data, ...this.searchResults];
      this.searching = false;
      if (htmlElemnetAndText) {
        htmlElemnetAndText[0].textContent = htmlElemnetAndText[1];
      }
      if (this.searchResults.length < 1) {
        this.noData = true;
      }
    } catch (error) {
      this.searchResults = [];
      if (htmlElemnetAndText) {
        htmlElemnetAndText[0].textContent = htmlElemnetAndText[1];
      }
      this.noData = true;
      this._general.notify('error',
        'We could not connect to our server. Please try again later!'
      );
    }
  }

  select(item: MerchantItems) {
    this.selected = {
      name: item.name,
      price: item.price,
      images: [item.primary_picture],
      description: null
    };
    this.view = 'create-product';
  }

  closeCreateProduct() {
    this.selected = null;
    this.view = 'search';
  }
}
