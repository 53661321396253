import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MerchantDashboardData } from '../../models/dashboard';

@Component({
  selector: 'app-repayment-report',
  templateUrl: './repayment-report.component.html',
  styleUrls: ['./repayment-report.component.css']
})
export class RepaymentReportComponent implements OnInit {
  merchantDashBoardData: Partial<MerchantDashboardData> = {};
  makingAnApiCall = false;
  filterOption = '9';
  modal = '';
  summaryFilterOptions = [
    { id: '1', text: 'Today' },
    { id: '2', text: 'Last 24 hours' },
    { id: '3', text: 'Yesterday' },
    { id: '4', text: 'Last 7 days' },
    { id: '6', text: 'This Week' },
    { id: '7', text: 'This Month' },
    { id: '5', text: 'Last 30 days' },
    { id: '8', text: 'This Year' },
    { id: '9', text: 'Lifetime' },
  ];

  constructor(
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.fetchStats();
  }

  fetchStats(filter = '9') {
    this.filterOption = filter;
    this.makingAnApiCall = true;
    this.modal = '';
    this._api.getDashboard(filter).subscribe(val => {
      const { data } = val;
      this.merchantDashBoardData = data;
      this.makingAnApiCall = false;
    });
  }

  getSummaryFilterOption(id: string) {
    return this.summaryFilterOptions.find(i => i.id === id);
  }
}
