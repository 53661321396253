import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GeneralService } from 'src/app/services/general.service';
import { debounceTime, pluck } from 'rxjs/operators';
import { fromEvent, Subscription, TimeoutError } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchSalesBox', { static: false, read: ElementRef })
  searchSalesBox: ElementRef;
  loading = true;
  noData = false;
  allCustomers: any[] = [];
  destroyObservable: Subscription[] = [];
  currentCustomer: any;
  widthNumber = 98;
  mySalesCarouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoWidth: true,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    responsiveRefreshRate: 350,
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    }
  };
  modal = '';

  constructor(
    public _general: GeneralService,
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.fetchSalesByMerchant();
  }

  ngAfterViewInit() {
    this.destroyObservable[0] = fromEvent(
      this.searchSalesBox.nativeElement, 'input').pipe(pluck('target', 'value'),
      debounceTime(500)).subscribe(val => this.searchSales(val as string)
    );
  }

  searchSales(value: string) {
    if (value.length > 2) {
      const searchParameter = { search: value, sales: true, page_number: 1 };
      this.destroyObservable[0] = this._api.searchOrdersOrRepaymentOrSales(searchParameter).subscribe(val => {
        this.modifySalesResponse(val);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status.toString().startsWith('5')) {
          this._general.notify('error', 'Could not initiate search. Please try again later');
        }
      });
    } else {
      this.fetchSalesByMerchant();
    }
  }

  modifySalesResponse(val) {
    const { data } = val;
    (data as Array<any>).forEach(element => {
      const { customer_, items } = element;
      element['modifiedCustomer'] = customer_;
      element['pictureInView'] = items.length ? items[0].primary_picture : '';
    });
    this.allCustomers = [...data];
    this.noData = this.allCustomers.length < 0;
    this.loading = false;
  }

  fetchSalesByMerchant() {
    this._api.salesByMerchant().subscribe((val) => {
      this.modifySalesResponse(val);
    }, (err) => {
      this.noData = true;
      this.loading = false;
      if (err instanceof TimeoutError) {
        this._general.notify('error', 'Sorry, could not fetch your data. Please check your connection.');
      } else {
        this._general.notify('error', 'Oops something went wrong. Please try again later.');
      }
    });
  }

  showOrHide(element) {
    (document.getElementById(element) as HTMLDivElement).classList.toggle(
      'show'
    );
  }

  viewSale(customer: any) {
    this.currentCustomer = customer;
    this.modal = 'details';
  }

  changePictureInView(customer, event: Event) {
    const img = event.target as HTMLImageElement;
    customer.pictureInView = img.src;
  }

  ngOnDestroy() {
    this.destroyObservable.forEach(element => element.unsubscribe());
  }
}
