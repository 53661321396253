import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-add-products-from-whatsapp',
  templateUrl: './add-products-from-whatsapp.component.html',
  styleUrls: ['./add-products-from-whatsapp.component.scss']
})
export class AddProductsFromWhatsappComponent implements OnInit {
  loading = false;
  @Output() connect = new EventEmitter();
  data = [];
  current = null;

  constructor(
    private _api: ApiService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    if (this._auth.merchant.sender_id) this.getData();
  }

  async getData() {
    this.loading = true;
    const data = await this._api.getWhatsappStatusBySlug(this._auth.merchant.slug).toPromise<any>();
    this.loading = false;
    this.data = data.data;
  }

  add(item) {
    this.current = {
      name: item.name,
      price: 0,
      description: item.caption,
      images: [item.image]
    };
  }

  closeAdd() {
    this.current = null;
  }
}
