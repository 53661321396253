import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { take } from 'rxjs/operators';

interface IProduct {
  id: string | number;
  image: string;
  name: string;
  price: string;
  url: string;
  description: string;
}

@Component({
  selector: 'app-add-products-from-flutterwave',
  templateUrl: './add-products-from-flutterwave.component.html',
  styleUrls: ['./add-products-from-flutterwave.component.scss']
})
export class AddProductsFromFlutterwaveComponent implements OnInit {

  store = '';
  view = 'auth';
  items: IProduct[] = [];
  displayItems: IProduct[] = [];
  selected = null;
  page = 1;
  search = false;
  query = '';
  error = '';
  loading: boolean;
  confirmed = false;
  countdown = 20;
  private interval = null;

  constructor(private _api: ApiService) {
  }

  ngOnInit(): void {
  }

  authenticate() {
    if (!this.store) return;
    this.fetchProducts(1);
  }

  startCountDown() {
    this.countdown = 20;
    this.interval = setInterval(() => {
      if (this.countdown > 0) this.countdown--;
    }, 1000);
  }

  stopCountdown(cb?) {
    if (this.interval) clearInterval(this.interval);
    this.countdown = 0;
    if (cb) setTimeout(() => cb(), 1000);
  }

  fetchProducts(page: number) {
    if (page === 1) this.view = 'fetching';
    else this.loading = true;
    this.loading = true;
    const store = this.store.replace('https://flutterwave.com/store/', '');
    this.startCountDown();
    this._api.fetchProductsFromFlutterwave(store, page).pipe(take(1)).subscribe(data => {
      this.stopCountdown(() => {
        this.page = page;
        let items = data.map((item) => ({ ...item, id: item.url }));
        if (page > 1) items = [...this.items, ...items];
        this.items = items;
        this.displayItems = items;
        this.view = 'list';
        this.loading = false;
      });
    }, (err) => {
      this.stopCountdown();
      this.loading = false;
      this.view = 'error';
      this.error = err.error.message || 'Network error';
    });
  }

  loadMore() {
    this.fetchProducts(this.page + 1);
  }

  select(item: IProduct) {
    this.selected = {
      name: item.name,
      price: parseInt(item.price.replace(/₦|,|NGN| /gi, ''), 10),
      images: [item.image],
      description: item.description
    };
    this.view = 'create-product';
  }

  closeCreateProduct() {
    this.selected = null;
    this.view = 'list';
  }

  handleSearch() {
    const query = this.query;
    if (query.length) {
      this.displayItems = this.items.filter(p => {
        return p.name && p.name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.displayItems = this.items;
    }
  }

  stopSearch() {
    this.query = '';
    this.search = false;
    this.displayItems = this.items;
  }
}
