import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-welcome-settings',
  templateUrl: './welcome-settings.component.html',
  styleUrls: ['./welcome-settings.component.scss']
})
export class WelcomeSettingsComponent implements OnInit {
  @Output() close = new EventEmitter();

  carouselOption: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    items: 1,
  };
  installments = [
    { value: 7, text: '7', unit: 'Days' },
    { value: 15, text: '15', unit: 'Days' },
    { value: 30, text: '1', unit: 'Month' },
    { value: 60, text: '2', unit: 'Month' },
    { value: 90, text: '3', unit: 'Month' },
    { value: 180, text: '6', unit: 'Month' },
  ];
  rates = [0, 1, 3, 5, 7, 10];
  partnership = false;

  history: string[] = [];
  values = {
    max_credit_duration: 0,
    monthly_interest_rate: 0,
    upfront_rate: 0,
  };

  public get view(): string {
    return this.history[this.history.length - 1];
  }

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    public _auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.pushView('options');
  }

  pushView(view: string) {
    this.history.push(view);
  }

  popView() {
    this.history.pop();
  }

  selectInstallment(value) {
    this.values.max_credit_duration = value;
    this.pushView('upfront');
  }

  selectUpfront(value) {
    this.values.upfront_rate = value;
    this.pushView('interest-rate');
  }

  selectRate(value) {
    this.values.monthly_interest_rate = value;
    this.pushView('saving-credit-info');
    this._api.saveCreditInterestSettings(this.values).pipe(take(1)).subscribe(val => {
      this._general.notify('success', val.message || 'Saved');
      const merchant = { ...this._auth.merchant, ...this.values };
      this._auth.setMerchant(merchant);
      this.pushView('success');
    }, () => {
      this._general.notify('err', 'An error occurred, please try again');
      this.popView();
    });
  }

  async enablePartnership() {
    this.pushView('connecting');
    this._api.activatePartnership().subscribe(val => {
      const merchant = this._auth.merchant;
      this._auth.setMerchant({ ...merchant, partnership: 1 });
      this._general.notify('success', val.message);
      this.pushView('success');
    }, () => {
      this._general.notify('err', 'An error occurred, please try again');
      this.popView();
    });
  }
}
