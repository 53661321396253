<div class="card mb-4">
  <div class="mb-5 d-flex align-items-center">
    <h5 class="title m-0">Credit order</h5>
    <div *ngIf="loading" class="spinner-grow spinner-grow-sm ms-3" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center justify-content-between mb-3 pb-3 border-bottom">
    <div class="title">Enable collection</div>
    <div class="form-check form-switch">
      <input
        class="form-check-input" type="checkbox" id="collection" (change)="handleCollectionChange($event)"
        [checked]="merchant.collection === 1"
      />
    </div>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-between mb-3 pb-3 border-bottom">
    <div class="title">Run eligibility</div>
    <div class="form-check form-switch">
      <input
        class="form-check-input" type="checkbox" id="eligibility" (change)="handleEligibilityChange($event)"
        [checked]="merchant.eligibility === 1"
      />
    </div>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-between mb-3 pb-3 border-bottom">
    <div class="title">Run insurance</div>
    <div class="form-check form-switch">
      <input
        class="form-check-input" type="checkbox" id="insurance" (change)="handleInsuranceChange($event)"
        [checked]="merchant.insurance === 1"
      />
    </div>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-between">
    <div class="title">Use data collection widget</div>
    <div class="form-check form-switch">
      <input
        class="form-check-input" type="checkbox" id="widget" (change)="handleWidgetChange($event)"
        [checked]="merchant.widget === 1"
      />
    </div>
  </div>
</div>
