<div class="card d-none d-md-flex">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<div class="card d-md-none">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <div *ngIf="view === 'options'">
    <h3 class="title mb-5">
      Pull products from other merchants on Creditclan
    </h3>
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div (click)="pushView('filter')" class="card flex-row flex-md-column align-items-center px-5 py-5 text-center cursor-pointer w-100">
        <i class="fa fa-users mb-md-3 fa-2x text-primary me-3 me-md-0"></i>
        Search all stores
      </div>
      <div
        (click)="pushView('slug')"
        class="card flex-row flex-md-column align-items-center px-5 py-5 text-center cursor-pointer w-100 ms-md-3 mt-3 mt-md-0"
      >
        <i class="fa fa-user mb-md-3 fa-2x text-warning me-3 me-md-0"></i>
        Search specific store
      </div>
    </div>
  </div>

  <div *ngIf="view === 'slug'">
    <h5 class="title mb-5 d-flex align-items-center">
      <button (click)="popView()" class="btn btn-link px-0 me-3"><i class="fa fa-arrow-left"></i></button>
      Enter merchant slug
    </h5>
    <div class="form-group mb-4">
      <label for="shop-id">Merchant store link</label>
      <input [(ngModel)]="slug" type="text" class="form-control" id="shop-id"/>
    </div>
    <button (click)="searchBySlug()" [disabled]="!slug || loading" class="btn btn-primary mt-5">
      <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Search
    </button>
  </div>

  <div *ngIf="view === 'filter'">
    <h5 class="title mb-5 flex align-items-center">
      <button (click)="popView()" class="btn btn-link px-0 me-3"><i class="fa fa-arrow-left"></i></button>
      <!--    Filter options-->
      Enter search keyword
    </h5>
    <div class="form-group mb-4">
      <label for="keyword">Keyword</label>
      <input [(ngModel)]="keyword" type="text" class="form-control" id="keyword"/>
    </div>
    <div class="mt-5">
      <button (click)="searchStore()" type="submit" class="btn btn-primary" [disabled]="loading">
        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Search
      </button>
    </div>
    <!--  <form class="card p-4" [formGroup]="searchForm" (ngSubmit)="filter()">-->
    <!--    <div class="form-group mb-4">-->
    <!--      <label for="categories">Categories</label>-->
    <!--      <select class="form-control" id="categories" formControlName="category_id">-->
    <!--        <option *ngFor="let c of categories" [value]="c.id">{{ c.name }}</option>-->
    <!--      </select>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <p>Sizes</p>-->
    <!--      <div *ngFor="let s of sizes" class="custom-control custom-checkbox custom-control-inline">-->
    <!--        <input type="checkbox" class="custom-control-input" [id]="'size'+s.value" [attr.data-value]="s.value" [value]="s.value"-->
    <!--               (change)="handleSizesChange($event)">-->
    <!--        <label class="custom-control-label" [for]="'size'+s.value">{{ s.text }}</label>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <p>Genders</p>-->
    <!--      <div *ngFor="let g of genders" class="custom-control custom-checkbox custom-control-inline">-->
    <!--        <input type="checkbox" class="custom-control-input" [id]="'gender'+g.value" [attr.data-value]="g.value" [value]="g.value"-->
    <!--               (change)="handleGenderChange($event)">-->
    <!--        <label class="custom-control-label" [for]="'gender'+g.value">{{ g.text }}</label>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <p>Shipping source</p>-->
    <!--      <div *ngFor="let s of sources" class="custom-control custom-radio custom-control-inline">-->
    <!--        <input type="radio" [id]="'source'+s.value" [attr.data-value]="s.value" name="shipping_source" class="custom-control-input"-->
    <!--               [value]="s.value"-->
    <!--               formControlName="shipping_source">-->
    <!--        <label class="custom-control-label" [for]="'source'+s.value">{{ s.text }}</label>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <label for="price">Price</label>-->
    <!--      <div class="input-group">-->
    <!--        <input id="price" type="number" class="form-control override" placeholder="Min price" formControlName="min_price">-->
    <!--        <input type="number" class="form-control override" placeholder="Max price" formControlName="max_price">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <label for="discount">Discount</label>-->
    <!--      <input type="range" class="custom-range" id="discount" formControlName="discount" min="0" max="100">-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <p>Payment type</p>-->
    <!--      <div class="custom-control custom-radio custom-control-inline">-->
    <!--        <input type="radio" id="credit" name="on_credit" class="custom-control-input" value="1" formControlName="on_credit">-->
    <!--        <label class="custom-control-label" for="credit">On Credit</label>-->
    <!--      </div>-->
    <!--      <div class="custom-control custom-radio custom-control-inline">-->
    <!--        <input type="radio" id="outright" name="on_credit" class="custom-control-input" value="0" formControlName="on_credit">-->
    <!--        <label class="custom-control-label" for="outright">Outright</label>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <label for="installments">Monthly installments</label>-->
    <!--      <div class="input-group">-->
    <!--        <input id="installments" type="number" class="form-control override" placeholder="Min monthly installments"-->
    <!--               formControlName="min_no_of_months">-->
    <!--        <input type="number" class="form-control override" placeholder="Max monthly installments" formControlName="max_no_of_months">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="form-group mb-4">-->
    <!--      <label for="repayment">Monthly repayment</label>-->
    <!--      <div class="input-group">-->
    <!--        <input id="repayment" type="number" class="form-control override" placeholder="Min monthly repayment" formControlName="min_monthly_amount">-->
    <!--        <input type="number" class="form-control override" placeholder="Max monthly repayment" formControlName="max_monthly_amount">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="mt-5">-->
    <!--      <button type="submit" class="btn btn-primary" [disabled]="loading">-->
    <!--        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="loading"></i>Search-->
    <!--      </button>-->
    <!--    </div>-->
    <!--  </form>-->
  </div>

  <div *ngIf="view === 'list'">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h5 class="title m-0 d-flex align-items-center">
        <button (click)="popView()" class="btn btn-link px-0 me-3"><i class="fa fa-arrow-left"></i></button>
        Results ({{ pagination.pages * pagination.count }})
      </h5>
    </div>
    <div class="flex-row align-items-center mb-4 d-none d-md-flex">
      <input
        type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
        placeholder="Filter by name" [(ngModel)]="query" (input)="handleSearch()"
      />
    </div>
    <div *ngIf="selected.length" class="card bg-primary d-flex flex-row justify-content-between align-items-center mb-4 p-3 px-4"
         style="position: sticky; top: -30px; z-index: 1000000;">
      <h5 class="title text-white m-0">{{ selected.length }} item(s) selected</h5>
      <button (click)="addItems()" class="btn btn-outline-light" [disabled]="adding">
        <i class="fa fa-circle-notch fa-spin me-2" *ngIf="adding"></i>Add to store
      </button>
    </div>
    <div class="row" *ngIf="displayItems.length">
      <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let item of displayItems">
        <div class="box" [ngClass]="{selected: isSelected(item)}">
          <app-square-box (click)="select(item)" class="cursor-pointer">
            <img class="rounded w-100 fit-cover" [src]="item.primary_picture" alt="">
          </app-square-box>
        </div>
        <div [title]="item.name" class="title mt-2 text-ellipsize">{{ item.name }}</div>
        <div class="mt-1">{{ item.price | currency:'NGN':'₦':'0.0' }}</div>
      </div>
    </div>

    <div *ngIf="loading" class="row">
      <div class="col-6 col-md-4 col-lg-3 mb-3" *ngFor="let i of [1,2,3,4]">
        <div class="card p-0 skeleton fit-content">
          <img [src]="'assets/images/plus.svg'" alt="">
        </div>
        <div class="title skeleton fit-content mt-2">Product name</div>
        <small class="skeleton fit-content">N200,000</small>
      </div>
    </div>

    <div class="mt-4 text-center" *ngIf="pagination.next && !loading && items.length && items.length === displayItems.length">
      <button (click)="loadMore()" class="btn btn-link">Load more</button>
    </div>

    <button (click)="search = true" class="floating-action-button search-fab d-md-none" [ngClass]="{show: !search}">
      <i class="fa fa-search"></i>
    </button>

    <div class="mobile-search" [ngClass]="{show: search}">
      <div class="d-flex align-items-center" id="searchDiv">
        <input type="text" autocomplete="off" class="form-control override rounded-full form-control-lg m-0 mb-1"
               placeholder="Search" (input)="handleSearch()" [(ngModel)]="query"/>
        <button (click)="stopSearch()" class="search-button ms-2">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
