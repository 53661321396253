import { Component, Input, OnInit } from '@angular/core';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-profile-picture',
  templateUrl: './settings-profile-picture.component.html',
  styleUrls: ['./settings-profile-picture.component.css']
})
export class SettingsProfilePictureComponent implements OnInit {

  @Input() merchant: MerchantProfileDetails;
  imagePreview: string = null;
  imageFile: File = null;
  loading = false;

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private _auth: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  handleLogoChange(event: Event) {
    if (event.target['files'][0].size > 5000000) {
      this._general.notify('warning', 'Please upload pictures less than 5.0MB');
      return;
    }
    this.imageFile = event.target['files'][0];
    let reader: FileReader;
    if (FileReader) {
      reader = new FileReader();
      reader.onload = e => {
        this.imagePreview = `${ e.target['result'] }`;
        this.uploadPicture();
      };
      reader.readAsDataURL(event.target['files'][0]);
    }
  }

  uploadPicture() {
    if (!this.imageFile) this._general.notify('warning', 'You have not added any picture!');
    const fd = new FormData();
    fd.append('picture', this.imageFile);
    this.loading = true;
    this._api.updateProfilePicture(fd).subscribe(val => {
      const { message } = val;
      this._general.notify('success', message);
      this._auth.setMerchant({ ...this._auth.merchant, picture: this.imagePreview });
      this.loading = false;
    }, () => {
      this._general.notify('error', 'Failed to upload picture');
      this.loading = false;
    });
    return;
  }
}
