<div *ngIf="view === 'items'">
  <div *ngIf="items.length">
    <!--    <div class="card border rounded-0 bg-off-white d-flex flex-row justify-content-between align-items-center mt-4">-->
    <!--      <div class="text-center" (click)="modal = 'upfront-info'">-->
    <!--        <h5 class="title m-0">{{ request.upfront_amt | currency:'NGN':'₦':'0.0' }}</h5>-->
    <!--        <small class="m-0 mt-1">-->
    <!--          Upfront amount-->
    <!--          <span *ngIf="request.req_status >= 2 && request.upfront_paid == 1" class="badge bg-success ms-1">Paid</span>-->
    <!--          <span *ngIf="request.req_status < 2 && request.upfront_paid == 0" class="badge bg-danger ms-1">Not paid</span>-->
    <!--        </small>-->
    <!--      </div>-->
    <!--      <div class="text-center">-->
    <!--        <h5 class="title m-0">{{ request.total_credit  | currency:'NGN':'₦':'0.0' }}</h5>-->
    <!--        <small class="m-0 mt-2">-->
    <!--          Total credit-->
    <!--        </small>-->
    <!--      </div>-->
    <!--      <div class="text-center">-->
    <!--        <h5 class="title m-0">{{ request.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}</h5>-->
    <!--        <small class="m-0 mt-2">Total amount</small>-->
    <!--      </div>-->
    <!--    </div>-->
    <h5 class="title mb-5">Items Purchased</h5>

    <div class="d-flex">
      <button (click)="view = 'add'" class="mb-5 btn btn-outline-secondary">
        <i class="fa fa-plus me-3"></i><span>Add another item</span>
      </button>
    </div>

    <div class="d-flex align-items-center pb-3 mb-3 border-bottom" *ngFor="let item of items">
      <img [src]="item.primary_picture || 'assets/images/plus.svg'" alt="Item" class="rounded" style="width: 30px;"/>
      <div class="flex-grow-1 px-3">
        <div>{{ item.name }}</div>
        <small>{{ item.price | currency:'NGN':'₦':'0.0' }}</small>
      </div>
      <div class="input-group input-group-sm w-auto" style="flex-wrap: nowrap;">
        <div class="input-group-prepend">
          <button (click)="decrementQuantity(item.id)" class="btn btn-outline-secondary" type="button">-</button>
        </div>
        <input type="text" class="form-control override" [(ngModel)]="item.quantity" style="max-width: 40px;min-width: 40px;">
        <div class="input-group-append">
          <button (click)="incrementQuantity(item.id)" class="btn btn-outline-secondary" type="button">+</button>
        </div>
      </div>
      <button (click)="removeItem(item.id)" class="btn btn-outline-danger btn-sm ms-2">
        <i class="fa fa-trash"></i>
      </button>
    </div>

    <div *ngIf="itemsPrice() > request.cred_plus_upfront" class="alert alert-danger rounded mt-4">
      Total price of items cannot be larger than order amount
    </div>

    <div class="card bg-off-white mt-5 font-size-sm p-3">
      <div class="d-flex flex-row align-items-center justify-content-between border-bottom pb-2 mb-2">
        <small class="m-0">Total price of items</small>
        <div class="m-0 fw-bold">{{ itemsPrice() | currency:'NGN':'₦':'0.0' }}</div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-between">
        <small class="m-0">Order amount</small>
        <div class="m-0 fw-bold">{{ request.cred_plus_upfront | currency:'NGN':'₦':'0.0' }}</div>
      </div>
    </div>

    <button *ngIf="items.length" (click)="submitItems()" type="button" class="btn btn-primary mt-5" [disabled]="loading">Add items</button>
  </div>

  <div *ngIf="!items.length">
    <h5 class="title mb-5">Add product</h5>
    <div class="card mb-4 p-5 text-center cursor-pointer mt-4" (click)="view = 'add'">
      <br><br>
      <i class="fa fa-plus fa-2x mb-2 text-warning"></i> Click to add
      <br><br>
    </div>
  </div>
</div>

<div *ngIf="view === 'add'">
  <form [formGroup]="productForm">
    <!-- Product form -->
    <div>
      <h5 class="title mb-5">
        <!--        <button (click)="view = 'items'" class="btn btn-sm btn-link px-0 me-3">-->
        <!--          <i class="fa fa-arrow-left"></i>-->
        <!--        </button>-->
        Setup product
      </h5>
      <label class="card p-0 mb-4 cursor-pointer" style="max-width: 100px">
        <input hidden type="file" id="take-picture" accept="image/*" (change)="fileChangeEvent($event)"/>
        <img [src]="productForm.get('primary_picture').value || 'assets/images/plus.svg'" class="rounded" alt=""/>
      </label>
      <div class="form-group mb-4">
        <label for="name">Product name</label>
        <input formControlName="name" class="form-control m-0" type="text" id="name"/>
      </div>
      <div class="d-flex align-items-center">
        <div class="form-group flex-grow-1">
          <label for="price">Product price</label>
          <input formControlName="price" class="form-control m-0" type="number" id="price"/>
          <small class="text-danger" *ngIf="priceMismatch">
            Product Price cannot be bigger than credit price
          </small>
        </div>
        <div class="form-group ms-3">
          <label for="name">Quantity</label>
          <div class="input-group w-auto mt-2" style="flex-wrap: nowrap;">
            <div class="input-group-prepend">
              <button
                class="btn btn-outline-secondary" type="button"
                (click)="productForm.get('quantity').setValue(max(1, +productForm.value.quantity - 1))"
              >-
              </button>
            </div>
            <input type="number" class="form-control override" formControlName="quantity" style="max-width: 60px;min-width: 60px;"/>
            <div class="input-group-append">
              <button
                (click)="productForm.get('quantity').setValue(+productForm.value.quantity + 1)"
                class="btn btn-outline-secondary" type="button"
              >+
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <!-- <button (click)="pushView('search')" class="btn btn-link px-0">Search from store</button> -->
        <button (click)="submitNewProductForm()" type="button" [disabled]="productForm.invalid" class="btn btn-primary">
          Add product
        </button>
      </div>
    </div>
  </form>
</div>

<app-dialog *ngIf="modal === 'cropping'" [closable]="false" [nested]="true" [centered]="true">
  <div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)" [maintainAspectRatio]="true" [aspectRatio]="1"
      [resizeToWidth]="500" [resizeToHeight]="500" [cropperMinWidth]="500" [cropperMinHeight]="500" [onlyScaleDown]="true" format="jpeg"
      [imageQuality]="92"
    >
    </image-cropper>
  </div>
  <div class="text-center mt-5">
    <button (click)="imageCroppingDone()" type="button" class="btn btn-success">Done</button>
  </div>
</app-dialog>
