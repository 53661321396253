import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { NigeriaBanks, NigerianBank } from 'src/app/models/nigerianBanks';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-account-details',
  templateUrl: './settings-account-details.component.html',
  styleUrls: ['./settings-account-details.component.css']
})
export class SettingsAccountDetailsComponent implements OnInit, OnChanges {
  updateBankInfoForm: FormGroup;
  @Input() merchant: MerchantProfileDetails;
  public nigerianBanks: Array<NigerianBank> = NigeriaBanks().data;
  loading: boolean;

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private fb: FormBuilder,
    private _auth: AuthService
  ) {
    this.updateBankInfoForm = this.fb.group({
      bank_code: ['', Validators.required],
      account_number: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      bank_id: [''],
      account_name: ['']
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { account_name, account_number, bank_id } = changes.merchant.currentValue;
    let { bank_code } = changes.merchant.currentValue;
    if (bank_code) bank_code = ('0' + bank_code).slice(-3);
    this.updateBankInfoForm.patchValue({ account_name, account_number, bank_id, bank_code });
    if (account_name) this.updateBankInfoForm.disable();
  }

  change() {
    this.updateBankInfoForm.get('account_name').setValue('');
    this.updateBankInfoForm.enable();
  }

  verifyAccount() {
    const { account_number, bank_code } = this.updateBankInfoForm.value;
    const bank_id = this.nigerianBanks.find(n => n.bank_code === bank_code).id;
    const payload = { account_number, bank_code };
    this.loading = true;
    this.updateBankInfoForm.disable();
    this._api.getAccountDetails(payload).subscribe((res) => {
      this.loading = false;
      if (res.status === 'success') {
        this.updateBankInfoForm.get('account_name').setValue(res.data.account_name);
        this.updateBankInfoForm.get('bank_id').setValue(bank_id);
        this.submit();
      } else {
        this.updateBankInfoForm.enable();
        this._general.notify('error', res.message);
      }
    }, () => {
      this.loading = false;
      this.updateBankInfoForm.enable();
      this._general.notify('error', 'Could not confirm you bank details. Please check that you entered the right details');
    });
  }

  submit() {
    this.loading = true;
    this.updateBankInfoForm.disable();
    this._api.updateBankInfo(this.updateBankInfoForm.value).subscribe(async ({ message }) => {
      this.loading = false;
      this.updateBankInfoForm.enable();
      const completion_details = { ...this.merchant.completion_details, bank_details: true };
      this._auth.updateMerchant({ ...this.updateBankInfoForm.value, completion_details });
      this._general.notify('success', message);
    }, err => {
      this.loading = false;
      this._general.notify('error', err.error.message || 'An error occurred!');
    });
  }
}
