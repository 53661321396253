import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-search-requests',
  templateUrl: './search-requests.component.html',
  styleUrls: ['./search-requests.component.css']
})
export class SearchRequestsComponent implements OnInit {
  query = '';
  searchResults = [];
  searching = false;
  @Output() view = new EventEmitter();

  constructor(private _api: ApiService) {
  }

  ngOnInit(): void {
  }

  search() {
    if (this.query) {
      this.searching = true;
      this._api.searchOrdersOrRepaymentOrSales({ search: this.query, page_number: '1', orders: true }).subscribe(({ data }: any) => {
        this.searching = false;
        this.searchResults = data;
      }, (err) => {
        console.log({ err });
      });
    }
  }
}
