import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';
import { MerchantProfileDetails } from '../../models/merchantProfile';

@Component({
  selector: 'app-settings-theme-color',
  templateUrl: './settings-theme-color.component.html',
  styleUrls: ['./settings-theme-color.component.scss']
})
export class SettingsThemeColorComponent implements OnInit {
  @Input() merchant: MerchantProfileDetails;
  colors = [
    '#3F51B5', // Indigo
    '#2196F3', // Blue
    '#F44336', // Red
    '#E91E63', // Pink
    '#9C27B0', // Purple
    '#03A9F4', // Light blue
    '#00BCD4', // Cyan
    '#009688', // Teal
    '#4CAF50', // Green
    '#8BC34A', // Light green
    '#FDD835', // Yellow
    '#FFC107', // Amber
    '#FF9800', // Orange
    '#FF5722', // Deep orange
    '#795548', // Brown
    '#607D8B', // Blue grey
    '#9E9E9E', // Grey
  ];
  loading = false;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  async saveThemeColor(color) {
    this.loading = true;
    const res = await this._api.updateOnlineStore({ theme_color: color }).toPromise<any>();
    this._general.notify('success', res.message || 'Saved');
    this._auth.updateMerchant({ theme_color: color });
    this.loading = false;
  }
}
