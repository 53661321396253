import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MerchantProfileDetails } from '../../models/merchantProfile';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings-collection-filter',
  templateUrl: './settings-collection-filter.component.html',
  styleUrls: ['./settings-collection-filter.component.scss']
})
export class SettingsCollectionFilterComponent implements OnInit, OnChanges {

  @Input() merchant: MerchantProfileDetails;
  loading = false;
  options = [
    { id: 'top', text: 'Top' },
    { id: 'side-short', text: 'Side short' },
    { id: 'side-long', text: 'Side long' },
  ];
  filter_position = 'side-short';

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const merchant: MerchantProfileDetails = changes.merchant.currentValue;
    const { filter_position } = merchant;
    this.filter_position = filter_position || 'side-short';
  }

  handleChange(value) {
    this.save(value);
  }

  async save(filter_position) {
    this.loading = true;
    const res = await this._api.updateOnlineStore({ filter_position }).toPromise<any>();
    this._general.notify('success', res.message || 'Saved');
    this._auth.updateMerchant({ filter_position });
    this.loading = false;
  }
}
