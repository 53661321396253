<div
  *ngIf="loading"
  class="d-flex justify-content-center align-items-center p-5"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div *ngIf="noData && !loading" class="col-12 text-center">
  <img
    src="assets/images/Empty-rafiki.svg"
    class="mx-auto"
    style="max-width: 260px;"
    alt=""
  />
  <h3 *ngIf="day == '4'; else Others" class="text-dark mb-0">
    We couldn't fetch any defaulting repayments.
  </h3>
  <ng-template #Others>
    <h3 class="text-dark mb-0">
      We couldn't fetch any repayment for {{ day }}
    </h3>
  </ng-template>
  <p class="mb-3 pt-2">
    When you have some repayment reports they will show up here
  </p>
  <!-- /repayment-reports -->
  <button
    [routerLink]="['/repayment-reports']"
    class="btn btn-md btn-outline-primary secondary mt-4"
    style="cursor: pointer;"
  >
    Go back
  </button>
  <br/>
  <br/>
  <br/>
  <br/>
</div>
