<div *ngIf="!view && loading" class="d-flex justify-content-center align-items-center p-5">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div *ngIf="view === 'no-account'" class="empty d-flex flex-column align-items-center text-center">
    <img src="https://www.flaticon.com/svg/static/icons/svg/3388/3388466.svg" alt="No account number" width="100">

    <h5 class="title fw-bold mb-4 mt-5">No account number</h5>
    <div style="max-width: 300px; margin: auto;">
        Account number not provided by customer, click the button below to
        request for account number
    </div>

    <button class="btn btn-outline-primary mt-5">Send Request</button>
</div>

<div *ngIf="view === 'details'">
    <button class="btn btn-link px-0 btn-sm mb-5" (click)="back.emit()">
        <i class="fa fa-arrow-left me-2"></i>Back
    </button>
    <h5 class="title mb-5">
        Bank statement analysis
        <small class="d-block mt-1">
            Get consent from customer to run analysis on their bank statement for a better credit analysis result
        </small>
    </h5>
    <div class="border-bottom pb-3 mb-3">
        <div class="title fw-bold">{{ request.account_name }}</div>
        <small>Account name</small>
    </div>
    <div class="border-bottom pb-3 mb-3">
        <div class="title fw-bold">{{ request.account_number }}</div>
        <small>Account number</small>
    </div>
    <div class="border-bottom pb-3 mb-3">
        <div class="title fw-bold">{{ request.phone_no }}</div>
        <small>Phone number</small>
    </div>
    <button (click)="start()" class="btn btn-primary mt-5" [disabled]="loading">
        <i *ngIf="loading" class="fa fa-circle-notch fa-spin me-2"></i> Start
    </button>
</div>

<div *ngIf="view === 'verification'">
    <button class="btn btn-link px-0 btn-sm mb-5" (click)="view = 'details'">
        <i class="fa fa-arrow-left me-2"></i>Back
    </button>
    <h5 class="title mb-5">Verify ticket number
        <small class="d-block mt-1">
            A ticket number and password has been sent to customer's phone number, enter information below to
            continue
        </small>
    </h5>
    <form [formGroup]="verifyForm" (submit)="submitVerifyForm()">
        <div class="form-group">
            <label for="ticket">Ticket number</label>
            <input formControlName="ticketNo" type="text" class="form-control" id="ticket">
        </div>
        <div class="form-group">
            <label for="ticket">Password</label>
            <input formControlName="password" type="password" class="form-control" id="ticket">
        </div>
        <button type="submit" [disabled]="verifyForm.invalid || loading" class="btn btn-primary mt-5">
            <i *ngIf="loading" class="fa fa-circle-notch fa-spin me-2"></i> Verify
        </button>
    </form>
</div>

<div *ngIf="view === 'analysis-complete'" class="empty d-flex flex-column align-items-center text-center">
    <img src="https://www.flaticon.com/svg/static/icons/svg/3388/3388612.svg" alt="No account number" width="100">

    <h5 class="title fw-bold mb-4 mt-5">Analysis complete</h5>
    <div style="max-width: 300px; margin: auto;">
        Bank statement analysis is complete, click the link below to view analysis result
    </div>

    <a target="_blank" class="btn btn-primary mt-5">View result</a>
</div>

<div *ngIf="view === 'error'" class="empty d-flex flex-column align-items-center text-center">
    <img src="assets/images/svg/crossed.svg" alt="No account number" width="100">

    <h5 class="title fw-bold mb-4 mt-5">An error occurred</h5>
    <div style="max-width: 300px; margin: auto;">
        {{ error }}
    </div>

    <button (click)="back.emit()" class="btn btn-outline-secondary mt-5">
        <i class="fa fa-arrow-left me-2"></i> Back
    </button>
</div>
