<div class="row wrap">
  <div class="col-0 col-sm-6 left-content py-5 d-none d-md-block"></div>
  <div class="col-12 col-md-6 left">
    <div class="content">
      <div class="my-auto">
        <!-- Do you sell online -->
        <div *ngIf="view === 'question'">
          <h5 class="title mb-5">
            Do you sell on whatsapp status
          </h5>
          <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer" (click)="pushView('whatsapp-business')">
            Yes I do
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer" (click)="pushView('create-account')">
            No I dont
            <i class="fas fa-angle-right float-right ms-2"></i>
          </div>
          <p class="mt-5">
          <span class="d-block mt-3">
            Already have an account ?
            <a routerLink="/login" class="text-primary" id="login">Login</a>
          </span>
          </p>
        </div>

        <!-- Provide Whatsapp -->
        <div *ngIf="view === 'whatsapp-business'">
          <button class="btn btn-link mb-4 px-0" (click)="popView()">
            <i class="fas fa-arrow-left me-2"></i> Back
          </button>
          <h5 class="title mb-3">
            Kindly provide your WhatsApp business number
          </h5>
          <p class="text-muted mb-5">Allow customers pay up to thrice on whatsapp</p>
          <div class="form-group">
            <label for="whatsapp_no"></label>
            <input [(ngModel)]="whatsapp_no" type="tel" class="form-control form-control-lg" id="whatsapp_no" placeholder="Enter whatsapp number">
          </div>
          <button (click)="pushView('create-account')" [disabled]="whatsapp_no.length < 11" class="btn btn-primary mt-4">Next</button>
          <p class="mt-4">
          <span class="d-block mt-3">
            No I dont sell on whatsapp business
            <button (click)="whatsapp_no = ''; pushView('create-account')" class="text-primary btn btn-link px-0">Skip</button>
          </span>
          </p>
        </div>

        <!-- Create account -->
        <div *ngIf="view === 'create-account'">
          <div class="">
            <button (click)="popView()" class="btn btn-link mb-4 px-0">
              <i class="fas fa-arrow-left me-2"></i>Back
            </button>
            <h5 class="title mb-5">
              Finish setting up your account
            </h5>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="merchantForm" class="m-0">
                  <div class="form-group mb-4 required-field">
                    <label for="businessName">Business name</label>
                    <input type="text" formControlName="name" class="form-control" id="businessName"/>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group mb-4 required-field">
                        <label for="email">Email or phone</label>
                        <input formControlName='emailOrPhone' type="email" class="form-control" id="email"/>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-4 required-field">
                    <label for="inputPassword">Password</label>
                    <input formControlName="password" type="password" id="inputPassword" class="form-control"
                           aria-describedby="passwordHelpInline" autocomplete="off"/>
                    <small id="passwordHelpInline" class="text-muted">
                      Must be 8-20 characters long.
                    </small>
                  </div>

                  <div class="form-group form-check d-flex align-items-center mt-5">
                    <input type="checkbox" class="form-check-input mt-0" id="exampleCheck1" [(ngModel)]="termsAccepted"
                           [ngModelOptions]="{standalone: true}">
                    <label class="form-check-label ms-2" for="exampleCheck1">I accept
                      <a class="text-primary">terms and conditions</a>
                    </label>
                  </div>

                  <div class="mt-5">
                    <button [disabled]="!merchantForm.valid || !termsAccepted || loading" (click)="submitDetails()"
                            class="btn btn-lg btn-login btn-primary">
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- Creating -->
        <div *ngIf="view === 'creating'">
          <div class="loader-bounce">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
          <div class="text-center mt-5">
            <h5 class="w-75 my-0 mx-auto">
              Creating your page
            </h5>
            <p class="mt-3 text-muted mx-auto" style="max-width: 300px">No doubt your sales will blow by 500%</p>
          </div>
        </div>

        <!-- Success -->
        <div *ngIf="view === 'success'">
          <div class="mx-auto text-center d-flex flex-column align-items-center">
            <img src="../../../../assets/images/svg/shopping-cart-success.svg" class="mx-auto rounded mb-2 mt-5" alt="" style="max-width: 200px;"/>
            <div class="mt-5 position-relative text-center">
              <h5 class="title mb-2">Congratulations!</h5>
              <div class="title">
                Your store has been setup successfully
              </div>
            </div>
            <a routerLink="/dashboard" class="btn mt-5 btn-primary btn-lg">
              Continue to store
            </a>
            <button (click)="share()" class="btn btn-link mt-2">
              Share link online
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
