import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-settings-tags',
  templateUrl: './settings-tags.component.html',
  styleUrls: ['./settings-tags.component.scss']
})
export class SettingsTagsComponent implements OnInit {
  tagForm: any;
  loading = false;
  tags = [];

  constructor(
    public _general: GeneralService,
    private fb: FormBuilder,
    private apiservice: ApiService,
    public _auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.tagForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.getTags();
  }

  async addTag() {
    if (this.tagForm.invalid) return;
    this.loading = true;
    this.tagForm.disable();
    const res = await this.apiservice.addTag(this.tagForm.value.name).toPromise<any>();
    this.tags.push(res.data);
    this.loading = false;
    this.tagForm.enable();
    this.tagForm.reset();
  }

  async getTags() {
    const res = await this.apiservice.getTags().toPromise<any>();
    this.tags = res.data;
  }
}
