import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductCategories } from 'src/app/models/categories';
import { Merchant } from 'src/app/models/merchant';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './onboarding-whatsapp.component.html',
  styleUrls: ['./onboarding-whatsapp.component.scss']
})
export class OnboardingWhatsappComponent implements OnInit, OnDestroy {
  categories: Array<{ id: number; name: string }> = ProductCategories().data;
  merchantForm: FormGroup;
  whatsapp_no = '';
  view = '';
  termsAccepted = false;
  loading = false;
  link: string;
  history: string[] = [];

  constructor(
    private fb: FormBuilder,
    private _general: GeneralService,
    private _auth: AuthService,
    private _api: ApiService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    let publisher = this.activatedRoute.snapshot.queryParamMap.get('publisher');
    if (publisher !== null && publisher === '1') publisher = '1';
    else publisher = '0';

    this.merchantForm = this.fb.group({
      emailOrPhone: ['', [Validators.required, Validators.pattern(/(^[0]\d{10}$)|(^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$)/)]],
      name: ['', Validators.required],
      password: ['', Validators.required],
      business_type: ['1', Validators.required],
      agent_no: [''],
      online: ['0'],
      publisher
    });

    this.pushView('question');
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'auto';
  }

  pushView(view: string) {
    this.history.push(view);
    this.view = view;
  }

  popView() {
    this.history.pop();
    this.view = this.history[this.history.length - 1];
  }

  submitDetails() {
    const { emailOrPhone, ...rest } = this.merchantForm.value;
    const payload = {
      ...rest,
      [!!parseInt(emailOrPhone, 10) ? 'phone' : 'email']: emailOrPhone
    };
    if (this.whatsapp_no) payload['whatsapp_no'] = this.whatsapp_no;
    this.register(payload);
  }

  register(payload: Partial<Merchant>) {
    this.loading = true;
    this.pushView('creating');
    this._api.register(payload).subscribe(async val => {
      const { completion_details, token } = val;
      this._auth.saveToken(token);
      const { global_catalogue_link } = completion_details;
      this.link = global_catalogue_link;
      this.loading = false;
      this.pushView('success');
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      const { status, error: e } = err;
      if (status >= 400 && status < 500) {
        this._general.notify('error', e.message);
      } else if (status === 500) {
        this._general.notify('error', e.message);
      } else {
        this._general.notify('error', 'It seems you entered incorrect details. Check what you entered and try again.');
      }
      this.popView();
    });
  }

  share() {
    const payload = { info: { description: null, link: this.link } };
    this._general.broadCastSocialShare(payload);
  }
}
