<div *ngIf="!_auth.merchant.sender_id" class="d-flex flex-column align-items-center text-center py-5">
  <img src="/assets/images/svg/no-connection.svg" alt="no connection" class="w-25">
  <h4 class="mt-5 mb-0">Enable Whatsapp integration</h4>
  <p class="mt-3">Connect your whatsapp to enable whatsapp shopping and add products from your whatsapp status</p>
  <button (click)="connect.emit()" class="btn btn-primary mt-4">Connect whatsapp</button>
</div>

<ng-template [ngIf]="_auth.merchant.sender_id">
  <div *ngIf="loading && !data.length">
    <br><br><br>
    <div class="loader-bounce">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
    <div class="title mt-4 text-center w-75 mx-auto font-italic">
      Fetching your whatsapp status
    </div>
    <br><br><br>
  </div>
  <div *ngIf="!loading && !data.length" class="d-flex flex-column align-items-center text-center py-5">
    <img src="/assets/images/svg/empty-box.svg" alt="no connection" class="w-25">
    <h4 class="mt-5 mb-0">You have not posted any status</h4>
    <p class="mt-3">Statuses you post on your whatsapp will appear here</p>
    <button (click)="getData()" class="btn btn-outline-secondary mt-2">
      <i class="fa fa-redo-alt me-2"></i> Retry
    </button>
  </div>
  <ng-template [ngIf]="!loading && data.length" class="items">
    <ng-template [ngIf]="!current">
      <h5 class="title">Add products from your Whatsapp status</h5>
      <div *ngFor="let item of data" class="card d-flex flex-row align-items-center p-3 mt-2">
        <app-square-box class="w-100" style="max-width: 120px">
          <img class="w-100 h-100" [src]="item.image" alt="status"/>
        </app-square-box>
        <div class="d-flex flex-column ms-4">
          <div>{{ item.caption || 'No caption' }}</div>
          <button (click)="add(item)" class="btn btn-outline-secondary btn-sm mt-2">Add product</button>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="current">
      <app-create-product-embedded [item]="current" (close)="closeAdd()"></app-create-product-embedded>
    </ng-template>
  </ng-template>
</ng-template>
