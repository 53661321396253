<div class="mt-5" *ngIf="loading">
  <div class="d-flex align-items-center mb-3 border-bottom pb-3 px-4" *ngFor="let i of [1,2,3]">
    <div class="skeleton fit-content" style="width: 50px;height: 50px;border-radius: 50%!important;"></div>
    <div class="d-flex flex-column flex-grow-1 justify-content-between ms-3">
      <h6 class="mb-1 skeleton">Lorem, ipsum.</h6>
      <small class="skeleton">Lorem ipsum dolor sit amet.</small>
    </div>
  </div>
</div>
<div class="mt-5" *ngIf="!loading && comments.length">
  <div class="d-flex align-items-center mb-3 border-bottom pb-3 px-4" *ngFor="let comment of comments">
    <img src="assets/images/placeholder-profile.png" alt="" class="fit-cover rounded-circle"
         style="width: 50px;height: 50px;">
    <div class="d-flex flex-column flex-grow-1 justify-content-between ms-3">
      <h6 class="mb-1 d-flex justify-content-between align-items-center">
        {{ comment.name }}
        <small class="text-muted">{{ comment.customer_rating }}/5</small>
      </h6>
      <p class="m-0">{{ comment.comment }}</p>
    </div>
  </div>
</div>

<div class="d-flex flex-column align-items-center justify-content-center" *ngIf="!loading && !comments.length">
  <br><br><br><br>
  <i class="fa fa-ban text-muted fa-2x"></i>
  <p class="mt-4">No comments</p>
</div>
