<app-header [currentRoute]="'repayments'"></app-header>

<app-default-layout>
  <div class="row">
    <div class="col-md-9">
      <nav class="card py-3 px-4 d-none d-md-flex justify-content-between align-items-center flex-row mb-4">
        <h5 class="title m-0">Credit orders</h5>
        <div class="d-inline-flex align-items-center">
          <label class="form-group m-0">
            <input
              type="search" name="search" class="form-control form-control-lg override rounded-full me-2" placeholder="Search.." #search
            />
          </label>
          <div class="form-group m-0 ms-4">
            <select name="orderby" class="form-control m-0">
              <option value="" selected>Sort by Today</option>
              <option value="week">Sort by this week</option>
              <option value="month">Sort by this month</option>
              <option value="repayments">All repayments</option>
              <option value="repayments">Defaulting payments</option>
            </select>
          </div>
        </div>
      </nav>

      <div *ngIf="items.length < 1 && !loading" class="col-12 text-center">
        <div *ngIf="!searched.meta">
          <br><br><br><br>
          <img src="/assets/images/Empty-rafiki.svg" class="mx-auto" style="max-width: 260px;" alt=""/>
          <h3 class="text-dark mb-0">
            No repayments
          </h3>
          <p class="mb-3 pt-2">
            There are no pending credit
          </p>
          <br><br><br><br>
        </div>
        <div *ngIf="searched.meta" class="d-flex flex-column align-items-center text-center">
          <br><br><br><br><br>
          <div class="d-flex align-items-center justify-content-center p-3"
               style="width: 100px; height: 100px; background: rgba(0, 0, 0, .03); border-radius: 50%;">
            <img src="assets/images/svg/search-error.svg" alt="Search error" style="width: 50px;">
          </div>
          <h5 class="text-muted mt-4">No result</h5>
          <br><br><br><br><br>
        </div>
      </div>

      <div *ngIf="!loading">
        <div *ngFor="let repayment of items; index as i" class="mb-3 mb-md-4">
          <div class="card d-flex flex-row align-items-center p-0 cursor-pointer" [id]="repayment?.id"
               (click)="viewARepaymentSchedule(repayment)">
            <div class="modal-img-transact-container" style="position: relative;">
              <img [src]="repayment?.picture || 'assets/images/placeholder-profile.png'" alt="img-trasact"/>
            </div>
            <div class="modal-transact-content flex-grow-1 ms-md-4 px-2 pe-md-4 pt-3 pb-2">
              <div class="d-flex flex-row align-items-start align-items-md-center py-1">
                <img [src]="repayment?.picture || 'assets/images/placeholder-profile.png'" alt="img-trasact"
                     class="ms-2 me-3 d-md-none rounded-circle img-mobile"
                     [class]="repayment?.amount_paid ? 'ring-green' : 'ring-red'"/>
                <div>
                  <h5 class="product-title mb-1">
                    {{ repayment?.full_name }}&nbsp;•&nbsp;{{ repayment?.phone }}
                  </h5>
                  <div class="font-size-md">
                    <span class="text-muted">Paid: </span>
                    {{ repayment?.amount_paid | currency:'NGN':'₦':'0.0' }}
                  </div>
                  <div class="font-size-md">
                    <span class="text-muted">Balance: </span>
                    {{ (repayment?.total_repayment_amount - repayment?.amount_paid) | currency:'NGN':'₦':'0.0' }}
                  </div>
                  <div class="progress w-100 mt-1 mt-2 mt-md-3 mb-2" style="height: 4px;">
                    <div class="progress-bar progress-bar-animated bg-success" role="progressbar" aria-valuenow="20"
                         aria-valuemin="0" aria-valuemax="100"
                         [ngStyle]="{ width: repayment?.percentage_repayment_completion + '%' }"></div>
                  </div>
                </div>
              </div>

              <div class="mt-4 border-bottom pb-4 d-none d-md-block">
                <owl-carousel-o [options]="myRepaymentCarouseloptions">
                  <ng-container *ngFor="let item of repayment?.items; index as i">
                    <ng-template [width]="widthNumber" carouselSlide>
                      <div class="parentImg m-0 position-relative">
                        <img [src]="item?.primary_picture" [alt]="item?.name" [title]="item?.name"
                             style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;" class="me-2"/>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>

              <hr class="divider mt-0 mb-2 d-none d-md-block"/>

              <div class="mt-md-4 d-none d-md-block">
                <button (click)="viewARepaymentSchedule(repayment)" class="btn btn-outline-secondary btn-sm">
                  View details
                </button>
              </div>

              <hr class="divider my-2 d-md-none"/>

              <div class="d-flex d-md-none align-items-center justify-content-around">
                <div class="flex-grow-1 text-center">
                  <button (click)="viewARepaymentSchedule(repayment)" class="btn btn-link btn-sm">
                    <i class="fa fa-clipboard-list me-1"></i>
                    View details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="loading || fetching">
        <div *ngFor="let item of [1,2]; index as i" class="mb-3 mb-md-4">
          <div class="card d-flex flex-row align-items-center p-0">
            <div class="modal-img-transact-container skeleton fit-content" style="position: relative;">
              <img src="/assets/images/plus.svg" alt="img-trasact"/>
            </div>
            <div class="modal-transact-content flex-grow-1 ms-md-4 ps-1 pe-2 pe-md-4 pt-2 pb-2">
              <div class="d-flex flex-row align-items-center">
                <div class="skeleton fit-content me-3 ms-2 d-md-none">
                  <img src="/assets/images/plus.svg" alt="img-trasact" class="d-md-none rounded img-mobile"/>
                </div>
                <div class="flex-grow-1">
                  <h5 class="product-title mb-1 d-flex align-items-center skeleton">Lorem, ipsum.</h5>
                  <h5 class="product-title fw-normal mb-1 skeleton">Lorem, ipsum dolor.</h5>
                  <div class="mt-2 skeleton">Lorem.</div>
                </div>
              </div>

              <div class="d-none d-md-flex mt-4">
                <div class="parentImg skeleton fit-content">
                  <img src="/assets/images/plus.svg"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
                <div class="parentImg skeleton fit-content ms-2">
                  <img src="/assets/images/plus.svg"
                       style="width: 70px; height: 70px; border-radius: 4px; object-fit: cover;"/>
                </div>
              </div>

              <div class="mt-md-4 d-none d-md-block">
                <button type="button" class="btn btn-outline-secondary btn-sm skeleton fit-content">
                  View details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 text-center" *ngIf="!searched.data.length && !loading && items.length && meta.next">
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
      <div class="mt-5 text-center" *ngIf="!loading && searched.data.length && searched.meta.next">
        <button (click)="loadMore()" class="btn btn-link">Load more</button>
      </div>
    </div>

    <div class="col-md-3 d-none d-md-flex">
      <div class="sticky-sidebar w-100">
        <div class="card mb-3 py-5 flex-column align-items-center text-center">
          <div class="h2 mb-2">{{ repayments.length }}</div>
          <div class="m-0 text-muted title">Total repayments</div>
        </div>
        <div class="card p-0">
          <ul class="mobile-menu">
            <li class="border-bottom">
              <a (click)="_general.navigateTo('/requests')">
                <i class="icon fa fa-exclamation-circle text-danger"></i>Pending orders
              </a>
            </li>
            <li>
              <a (click)="_general.navigateTo('/sales')">
                <i class="icon fa fa-door-closed text-success"></i>Closed orders
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</app-default-layout>

<app-dialog
  *ngIf="currentRepayment" (close)="closeRepaymentDetails($event)" #dialogView size="lg"
  title="Repayment details" [fullscreen]="true"
>
  <app-view-a-repayment [repayment]="currentRepayment" (close)="dialogView.close.emit($event)"></app-view-a-repayment>
</app-dialog>
