<div class="d-flex flex-column align-items-stretch h-100">
  <div *ngIf="view === 'options'">
    <div (click)="pushView('no-credit-meaning')" class="card">
      <h5 class="title m-0">Partner me with banks</h5>
      <p class="m-0 mt-1">Your customers buy on credit, bank pays</p>
      <div class="mt-3">
        <button class="btn btn-primary">Activate bank partnership</button>
      </div>
      <br><br><br><br><br><br>
      <div class="d-flex justify-content-end">
        <img src="/assets/images/svg/credit-card-payment.svg" alt="Bank partnership" style="width: 200px">
      </div>
    </div>
    <div (click)="pushView('credit-duration')" class="card mt-4 d-flex flex-row align-items-center justify-content-between cursor-pointer">
      <h5 class="title m-0">Let me manage credit myself</h5>
      <i class="fa fa-arrow-right ml-2"></i>
    </div>
  </div>

  <div *ngIf="view === 'do-you-sell-online'">
    <h3 class="title mb-5">
      Would you like to sell on credit
    </h3>
    <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
         (click)="pushView('should-allow-bank')">
      <span class="fw-bold">Yes, I'm interested</span>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
    <div class="card flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
         (click)="close.emit()">
      <div>
        <span class="fw-bold">No credit, not now</span>
        <small class="d-block mt-1 text-muted">
          You may be missing out on up to 200% growth in your sales
        </small>
      </div>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
  </div>

  <div *ngIf="view === 'should-allow-bank'">
    <h3 class="title mb-5">
      Would you handle credit yourself or allow banks pay for your transaction
    </h3>
    <div class="card bg-off-white flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer" (click)="pushView('credit-duration')">
      <div>
        <span class="fw-bold">Yes, I would handle credit</span>
        <ul class="mt-3 ps-3 font-size-md">
          <li>We help you with analysis and recommend what to give out</li>
          <li>We help with collection (Online and agents)</li>
          <li>We help with notifications</li>
          <li>We take 5% of anything we collect.</li>
        </ul>
      </div>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
    <div class="card bg-off-white flex-row align-items-center justify-content-between p-4 mb-3 cursor-pointer"
         (click)="pushView('no-credit-meaning')">
      <div>
        <span class="fw-bold">No connect with banks</span>
        <ul class="mt-3 ps-3 font-size-md">
          <li>You offer your customers to buy on credit</li>
          <li>If buyer is eligible, you get credited by bank upon receipt confirmation.</li>
        </ul>
      </div>
      <i class="fas fa-angle-right float-right ms-2"></i>
    </div>
    <button (click)="close.emit()" class="btn btn-link px-0 mt-2">Skip for now</button>
  </div>

  <div *ngIf="view === 'credit-meaning'" class="text-center">
    <button class="btn btn-link mb-4 px-0" (click)="popView()">
      <i class="fas fa-arrow-left me-2"></i> Back
    </button>
    <h5 class="title mb-5 mx-auto">
      What this means
    </h5>
    <owl-carousel-o [options]="carouselOption" class="text-center">
      <ng-template carouselSlide>
        <div class="px-5 pt-5 text-center">
          <img src="/assets/images/svg/deliveries.svg" style="max-width: 200px;" alt="illustration" class="mx-auto">
          <p class="mt-5" style="max-width: 400px; margin: auto;">
            You will setup a store and get everyone to pull from it and get the rest to connect
          </p>
          <button (click)="pushView('credit-duration')" class="btn btn-primary mt-5 mb-4">
            Get started
          </button>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>

  <div *ngIf="view === 'no-credit-meaning'">
    <button class="btn btn-link mb-4 px-0" (click)="popView()">
      <i class="fas fa-arrow-left me-2"></i> Back
    </button>
    <app-settings-bank-partnership (done)="pushView('success')" [merchant]="_auth.merchant" [start]="true"></app-settings-bank-partnership>
  </div>

  <div *ngIf="view === 'credit-duration'">
    <button class="btn btn-link mb-4 px-0" (click)="popView()">
      <i class="fas fa-arrow-left me-2"></i> Back
    </button>
    <h3 class="title mb-5">
      What is your maximum credit duration
    </h3>
    <div class="row mb-4">
      <div (click)="selectInstallment(installment.value)" *ngFor="let installment of installments" class="col-4">
        <div class="card py-4 px-2 mb-3 bg-off-white cursor-pointer text-center"
             (click)="selectInstallment(installment.value)">
          <div class="h4 m-0">{{ installment.text }}</div>
          <small>{{ installment.unit }}</small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'upfront'">
    <button class="btn btn-link mb-4 px-0" (click)="popView()">
      <i class="fas fa-arrow-left me-2"></i> Back
    </button>
    <h3 class="title mb-5">
      Select default upfront rate for all products on credit
    </h3>
    <div class="row mb-4">
      <div *ngFor="let rate of [5,10,15,20,25,30,40,50]" class="col-4">
        <div class="card py-4 px-2 mb-3 bg-off-white cursor-pointer text-center" (click)="selectUpfront(rate)">
          <div class="h4 m-0">{{ rate }}%</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'interest-rate'">
    <button class="btn btn-link mb-4 px-0" (click)="popView()">
      <i class="fas fa-arrow-left me-2"></i> Back
    </button>
    <h3 class="title mb-5">
      At what interest rate per month
    </h3>
    <div class="row mb-4">
      <div *ngFor="let rate of rates" class="col-4">
        <div class="card py-4 px-2 mb-3 bg-off-white cursor-pointer text-center" (click)="selectRate(rate)">
          <div class="h4 m-0">{{ rate }}%</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'connecting'">
    <br><br><br>
    <div class="loader-bounce">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
    <div class="title mt-4 text-center w-75 mx-auto font-italic">
      Connecting with financial institutions
    </div>
    <br><br><br>
  </div>

  <div *ngIf="view === 'saving-credit-info'">
    <br><br><br>
    <div class="loader-bounce">
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
    </div>
    <div class="title mt-4 text-center w-75 mx-auto font-italic">
      Saving
    </div>
    <br><br><br>
  </div>

  <div *ngIf="view === 'success'">
    <div class="mx-auto text-center d-flex flex-column align-items-center">
      <img src="assets/images/svg/tick.svg" class="mx-auto rounded mb-2 mt-5" alt="" style="max-width: 100px;"/>
      <div class="mt-5 position-relative text-center">
        <h3 class="mx-auto title mb-2">Setup completed!</h3>
      </div>
      <button (click)="close.emit()" class="btn btn-link text-danger mt-2">
        Close
      </button>
    </div>
  </div>
</div>
