import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StoreService } from './store.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
import { AuthResponse } from 'src/app/models/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated = false;
  public merchantID: string = undefined;
  private _merchant: MerchantProfileDetails = null;
  public $merchant = new BehaviorSubject<MerchantProfileDetails>(this._merchant);

  api = 'https://sellbackend.creditclan.com/merchantclan/public/index.php/api';

  constructor(private http: HttpClient, private router: Router, private _store: StoreService) {
  }

  public get merchant(): MerchantProfileDetails {
    return this._merchant;
  }

  public get token(): string {
    return localStorage.getItem('token') || null;
  }

  public get isProfileComplete(): boolean {
    if (!this._merchant) return false;
    const { completion_details: { email_validated, business_details, bank_details, picture } } = this._merchant;
    return [email_validated, business_details, bank_details, picture].every(v => v);
  }

  public get isAccountSetup(): boolean {
    if (!this._merchant) return false;
    return this.merchant.completion_details.bank_details;
  }

  public get isCreditSetup(): boolean {
    if (!this._merchant) return false;
    const { monthly_interest_rate, max_credit_duration, upfront_rate, partnership } = this.merchant;
    return ((!!monthly_interest_rate && !!max_credit_duration && !!upfront_rate) || partnership === 1);
  }

  public get whatsappOnly(): boolean {
    const { whatsapp_no, online } = this.merchant;
    return (whatsapp_no && !online);
  }

  public get canShare(): boolean {
    return this.isAccountSetup && this.isCreditSetup;
  }

  saveToken(token) {
    localStorage.setItem('token', token);
  }

  setMerchant(merchant: MerchantProfileDetails) {
    this._merchant = merchant;
    this.$merchant.next(this._merchant);
  }

  updateMerchant(data) {
    this._merchant = { ...this._merchant, ...data };
    this.$merchant.next(this._merchant);
  }

  authenticate(merchant) {
    this.setMerchant(merchant);
    this.isAuthenticated = true;
  }

  public get authenticated(): boolean {
    return this.isAuthenticated;
  }

  getMerchantFromToken(token) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });
    return this.http.get(`${ this.api }/profile`, { headers });
  }

  async refreshMerchantData() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${ token }` });
    const data = await this.http.get(`${ this.api }/profile`, { headers }).toPromise<any>();
    const { completion_details, ...merchant } = data.data;
    this.updateMerchant({ ...merchant });
  }

  login(
    data: Partial<{
      email: string;
      password: string;
      phone: string;
      interests?: string;
    }>
  ): Promise<AuthResponse> {
    return this.http.post<any>(`${ this.api }/login`, data).toPromise();
  }

  async logout() {
    this.isAuthenticated = false;
    this._merchant = null;
    this.$merchant.next(null);
    this._store.reset();
    localStorage.removeItem('token');
    await this.router.navigate(['/login']);
  }
}
