import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantItems } from 'src/app/models/items';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-search-store-items',
  templateUrl: './search-store-items.component.html',
  styleUrls: ['./search-store-items.component.css']
})
export class SearchStoreItemsComponent implements OnInit {

  query = '';
  searchResults = [];
  searching = false;

  constructor(private _api: ApiService, private router: Router) {
  }

  ngOnInit(): void {
  }

  search() {
    if (this.query) {
      this.searching = true;
      this._api.searchItems({ search: this.query, page_number: '1' }).subscribe(({ data }) => {
        this.searching = false;
        this.searchResults = data;
      }, (err) => {
        console.log({ err });
      });
    }
  }

  viewProduct(item: MerchantItems) {
    this.router.navigate([`/products`], { queryParams: { id: item.id } });
  }

  async shareItem(item) {
    try {
      await window.navigator['share']({
        text: item.name,
        title: 'Product',
        url: item.item_link
      });
    } catch (err) {
      console.log('Error: ' + err);
    }
  }
}
