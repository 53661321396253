import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-view-customer-data',
  templateUrl: './view-customer-data.component.html',
  styleUrls: ['./view-customer-data.component.css']
})
export class ViewCustomerDataComponent implements OnInit {
  @Input() customer: {
    address;
    full_name: string;
    phone_no: string;
    income?: string;
    email: string;
    total_credit: string;
    picture: string;
    company_name: string,
    date_of_birth: string,
    gender: string,
    occupation: string,
    work_address: string,
    net_monthly_income: string,
    city: string,
    state: string,
    credit: boolean
  };
  constructor(public generalservice: GeneralService) { }

  ngOnInit(): void { }
}
