<app-header [currentRoute]="'payment'"></app-header>

<app-default-layout>
  <div class="h-100 my-auto">
    <swiper [breakpoints]="breakpoints">
      <ng-template swiperSlide>
        <div class="card bg-off-white shadow-sm py-5" style="min-height: 400px;">
          <div class="flex-grow-1">
            <div class="fw-bolder fs-1">N0.0</div>
            <div class="fw-medium fs-6 mt-1">Wallet balance</div>
            <button class="btn btn-outline-secondary mt-4">View</button>
          </div>
          <i class="fa fa-wallet fa-5x text-primary ms-auto mt-5"></i>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="card bg-off-white shadow-sm py-5" style="min-height: 400px;">
          <div class="flex-grow-1">
            <div class="fw-bolder fs-1">N0.0</div>
            <div class="fw-medium fs-6 mt-1">Payments</div>
            <button class="btn btn-outline-secondary mt-4">View</button>
          </div>
          <i class="fa fa-money-bill-wave fa-5x text-warning ms-auto mt-5"></i>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="card bg-off-white shadow-sm py-5" style="min-height: 400px;">
          <div class="flex-grow-1">
            <div class="fw-bolder fs-1">N0.0</div>
            <div class="fw-medium fs-6 mt-1">Withdrawals</div>
            <button class="btn btn-outline-secondary mt-4">View</button>
          </div>
          <i class="fa fa-money-bill-wave fa-5x text-warning ms-auto mt-5"></i>
        </div>
      </ng-template>
    </swiper>
  </div>
</app-default-layout>
