<div *ngIf="merchant.partnership == 0">
    <h5 class="title">Let banks fund your transactions</h5>
    <ol class="p-0 ms-3 my-4">
        <li>Your customers buy on credit</li>
        <li>We handle delivery</li>
        <li>Partner pays you item cost</li>
    </ol>
    <div class="form-group form-check d-flex align-items-center mt-4">
        <input type="checkbox" class="form-check-input mt-0" id="exampleCheck1" [(ngModel)]="termsAccepted">
        <label class="form-check-label ms-2" for="exampleCheck1">I accept terms and conditions</label>
    </div>
    <button [disabled]="!termsAccepted || loading" class="btn btn-primary mt-2"
        (click)="enablePartnership()">Continue</button>
    <br><br><br><br><br>
    <img class="w-50 ms-auto" src="assets/images/svg/switches.svg" alt="Illustration" style="max-width: 300px;">
</div>

<div *ngIf="merchant.partnership == 1">
    <h5 class="title mb-4">Your request for bank partnership is pending</h5>
    <button [disabled]="loading" class="btn btn-danger" (click)="disablePartnership()">
        Cancel request
    </button>
    <br><br><br><br><br>
    <img class="w-50 ms-auto" src="assets/images/svg/fast-loading.svg" alt="Illustration" style="max-width: 300px;">
</div>
