import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GeneralService } from 'src/app/services/general.service';
import { timeout } from 'rxjs/operators';
import { TimeoutError } from 'rxjs';
import { IRequest } from 'src/app/models/requests';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-view-a-recent-request',
  templateUrl: './view-a-recent-request.component.html',
  styleUrls: ['./view-a-recent-request.component.css']
})
export class ViewARecentRequestComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() deleteOrder = new EventEmitter();
  @Output() checkoutSuccessful = new EventEmitter();
  @Input() request: IRequest | any;
  loading = true;
  confirmation = false;
  deleting = false;
  view = 'details';
  mobile = window.innerWidth < 768;
  modal = '';
  notifyCustomer = false;
  password = '';
  schedule = null;
  confirmingUpfrontPayment: boolean;
  sendingReminder = false;

  constructor(
    private _api: ApiService,
    public _general: GeneralService,
    public _auth: AuthService,
  ) {
  }

  public get card(): any {
    return JSON.parse(this.request.card) || {};
  }

  get upfront(): string | number {
    return this.request.offer?.upfront || this.request.upfront_amt;
  }

  get partnered() {
    return this._auth.merchant.partnership === 1;
  }

  get not_partnered() {
    return this._auth.merchant.partnership === 0;
  }

  get credit_partnered() {
    return this.request?.credit && this._auth.merchant.partnership === 1;
  }

  get credit_not_partnered() {
    return this.request?.credit && this._auth.merchant.partnership === 0;
  }

  get upfront_paid() {
    return this.request.req_status >= 2 && this.request.upfront_paid === 1;
  }

  ngOnInit(): void {
    if (this.request.credit) this.getSchedule();
  }

  getInterest() {
    const interest = +this.request.cred_plus_upfront - this.getItemsTotal();
    return interest > 0 ? interest : 0;
  }

  async getSchedule() {
    try {
      this.loading = true;
      const { data } = await this._api.fetchASingleSchedule(`${ this.request.id }`);
      this.schedule = data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.schedule = null;
    }
  }

  editingDone() {
    this.modal = '';
  }

  askConfirmation() {
    if (this.request.credit) {
      if (!this.request.card_exists || !this.request.customer_data) {
        this.modal = 'pending';
        return;
      }
      this.modal = 'confirmation';
    } else {
      if (this.request.upfront_paid === 0) {
        this.modal = 'pending';
      } else {
        this.modal = 'confirmation';
      }
    }
  }

  removeOrder() {
    this.deleting = true;
    this._api.deleteOrder(`${ this.request.id }`).subscribe(() => {
      this.close.emit(true);
    }, () => {
      this.modal = '';
      this.deleting = false;
      this._general.notify(
        'error',
        'An error occurred. Please try again.'
      );
    });
  }

  checkout() {
    const btn: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.check_01');
    const prevText = btn[0].textContent;
    btn[0].textContent = 'Processing...';
    btn.forEach(element => (element.disabled = true));
    this._api.completeADeal({
      request_id: this.request.id.toString(),
      password: this.password
    }).pipe(timeout(60000)).subscribe(
      async ({ status }) => {
        if (status) {
          if (this.notifyCustomer) {
            const { message } = await this._api.notifyCustomer({
              request_id: `${ this.request.id }`
            });
            this._general.notify('success', message);
          }
          return this.close.emit(true);
        }
        // this.notifyCustomer ? this.checkoutSuccessful.emit(this.request.id) : null;
        this.password = '';
        btn[0].textContent = prevText;
        btn.forEach(element => (element.disabled = false));
        // this.cancelCheckConfirmationForm();
      },
      err => {
        if (err instanceof TimeoutError) {
          btn.forEach(element => {
            element.innerText = 'Checkout';
            element.disabled = false;
          });
          this._general.notify('error',
            'Could not process this request at this time. Please check your connection and try again'
          );
          this.modal = '';
          return;
        }
        this.modal = '';
        btn.forEach(element => {
          element.disabled = false;
        });
        const { message } = err.error;
        btn[0].textContent = prevText;
        btn.forEach(element => (element.disabled = false));
        this.notifyCustomer = !this.notifyCustomer;
        this._general.notify('error', message);
      }
    );
  }

  async confirmUpfrontPayment() {
    try {
      this.confirmingUpfrontPayment = true;
      await this._api.confirmUpfrontPayment(this.request.id).toPromise();
      this.confirmingUpfrontPayment = false;
      this.update.emit({ ...this.request, upfront_paid: 1 });
    } catch (e) {
      console.log(e);
      this.confirmingUpfrontPayment = false;
    }
  }

  filterOutright(i) {
    return i.sell_on_credit === 0;
  }

  filterCredit(i) {
    return i.sell_on_credit === 1;
  }

  getItemsTotal() {
    return this.request.items.reduce((acc, item) => {
      acc += +item.item_price * +item.quantity;
      return acc;
    }, 0);
  }

  perMonthCreditTotal() {
    return this.request.items.filter(this.filterCredit).reduce((acc, item) => {
      acc += (+item.item_price * +item.quantity) / +this.request.no_of_months;
      return acc;
    }, 0);
  }

  creditUpfrontTotal() {
    return this.request.items.filter(this.filterCredit).reduce((acc, item) => {
      acc += +item.upfront_amount * +item.quantity;
      return acc;
    }, 0);
  }

  copyLink() {
    const link = `${ this._general.clientBaseUrl }/request/${ this.request.slug }`;
    this._general.copyLink(link);
  }

  shareToWhatsapp() {
    if (this.request.phone_no) {
      const link = `https://wa.me/+234${ this.request.phone_no.substring(1) }?text=https://zen-knuth-57e93d.netlify.app/request/${ this.request.id }`;
      window.open(link, '_blank');
    } else {
      this._general.notify('error', 'No phone number provided by customer');
    }
  }

  shareRequestLink() {
    const url = `https://zen-knuth-57e93d.netlify.app/request/${ this.request.id }`;
    if (window.navigator['share']) {
      window.navigator['share']({
        title: 'Request',
        text: `Check status of your request`,
        url
      }).catch(error => console.log('Error sharing', error));
    } else {
      this._general.broadCastSocialShare({
        info: { description: 'Store', link: url }
      });
    }
  }

  handleManualItemsDone(data) {
    this.modal = '';
    this.update.emit(data);
  }

  async sendReminderToCustomer() {
    this.sendingReminder = true;
    try {
      await this._api.sendReminderToCustomer(this.request.id).toPromise();
      this._general.notify('success', 'Reminder sent to customer');
    } catch (e) {
      console.log({ e });
    }
    this.sendingReminder = false;
  }
}
