<app-header currentRoute="pricing"></app-header>

<app-default-layout>
  <section>
    <div class="row justify-content-center">
      <!--      <div class="col-sm-4">-->
      <!--        <div class="card text-center">-->
      <!--          <div class="title">-->
      <!--            <i class="fa fa-paper-plane" aria-hidden="true"></i>-->
      <!--            <h2>Basic</h2>-->
      <!--          </div>-->
      <!--          <div class="price">-->
      <!--            <h4><sup>₦</sup>25</h4>-->
      <!--          </div>-->
      <!--          <div class="option">-->
      <!--            <ul>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> 10 GB Space</li>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> 3 Domain Names</li>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> 20 Email Address</li>-->
      <!--              <li><i class="fa fa-times" aria-hidden="true"></i> Live Support</li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--          <a href="#">Order Now </a>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- END Col one -->
      <!--      <div class="col-sm-4">-->
      <!--        <div class="card text-center">-->
      <!--          <div class="title">-->
      <!--            <i class="fa fa-plane" aria-hidden="true"></i>-->
      <!--            <h2>Standard</h2>-->
      <!--          </div>-->
      <!--          <div class="price">-->
      <!--            <h4><sup>₦</sup>50</h4>-->
      <!--          </div>-->
      <!--          <div class="option">-->
      <!--            <ul>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> 50 GB Space</li>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> 5 Domain Names</li>-->
      <!--              <li><i class="fa fa-check" aria-hidden="true"></i> Unlimited Email Address</li>-->
      <!--              <li><i class="fa fa-times" aria-hidden="true"></i> Live Support</li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--          <a href="#">Order Now </a>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- END Col two -->
      <div class="col-sm-4">
        <div class="card text-center">
          <div class="title">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h2>Premium</h2>
          </div>
          <div class="price">
            <h4><sup>₦</sup>100</h4>
          </div>
          <p class="text-white fs-5">5% discount on whatever we sell for you on credit</p>
          <div class="option">
            <ul>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>
                Get access to working capital
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>
                Quick credit at 0%
              </li>
              <li>
                <i class="fa fa-check" aria-hidden="true"></i>
                Technology support everywhere.
              </li>
            </ul>
          </div>
          <a href="#">Get premium</a>
        </div>
      </div>
      <!-- END Col three -->
    </div>
    <br><br><br><br><br><br>
  </section>
</app-default-layout>
