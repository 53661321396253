import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-repayment-in-request',
  templateUrl: './repayment-in-request.component.html',
  styleUrls: ['./repayment-in-request.component.css']
})
export class RepaymentInRequestComponent implements OnInit, OnChanges {
  @Input() repaymentSchedule: any;
  repayment: any[] = [];
  total = 0;

  constructor(public _general: GeneralService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges) {
    this.total = 0;
    const { currentValue } = change.repaymentSchdule;
    this.repayment = currentValue ? currentValue : [];
    if (this.repayment.length > 0) {
      this.total = this.repayment.reduce((acc, element) => {
        acc += parseFloat(element.amount);
        return acc;
      }, this.total);
    }
  }
}
