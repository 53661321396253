import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take, timeout } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

interface IProduct {
  id: string | number;
  image: string;
  name: string;
  price: string;
  url: string;
  category: string;
  brand: string;
}

@Component({
  selector: 'app-add-products-from-jumia',
  templateUrl: './add-products-from-jumia.component.html',
  styleUrls: ['./add-products-from-jumia.component.scss']
})
export class AddProductsFromJumiaComponent implements OnInit {
  authForm: FormGroup;
  view = 'auth';
  loading = false;
  items: IProduct[] = [];
  displayItems: IProduct[] = [];
  selected = null;
  page = 1;
  search = false;
  query = '';
  error = '';
  confirmed = false;
  countdown = 20;
  private interval = null;

  constructor(
    private fb: FormBuilder,
    private _api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.authForm = this.fb.group({
      shop: ['', Validators.required],
    });
  }

  async authenticate() {
    if (this.authForm.invalid) return;
    await this.fetchProducts(1);
  }

  startCountDown() {
    this.countdown = 20;
    this.interval = setInterval(() => {
      if (this.countdown > 0) this.countdown--;
    }, 1000);
  }

  stopCountdown(cb?) {
    if (this.interval) clearInterval(this.interval);
    this.countdown = 0;
    if (cb) setTimeout(() => cb(), 1000);
  }

  fetchProducts(page: number) {
    if (page === 1) this.view = 'fetching';
    else this.loading = true;
    this.loading = true;
    const shop = this.authForm.value.shop.replace('https://www.jumia.com.ng/', '');
    this.startCountDown();
    this._api.fetchProductsFromJumia(shop, page).pipe(take(1)).pipe(timeout(60000)).subscribe(data => {
      this.stopCountdown(() => {
        this.page = page;
        let items = data.map((item, i) => ({ ...item, id: i }));
        if (page > 1) {
          items = [...this.items, ...items];
        }
        this.items = items;
        this.displayItems = items;
        this.view = 'list';
        this.loading = false;
      });
    }, (err) => {
      this.stopCountdown();
      this.loading = false;
      this.view = 'error';
      this.error = err.error.message || 'Network error';
    });
  }

  loadMore() {
    this.fetchProducts(this.page + 1);
  }

  select(item: IProduct) {
    this.selected = {
      name: item.name.trim().replace(/\n/gi, ''),
      price: item.price.trim().replace(/[₦, ]/gi, ''),
      images: [item.image],
      description: item.name,
      id: item.url
    };
    this.view = 'create-product';
  }

  closeCreateProduct() {
    this.selected = null;
    this.view = 'list';
  }

  handleSearch() {
    const query = this.query;
    if (query.length) {
      this.displayItems = this.items.filter(p => {
        return p.name && p.name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.displayItems = this.items;
    }
  }

  stopSearch() {
    this.query = '';
    this.search = false;
    this.displayItems = this.items;
  }
}
