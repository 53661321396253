import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ApiService } from 'src/app/services/api.service';
import { take, timeout } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NigeriaBanks, NigerianBank } from 'src/app/models/nigerianBanks';
import { AuthService } from 'src/app/services/auth.service';
import { MerchantProfileDetails } from 'src/app/models/merchantProfile';

@Component({
  selector: 'app-profile-mgt',
  templateUrl: './profile-mgt.component.html',
  styleUrls: ['./profile-mgt.component.scss']
})
export class ProfileMgtComponent implements OnInit, OnDestroy {
  @ViewChild('confirmBankDetailsInput', { read: ElementRef, static: false })
  confirmBankDetailsInput: ElementRef;
  @Output() close = new EventEmitter();

  public nigerianBanks: Array<NigerianBank> = NigeriaBanks().data;
  public businessTypes: any[] = [
    { id: 1, value: 'fashion' },
    { id: 2, value: 'cosmetics' },
    { id: 3, value: 'jewelries' },
    { id: 4, value: 'electronics' },
    { id: 5, value: 'computers' },
    { id: 6, value: 'furnitures' },
    { id: 7, value: 'gaming' },
    { id: 8, value: 'fragrances' },
    { id: 9, value: 'groceries' },
    { id: 10, value: 'beers, wines and spirits' }
  ];
  private subscriptions: Subscription[] = [];

  businessDetailsForm: FormGroup;
  accountInfoForm: FormGroup;
  changeEmailForm: FormGroup;
  changePhoneForm: FormGroup;
  otp = '';

  merchant: MerchantProfileDetails = null;

  history: string[] = [];
  loading = false;
  imageFile: File;
  imagePreview = null;

  public get view(): string {
    return this.history[this.history.length - 1] || '';
  }

  constructor(
    private _general: GeneralService,
    private _api: ApiService,
    private fb: FormBuilder,
    private _auth: AuthService
  ) {
    this.subscriptions.push(
      this._auth.$merchant.subscribe({
        next: merchant => {
          this.merchant = merchant;
          const { completion_details: { email_validated, business_details, bank_details, picture } } = merchant;
          if (!bank_details) return this.pushView('welcome');
          if (!business_details) return this.pushView('business-details');
          if (!picture) return this.pushView('picture');
          if (!email_validated) return this.preValidate();
          this.pushView('finished');
        }
      })
    );
  }

  pushView(view: string) {
    if (this.history.includes(view)) {
      this.history = this.history.reduce((acc, v) => {
        if (!acc.includes(view)) acc.push(v);
        return acc;
      }, []);
    } else {
      this.history.push(view);
    }
  }

  popView() {
    this.history.pop();
  }

  preValidate() {
    const { email, phone, completion_details: { email_validated } } = this.merchant;
    if (email && !email_validated) return this.pushView('validate-email');
    if (phone && !email && !email_validated) return this.pushView('validate-phone');
  }

  ngOnInit(): void {
    this.changeEmailForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });

    this.changePhoneForm = this.fb.group({
      phone: ['', [Validators.pattern(/(^[0]\d{10}$)/gi), Validators.required]]
    });

    const { name, address, whatsapp_no, business_type } = this.merchant;

    const whatsapp = `${ whatsapp_no }`.startsWith('234') ? `${ whatsapp_no }`.replace('234', '0') : whatsapp_no;

    this.businessDetailsForm = this.fb.group({
      name: [name, Validators.required],
      address: [address, Validators.required],
      whatsapp_no: [whatsapp || '', [Validators.required, Validators.pattern(/(^[0]\d{10}$)/gi)]],
      business_type: [business_type, Validators.required]
    });

    this.accountInfoForm = this.fb.group({
      bank_code: ['', Validators.required],
      account_number: ['', [Validators.required, Validators.pattern(/(^\d{10}$)/gi)]],
      account_name: [''],
      bank_id: ['']
    });
  }

  changeEmail() {
    if (this.changeEmailForm.invalid) return;
    this.loading = true;
    this._api.changeVerificationToPhoneOrEmail(this.changeEmailForm.value).subscribe(() => {
      this.loading = false;
      this._general.notify('success', 'Email address changed');
      this._auth.setMerchant({ ...this.merchant, email: this.changeEmailForm.value.email });
      this.pushView('validate-email');
    }, err => {
      this._general.notify('error', err.error.message || 'Unable to change phone number. Please try again');
    });
  }

  changePhone() {
    if (this.changePhoneForm.invalid) return;
    this.loading = true;
    this._api.changeVerificationToPhoneOrEmail(this.changePhoneForm.value).subscribe(() => {
      this.loading = false;
      this._general.notify('success', 'Phone number changed');
      this._auth.setMerchant({ ...this.merchant, phone: this.changePhoneForm.value.phone });
      this.pushView('validate-phone');
    }, err => {
      this._general.notify('error', err.error.message || 'Unable to change phone number. Please try again');
    });
  }

  sendVerificationEmail() {
    this.loading = true;
    this._api.sendVerificationEmail().pipe(timeout(60000)).subscribe(() => {
      this.loading = false;
      this.pushView('validate-email-code');
    }, err => this.generalErrorHandler(err));
  }

  sendVerificationSMS() {
    this.loading = true;
    this._api.sendVerificationSMS().pipe(timeout(60000)).subscribe(() => {
      this.loading = false;
      this.pushView('validate-phone-code');
    }, err => this.generalErrorHandler(err));
  }

  submitSMSVerification() {
    if (!this.otp.length) return;
    this.loading = true;
    this._api.sendVerificationCodeFromPhoneToServer({ code: this.otp }).pipe(take(1)).subscribe(async () => {
      this._auth.setMerchant({
        ...this.merchant, completion_details: { ...this.merchant.completion_details, email_validated: true }
      });
      this.loading = false;
    }, err => this.generalErrorHandler(err));
  }

  submitEmailVerification() {
    if (!this.otp.length) return;
    this.loading = true;
    this._api.verifyMerchantEmail(this.otp).pipe(timeout(60000)).subscribe(async () => {
      const res = await this._api.fetchCompletionDetails();
      this._auth.setMerchant({
        ...this.merchant, completion_details: { ...this.merchant.completion_details, ...res }
      });
      this.loading = false;
    }, err => this.generalErrorHandler(err));
  }

  generalErrorHandler(err: HttpErrorResponse) {
    this.loading = false;
    return this._general.notify('error', err.error.message || 'An error occurred, please try again');
  }

  submitBusinessDetailsForm() {
    if (!this.businessDetailsForm.valid) return this._general.notify('error', 'Please fill all fields correctly');
    const payload = { ...this.businessDetailsForm.value };
    payload.whatsapp_no = `234${ payload.whatsapp_no.substring(1) }`;
    this.businessDetailsForm.disable();
    this.loading = true;
    this._api.updateProfileData(payload).pipe(timeout(60000)).subscribe(async () => {
      this._auth.setMerchant({
        ...this.merchant,
        ...payload,
        completion_details: { ...this.merchant.completion_details, business_details: true }
      });
      this.businessDetailsForm.disable();
      this.loading = false;
    }, err => {
      this.businessDetailsForm.enable();
      this.generalErrorHandler(err);
    });
  }

  validateAccount() {
    const { account_number, bank_code } = this.accountInfoForm.value;
    const payload = { account_number, bank_code };
    this.loading = true;
    this._api.getAccountDetails(payload).pipe(take(1)).subscribe((val: any) => {
      if (val.status) {
        const { account_name } = val.data;
        this.accountInfoForm.get('account_name').setValue(account_name);
        const bank_id = this.nigerianBanks.find(bank => bank.bank_code === this.accountInfoForm.value.bank_code).id;
        this.accountInfoForm.get('bank_id').setValue(bank_id);
      } else {
        this._general.notify('error', val.message || 'Couldn\'t validate account, please check and try again');
      }
      this.loading = false;
    }, () => {
      this.loading = false;
      this._general.notify('error', 'Could not confirm you bank details. Please check that you entered the right details');
    });
  }

  submitAccountInfoForm() {
    const payload = { ...this.accountInfoForm.value };
    this.loading = true;
    this._api.updateBankInfo(payload).subscribe(async () => {
      this._auth.setMerchant({
        ...this.merchant,
        ...payload,
        completion_details: { ...this.merchant.completion_details, bank_details: true }
      });
      this.loading = false;
    }, err => this.generalErrorHandler(err));
  }

  handleImageChange(event) {
    const file = event.target.files[0];
    if (file.size > 5000000) this._general.notify('error', 'Please upload pictures less than 5.0MB');
    this.imageFile = file;
    let reader: FileReader;
    if (FileReader) {
      reader = new FileReader();
      reader.onload = e => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  submitPicture() {
    if (!this.imagePreview) return this._general.notify('error', 'You have not added a picture!');
    const fd = new FormData();
    fd.append('picture', this.imageFile);
    this.loading = true;
    this._api.updateProfilePicture(fd).pipe(timeout(60000)).subscribe(async () => {
      this._auth.setMerchant({
        ...this.merchant,
        picture: this.imagePreview,
        completion_details: { ...this.merchant.completion_details, picture: true }
      });
      this.loading = false;
    }, err => {
      this.loading = false;
      this._general.notify('error', err.error.message || 'An error occurred, please try again later.');
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
