<app-header [currentRoute]="'more'"></app-header>

<div class="nav-margin">
  <div class="mobile-menu-wrapper p-0">
    <nav class="mobile-nav">
      <ul class="mobile-menu">
        <li class="border-bottom">
          <a (click)="launchModalIfProfileComplete('business-loan')">
            <i class="icon fa fa-money-bill text-success"></i>Business loan
          </a>
        </li>
        <li class="border-bottom">
          <a (click)="_general.navigateTo('/repayments')">
            <i class="icon fa fa-credit-card text-primary"></i>Credit
          </a>
        </li>
        <li class="border-bottom">
          <a (click)="_general.navigateTo('/sales')">
            <i class="icon fa fa-luggage-cart text-danger"></i>Sales
          </a>
        </li>
        <li class="border-bottom">
          <a (click)="_general.navigateTo('/customers')">
            <i class="icon fa fa-users text-info"></i>Customers
          </a>
        </li>
        <li class="border-bottom">
          <a (click)="_general.navigateTo('/repayment-reports')">
            <i class="icon far fa-clipboard text-warning"></i>Reports
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<app-dialog *ngIf="modal === 'business-loan'" (close)="modal = ''" [close-button]="false" title="Business Loan" #dialogBL>
  <app-business-loan (close)="dialogBL.dismiss()"></app-business-loan>
</app-dialog>

<app-bottom-nav></app-bottom-nav>
