import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { ILoanStages } from '../../models/api';

@Component({
  selector: 'app-business-loan',
  templateUrl: './business-loan.component.html',
  styleUrls: ['./business-loan.component.scss']
})
export class BusinessLoanComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() selected: any = {};

  modal = '';
  quickLoans = [
    { amount: 50000, tenor: 3 },
    { amount: 100000, tenor: 0 },
    { amount: 250000, tenor: 0 },
    { amount: 500000, tenor: 0 },
    { amount: 1000000, tenor: 0 },
    { amount: 5000000, tenor: 0 },
    { amount: 10000000, tenor: 0 },
    { amount: 25000000, tenor: 0 },
    { amount: 50000000, tenor: 0 },
  ];
  view = '';
  loan = {
    amount: 5000,
    tenor: 1
  };
  loading = false;
  iframe: any;
  stages: ILoanStages;
  deleting: boolean;

  constructor(
    public _auth: AuthService,
    private _api: ApiService,
    private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
    const { loan, account_number, account_name, bank_id } = this._auth.merchant;
    if (!account_number || !account_name || !bank_id) {
      this._general.showPendingSetup();
      return this.close.emit();
    }
    if (loan) {
      this.initLoan();
    } else {
      this.view = 'quickloans';
    }
    if (this.selected.amount && this.selected.tenor) {
      this.selectQuickLoan(this.selected);
    }
  }

  async initLoan() {
    this.view = 'loading';
    try {
      this.stages = await this._api.initAnalysis(this._auth.merchant.loan.amount).toPromise();
      const completed = this.stages.required_analysis.every(a => this._auth.merchant.loan.analysis_completed.includes(a));
      if (completed) {
        this.view = 'pending';
      } else {
        this.view = 'pending';
      }
    } catch (e) {
      console.log(e);
    }
  }

  setView(view) {
    this.view = view;
  }

  selectQuickLoan(loan) {
    if (loan.amount && loan.tenor) {
      this.loan = loan;
      this.setView('confirm');
    } else {
      this.setView('loan-inputs');
    }
  }

  handleLoanFromInput() {
    this.setView('confirm');
  }

  async apply() {
    try {
      this.setView('applying');
      const { amount, tenor } = this.loan;
      const res = await this._api.sendLoanRequest({ amount, duration: tenor }).toPromise<any>();
      this._auth.updateMerchant({ loan: { ...res.data.request, analysis_completed: [] } });
      this.setView('partners');
    } catch (e) {
      this.setView('confirm');
    }
  }

  async cancelLoan() {
    this.deleting = true;
    try {
      const { id, creditclan_request_id } = this._auth.merchant.loan;
      await this._api.cancelLoan(id).toPromise();
      if (creditclan_request_id) {
        await this._api.cancelLoanCC(this._auth.merchant.loan.creditclan_request_id).toPromise();
      }
      this._auth.updateMerchant({ loan: null });
      this.setView('quickloans');
    } catch (e) {
      console.log({ e });
    }
    this.deleting = false;
    this.modal = '';
  }
}
