<div class="row">
  <div class="col-12 col-md-8 mx-auto">
    <div class="product-single-details">
      <h1 class="product-title mt-2 mb-2">Repayment Schedule</h1>

      <div class="mt-3">
        <table class="table table-bordered">
          <thead style="color: #fff;">
            <tr class="bg-primary">
              <th class="py-2" scope="col">#</th>
              <th class="py-2" scope="col">Date</th>
              <th class="py-2" scope="col">S/N</th>
              <th class="py-2" scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let repaymentSchedule of repayment; index as i">
              <th class="py-2" scope="row">{{ i + 1 }}</th>
              <td class="py-2">{{ repaymentSchedule?.date | date: "mediumDate" }}</td>
              <td class="py-2">{{ i + 1 }} of {{ repayment.length }}</td>
              <td class="py-2">
                <span><strong class="currency">
                    {{ repaymentSchedule?.amount | currency:'NGN':'₦':'0.0' }}
                  </strong></span>
              </td>
            </tr>

            <tr>
              <th class="py-2" scope="row">#</th>
              <td colspan="2" class="text-center py-2">
                <strong style="text-transform: uppercase;">Total</strong>
              </td>
              <td class="py-2">
                <span>
                  <strong class="currency">{{ total | currency:'NGN':'₦':'0.0' }}</strong>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button class="d-none btn btn-md btn-primary mt-3 mb-3">
        checkout
      </button>
    </div>
    <!-- End .product-single-details -->
  </div>
  <!-- End .col-lg-5 -->
</div>
