<ng-container *ngIf="stages">
  <p class="mb-4">The following analysis is required to determine your eligibility for this loan</p>
  <div *ngIf="stages.required_analysis.includes('data')" (click)="launchDataCollectionWidget()"
       class="card py-4 px-4 d-flex align-items-center flex-row cursor-pointer mb-3"
       [ngClass]="{ disabled: isCompleted('data')}">
    <i *ngIf="!isCompleted('data')" class="fa fa-circle text-warning me-4" style="font-size: 1.2rem"></i>
    <i *ngIf="isCompleted('data')" class="fa fa-check text-success me-4" style="font-size: 1.2rem"></i>
    <div class="flex-grow-1">
      <div class="title mb-0 fw-bold">Data analysis</div>
      <small>Let us get to know you</small>
    </div>
    <i class="fa fa-circle-notch fa-spin ms-2" *ngIf="loading == 'data'"></i>
  </div>
  <div *ngIf="stages.required_analysis.includes('repayment')" (click)="launchRepaymentWidget()"
       class="card py-4 px-4 d-flex align-items-center flex-row cursor-pointer mb-3"
       [ngClass]="{ disabled: isCompleted('repayment')}">
    <i *ngIf="!isCompleted('repayment')" class="fa fa-circle text-warning me-4" style="font-size: 1.2rem"></i>
    <i *ngIf="isCompleted('repayment')" class="fa fa-check text-success me-4" style="font-size: 1.2rem"></i>
    <div class="flex-grow-1">
      <div class="title mb-0 fw-bold">Setup repayment</div>
      <small>Provide card for repayment</small>
    </div>
    <i class="fa fa-circle-notch fa-spin ms-2" *ngIf="loading == 'repayment'"></i>
  </div>
</ng-container>
